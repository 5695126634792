import {Button} from 'primereact/button';
import {useDataTable} from '@iamsoftware/react-hooks';

import {SystemLogsService as Service} from './SystemLogsService';

export const SystemLogs = () => {

  const dataKey = '_id';

  const {render: renderDataTable, refreshLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'key', header: 'Key', width: 250, matchMode: 'contains'},
      {field: 'message', header: 'Message', minWidth: 250, matchMode: 'contains'},
      {field: 'count', header: 'Count', width: 170, dataType: 'number'},
      {field: 'last_updated_date', header: 'Date', width: 170, dataType: 'date-time'}
    ],
    indexColumnWidth: 45,
    getList: params => {
      return Service.getList(params);
    }
  });

  const deleteMany = () => {
    Service.deleteMany().then(() => {
      refreshLazyData();
    });
  }

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-4 flex gap-2">
          <Button label="Refresh" icon="pi pi-refresh" className="p-button-sm" onClick={refreshLazyData}/>
          <Button label="Xóa tất cả" icon="pi pi-trash" className="p-button-sm" onClick={deleteMany} severity="danger"/>
        </div>
      </div>
      {renderDataTable()}
    </div>
  );
}