import {EntityService} from '../../../service/EntityService';

export class SyncInvoicesService extends EntityService {

  static entity = 'system/external_account_sync';

  static setDuration(_id: string, type: string, duration: number): Promise<any> {
    return this.axios.patch(`system/external_account/${_id}/duration/${type}`, {duration}).then(res => res.data);
  }

}
