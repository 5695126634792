import {useEffect, useState, Fragment} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment/moment';

import {Dropdown} from 'primereact/dropdown';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {DashboardService} from './DashboardService';
import {GmailService} from '../hop-thu/GmailService';
import {InvoicesService} from '../hoa-don/InvoicesService';
import {ExternalAccountsService} from '../tai-khoan-thue/ExternalAccountsService';
import {UsersService} from '../../service/UsersService';

import {TaxCodeStatus} from '../bao-cao/tinh-trang-doanh-nghiep/TaxCodeStatus';

export default function Dashboard() {

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);
  const [unAuth, setUnAuth] = useState(0);

  const [stats, setStats] = useState(null);
  const [recentAdjust, setRecentAdjust] = useState([]);

  const [invoiceQuantity, setInvoiceQuantity] = useState(0);
  const [used, setUsed] = useState(0);
  const [thruDate, setThruDate] = useState(null);
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [peakMessage, setPeakMessage] = useState(null);

  useEffect(() => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      const _accounts = [];
      let _unAuth = 0;
      items.forEach(item => {
        _accounts.push({value: item._id, label: `${item.username} - ${item.profile?.name || ''}`});
        if (!item.token) {
          _unAuth++;
        }
      });
      setUnAuth(_unAuth);
      if (_accounts.length) {
        setAccount(_accounts[0].value);
      }
      setAccounts(_accounts);
    });
    UsersService.getLicense().then(data => {
      if (data?.thru_date) {
        setThruDate(data.thru_date);
        setDaysRemaining(moment(data.thru_date).diff(moment(), 'days'));
      }
      setInvoiceQuantity(data?.invoice_quantity);
    });
    UsersService.getInUse().then(data => {
      setUsed(data.invoice);
    });
  }, []);

  useEffect(() => {
    if (account) {
      DashboardService.getStats(account).then(data => {
        setStats(data);
      });
      DashboardService.getRecentAdjust(account).then(({items}) => {
        setRecentAdjust(items);
      });
    }
  }, [account]);

  useEffect(() => {
    const peakMessages = [];
    if (used > invoiceQuantity) {
      peakMessages.push('số lượng');
    }
    if (daysRemaining < 0) {
      peakMessages.push('thời gian');
    }
    if (peakMessages.length) {
      setPeakMessage(peakMessages.join(' và '));
    } else {
      setPeakMessage(null);
    }
  }, [invoiceQuantity, used, daysRemaining]);

  useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('state')) {
        try {
          const state = JSON.parse(params.get('state'));
          switch (state.action) {
            case 'OAuthGmailConsent':
              GmailService.makeToken(state.id, params.get('code')).then(() => {
                window.location.href = '/#/hop-thu/tai-khoan';
              });
              break;
          }
        } catch (e) {
        }
      }
    }
  }, [window.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="grid">
      <div className="field col-6 p-fluid mb-0">
        <label htmlFor="account">Tài khoản thuế
          <Fragment>
            {unAuth > 0 && <Link to="/tai-khoan-thue" className="alert-danger ml-2">(Chưa kết nối {unAuth}/{accounts?.length} tài khoản)</Link>}
            {unAuth === 0 && <Link to="/tai-khoan-thue" className="alert-success ml-2">(Chưa kết nối {unAuth}/{accounts?.length} tài khoản)</Link>}
          </Fragment>
        </label>
        <Dropdown value={account} options={accounts} onChange={e => setAccount(e.value)} filter={true}/>
      </div>
      <div className="col-6"></div>

      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0 bg-blue-100 overview-box overview-box-blue">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-900 font-medium mb-3">Hóa đơn bán ra</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.sold)}</div>
            </div>
            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
              <i className="pi pi-shopping-cart text-blue-500 text-xl"/>
            </div>
          </div>
          <span className="text-green-500 font-medium">3 tháng </span>
          <span className="text-500">gần đây</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0 bg-cyan-100 overview-box overview-box-cyan">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-900 font-medium mb-3">Hóa đơn mua vào</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.purchase)}</div>
            </div>
            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
              <i className="pi pi-shopping-cart text-cyan-500 text-xl"/>
            </div>
          </div>
          <span className="text-green-500 font-medium">3 tháng </span>
          <span className="text-500">gần đây</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0 bg-orange-100 overview-box overview-box-orange">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-900 font-medium mb-3">Hóa đơn mua vào bị điều chỉnh</span>
              <div className="text-900 font-medium text-xl">{FormatDisplay.number(stats?.adjust)}</div>
            </div>
            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
              <i className="pi pi-calendar-minus text-orange-500 text-xl"/>
            </div>
          </div>
          <span className="text-green-500 font-medium">3 tháng </span>
          <span className="text-500">gần đây</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3" style={{position: 'relative'}}>
        {peakMessage && <div style={{position: 'absolute', top: '-3.5rem', color: 'red'}}>
					Quý khách đã hết hạn bản quyền <b>{peakMessage}</b>.<br/>Vui lòng liên hệ với Minvoice qua số điện thoại <b>0901.80.16.18</b><br/>hoặc qua chức năng hỗ trợ kỹ thuật ngay phía dưới.
				</div>}
        <div className="card mb-0 bg-purple-100 overview-box overview-box-purple">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-900 font-medium uppercase mb-3">Thông tin bản quyền</span>
              <div className="text-900 font-medium text-xl"><span className="font-normal text-base">Đã sử dụng </span>{FormatDisplay.number(used)} / {FormatDisplay.number(invoiceQuantity)}</div>
            </div>
            <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
              <i className="pi pi-calendar-times text-purple-500 text-xl"/>
            </div>
          </div>
          <span className="text-500">Ngày hết hạn </span>
          <span className={`${daysRemaining > 0 ? 'text-green-500' : 'text-orange-500'} font-medium`}>{FormatDisplay.date(thruDate)} (Còn lại {FormatDisplay.number(daysRemaining)} ngày).</span>
        </div>
      </div>

      <div className="col-12 xl:col-6">
        <div className="card">
          <h5>Hóa đơn bị thay đổi bởi Người bán - Nhà cung cấp</h5>
          <DataTable value={recentAdjust} responsiveLayout="scroll" showGridlines size="small">
            <Column field="header.tthai" header="Trạng thái" body={rowData => {
              let tthai = rowData.header.tthai;
              InvoicesService.tthais.forEach(t => {
                if (t.value === tthai) {
                  tthai = t.label;
                }
              });
              return tthai;
            }}/>
            <Column field="header.khhdon" header="Ký hiệu" body={rowData => `${rowData.header.khmshdon}${rowData.header.khhdon}`} style={{width: '6rem'}}/>
            <Column field="header.shdon" header="Số hóa đơn" body={rowData => rowData.header.shdon} style={{width: '6.6rem'}}/>
            <Column field="header.tdlap" header="Ngày lập" body={rowData => FormatDisplay.date(rowData.header.tdlap)} style={{width: '6.8rem'}}/>
            <Column field="header.nbmst" header="Thông tin người bán" body={rowData => <div>
              <p className="mb-0">MST: {rowData.header.nbmst}</p>
              <p className="mb-0">{(rowData.header.nbten || rowData.header.nbtnban)}</p>
            </div>}/>
          </DataTable>
        </div>
      </div>

      <div className="col-12 xl:col-6">
        <div className="card">
          <h5>Trạng thái doanh nghiệp</h5>
          <TaxCodeStatus account={account}/>
        </div>
      </div>

    </div>
  );
}