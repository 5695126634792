import {EntityService} from '../../../service/EntityService';

export class SystemLogsService extends EntityService {

  static entity = 'system/logs';

  static deleteMany(): Promise<any> {
    return this.axios.delete(`${this.entity}`).then(res => res.data);
  }

}
