import {Route, Routes} from 'react-router-dom';

import {List} from './List';
import {Sync} from './Sync';

export const TaiKhoanThue = ({me}) => {
  return (
    <Routes>
      <Route path="" element={<List me={me}/>}/>
      <Route path="dong-bo" element={<Sync/>}/>
    </Routes>
  );
}