import {useEffect, useState} from 'react';

import {Dropdown} from 'primereact/dropdown';
import {useDataTable} from '@iamsoftware/react-hooks';

import {EmailService as Service} from '../EmailService';

export const DongBo = () => {

  const dataKey = '_id';

  const [requiredParams, setRequiredParams] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    Service.getList('{}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        _accounts.push({value: item._id, label: item.name});
      });
      if (_accounts.length) {
        setAccount(_accounts[0].value);
      }
      setAccounts(_accounts);
    });
  }, []);

  useEffect(() => {
    if (account) {
      const _requiredParams = {
        account: {value: account, matchMode: 'ObjectId'}
      }
      setRequiredParams(_requiredParams);
    }
  }, [account]);

  const {render: renderDataTable} = useDataTable({
    dataKey,
    columns: [
      {field: 'created_date', header: 'Thời gian', width: 160, dataType: 'date-time'},
      {field: 'q', header: 'Query', minWidth: 160},
      {field: 'status', header: 'Trạng thái', width: 140, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', minWidth: 140, matchMode: 'contains'},
      {field: 'total', header: 'Tổng số hóa đơn', width: 160, dataType: 'number'},
      {field: 'created', header: 'Hóa đơn mới', width: 160, dataType: 'number'}
    ],
    indexColumnWidth: 45,
    getList: params => {
      return Service.getSync(params);
    },
    requiredParams
  });

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid p-fluid">
        <div className="col-12">
          <Dropdown value={account} options={accounts} onChange={e => setAccount(e.value)} filter={true}/>
        </div>
      </div>
      {renderDataTable()}
    </div>
  );
}