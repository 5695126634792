import {Fragment, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {FormatDisplay} from '@iamsoftware/react-hooks';

import {InvoiceInfoService} from './InvoiceInfoService';
import {InvoicesService} from '../../app/hoa-don/InvoicesService';

import {TaxCodeInfo} from '../../shared/components/TaxCodeInfo';

import {InvoiceStatus} from './InvoiceStatus';
import {Button} from 'primereact/button';

export default function InvoiceInfo({from}) {

  const {search} = useLocation();

  const [invoiceId, setInvoiceId] = useState(null);
  const [invoiceState, setInvoiceState] = useState(null);
  const [invoiceDoc, setInvoiceDoc] = useState(null);
  const [invoiceInfo, setInvoiceInfo] = useState(null);
  const [invoiceWarning, setInvoiceWarning] = useState(null);

  const [displayStatus, setDisplayStatus] = useState(false);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);

      let promise;
      if (from === 'xml') {
        promise = InvoiceInfoService.getXmlInfo(params.get('id'));
      } else {
        promise = InvoiceInfoService.getInvoiceInfo(params.get('id'));
      }

      setInvoiceId(null);
      promise.then(data => {
        let _invoiceInfo;
        if (data.invoice) {
          _invoiceInfo = data.invoice.header;
          if (data.invoice.detail && data.invoice.detail.id) {
            _invoiceInfo = data.invoice.detail;
            if (data.invoice.header.tthai) {
              _invoiceInfo.tthai = data.invoice.header.tthai;
            }
          }
          setInvoiceWarning(data.invoice.warning);
          setInvoiceDoc(data.invoice);
          setInvoiceId(data.invoice._id);
        } else {
          _invoiceInfo = data.inspected_data;
          setInvoiceWarning(data.warning);
          setInvoiceDoc(data);
        }

        switch (_invoiceInfo.tthai) {
          case 1:
            _invoiceInfo.tthai_class = 'p-message-success';
            break;
          case 6:
            _invoiceInfo.tthai_class = 'p-message-error';
            break;
          default:
            _invoiceInfo.tthai_class = 'p-message-warn';
        }
        InvoicesService.tthais.forEach(tthai => {
          if (tthai.value === _invoiceInfo.tthai) {
            _invoiceInfo.tthai_text = tthai.label;
          }
        });

        try {
          const nbcks = JSON.parse(_invoiceInfo.nbcks);

          const cks = nbcks.Subject;
          const values = cks.trim()
            .replace(/[^,\s=]+=/, "")
            .split(/,*\s*[^,\s=]+=/);
          const keys = cks.match(/[^,\s=]+(?==)/g);
          let newSubject = keys.reduce((prevValue, key, index) => {
            return {...prevValue, [key]: values[index]};
          }, {});

          _invoiceInfo.nbcks_object = nbcks;
          _invoiceInfo.nbcks_SigningBy = newSubject.CN;
        } catch (e) {
        }

        setInvoiceInfo(_invoiceInfo);
      });
    }
  }, [from, search, invoiceState]);

  return (
    <div className="invoice-info p-3">
      {!!invoiceInfo &&
				<div className="grid invoice-info">
					<div className="col-8">
						<div className="grid">
							<div className="col-12">
								<h5 className="owner-color" style={{backgroundColor: '#cccccc36', borderRadius: '10px'}}>
									<div className="bg-icon mr-2"><i className="pi pi-shopping-bag"></i></div>
									ĐƠN VỊ BÁN HÀNG
								</h5>
								<div className="grid">
									<div className="col-fixed" style={{width: '90px'}}>
										Đơn vị:
									</div>
									<div className="col" dangerouslySetInnerHTML={{__html: invoiceInfo.nbten || invoiceInfo.nbtnban}}></div>
								</div>
								<div className="grid">
									<div className="col-fixed" style={{width: '90px'}}>
										Mã số thuế:
									</div>
									<div className="col" dangerouslySetInnerHTML={{__html: invoiceInfo.nbmst}}></div>
								</div>
								<div className="grid">
									<div className="col-fixed" style={{width: '90px'}}>
										Địa chỉ:
									</div>
									<div className="col" dangerouslySetInnerHTML={{__html: invoiceInfo.nbdchi}}></div>
								</div>
							</div>
							<div className="col-12">
								<h5 className="owner-color" style={{backgroundColor: '#cccccc36', borderRadius: '10px'}}>
									<div className="bg-icon mr-2"><i className="pi pi-shopping-cart"></i></div>
									ĐƠN VỊ MUA HÀNG
								</h5>
								<div className="grid">
									<div className="col-fixed" style={{width: '90px'}}>
										Đơn vị:
									</div>
									<div className="col" dangerouslySetInnerHTML={{__html: invoiceInfo.nmten}}></div>
								</div>
								<div className="grid">
									<div className="col-fixed" style={{width: '90px'}}>
										Mã số thuế:
									</div>
									<div className="col" dangerouslySetInnerHTML={{__html: invoiceInfo.nmmst}}></div>
								</div>
								<div className="grid">
									<div className="col-fixed" style={{width: '90px'}}>
										Địa chỉ:
									</div>
									<div className="col" dangerouslySetInnerHTML={{__html: invoiceInfo.nmdchi}}></div>
								</div>
							</div>
							<div className="col-12">
								<h5 className="owner-color" style={{backgroundColor: '#cccccc36', borderRadius: '10px'}}>
									<div className="bg-icon mr-2"><i className="pi pi-money-bill"></i></div>
									THÔNG TIN THANH TOÁN
								</h5>
                {invoiceInfo.tgtcthue > 0 && <div className="grid">
									<div className="col-fixed" style={{width: '220px'}}>
										Tổng tiền chưa thuế:
									</div>
									<div className="col-fixed text-right" style={{width: '140px'}}>
                    {FormatDisplay.number(invoiceInfo.tgtcthue)}
									</div>
								</div>}
                {invoiceInfo.tgtthue > 0 && <div className="grid">
									<div className="col-fixed" style={{width: '220px'}}>
										Tổng tiền thuế:
									</div>
									<div className="col-fixed text-right" style={{width: '140px'}}>
                    {FormatDisplay.number(invoiceInfo.tgtthue)}
									</div>
								</div>}
                {invoiceInfo.tgtphi > 0 && <div className="grid">
									<div className="col-fixed" style={{width: '220px'}}>
										Tổng tiền phí:
									</div>
									<div className="col-fixed text-right" style={{width: '140px'}}>
                    {FormatDisplay.number(invoiceInfo.tgtphi)}
									</div>
								</div>}
                {invoiceInfo.ttcktmai > 0 && <div className="grid">
									<div className="col-fixed" style={{width: '220px'}}>
										Tổng tiền chiết khấu thương mại:
									</div>
									<div className="col-fixed text-right" style={{width: '140px'}}>
                    {FormatDisplay.number(invoiceInfo.ttcktmai)}
									</div>
								</div>}
                {invoiceInfo.tgtttbso > 0 && <div className="grid">
									<div className="col-fixed" style={{width: '220px'}}>
										Tổng tiền thanh toán:
									</div>
									<div className="col-fixed text-right" style={{width: '140px'}}>
										<b>{FormatDisplay.number(invoiceInfo.tgtttbso)}</b>
									</div>
								</div>}
							</div>
						</div>

					</div>
					<div className="col-4">
						<div className={`p-inline-message p-component p-inline-message-${invoiceWarning?.status === 'Cảnh báo' ? 'error' : 'success'} text-center`} style={{display: 'block'}}>
							<span className="p-inline-message-text text-2xl">{invoiceWarning?.status}</span>
						</div>
						<p className="mb-0">Tại thời điểm tra cứu ngày <b>{FormatDisplay.date(invoiceWarning?.date)}</b></p>
						<div className="card">
							<p><b>Mẫu số: {invoiceInfo.khmshdon}</b></p>
							<p><b>Ký hiệu: {invoiceInfo.khhdon}</b></p>
							<p><b>Số hóa đơn: {invoiceInfo.shdon}</b></p>
							<p><b>Ngày hóa đơn: {FormatDisplay.date(invoiceInfo.tdlap)}</b></p>
							<p><b>Ngày ký: {FormatDisplay.date(invoiceInfo.nky)}</b></p>
							<p><b>Ngày cấp mã: {FormatDisplay.date(invoiceInfo.ncma)}</b></p>
						</div>
            {invoiceInfo.tthai_text && <div className={`p-message p-component ${invoiceInfo.tthai_class} p-message-enter-done`}>
							<div className="p-message-wrapper">
								<span className="p-message-detail text-xl">{invoiceInfo.tthai_text}</span>
							</div>
						</div>}
						<Button icon="pi pi-search" label="Tra cứu trạng thái hóa đơn trên CQT" className="p-button-outlined" onClick={() => setDisplayStatus(true)}/>
					</div>
					<div className="col-12">
						<div className="card">
							<div className="grid">
								<div className="col-12">
									<h5 className="owner-color mb-1">KẾT QUẢ KIỂM TRA HÓA ĐƠN</h5>
								</div>

                {!(!invoiceInfo.nky && invoiceInfo.khhdon && invoiceInfo.khhdon[0] === 'K') && <Fragment>
                  {!invoiceWarning.ignone_nbcks && <Fragment>
										<div className="col-12">
											<b>Ngày ký hóa đơn</b>
										</div>
										<div className="col-12">
                      {!invoiceWarning.nkhdhle &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Ngày ký hóa đơn hợp lệ (Tại thời điểm ký hóa đơn chữ ký số còn hiệu lực)
												</p>
                      }
                      {!!invoiceWarning.nkhdhle &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Ngày ký hóa đơn không hợp lệ (Tại thời điểm ký hóa đơn chữ ký số không còn hiệu lực)
												</p>
                      }

                      {!invoiceWarning.nkhdtnlap &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Ngày ký hóa đơn trùng ngày lập ({FormatDisplay.date(invoiceInfo.nky)})
												</p>
                      }
                      {!!invoiceWarning.nkhdtnlap &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Ngày ký hóa đơn không trùng ngày lập (Ngày ký: {FormatDisplay.date(invoiceInfo.nky)}, Ngày lập: {FormatDisplay.date(invoiceInfo.tdlap)})
												</p>
                      }
										</div>
										<div className="col-12">
											<b>Thông tin chữ ký số</b>
										</div>
										<div className="col-12">
                      {!!invoiceInfo.nbcks_SigningBy &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Ký bởi: <b>{invoiceInfo.nbcks_SigningBy}</b>
												</p>
                      }
                      {!invoiceInfo.nbcks_SigningBy &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Ký bởi: <b>{invoiceInfo.nbcks_SigningBy}</b>
												</p>
                      }

                      {!!invoiceInfo.nbcks_object?.Issuer &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Nhà cung cấp: {invoiceInfo.nbcks_object?.Issuer}
												</p>
                      }
                      {!invoiceInfo.nbcks_object?.Issuer &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Nhà cung cấp: {invoiceInfo.nbcks_object?.Issuer}
												</p>
                      }

                      {!!(invoiceInfo.nbcks_object?.NotBefore && invoiceInfo.nbcks_object?.NotAfter) &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Hiệu lực: Từ ngày <b>{FormatDisplay.date(invoiceInfo.nbcks_object?.NotBefore)}</b> đến ngày <b>{FormatDisplay.date(invoiceInfo.nbcks_object?.NotAfter)}</b>
												</p>
                      }
                      {!(invoiceInfo.nbcks_object?.NotBefore && invoiceInfo.nbcks_object?.NotAfter) &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Hiệu lực: Từ ngày <b>{FormatDisplay.date(invoiceInfo.nbcks_object?.NotBefore)}</b> đến ngày <b>{FormatDisplay.date(invoiceInfo.nbcks_object?.NotAfter)}</b>
												</p>
                      }

                      {!!invoiceInfo.nbcks_object?.Issuer &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Serial number: {invoiceInfo.nbcks_object?.SerialNumber}
												</p>
                      }
                      {!invoiceInfo.nbcks_object?.SerialNumber &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Serial number: {invoiceInfo.nbcks_object?.SerialNumber}
												</p>
                      }
										</div>
										<div className="col-12">
											<b>Tình trạng file hóa đơn</b>
										</div>
										<div className="col-12">
                      {!invoiceWarning.xmlnven &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Cấu trúc file XML còn nguyên vẹn (Chưa chỉnh sửa sau khi ký điện tử)
												</p>
                      }
                      {!!invoiceWarning.xmlnven &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Cấu trúc file XML không còn nguyên vẹn (Đã chỉnh sửa sau khi ký điện tử)
												</p>
                      }
										</div>
									</Fragment>}
									<div className="col-12">
										<b>Tình trạng cấp mã của Cơ quan thuế</b>
									</div>
                  {(invoiceInfo.khhdon && invoiceInfo.khhdon[0]) === 'C' &&
										<div className="col-12">
                      {!invoiceWarning.cqtcma && (!invoiceWarning.is_sco || invoiceInfo.ncma) &&
												<Fragment>
													<p className="mb-2">
														<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
														Ngày cơ quan thuế cấp mã: <b>{FormatDisplay.date(invoiceInfo.ncma)}</b>
													</p>
                          {!invoiceWarning.cqtcmatnky &&
														<p className="mb-2">
															<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
															Ngày cấp mã hóa đơn trùng ngày ký ({FormatDisplay.date(invoiceInfo.nky)})
														</p>
                          }
                          {!!invoiceWarning.cqtcmatnky &&
														<p className="mb-2">
															<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
															Ngày cấp mã hóa đơn không trùng ngày ký (Ngày cấp mã: {FormatDisplay.date(invoiceInfo.ncma)}, Ngày ký: {FormatDisplay.date(invoiceInfo.nky)})
														</p>
                          }
												</Fragment>
                      }
                      {!!invoiceWarning.cqtcma &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Ngày cơ quan thuế ký cấp mã: không tồn tại
												</p>
                      }
											<p className="mb-2">
												<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
												Người bán có MST {invoiceInfo.nbmst} đã đăng ký sử dụng hóa đơn điện tử theo Nghị định 123/2020/NĐ-CP
											</p>
                      {!invoiceWarning.dcma &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Hóa đơn đã được cấp mã của Cơ quan thuế (Mã CQT: {invoiceInfo.mhdon})
												</p>
                      }
                      {!!invoiceWarning.dcma &&
												<p className="mb-2">
													<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
													Hóa đơn chưa được cấp mã của Cơ quan thuế.
												</p>
                      }
										</div>
                  }
                  {(invoiceInfo.khhdon && invoiceInfo.khhdon[0]) === 'K' &&
										<div className="col-12">
											<p className="mb-2">
												<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
												Hóa đơn không mã
											</p>
										</div>
                  }
								</Fragment>}
                {(!invoiceInfo.nky && invoiceInfo.khhdon && invoiceInfo.khhdon[0] === 'K') && <Fragment>
									<div className="col-12">
										<b>Tình trạng cấp mã của Cơ quan thuế</b>
									</div>
									<div className="col-12">
										<p className="mb-2">
											<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
											Hóa đơn gửi bảng tổng hợp không có XML
										</p>
									</div>
								</Fragment>}


                {typeof invoiceDoc?.warning?.owner_match === 'number' &&
									<Fragment>
										<div className="col-12">
											<b>Kiểm tra tên đơn vị {invoiceDoc.type === 'sold' ? 'bán' : 'mua'}</b>
										</div>
										<div className="col-12">
                      {invoiceDoc?.warning?.owner_match === 0 && <p className="mb-0">
												<i className="pi pi-check-circle mr-2" style={{color: 'green', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
												Tên đơn vị {invoiceDoc.type === 'sold' ? 'bán' : 'mua'} trên hóa đơn trùng với Tên đơn vị trên đăng ký Thuế.
											</p>}
                      {invoiceDoc?.warning?.owner_match > 0 && <p className="mb-0">
												<i className="pi pi-check-circle mr-2" style={{color: 'red', fontSize: '15px', fontWeight: 'bold', marginTop: '4px'}}></i>
												Tên đơn vị {invoiceDoc.type === 'sold' ? 'bán' : 'mua'} trên hóa đơn không trùng với Tên đơn vị trên đăng ký Thuế.
											</p>}
										</div>
										<div className="col-12">
											<p className="ml-3 mb-1">- Trên hóa đơn: <b>{invoiceDoc.type === 'sold' ? invoiceInfo.nbten : invoiceInfo.nmten}</b></p>
											<p className="ml-3 mb-0">- Trên đăng ký Thuế: <b>{invoiceDoc.warning.owner_name || ''}</b></p>
										</div>
									</Fragment>
                }
							</div>
						</div>
					</div>
					<div className="col-12 pt-0">
						<div className="card">
							<div className="grid">
								<div className="col-12">
									<h5 className="owner-color mb-1">TÌNH TRẠNG HOẠT ĐỘNG CỦA MST</h5>
								</div>
								<div className="col-12">
									<b>Người bán:</b>
								</div>
								<TaxCodeInfo taxCode={invoiceInfo.nbmst}/>
								<div className="col-12">
									<b>Người mua:</b>
								</div>
								<TaxCodeInfo taxCode={invoiceInfo.nmmst}/>
							</div>
						</div>
					</div>
				</div>
      }
      <InvoiceStatus display={displayStatus} setDisplay={setDisplayStatus} invoiceInfo={invoiceInfo} invoiceId={invoiceId} setInvoiceState={setInvoiceState}/>
    </div>
  );
}