import {TabView, TabPanel} from 'primereact/tabview';

import {Appl} from './appl';
import {Classification} from './classification';

export const TaxCodeClassification = () => {

  return (
    <TabView>
      <TabPanel header="Danh sách MST">
        <Appl/>
      </TabPanel>
      <TabPanel header="Tình trạng MST">
        <Classification/>
      </TabPanel>
    </TabView>
  );
}