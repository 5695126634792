import {Fragment, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {ToastService, useDataTableBasic, useDialogCrup, validateTaxCode} from '@iamsoftware/react-hooks';

import {TaxCodeService} from '../../../service/TaxCodeService';
import {InvoicesService} from '../InvoicesService';
import {XlsxService} from '../../../service/XlsxService';

export const MST = () => {

  const {search} = useLocation();

  const [taxCode, setTaxCode] = useState('');

  const [info, setInfo] = useState({});

  const [items, setItems] = useState(null);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);

      const account = params.get('account');
      const type = params.get('type');
      const filters = params.get('filters');

      if (account && type && filters) {
        InvoicesService.getTaxCodes(account, type, filters).then(data => {
          searchMany(data).then();
        });
      }
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchOne = () => {
    getOrgInfo(taxCode).then(data => {
      setItems([data]);
    });
  }

  const searchMany = async values => {
    const keys = {};
    values.forEach(value => {
      keys[value || ''] = true;
    });
    for (const k of Object.keys(keys)) {
      await getOrgInfo(k);
    }
    const complete = [];
    values.forEach(value => {
      complete.push(getOrgInfo(value || ''));
    });
    Promise.all(complete).then(results => {
      setItems(results);
    });
  }

  const getOrgInfo = input => {
    input = `${input}`.trim();
    return new Promise(resolve => {
      if (input && validateTaxCode(input)) {

        if (info[input]) {
          resolve(info[input]);
        } else {
          TaxCodeService.get(input).then(data => {
            if (data?.mst) {

              const dcctiet = [];
              if (data?.dctsdchi) {
                dcctiet.push(data?.dctsdchi);
              }
              if (data?.dctstxa) {
                dcctiet.push(data?.dctstxa);
              }
              if (data?.dctsthuyen) {
                dcctiet.push(data?.dctsthuyen);
              }
              if (data?.dctstinh) {
                dcctiet.push(data?.dctstinh);
              }

              data.dcctiet = dcctiet.join(', ');
              data.tthaictiet = TaxCodeService.msttthaiMap[data.tthai];

              setInfo(prevInfo => {
                prevInfo[input] = data;
                return prevInfo;
              });

              resolve(data);
            } else {
              resolve({tthaictiet: 'Không tìm thấy thông tin', mst: input});
            }
          });
        }
      } else {
        resolve({tthaictiet: 'Mã số thuế không hợp lệ', mst: input});
      }
    });
  }

  const {render: renderUpload, create: upload} = useDialogCrup({
    header: 'Upload Excel',
    width: '40rem',
    fields: [
      {field: 'file', header: 'Tải lên tệp tin', required: true, type: 'file', InputFileProps: {accept: '.xlsx, .xls'}, className: 'md:col-12 mb-1'},
      {field: 'colIndex', header: 'Số thứ tự cột', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-12'},
      {field: 'startRow', header: 'Bắt đầu từ dòng', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-12'}
    ],
    createItem: item => {
      const {file, colIndex, startRow} = item;

      return new Promise<any>((resolve, reject) => {
        XlsxService.readExcelFile(file[0], {startRow: startRow - 1}).then(data => {
          const values = [];
          if (data?.length) {
            data.forEach(row => {
              if (row[colIndex - 1]) {
                values.push(row[colIndex - 1]);
              }
            });
          }
          if (values.length) {
            resolve(values);
          } else {
            ToastService.error('Không tìm thấy dữ liệu');
            reject('Không tìm thấy dữ liệu');
          }
        });
      });
    },
    reloadLazyData(data?: any) {
      searchMany(data).then();
    }
  });

  const {render} = useDataTableBasic({
    tableHeader: `${items?.length || 0} Mã số thuế`,
    columns: [
      {
        field: 'tthaictiet', header: 'Tình trạng', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
          return <span className={TaxCodeService.getClassName(rowData.tthai)}>{rowData.tthaictiet}</span>
        }
      },
      {field: 'mst', header: 'Mã số thuế', width: 140},
      {field: 'tennnt', header: 'Tên tổ chức, cá nhân', minWidth: 150},
      {field: 'dcctiet', header: 'Địa chỉ', minWidth: 150},
      {field: 'tencqt', header: 'CQT quản lý', minWidth: 150}
    ],
    items
  });

  const exportExcel = () => {
    if (items?.length) {
      const aoa = [];
      aoa.push(['Tình trạng', 'Mã số thuế', 'Tên tổ chức, cá nhân', 'Địa chỉ', 'CQT quản lý']);
      items.forEach(item => {
        aoa.push([item.tthaictiet, item.mst, item.tennnt, item.dcctiet, item.tencqt]);
      });
      XlsxService.generateFile(aoa, 'tinh-trang-mst');
    } else {
      ToastService.error('Không tìm thấy dữ liệu');
    }
  }

  return (
    <Fragment>
      <div className="grid">
        <div className="col-3 p-fluid">
          <InputText value={taxCode} onChange={e => setTaxCode(e.target.value)} placeholder="Nhập MST cần tra cứu"/>
        </div>
        <div className="col-1">
          <Button icon="pi pi-search" label="Tra cứu" onClick={searchOne} className="p-button-outlined"/>
        </div>
        <div className="col-8 text-right">
          <Button icon="pi pi-file-excel" label="Tra cứu nhiều MST" onClick={() => upload({colIndex: 6, startRow: 7})} className="p-button-outlined p-button-help mr-2"/>
          <Button icon="pi pi-download" label="Xuất kết quả tra cứu ra Excel" onClick={exportExcel} className="p-button-outlined"/>
        </div>
      </div>
      <div className="grid mt-2">
        <div className="col-12">
          {render()}
        </div>
      </div>

      {renderUpload()}
    </Fragment>
  );
}