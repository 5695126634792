import {EntityService} from '../../../service/EntityService';

export class EmployeesService extends EntityService {

  static entity = 'users/employees';

  static changePassword(_id: string, data: any): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/change_password`, data).then(res => res.data);
  }

}
