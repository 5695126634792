import {Button} from 'primereact/button';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {EmailService as Service} from '../EmailService';

export const TaiKhoan = () => {

  const header = 'Tài khoản';
  const dataKey = '_id';

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'name', header: 'Tên tài khoản', minWidth: 150, matchMode: 'contains'},
      {field: 'email', header: 'Tài khoản email', minWidth: 150, matchMode: 'contains'},
      {field: 'host', header: 'Server host', minWidth: 150, matchMode: 'contains'},
      {field: 'port', header: 'Server port', width: 120, matchMode: 'contains'},
      {field: 'tls', header: 'SSL/TLS', width: 120, matchMode: 'equals', filterType: 'boolean', dataType: 'checkbox'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => doUpdate(item)},
            {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xóa', command: () => deleteItem(item)}
          ]
        });
      }
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'name', header: 'Tên tài khoản', required: true, className: 'md:col-12'},
      {field: 'email', header: 'Tài khoản email', required: true, className: 'md:col-12'},
      {field: 'password', header: 'Mật khẩu', required: true, className: 'md:col-12'},
      {field: 'host', header: 'Server host', required: true, className: 'md:col-9'},
      {field: 'port', header: 'Server port', required: true, type: 'InputNumber', className: 'md:col-3'},
      {field: 'tls', header: 'SSL/TLS', type: 'Checkbox', className: 'md:col-12'},
      {field: 'sync.init_sync', header: 'Đồng bộ từ ngày', required: true, InputTextProps: {type: 'date'}, className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const doUpdate = item => {
    if (item.sync.init_sync) {
      item['sync.init_sync'] = FormatDisplay.date(item.sync.init_sync, 'YYYY-MM-DD');
    }
    update(item);
  }

  const deleteItem = item => {
    ConfirmDialogService.confirm('Xóa tài khoản', 'Xóa tài khoản sẽ xóa toàn bộ hóa đơn, tập tin đính kèm liên quan đến tài khoản này. Thao tác không thể phục hồi.', () => {
      Service.delete(item._id).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    });
  }

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-12">
          <Button label="Thêm" icon="pi pi-plus" className="p-button-sm" onClick={create}/>
        </div>
      </div>
      {renderDataTable()}
      {renderDialogCrup()}
    </div>
  );
}