import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {Dropdown} from 'primereact/dropdown';
import {MegaMenu} from 'primereact/megamenu';

import {FormatDisplay, ToastService, useDataTable, validateTaxCode} from '@iamsoftware/react-hooks';

import {InvoicesService} from '../hoa-don/InvoicesService';
import {ExternalAccountsService} from '../tai-khoan-thue/ExternalAccountsService';
import {TagsService} from '../hoa-don/nhan-gan/TagsService';
import {XlsxService} from '../../service/XlsxService';

import {Filter} from './filter';

export const BangKe = ({header, partyColumns, endColumns, fieldFilter, defaultFilter, getData}) => {

  const [displayFilter, setDisplayFilter] = useState(null);

  const [requiredParams, setRequiredParams] = useState(null);

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);

  const [allTags, setAllTags] = useState(null);

  const [showMore, setShowMore] = useState({});

  const [footerValue, setFooterValue] = useState<any>({});

  useEffect(() => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        _accounts.push({value: item._id, label: `${item.username} - ${item.profile?.name || ''}`});
      });
      if (_accounts.length) {
        setAccount(_accounts[0].value);
      }
      setAccounts(_accounts);
    });
    TagsService.getList('{}').then(({items}) => {
      const _allTags = [];
      items.forEach(item => {
        _allTags.push(Object.assign(item, {value: item._id, label: item.name}));
      });
      setAllTags(_allTags);
    });
  }, []);

  const search = (params) => {
    if (params?.nbmst) {
      if (!validateTaxCode(params.nbmst)) {
        return ToastService.error('MST bên bán không hợp lệ');
      }
    }
    if (params?.nmmst) {
      if (!validateTaxCode(params.nmmst)) {
        return ToastService.error('MST bên mua không hợp lệ');
      }
    }
    setRequiredParams(params);
    setDisplayFilter(false);
  }

  const downloadExcel = () => {
    if (account) {
      const event = {
        filters: requiredParams
      }
      getData(account, JSON.stringify(event)).then(({items}) => {
        if (!items?.length) {
          return ToastService.error('Không tìm thấy hoá đơn');
        }
        const aoa = [[]];
        columns.forEach(column => {
          aoa[0].push(column.header);
          items.forEach((item, index) => {
            if (!aoa[index + 1]) {
              aoa[index + 1] = [];
            }
            aoa[index + 1].push(XlsxService.getCellValue(column, item));
          });
        });

        XlsxService.generateFile(aoa, 'bang-ke-hoa-don');
      });
    }
  }

  const actions = [
    {label: 'Lọc dữ liệu', icon: 'pi pi-fw pi-filter', className: 'iam-toolbar iam-toolbar-secondary', command: () => setDisplayFilter(true)},
    {label: 'Kết xuất excel', icon: 'pi pi-fw pi-file-excel', className: 'iam-toolbar iam-toolbar-secondary', command: downloadExcel, disabled: !requiredParams}
  ];

  const columns: any = [
    {
      field: 'nkekhai', header: 'Ngày kê khai', width: 125, dataType: 'custom', customCell(rowData, skipFormat: boolean): any {
        if (skipFormat === true) {
          return FormatDisplay.date(rowData.data?.nkekhai || '');
        }
        return <span title={rowData.data?.nkekhai_label}>{FormatDisplay.date(rowData.data?.nkekhai || '')}</span>;
      }
    },
    {
      field: 'keys.khhdon', header: 'Ký hiệu', width: 100, dataType: 'custom', customCell(rowData): any {
        return rowData.keys ? `${rowData.keys.khmshdon}${rowData.keys.khhdon}` : '';
      }
    },
    {
      field: 'data.tdlap', header: 'Ngày hoá đơn', width: 135, dataType: 'custom', customCell(rowData): any {
        return rowData.data ? FormatDisplay.date(rowData.data.tdlap) : '';
      }
    },
    {
      field: 'keys.shdon', header: 'Số hoá đơn', width: 120, dataType: 'custom', customCell(rowData): any {
        return rowData.keys ? rowData.keys.shdon : '';
      }
    },
    ...partyColumns,
    {
      field: 'header.tthang', header: 'Tổng tiền hàng', width: 130, sortable: false, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        let tthang = 0;
        if (rowData.header) {
          tthang = (rowData.header.tgtcthue || 0) + (rowData.header.ttcktmai || 0);
        }
        return skipFormat === true ? tthang : FormatDisplay.number(tthang);
      }, style: {textAlign: 'right'}
    },
    {
      field: 'header.ttcktmai', header: 'Tổng tiền CK', width: 130, sortable: false, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        const _number = rowData.header ? rowData.header.ttcktmai : '';
        return skipFormat === true ? _number : FormatDisplay.number(_number);
      }, style: {textAlign: 'right'}
    },
    {
      field: 'header.tgtcthue', header: 'Tổng tiền trước thuế', width: 160, sortable: false, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        const _number = rowData.header ? rowData.header.tgtcthue : '';
        return skipFormat === true ? _number : FormatDisplay.number(_number);
      }, style: {textAlign: 'right'}
    },
    {
      field: 'header.tgtthue', header: 'Tổng tiền thuế', width: 140, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        const _number = rowData.header ? rowData.header.tgtthue : '';
        return skipFormat === true ? _number : FormatDisplay.number(_number);
      }, style: {textAlign: 'right'}
    },
    {
      field: 'header.tgtphi', header: 'Tổng tiền phí', width: 140, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        const _number = rowData.header ? rowData.header.tgtphi : '';
        return skipFormat === true ? _number : FormatDisplay.number(_number);
      }, style: {textAlign: 'right'}
    },
    {
      field: 'header.tgtttbso', header: 'Tổng tiền thanh toán', width: 180, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        const _number = rowData.header ? rowData.header.tgtttbso : '';
        return skipFormat === true ? _number : FormatDisplay.number(_number);
      }, style: {textAlign: 'right'}
    },
    {
      field: 'header.dvtte', header: 'Tiền tệ', width: 70, sortable: false, dataType: 'custom', customCell(rowData: any): any {
        let dvtte = rowData.header?.dvtte;
        if (!dvtte || ['vnd', 'vnđ', 'VNĐ',].includes(dvtte)) {
          dvtte = 'VND';
        }
        return dvtte;
      }
    },
    {
      field: 'header.tgia', header: 'Tỷ giá', width: 80, sortable: false, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        const _number = rowData.header?.tgia || 1;
        return skipFormat === true ? _number : FormatDisplay.number(_number);
      }, style: {textAlign: 'right'}
    },
    {
      field: 'header.thtttoan', header: 'Hình thức TT', width: 130, sortable: false, dataType: 'custom', customCell(rowData: any): any {
        return rowData.header ? rowData.header.thtttoan : '';
      }
    },
    {
      field: 'header.tthai', header: 'Trạng thái', width: 120, dataType: 'custom', customCell(rowData): any {
        let tthai = rowData.header.tthai;
        InvoicesService.tthais.forEach(t => {
          if (t.value === tthai) {
            tthai = t.label;
          }
        });
        return tthai;
      }
    },
    {
      field: 'header.nky', header: 'Ngày ký', width: 100, dataType: 'custom', customCell(rowData): any {
        return rowData.header ? FormatDisplay.date(rowData.header.nky) : '';
      }
    },
    {
      field: 'header.mhdon', header: 'Mã tra cứu', width: 350, sortable: false, dataType: 'custom', customCell(rowData: any): any {
        return rowData.header ? rowData.header.mhdon : '';
      }
    },
    ...endColumns,
    {
      field: 'detail.thttlphi', header: 'Phí', width: 150, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        return rowData.detail?.thttlphi?.filter(phi => phi?.tlphi)?.map(phi => `- ${phi.tlphi}: ${skipFormat === true ? phi.tphi : FormatDisplay.number(phi.tphi)}`).join('\n');
      }
    },
    {
      field: 'warning.status', header: 'KQ kiểm tra', width: 130, dataType: 'custom', customCell(rowData, skipFormat: boolean): any {
        if (skipFormat === true) {
          return rowData.warning?.status || '';
        }
        let title = '';
        if (rowData.warning?.warning_array) {
          title = rowData.warning?.warning_array.join(' - ');
        }
        return <div className={rowData.warning?.status === 'Cảnh báo' ? 'alert alert-danger' : 'alert alert-info'} title={title}>{rowData.warning ? rowData.warning.status : ''}</div>
      }
    },
    {
      field: 'warning.warning_array', header: 'Cảnh báo', width: 160, dataType: 'custom', customCell(rowData, skipFormat: boolean): any {
        if (skipFormat === true) {
          return rowData.warning?.warning_array?.join(', ') || '';
        }
        return rowData.warning?.warning_array?.map(warning => <p className="mb-0 text-orange-500">- {warning}</p>);
      }
    },
    {
      field: 'tags', header: 'Nhãn', width: 160, sortable: false, dataType: 'custom', customCell(rowData): any {
        if (rowData.tags && rowData.tags.length) {
          let _tags = [];
          allTags?.forEach(tag => {
            rowData.tags.forEach(t => {
              if (t === tag._id) {
                _tags.push(tag.name);
              }
            });
          });
          return _tags.join(', ');
        } else {
          return '';
        }
      }, className: `${allTags?.length}`
    },
    {
      field: 'detail.hdhhdvu.ten', header: 'Diễn giải', width: 600, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        const hdhhdvuList = rowData.detail?.hdhhdvu?.filter(hdhhdvu => hdhhdvu.ten)?.slice(0, maxRowLength);
        const remainRow = rowData.detail?.hdhhdvu?.length - hdhhdvuList?.length;
        if (hdhhdvuList?.length) {
          if (skipFormat === true) {
            let hdhhdvuText = hdhhdvuList.map(hdhhdvu => hdhhdvu.ten)?.join(', ');
            if (remainRow > 0) {
              hdhhdvuText += `. Còn ${remainRow} dòng chưa hiển thị.`;
            }
            return hdhhdvuText;
          } else {
            if (hdhhdvuList.length <= 3 || showMore[rowData._id]) {
              return <div>
                {hdhhdvuList.map(hdhhdvu => hdhhdvu.ten)?.join(', ')}
                {remainRow > 0 && <div className="text-center">
									<i>Còn {remainRow} dòng chưa hiển thị.</i>
								</div>}
              </div>
            } else {
              return <div>
                {hdhhdvuList.slice(0, 3)?.map(hdhhdvu => hdhhdvu.ten)?.join(', ')}
                <div className="text-center">
                  <Button icon="pi pi-ellipsis-h" rounded text onClick={() => showMoreItem(rowData)} className="py-0 pointer" title="Xem thêm" style={{height: 'unset'}}/>
                </div>
              </div>
            }
          }
        } else {
          return '';
        }
      }, className: Object.keys(showMore)
    },
    {field: 'comment', header: 'Ghi chú', width: 150},
    {
      field: 'ttinlquan', header: 'Thông tin liên quan', sortable: false, dataType: 'custom', customCell(rowData, skipFormat: boolean): any {
        if (skipFormat === true) {
          return rowData.ttinlquan?.join(', ') || '';
        }
        return rowData.ttinlquan?.join(', ') || '';
      }, width: 200
    }
  ];

  const showMoreItem = rowData => {
    setShowMore(prevState => {
      prevState[rowData._id] = true;
      return {...prevState};
    });
  }

  const {render: renderDataTable} = useDataTable({
    stateKey: 'mainTable',
    columns,
    indexColumnWidth: 45,
    getList(params: any): Promise<any> {
      if (account) {
        return getData(account, params).then(result => {
          if (result?.aggs?.length) {
            const _footerValue = result.aggs[0] || {};
            _footerValue.tthang = (_footerValue.tgtcthue || 0) + (_footerValue.ttcktmai || 0);
            setFooterValue(_footerValue);
          } else {
            setFooterValue({});
          }
          return result;
        });
      } else {
        return new Promise(resolve => {
          resolve({items: [], totalRecords: 0});
        });
      }
    },
    requiredParams,
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={8}/>
          <Column footer={FormatDisplay.number(footerValue.tthang)} footerStyle={{textAlign: 'right', fontSize: '12px'}}/>
          <Column footer={FormatDisplay.number(footerValue.ttcktmai)} footerStyle={{textAlign: 'right', fontSize: '12px'}}/>
          <Column footer={FormatDisplay.number(footerValue.tgtcthue)} footerStyle={{textAlign: 'right', fontSize: '12px'}}/>
          <Column footer={FormatDisplay.number(footerValue.tgtthue)} footerStyle={{textAlign: 'right', fontSize: '12px'}}/>
          <Column footer={FormatDisplay.number(footerValue.tgtphi)} footerStyle={{textAlign: 'right', fontSize: '12px'}}/>
          <Column footer={FormatDisplay.number(footerValue.tgtttbso)} footerStyle={{textAlign: 'right', fontSize: '12px'}}/>
          <Column colSpan={13}/>
        </Row>
      </ColumnGroup>
    }
  });

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="field col-6 p-fluid mb-0">
          <label htmlFor="account">Tài khoản thuế ({accounts?.length} tài khoản)</label>
          <Dropdown value={account} options={accounts} onChange={e => setAccount(e.value)} filter={true}/>
        </div>
        <div className="col-6"></div>
      </div>
      <MegaMenu model={actions}/>
      <Filter header={header} display={displayFilter} setDisplay={setDisplayFilter} fieldFilter={fieldFilter} defaultFilter={defaultFilter} search={search}/>
      <div className="grid">
        <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 19rem)'}}>
          {renderDataTable()}
        </div>
      </div>
    </div>
  );
}

const maxRowLength = 20;