import {useEffect, useState} from 'react';

import {AutoComplete} from 'primereact/autocomplete';
import {Button} from 'primereact/button';
import {FormatDisplay, ToastService, useDataTable} from '@iamsoftware/react-hooks';

import {LoginServices as Service} from './LoginServices';
import {AgentsService} from '../../he-thong/dai-ly/AgentsService';

export const Login = () => {

  const dataKey = '_id';

  const [requiredParams, setRequiredParams] = useState(null);

  const [agent, setAgent] = useState(null);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    if (agent?.value) {
      setRequiredParams({user: {value: agent?.value, matchMode: 'ObjectId'}});
    } else {
      setRequiredParams({});
    }
  }, [agent?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, refreshLazyData} = useDataTable({
    dataKey,
    columns: [
      {
        field: 'user', header: 'Người dùng', minWidth: 180, sortable: false, dataType: 'custom', customCell(rowData): any {
          return rowData.user ? `[${rowData.user.username}] ${rowData.user.description}` : '';
        }
      },
      {
        field: 'username', header: 'Tài khoản', minWidth: 180, matchMode: 'contains', dataType: 'custom', customCell(rowData): any {
          return (
            <div>
              <p className="mb-0">{rowData.username}</p>
              <p className="mb-0">{rowData.profile?.name}</p>
              <p className="mb-0">sign_out: {rowData.sign_out.toString()}</p>
            </div>
          );
        }
      },
      {field: 'auto_login.auto', header: 'Tự động', width: 100, dataType: 'boolean', filterType: 'boolean', matchMode: 'equals'},
      {field: 'auto_login.run', header: 'Tiếp theo', width: 160, dataType: 'date-time'},
      {
        field: 'auto_login.ran', header: 'Lần chạy gần đây', width: 160, dataType: 'custom', customCell(rowData: any): any {
          return <span className={rowData.auto_login.last_result_is_success ? 'alert-success' : 'alert-danger'} title={`${rowData.auto_login.run_count} lượt`}>
            {FormatDisplay.dateTime(rowData.auto_login.ran)}
          </span>
        }
      },
      {
        field: 'auto_login.last_message', header: 'Thông báo lỗi', minWidth: 140, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <span title={`${rowData.auto_login.error_count} lượt`}>{rowData.auto_login.last_message}</span>
        }
      }
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 110,
    getList: params => {
      return Service.getList(params).then(data => {
        data?.items?.forEach(item => {
          item['auto_login.auto'] = item.auto_login?.auto;
          item['auto_login.run'] = item.auto_login?.run;
        });
        return data;
      });
    },
    requiredParams,
    initActions: items => {
      if (items) {
        items.forEach(item => {
          if (!item.sign_out) {
            if (item.auto_login?.auto) {
              item.actions = [
                {icon: 'pi pi-times', title: 'Bỏ tự động', className: 'p-button-warning', command: () => setAuto(item, false)},
              ];
              item.actionMenus = [
                {label: 'Đăng nhập ngay', icon: 'pi pi-bolt', command: () => doRun(item)}
              ];
            } else {
              item.actions = [
                {icon: 'pi pi-check', title: 'Sử dụng tự động', className: 'p-button-success', command: () => setAuto(item, true)},
              ];
            }
          }
        });
      }
    }
  });

  const setAuto = (item, auto) => {
    Service.setAuto(item[dataKey], {auto}).then(() => {
      ToastService.success();
      refreshLazyData();
    });
  }

  const doRun = item => {
    Service.setAuto(item[dataKey], {run: Date.now()}).then(() => {
      ToastService.success();
      refreshLazyData();
    });
  }

  const searchAgents = event => {
    const _event = {
      filters: {
        username: {value: event.query, matchMode: 'contains'}
      }
    };
    AgentsService.getList(JSON.stringify(_event)).then(({items}) => {
      const _agents = [];
      items.forEach(item => {
        _agents.push({value: item._id, label: `${item.username} - ${item.description}`});
      });
      setAgents(_agents);
    });
  }

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid p-fluid">
        <div className="col-4">
          <AutoComplete field="label" value={agent} suggestions={agents} completeMethod={searchAgents} onChange={e => setAgent(e.value)} dropdown={true} placeholder="Lọc theo Người dùng"/>
        </div>
        <div className="col-4">
        </div>
        <div className="col-4 p-unfluid text-right">
          <Button label="Refresh" icon="pi pi-refresh" className="p-button-sm" onClick={refreshLazyData}/>
        </div>
      </div>
      {renderDataTable()}
    </div>
  );
}