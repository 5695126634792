import {Service} from './Service';

export class UsersService extends Service {

  static entity: string = 'users';

  static login(username: string, password: string): Promise<any> {
    return this.axios.post(`${this.entity}/login`, {username, password}).then(res => res.data);
  }

  static getMe(): Promise<any> {
    return this.axios.get(`${this.entity}/me`).then(res => res.data);
  }

  static getLicense(): Promise<any> {
    return this.axios.get(`${this.entity}/license`).then(res => res.data);
  }

  static getInUse(): Promise<any> {
    return this.axios.get(`${this.entity}/in_use`).then(res => res.data);
  }

  static logout(): Promise<any> {
    return this.axios.get(`${this.entity}/logout`).then(res => res.data);
  }

  static setPassword(newPassword: string): Promise<any> {
    return this.axios.post(`${this.entity}/set-password`, {newPassword}).then(res => res.data);
  }

  static resetPassword(username: string, email: string): Promise<any> {
    return this.axios.post(`${this.entity}/reset-password`, {username, email}).then(res => res.data);
  }

}
