import {useEffect, useState} from 'react';

import {DataView} from 'primereact/dataview';
import {Dropdown} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {EmailService as Service} from '../EmailService';

import {Content} from './Content';

export const HopThuDen = ({me}) => {

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);

  const [search, setSearch] = useState('');
  const [first, setFirst] = useState(0);
  const [rows] = useState(10);
  const [dataState, setDataState] = useState(0);
  const [items, setItems] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    Service.getList('{}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        _accounts.push({value: item._id, label: item.name});
      });
      if (_accounts.length) {
        setAccount(_accounts[0].value);
      }
      setAccounts(_accounts);
    });
  }, []);

  useEffect(() => {
    if (account) {
      const params = {
        first,
        rows,
        filters: {
          account: {value: account, matchMode: 'ObjectId'},
          hasMimeXml: {value: true, matchMode: 'equals'}
        }
      };
      if (search) {
        params.filters['search_key'] = {value: search, matchMode: 'contains'};
      }
      Service.getMessages(JSON.stringify(params)).then(data => {
        setItems(data.items);
        setTotalRecords(data.totalRecords);
      });
    }
  }, [account, first, search, dataState]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPage = event => {
    setFirst(event.first);
    setDataState(Date.now);
  }

  const renderListItem = data => {
    return (
      <div className={`col-12 p-2 ${selectedItem?._id === data._id ? 'p-highlight' : ''}`} onClick={() => setSelectedItem(data)}>
        <div className="grid">
          <div className="col">
            {data.detail.subject}
          </div>
          <div className="col-fixed text-center" style={{width: '7rem'}}>
            {FormatDisplay.date(data.detail.date)}
          </div>
        </div>
        <div className="grid">
          <div className="col-12">
            {data.detail.from}
          </div>
        </div>
      </div>
    );
  }

  const renderHeader = () => {
    return (
      <div>
        <span className="p-input-icon-left w-full">
          <i className="pi pi-search"/>
          <InputText value={search} onChange={e => setSearch(e.target.value)} placeholder="Tìm kiếm theo người gửi"/>
        </span>
      </div>
    );
  }

  const _header = renderHeader();

  return (
    <div className="card pb-0">
      <div className="grid" style={{height: 'calc(100vh - 190px)'}}>
        <div className="col-fixed" style={{width: '40rem'}}>
          <div className="grid p-fluid">
            <div className="col-12">
              <Dropdown value={account} options={accounts} onChange={e => setAccount(e.value)} filter={true}/>
            </div>
          </div>
          <DataView value={items} layout="list" header={_header}
                    itemTemplate={renderListItem} lazy paginator paginatorPosition={'both'} rows={rows}
                    totalRecords={totalRecords} first={first} onPage={onPage}/>
        </div>
        <div className="col h-full pb-0">
          {selectedItem &&
						<Content me={me} message={selectedItem}/>
          }
        </div>
      </div>
    </div>
  );
}