import {useContext} from 'react';

import {AppMenuItem} from 'src/types/types';
import {LayoutContext} from 'src/layout/context/layoutcontext';
import {MenuProvider} from 'src/layout/context/menucontext';

import AppMenuitem from './AppMenuitem';

const AppMenu = () => {

  const {me} = useContext(LayoutContext);

  const appMenus: Array<AppMenuItem> = [
    {
      label: 'Quản lý hóa đơn',
      items: [
        {label: 'Tổng quan', icon: 'pi pi-fw pi-home', to: '/'},
        {label: 'Hóa đơn bán ra', icon: 'pi pi-fw pi-arrow-left', to: '/hoa-don/ban-ra'},
        {label: 'Hóa đơn mua vào', icon: 'pi pi-fw pi-arrow-right', to: '/hoa-don/mua-vao'},
        {label: 'Kiểm tra tình trạng MST', icon: 'pi pi-fw pi-id-card', to: '/hoa-don/kiem-tra-mst'},
        {label: 'Nhãn hoá đơn', icon: 'pi pi-fw pi-tags', to: '/hoa-don/nhan-gan'},
        {label: 'Thông báo 04SS', icon: 'pi pi-fw pi-volume-down', to: '/hoa-don/tbss'}
      ]
    },
    {
      label: 'Hộp thư',
      items: [
        {label: 'Danh sách tài khoản', icon: 'pi pi-fw pi-key', to: '/hop-thu/tai-khoan'},
        {label: 'Hộp thư đến', icon: 'pi pi-fw pi-envelope', to: '/hop-thu/hop-thu-den'},
        {label: 'Lịch sử đồng bộ', icon: 'pi pi-fw pi-history', to: '/hop-thu/dong-bo'}
      ]
    },
    {
      label: 'Danh mục',
      items: [
        {label: 'Hàng hóa / Dịch vụ', icon: 'pi pi-fw pi-qrcode', to: '/danh-muc/hang-hoa'}
      ]
    },
    {
      label: 'Báo cáo',
      items: [
        {label: 'Bảng kê hoá đơn mua vào', icon: 'pi pi-fw pi-circle', to: '/bao-cao/bang-ke-mua-vao'},
        {label: 'Bảng kê hoá đơn bán ra', icon: 'pi pi-fw pi-circle', to: '/bao-cao/bang-ke-ban-ra'},
        {label: 'Tờ khai thuế 01GTGT', icon: 'pi pi-fw pi-circle', to: '/bao-cao/to-khai-thue'},
        {label: 'Bảng kê nhận vào phần mềm kế toán', icon: 'pi pi-fw pi-circle', to: '/bao-cao/bang-ke-pmkt'},
        {label: 'Hóa đơn mua vào bị thay thế/điều chỉnh gần đây', icon: 'pi pi-fw pi-circle', to: '/bao-cao/hoa-don-mua-dc-tt'},
        {label: 'Biểu đồ hoá đơn Mua - Bán', icon: 'pi pi-fw pi-circle', to: '/bao-cao/bieu-do-hoa-don'},
        {label: 'Tình trạng doanh nghiệp', icon: 'pi pi-fw pi-circle', to: '/bao-cao/tinh-trang-doanh-nghiep'},
        {label: 'Tình hình sử dụng', icon: 'pi pi-fw pi-circle', to: '/bao-cao/tinh-hinh-su-dung'}
      ]
    },
    {
      label: 'Kết nối cơ quan thuế',
      items: [
        {label: 'Danh sách tài khoản', icon: 'pi pi-fw pi-share-alt', to: '/tai-khoan-thue'},
        {label: 'Lịch sử đồng bộ', icon: 'pi pi-fw pi-history', to: '/tai-khoan-thue/dong-bo'}
      ]
    },
    {
      label: 'Hệ thống',
      items: [
        // {label: 'Gói dịch vụ', icon: 'pi pi-fw pi-verified', to: '/he-thong/goi-dich-vu', visible: ['admin'].includes(me.type)},
        {label: 'Danh sách đại lý', icon: 'pi pi-fw pi-sitemap', to: '/he-thong/dai-ly', visible: ['admin', 'agent'].includes(me.type)}
      ]
    },
    {
      label: 'Administrator',
      items: [
        {label: 'System logs', icon: 'pi pi-fw pi-circle', to: '/administrator/system-logs'},
        {label: 'Sync invoices', icon: 'pi pi-fw pi-circle', to: '/administrator/sync-invoices'},
        {label: 'Sync invoice assets', icon: 'pi pi-fw pi-circle', to: '/administrator/sync-invoice-assets'},
        {label: 'Login services', icon: 'pi pi-fw pi-circle', to: '/administrator/login-services'},
        {label: 'Throttle', icon: 'pi pi-fw pi-circle', to: '/administrator/throttle'},
        {label: 'Forever logs', icon: 'pi pi-fw pi-circle', to: '/administrator/forever-logs'},
        {label: 'Cấu hình thời gian job', icon: 'pi pi-fw pi-circle', to: '/administrator/job-moment'},
        {label: 'Trạng thái MST', icon: 'pi pi-fw pi-circle', to: '/administrator/tax-code-classification'},
        {label: 'Cấu hình link tra cứu', icon: 'pi pi-fw pi-circle', to: '/administrator/invoice-search-links'}
      ],
      visible: ['admin'].includes(me.type)
    }
  ];

  const pluck = menu => {
    if (menu.items?.length) {
      if (typeof menu.visible !== 'boolean') {
        menu.items.forEach(item => pluck(item));
        menu.visible = menu.items.filter(item => item.visible !== false).length > 0;
      }
    }
  }
  appMenus.forEach(appMenu => {
    pluck(appMenu);
  });

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {appMenus.map((item, i) => {
          return !item?.seperator ? <AppMenuitem item={item} root={true} index={i} key={item.label}/> : <li className="menu-separator"></li>;
        })}
      </ul>
    </MenuProvider>
  );
}

export default AppMenu;