import {useEffect, useState} from 'react';

import axios from 'axios';
import * as ExcelJS from 'exceljs';

import {Button} from 'primereact/button';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {XlsxService} from '../../../service/XlsxService';
import {InvoicesService} from '../../hoa-don/InvoicesService';

export const InvoiceListPurchase = ({config, invoices, from, to}) => {

  const [submitting, setSubmitting] = useState(false);
  const [thttltsuat, setThttltsuat] = useState([]);
  const [total, setTotal] = useState({} as any);

  useEffect(() => {
    const _thttltsuat = [];
    const _total = {total_thtien: 0, total_tthue: 0};
    if (invoices?.length) {
      for (const invoice of invoices) {
        if (invoice.thttltsuat?.length) {
          for (const thttltsuat of invoice.thttltsuat) {
            if (isCalculable(thttltsuat.thtien)) {
              _total.total_thtien += thttltsuat.thtien;
            }
            if (isCalculable(thttltsuat.tthue)) {
              _total.total_tthue += thttltsuat.tthue;
            }

            let tthai = invoice.tthai;
            InvoicesService.m_tthais.forEach(t => {
              if (t.value === tthai) {
                tthai = t.label;
              }
            });
            _thttltsuat.push({
              ...thttltsuat,
              khmshdon: invoice.khmshdon,
              khhdon: invoice.khhdon,
              tdlap: invoice.tdlap,
              shdon: invoice.shdon,
              nmten: invoice.nmten,
              nmmst: invoice.nmmst,
              nbten: invoice.nbten,
              nbmst: invoice.nbmst,
              tthai
            });
          }
        }
      }
    }
    setThttltsuat(_thttltsuat);
    setTotal(_total);
  }, [invoices]);

  const exportExcel = async () => {
    if (!submitting) {
      setSubmitting(true);

      const fileName = 'bang-ke-mua-vao-pl-01';

      const url = `./export-template/${fileName}.xlsx`;
      const axiosResponse = await axios.get(url, {responseType: 'arraybuffer'});

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(axiosResponse.data);
      const worksheet = workbook.getWorksheet('Sheet1');

      worksheet.getCell('D4').value = `Từ ${FormatDisplay.date(from)} đến ${FormatDisplay.date(to)}`;
      worksheet.getCell('D5').value = config?.tenNNT || '';
      worksheet.getCell('D6').value = config?.mst || '';
      worksheet.getCell('D7').value = config?.tenDLyThue || '';

      let rowIndex = 15;

      worksheet.insertRow(rowIndex, ['']);
      worksheet.mergeCells(rowIndex, 2, rowIndex, 10);
      let mergedCell = worksheet.getCell(rowIndex, 2);
      mergedCell.value = `1. Hàng hoá, dịch vụ dùng riêng cho SXKD chịu thuế GTGT và sử dụng cho các hoạt động cung cấp hàng hoá, dịch vụ không kê khai, nộp thuế GTGT đủ điều kiện khấu trừ thuế:`;
      makeTableCell(mergedCell);
      rowIndex++;

      thttltsuat.forEach((item, itemIndex) => {
        worksheet.insertRow(rowIndex, ['']);

        mergedCell = worksheet.getCell(rowIndex, 2);
        mergedCell.value = itemIndex + 1;
        makeTableCell(mergedCell);

        mergedCell = worksheet.getCell(rowIndex, 3);
        mergedCell.value = `${item.khmshdon}${item.khhdon}`;
        makeTableCell(mergedCell);

        mergedCell = worksheet.getCell(rowIndex, 4);
        mergedCell.value = `${item.shdon}`;
        makeTableCell(mergedCell);

        mergedCell = worksheet.getCell(rowIndex, 5);
        mergedCell.value = FormatDisplay.date(item.tdlap);
        makeTableCell(mergedCell);

        mergedCell = worksheet.getCell(rowIndex, 6);
        mergedCell.value = `${item.nbten}`;
        makeTableCell(mergedCell);

        mergedCell = worksheet.getCell(rowIndex, 7);
        mergedCell.value = `${item.nbmst}`;
        makeTableCell(mergedCell);

        mergedCell = worksheet.getCell(rowIndex, 8);
        mergedCell.value = item.thtien;
        makeTableCell(mergedCell);

        mergedCell = worksheet.getCell(rowIndex, 9);
        mergedCell.value = item.tthue;
        makeTableCell(mergedCell);

        mergedCell = worksheet.getCell(rowIndex, 10);
        mergedCell.value = `${item.tthai}`;
        makeTableCell(mergedCell);

        rowIndex++;
      });

      worksheet.getCell(rowIndex, 6).value = total.total_thtien;
      rowIndex++;
      worksheet.getCell(rowIndex, 6).value = total.total_tthue;

      const updatedFile = await workbook.xlsx.writeBuffer();
      XlsxService.saveAsExcelFile(updatedFile, fileName);

      setSubmitting(false);
    }
  }

  const makeTableCell = cell => {
    cell.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'}
    };
  }

  return (
    <div className="grid">
      <div className="col-12 text-center">
        <b>BẢNG KÊ HOÁ ĐƠN, CHỨNG TỪ HÀNG HOÁ, DỊCH VỤ MUA VÀO VỚI HÓA ĐƠN GTGT</b>
      </div>
      <div className="col-12 text-right">
        <Button label="Kết xuất Excel" icon="pi pi-file-excel" outlined size="small" onClick={exportExcel} loading={submitting}/>
      </div>
      <div className="col-12">
        <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid">
          <div className="p-datatable-wrapper" style={{minWidth: '100rem'}}>
            <table className="p-datatable-table">
              <thead className="p-datatable-thead">
              <tr role="row">
                <th style={{width: '3rem', textAlign: 'center'}}>#</th>
                <th style={{width: '6rem'}}>Ký hiệu</th>
                <th style={{width: '8rem'}}>Ngày hóa đơn</th>
                <th style={{width: '8rem'}}>Số hóa đơn</th>
                <th>Tên người bán</th>
                <th style={{width: '10rem'}}>Mã số thuế</th>
                <th style={{width: '11rem'}}>Tổng tiền trước thuế</th>
                <th style={{width: '9rem'}}>Tổng tiền thuế</th>
                <th style={{width: '6rem'}}>Thuế suất</th>
                <th>Trạng thái hóa đơn</th>
              </tr>
              </thead>
              <tbody className="p-datatable-tbody">
              {thttltsuat?.map((item, itemIndex) => <tr key={itemIndex} role="row">
                <td style={{textAlign: 'center'}}>{itemIndex + 1}</td>
                <td>{item.khmshdon}{item.khhdon}</td>
                <td>{FormatDisplay.date(item.tdlap)}</td>
                <td>{item.shdon}</td>
                <td>{item.nbten}</td>
                <td>{item.nbmst}</td>
                <td className="text-right">{FormatDisplay.number(item.thtien)}</td>
                <td className="text-right">{FormatDisplay.number(item.tthue)}</td>
                <td className="text-center">{item.tsuat}</td>
                <td>{item.tthai}</td>
              </tr>)}
              <tr role="row">
                <td colSpan={6} className="alert-secondary"><b>Tổng cộng</b></td>
                <td className="alert-secondary text-right"><b>{FormatDisplay.number(total.total_thtien)}</b></td>
                <td className="alert-secondary text-right"><b>{FormatDisplay.number(total.total_tthue)}</b></td>
                <td colSpan={2} className="alert-secondary"></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const isCalculable = value => {
  return typeof value === 'number' && !Number.isNaN(value);
}