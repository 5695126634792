import {Fragment, useState} from 'react';

import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {ToastService, useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';

import {MstMivService} from '../../../service/MstMivService';
import {XlsxService} from '../../../service/XlsxService';

export const CCCD = () => {

  const [taxCode, setTaxCode] = useState('');

  const [info, setInfo] = useState({});

  const [items, setItems] = useState(null);

  const searchOne = () => {
    getOrgInfo(taxCode).then(data => {
      setItems([data]);
    });
  }

  const searchMany = values => {
    const keys = {};
    values.forEach(value => {
      keys[value || ''] = true;
    });
    let complete = [];
    Object.keys(keys).forEach(k => {
      complete.push(getOrgInfo(k));
    });
    Promise.all(complete).then(() => {
      complete = [];
      values.forEach(value => {
        complete.push(getOrgInfo(value || ''));
      });
      Promise.all(complete).then(results => {
        setItems(results);
      });
    });
  }

  const getOrgInfo = input => {
    input = `${input}`.trim();
    return new Promise(resolve => {
      if (input) {

        if (info[input]) {
          resolve(info[input]);
        } else {
          MstMivService.searchCMND(input).then(data => {
            if (data?.mst_tncn) {

              data.key = input;

              setInfo(prevInfo => {
                prevInfo[input] = data;
                return prevInfo;
              });

              resolve(data);
            } else {
              const trang_thai = data?.error || 'Không tìm thấy thông tin';
              resolve({trang_thai, trang_thai_error: true, key: input});
            }
          }).catch(() => {
            resolve({trang_thai: 'Không tìm thấy thông tin', trang_thai_error: true, key: input});
          });
        }
      } else {
        resolve({trang_thai: 'CMND/CCCD không hợp lệ', trang_thai_error: true, key: input});
      }
    });
  }

  const {render: renderUpload, create: upload} = useDialogCrup({
    header: 'Upload Excel',
    width: '40rem',
    fields: [
      {field: 'file', header: 'Tải lên tệp tin', required: true, type: 'file', InputFileProps: {accept: '.xlsx, .xls'}, className: 'md:col-12 mb-1'},
      {field: 'colIndex', header: 'Số thứ tự cột', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-12'},
      {field: 'startRow', header: 'Bắt đầu từ dòng', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-12'}
    ],
    createItem: item => {
      const {file, colIndex, startRow} = item;

      return new Promise<any>((resolve, reject) => {
        XlsxService.readExcelFile(file[0], {startRow: startRow - 1}).then(data => {
          const values = [];
          if (data?.length) {
            data.forEach(row => {
              if (row[colIndex - 1]) {
                values.push(row[colIndex - 1]);
              }
            });
          }
          if (values.length) {
            resolve(values);
          } else {
            ToastService.error('Không tìm thấy dữ liệu');
            reject('Không tìm thấy dữ liệu');
          }
        });
      });
    },
    reloadLazyData(data?: any) {
      searchMany(data);
    }
  });

  const {render} = useDataTableBasic({
    tableHeader: `${items?.length || 0} CMND/CCCD`,
    columns: [
      {
        field: 'trang_thai', header: 'Tình trạng', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
          return <span className={`${rowData.trang_thai_error ? 'alert-danger' : 'alert-success'}`}>{rowData.trang_thai}</span>
        }
      },
      {field: 'key', header: 'CMND/CCCD', width: 160},
      {field: 'ho_ten', header: 'Họ Tên', minWidth: 150},
      {field: 'mst_tncn', header: 'Mã số thuế', width: 140},
      {field: 'ngay_cap', header: 'Ngày cấp', width: 150},
      {field: 'noi_cap', header: 'Nơi cấp', minWidth: 150}
    ],
    items
  });

  const exportExcel = () => {
    if (items?.length) {
      const aoa = [];
      aoa.push(['Tình trạng', 'CMND/CCCD', 'Họ Tên', 'Mã số thuế', 'Ngày cấp', 'Nơi cấp']);
      items.forEach(item => {
        aoa.push([item.trang_thai, item.key, item.ho_ten, item.mst_tncn, item.ngay_cap, item.noi_cap]);
      });

      XlsxService.generateFile(aoa, 'tra-cuu-cmnd-cccd');
    } else {
      ToastService.error('Không tìm thấy dữ liệu');
    }
  }

  return (
    <Fragment>
      <div className="grid">
        <div className="col-3 p-fluid">
          <InputText value={taxCode} onChange={e => setTaxCode(e.target.value)} placeholder="Nhập CMND/CCCD cần tra cứu"/>
        </div>
        <div className="col-1">
          <Button icon="pi pi-search" label="Tra cứu" onClick={searchOne} className="p-button-outlined"/>
        </div>
        <div className="col-8 text-right">
          <Button icon="pi pi-file-excel" label="Tra cứu nhiều CMND/CCCD" onClick={() => upload({colIndex: 6, startRow: 7})} className="p-button-outlined p-button-help mr-2"/>
          <Button icon="pi pi-download" label="Xuất kết quả tra cứu ra Excel" onClick={exportExcel} className="p-button-outlined"/>
        </div>
      </div>
      <div className="grid mt-2">
        <div className="col-12">
          {render()}
        </div>
      </div>

      {renderUpload()}
    </Fragment>
  );
}