import {Route, Routes} from 'react-router-dom';

import {SystemLogs} from './system-logs';
import {SyncInvoices} from './sync-invoices';
import {SyncInvoiceAssets} from './sync-invoice-assets';
import {Login} from './login-services';
import {ForeverLogs} from './forever-logs';
import {Throttle} from './throttle';
import {JobMoment} from './job-moment';
import {TaxCodeClassification} from './tax-code-classification';
import {InvoiceSearchLinks} from './invoice-search-links';

export default function Administrator() {

  return (
    <Routes>
      <Route path="system-logs" element={<SystemLogs/>}/>
      <Route path="sync-invoices" element={<SyncInvoices/>}/>
      <Route path="sync-invoice-assets" element={<SyncInvoiceAssets/>}/>
      <Route path="login-services" element={<Login/>}/>
      <Route path="forever-logs" element={<ForeverLogs/>}/>
      <Route path="throttle" element={<Throttle/>}/>
      <Route path="job-moment" element={<JobMoment/>}/>
      <Route path="tax-code-classification" element={<TaxCodeClassification/>}/>
      <Route path="invoice-search-links" element={<InvoiceSearchLinks/>}/>
    </Routes>
  );
}
