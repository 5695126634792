import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {FormatDisplay, useDataTableBasic} from '@iamsoftware/react-hooks';

import {ThrottleService} from './ThrottleService';

export const Throttle = () => {

  const [items, setItems] = useState(null);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    ThrottleService.load().then(data => {
      setItems(data);
    });
  }

  const {render} = useDataTableBasic({
    columns: [
      {field: 'key', header: 'Key', minWidth: 250, matchMode: 'contains'},
      {field: 'address', header: 'Address', minWidth: 250, matchMode: 'contains'},
      {field: 'self', header: 'Self', dataType: 'boolean', width: 150},
      {field: 'REQUESTING', header: 'REQUESTING', width: 170, dataType: 'number'},
      {field: 'TOTAL_REQUEST', header: 'TOTAL_REQUEST', width: 170, dataType: 'number'}
    ],
    items
  });

  return (
    <div className="card">
      <div className="grid p-fluid">
        <div className="col-1">
          <Button label="Reload" icon="pi pi-refresh" onClick={load}/>
        </div>
        <div className="col pt-3">
          <p>Tổng số <b>{FormatDisplay.number(items?.length)}</b> Bot</p>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          {render()}
        </div>
      </div>
    </div>
  );
}