import {useEffect, useRef, useState} from 'react';

import {Messages} from 'primereact/messages';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {Calendar} from 'primereact/calendar';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {HDDTService} from './HDDTService';
import {InvoicesService} from '../../app/hoa-don/InvoicesService';

import {UploadXml} from '../../app/dashboard/UploadXml';

const tctbaoMap = {
  1: 'Huỷ',
  2: 'Điều chỉnh',
  3: 'Thay thế',
  4: 'Giải trình'
};

const tchatMap = {
  1: 'Thay thế cho hóa đơn',
  2: 'Bị thay thế bởi hóa đơn có'
};

const tthaiMap = {
  2: 'Thay thế cho hóa đơn',
  3: 'Điều chỉnh cho hóa đơn',
  4: 'Bị thay thế bởi hóa đơn có',
  5: 'Bị điều chỉnh bởi hóa đơn có'
};

export default function KiemTraHoaDon() {

  const msgs = useRef(null);

  const [captchaContent, setCaptchaContent] = useState(null);
  const [ckey, setCkey] = useState(null);
  const [cvalue, setCvalue] = useState('');
  const [nbmst, setNbmst] = useState('');
  const [type, setType] = useState(null);
  const [khhdon, setKhhdon] = useState('');
  const [shdon, setShdon] = useState('');
  const [tgtttbso, setTgtttbso] = useState(null);
  const [khmshdon, setKhmshdon] = useState(null);
  const [tdlap, setTdlap] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const [tthai, setTthai] = useState(null);
  const [ttxly, setTtxly] = useState(null);
  const [hdtbssrses, setHdtbssrses] = useState(null);
  const [hdonLquans, setHdonLquans] = useState(null);

  const types = [
    {value: '{"khmshdon":1,"hdon":"01"}', label: "1 - Hóa đơn điện tử giá trị gia tăng"},
    {value: '{"khmshdon":2,"hdon":"02"}', label: "2 - Hóa đơn bán hàng"},
    {value: '{"khmshdon":3,"hdon":"03"}', label: "3 - Hóa đơn bán tài sản công"},
    {value: '{"khmshdon":4,"hdon":"04"}', label: "4 - Hóa đơn bán hàng dự trữ quốc gia"},
    {value: '{"khmshdon":5,"hdon":"05"}', label: "5 - Hóa đơn khác"},
    {value: '{"khmshdon":6,"hdon":"06_01"}', label: "6 - Phiếu xuất kho kiêm vận chuyển nội bộ"},
    {value: '{"khmshdon":6,"hdon":"06_02"}', label: "6 - Phiếu xuất kho gửi bán hàng đại lý"}
  ];

  useEffect(() => {
    loadCaptcha();
    setType(types[0].value);

    const script = document.createElement('script');
    const body = document.getElementsByTagName('body')[0];
    script.src = '/m-invoice/tawk.js';
    body.appendChild(script);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCaptcha = () => {
    HDDTService.getCaptcha().then(({key, content}) => {
      setCkey(key);
      setCaptchaContent(content);
      setCvalue('');
    });
  }

  const onTypeChange = (value) => {
    setType(value);
    if (value) {
      setKhmshdon(JSON.parse(value).khmshdon);
    }
  }

  const onKhhdonChange = (value) => {
    if (value.length === 7) {
      value = value.substring(1, 7);
    }
    setKhhdon(value.toUpperCase());
  }

  const reset = () => {
    loadCaptcha();
    setType(types[0].value);
    setNbmst('');
    setKhmshdon('');
    setKhhdon('');
    setShdon('');
    setTgtttbso(null);
    setTdlap(null);
    setCvalue('');
    resetResult();
  }

  const resetResult = () => {
    msgs.current.clear();
    setTthai(null);
    setTtxly(null);
    setHdtbssrses(null);
    setHdonLquans(null);
  }

  const handleSubmit = (event) => {
    resetResult();

    let valid = true;
    if (tgtttbso) {
      if (`${tgtttbso}`.split('.')[0]?.length > 14 || `${tgtttbso}`.split('.')[1]?.length > 7) {
        valid = false;
        msgs.current.show({severity: 'error', detail: 'Tổng tiền thanh toán không hợp lệ', sticky: true});
      }
    }

    if (valid && !submitting) {
      setSubmitting(true);

      const _type = JSON.parse(type);
      HDDTService.queryGuestInvoices({
        khmshdon: _type.khmshdon,
        hdon: _type.hdon,
        nbmst,
        khhdon,
        shdon,
        tgtttbso,
        tdlap: tdlap ? new Date(tdlap).toISOString() : '',
        cvalue,
        ckey
      }).then(result => {
        if (result && result.tthai) {
          InvoicesService.tthais.forEach(t => {
            if (t.value === result.tthai) {
              setTthai(t.label);
            }
          });
          InvoicesService.ttxlys.forEach(t => {
            if (t.value === result.ttxly) {
              setTtxly(t.label);
            }
          });

          if (result.hdtbssrses && result.hdtbssrses.length) {
            let _hdtbssrses = result.hdtbssrses.map((tbss, index) => {
              let _content = '';
              switch (tbss.loai) {
                case 0:
                  _content = `Hóa đơn có thông báo rà soát số ${tbss.so}, ngày thông báo ${FormatDisplay.date(tbss.ngay)}, lý do ${tbss.ldo}.`;
                  break;
                case 1:
                case 2:
                  let ttxly = '';
                  if ([8, 9].includes(tbss.ttxly)) {
                    if (tbss.kqtnhan === 0) {
                      ttxly = `Cơ quan thuế không tiếp nhận, lý do ${tbss.gchu}.`;
                    } else if (tbss.kqtnhan === 1) {
                      ttxly = 'Cơ quan thuế tiếp nhận.';
                    }
                  } else {
                    ttxly = 'Cán bộ thuế đang xử lý.';
                  }
                  _content = `Hóa đơn có ${tbss.ten} ngày ${FormatDisplay.date(tbss.ngay)}. Tính chất ${tctbaoMap[tbss.tctbao]}, lý do ${tbss.ldo}.<br/>${ttxly}`;
                  break;
              }
              return <p key={index} dangerouslySetInnerHTML={{__html: _content}}></p>
            });
            setHdtbssrses(_hdtbssrses);
          }

          if (result.hdonLquans && result.hdonLquans.length) {
            let _hdonLquans = result.hdonLquans.map((hdon, index) => {

              if ([5, 6, 7].includes(hdon.ttxly)) {
                let _content;
                if (4 === result.tthai) {
                  _content = `${tchatMap[hdon.tchat]} ký hiệu mẫu số <b>${hdon.khmshdon}</b>, ký hiệu hóa đơn <b>${hdon.khhdon}</b>, số hóa đơn <b>${hdon.shdon}</b>.`;
                } else {
                  _content = `${tthaiMap[result.tthai]} ký hiệu mẫu số <b>${hdon.khmshdon}</b>, ký hiệu hóa đơn <b>${hdon.khhdon}</b>, số hóa đơn <b>${hdon.shdon}</b>.`;
                }
                return <p key={index} dangerouslySetInnerHTML={{__html: _content}}></p>
              } else {
                return null;
              }

            });
            setHdonLquans(_hdonLquans);
          }

        } else if (result && result.message) {
          msgs.current.show({severity: 'error', detail: result.message, sticky: true});
        } else {
          msgs.current.show({severity: 'error', detail: 'Không tồn tại hóa đơn có thông tin trùng khớp với các thông tin tổ chức, cá nhân tìm kiếm.', sticky: true});
        }
      }).catch(error => {
        if (error && error.message) {
          msgs.current.show({severity: 'error', detail: error.message, sticky: true});
        }
      }).finally(() => {
        setSubmitting(false);
        loadCaptcha();
      });
    }
    event.preventDefault();
  }

  return (
    <div className="layout-wrapper layout-overlay layout-static-sidebar-inactive" style={{background: '#4587ea'}}>
      <div className="layout-topbar">
        <div className="layout-topbar-logo">
          <img src="/m-invoice/logominvoice_ngang-2023.png" alt="" style={{height: '5rem'}}/>
        </div>
      </div>
      <div className="layout-main-container">
        <div className="layout-main">
          <div className="grid">
            <div className="col-12 text-center">
              <h1 className="uppercase text-white">Tra cứu - Kiểm tra hóa đơn hợp lệ</h1>
            </div>
            <div className="col-12 xl:col-6">
              <div className="card h-full">
                <h2>Tra cứu hóa đơn điện tử trên Cơ quan thuế</h2>
                <form onSubmit={handleSubmit}>
                  <div className="p-fluid">
                    <div className="field grid">
                      <label htmlFor="nbmst" className="col-12 mb-2 md:col-4 md:mb-0">Mã số thuế người bán<span className="required">*</span></label>
                      <div className="col-12 md:col-8">
                        <InputText id="nbmst" value={nbmst} onChange={e => setNbmst(e.target.value)} keyfilter="int" maxLength={14} required/>
                      </div>
                    </div>
                    <div className="field grid">
                      <label htmlFor="type" className="col-12 mb-2 md:col-4 md:mb-0">Loại hóa đơn<span className="required">*</span></label>
                      <div className="col-12 md:col-8">
                        <Dropdown id="type" value={type} options={types} onChange={e => onTypeChange(e.value)}/>
                      </div>
                    </div>
                    <div className="field grid">
                      <label htmlFor="khhdon" className="col-12 mb-2 md:col-4 md:mb-0">Ký hiệu hóa đơn<span className="required">*</span></label>
                      <div className="col-12 md:col-8">
                        <InputText id="khhdon" value={khhdon} onChange={e => onKhhdonChange(e.target.value)} keyfilter="alphanum" maxLength={6} required placeholder="Nhập ký hiệu hóa đơn. Ví dụ C21TAA"/>
                      </div>
                    </div>
                    <div className="field grid">
                      <label htmlFor="shdon" className="col-12 mb-2 md:col-4 md:mb-0">Số hóa đơn<span className="required">*</span></label>
                      <div className="col-12 md:col-8">
                        <InputText id="shdon" value={shdon} onChange={e => setShdon(e.target.value)} keyfilter="int" maxLength={8} required/>
                      </div>
                    </div>
                    {khmshdon !== 6 &&
											<div className="field grid">
												<label htmlFor="tgtttbso" className="col-12 mb-2 md:col-4 md:mb-0">Tổng tiền thanh toán<span className="required">*</span></label>
												<div className="col-12 md:col-8">
													<InputNumber locale="vi-VN" inputId="tgtttbso" value={tgtttbso} onValueChange={e => setTgtttbso(e.value)} maxFractionDigits={7} required/>
												</div>
											</div>
                    }
                    {khmshdon === 6 &&
											<div className="field grid">
												<label htmlFor="tdlap" className="col-12 mb-2 md:col-4 md:mb-0">Ngày lập hóa đơn<span className="required">*</span></label>
												<div className="col-12 md:col-8">
													<Calendar id="tdlap" value={tdlap} onChange={e => setTdlap(e.value)} dateFormat="dd/mm/yy" required/>
												</div>
											</div>
                    }
                    <div className="field grid">
                      <div className="col-12 md:col-4"></div>
                      <div className="col-12 md:col-8">
                        <div className="grid">
                          <div className="col-fixed" style={{width: '200px'}}>
                            <label>&nbsp;</label>
                            <div className="captcha" dangerouslySetInnerHTML={{__html: captchaContent}}></div>
                          </div>
                          <div className="col-fixed pl-0" style={{width: '30px'}}>
                            <label>&nbsp;</label>
                            <i className="pi pi-refresh pointer mt-2" style={{fontSize: 20}} onClick={loadCaptcha}></i>
                          </div>
                          <div className="col">
                            <label>&nbsp;</label>
                            <InputText value={cvalue} onChange={e => setCvalue(e.target.value)} required placeholder="Nhập mã captcha"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    {tthai &&
											<div className="card-container blue-container overflow-hidden">
												<p>Tồn tại hóa đơn có thông tin trùng khớp với các thông tin tổ chức, cá nhân tìm kiếm.</p>
												<p>Trạng thái hoá đơn: {tthai}</p>
												<p>Trạng thái xử lý hoá đơn: {ttxly}</p>
                        {hdtbssrses}
                        {hdonLquans}
											</div>
                    }
                    <Messages ref={msgs}/>
                  </div>
                  <div className="grid mt-1">
                    <div className="col-12 text-center">
                      <Button label="Tìm kiếm" icon="pi pi-search" className="p-button-outlined p-button-sm mr-2" loading={submitting}/>
                      <Button label="Bỏ tìm kiếm" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={reset}/>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 xl:col-6">
              <div className="card h-full">
                <h2>Kiểm tra hóa đơn XML</h2>
                <UploadXml me={null}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}