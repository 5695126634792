import {Fragment, useContext, useState} from 'react';

import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {ToastService} from '@iamsoftware/react-hooks';

import {LayoutContext} from 'src/layout/context/layoutcontext';

import {UsersService} from 'src/service/UsersService';

export default function Login() {

  const {layoutConfig} = useContext(LayoutContext);

  const [submitting, setSubmitting] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const [forgotPassword, setForgotPassword] = useState(false);

  const handleSubmit = event => {
    if (!submitting) {
      setSubmitting(true);

      UsersService.login(username, password).then(() => {
        window.location.hash = '/';
      }).catch(() => {
      }).finally(() => {
        setSubmitting(false);
      });
    }
    event.preventDefault();
  }

  const resetPassword = event => {
    if (!submitting) {
      setSubmitting(true);

      UsersService.resetPassword(username, email).then(() => {
        ToastService.success('Hoàn thành yêu cầu đặt lại mật khẩu. Mật khẩu mới đã được gửi đến email cuả quý khách!');
        setForgotPassword(false);
      }).catch(() => {
      }).finally(() => {
        setSubmitting(false);
      });
    }
    event.preventDefault();
  }

  const containerClassName = classNames('surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden', {'p-input-filled': layoutConfig.inputStyle === 'filled'});

  return (
    <div className={containerClassName}>

      <div className="flex flex-column align-items-center justify-content-center">
        <img src="/homecasta/logo.png" alt="Home Casta" className="mb-5 flex-shrink-0" style={{width: '20rem'}}/>

        <div style={{borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)'}}>
          <div className="w-full surface-card py-8 px-5 sm:px-8" style={{borderRadius: '53px'}}>
            <div className="text-center mb-5">
              <div className="text-900 text-3xl font-medium mb-3">{forgotPassword === false ? 'Đăng nhập' : 'Quên mật khẩu'}</div>
              <span className="text-600 font-medium">Quản lý hóa đơn</span>
            </div>

            {!forgotPassword && <Fragment>
							<form onSubmit={handleSubmit} className="w-30rem">
								<label htmlFor="username" className="block text-900 text-xl font-medium mb-2">Tài khoản</label>
								<InputText id="username" className="w-full md:w-30rem mb-5" value={username} onChange={e => setUsername(e.target.value)} required/>

								<label htmlFor="password" className="block text-900 text-xl font-medium mb-2">Mật khẩu</label>
								<Password id="password" className="w-full md:w-30rem mb-5" value={password} onChange={e => setPassword(e.target.value)} toggleMask feedback={false} required/>

								<Button label="Đăng nhập" icon="pi pi-user" className="w-full" loading={submitting}/>
							</form>
							<div className="grid mt-2">
								<div className="col-12 text-right">
									<span className="text-primary pointer" onClick={() => setForgotPassword(true)}>Quên mật khẩu?</span>
								</div>
							</div>
						</Fragment>}

            {forgotPassword && <Fragment>
							<form onSubmit={resetPassword} className="w-30rem">

								<label htmlFor="username" className="block text-900 text-xl font-medium mb-2">Tài khoản</label>
								<InputText id="username" className="w-full md:w-30rem mb-5" value={username} onChange={e => setUsername(e.target.value)} required/>

								<label htmlFor="emailAddress" className="block text-900 text-xl font-medium mb-2">Email</label>
								<InputText id="emailAddress" className="w-full md:w-30rem mb-5" value={email} onChange={e => setEmail(e.target.value)} required/>

								<Button label="Lấy lại mật khẩu" icon="pi pi-history" className="w-full" severity="warning" loading={submitting}/>
							</form>
							<div className="grid mt-2">
								<div className="col-12">
									<span className="text-primary pointer" onClick={() => setForgotPassword(false)}><i className="pi pi-arrow-left mr-2"></i>Quay lại</span>
								</div>
							</div>
						</Fragment>}

          </div>
        </div>
      </div>
    </div>
  );
}