import {ReportService} from '../ReportService';

import {BangKe} from '../BangKe';

export const BangKeMuaVao = () => {

  const header = 'Bảng kê hoá đơn mua vào';

  const partyColumns = [
    {
      field: 'data.nbten', header: 'Tên người bán', width: 200, dataType: 'custom', customCell(rowData: any): any {
        return rowData.header?.nbten || rowData.header?.nbtnban;
      }
    },
    {
      field: 'header.nbdchi', header: 'Địa chỉ', width: 200, sortable: false, dataType: 'custom', customCell(rowData: any): any {
        return rowData.header ? rowData.header.nbdchi : '';
      }
    },
    {
      field: 'data.keys', header: 'Mã số thuế', width: 120, dataType: 'custom', customCell(rowData: any): any {
        return rowData.keys ? rowData.keys.nbmst : '';
      }
    }
  ];

  const endColumns = [
    {
      field: 'data.nmten', header: 'Tên người mua', width: 200, dataType: 'custom', customCell(rowData: any): any {
        return rowData.data ? rowData.data.nmten : '';
      }
    },
    {
      field: 'header.nmdchi', header: 'Địa chỉ người mua', width: 200, sortable: false, dataType: 'custom', customCell(rowData: any): any {
        return rowData.header ? rowData.header.nmdchi : '';
      }
    },
    {
      field: 'data.nmmst', header: 'MST người mua', width: 150, dataType: 'custom', customCell(rowData: any): any {
        return rowData.data ? rowData.data.nmmst : '';
      }
    }
  ];

  const getData = (account: string, lazyLoadEvent: string) => {
    return ReportService.getBangKe('purchase', account, lazyLoadEvent);
  }

  const fieldFilter = [
    {field: 'nbmst', header: 'MST bên bán', className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'}
  ];

  return <BangKe header={header} partyColumns={partyColumns} endColumns={endColumns} fieldFilter={fieldFilter} defaultFilter={0} getData={getData}/>;
}