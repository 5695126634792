import {EntityService} from '../../service/EntityService';

export class EmailService extends EntityService {

  static entity = 'email';

  static getSync(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/sync`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static getMessages(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/email_messages`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static getMessage(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/email_messages/${id}`).then(res => res.data);
  }

}
