import {Fragment, useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {ClassificationService} from '../classification/ClassificationService';
import {ApplService as Service} from './ApplService';
import {XlsxService} from '../../../../service/XlsxService';

export const Appl = () => {

  const [classes, setClasses] = useState([]);
  const [classification, setClassification] = useState(null);

  useEffect(() => {
    const _event = {
      filters: {
        active: {value: true, matchMode: 'equals'},
        ref: {value: 'SMI', matchMode: 'equals'}
      }
    };
    ClassificationService.getList(JSON.stringify(_event)).then(({items}) => {
      setClasses(items.map(item => {
        return {value: item._id, label: item.name};
      }));
      if (items?.length) {
        setClassification(items[0]._id);
      }
    });
  }, []);

  return (
    <div className="grid">
      <div className="col-8 p-fluid">
        <Dropdown value={classification} options={classes} onChange={e => setClassification(e.value)} filter={true}/>
      </div>

      <Screen classification={classification}/>
    </div>
  );
}

const Screen = ({classification}) => {

  const dataKey = '_id';

  const inputExcel = useRef(null);

  const [requiredParams, setRequiredParams] = useState(null);

  useEffect(() => {
    if (classification) {
      setRequiredParams({classification: {value: classification, matchMode: 'ObjectId'}});
    } else {
      setRequiredParams(null);
    }
  }, [classification]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: Array<any> = [
    {field: 'mst', header: 'Mã số thuế', width: 160, matchMode: 'contains'},
    {field: 'tennnt', header: 'Tên người nộp thuế', minWidth: 150, matchMode: 'contains'},
    {field: 'macqt', header: 'CQT', width: 160, matchMode: 'contains'},
    {field: 'tencqt', header: 'Tên Cơ quan thuế', minWidth: 160, matchMode: 'contains'},
    {field: 'gchu', header: 'Ghi chú', minWidth: 150, matchMode: 'contains'}
  ];

  const {render: renderDataTable, refreshLazyData, reloadLazyData} = useDataTable({
    dataKey,
    columns,
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return Service.getList(params);
    },
    requiredParams,
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => update(item)},
            {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => deleteItem(item)}
          ];
        });
      }
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header: 'Mã số thuế',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'mst', header: 'Mã số thuế', required: true, className: 'md:col-12'},
      {field: 'tennnt', header: 'Tên người nộp thuế', className: 'md:col-12'},
      {field: 'macqt', header: 'CQT', className: 'md:col-12'},
      {field: 'tencqt', header: 'Tên Cơ quan thuế', className: 'md:col-12'},
      {field: 'gchu', header: 'Ghi chú', type: 'InputTextarea', className: 'md:col-12'}
    ],
    createItem: (item) => {
      item.classification = classification;
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData: refreshLazyData
  });

  const deleteItem = item => {
    ConfirmDialogService.confirm('Xoá MST', `Xoá Mã số thuế ${item.mst}?`, () => {
      Service.delete(item._id).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    });
  }

  const deleteMany = () => {
    ConfirmDialogService.confirm('Xoá MST', 'Xoá toàn bộ Mã số thuế của Tình trạng này?', () => {
      ClassificationService.deleteAppl(classification).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    });
  }

  const downloadExcel = () => {
    const _event = {
      filters: requiredParams
    };
    Service.getList(JSON.stringify(_event)).then(({items}) => {
      const aoa = [[]];
      columns.forEach(column => {
        aoa[0].push(column.header);
        items.forEach((item, index) => {
          if (!aoa[index + 1]) {
            aoa[index + 1] = [];
          }
          aoa[index + 1].push(XlsxService.getCellValue(column, item));
        });
      });

      XlsxService.generateFile(aoa, 'danh-sach-mst');
    });
  }

  const onUploadExcel = e => {
    if (e.target.files && e.target.files.length === 1) {
      XlsxService.readExcelFile(e.target.files[0], {startRow: 1}).then(rows => {
        if (rows?.length) {
          const batchItems = [];
          rows.forEach(row => {
            if (row[0]) {
              const existsItem = batchItems.find(it => it.mst === row[0]);
              if (existsItem) {
                existsItem.gchuSet.add((row[4] || '').trim());
              } else {
                const gchuSet = new Set();
                gchuSet.add((row[4] || '').trim());

                const item = {
                  classification,
                  mst: row[0],
                  tennnt: row[1],
                  macqt: row[2],
                  tencqt: row[3],
                  gchuSet
                };
                batchItems.push(item);
              }
            }
          });
          batchItems.forEach(batchItem => {
            batchItem.gchu = Array.from(batchItem.gchuSet).join('; ');
            delete batchItem.gchuSet;
          });
          Service.batchStore(batchItems).then(() => {
            ToastService.success();
            reloadLazyData();
          });
        } else {
          ToastService.error('Không có dữ liệu');
        }
      });
    }
  }

  return (
    <Fragment>
      <div className="col-12 flex justify-content-between">
        <div className="flex gap-2">
          <Button label="Thêm" icon="pi pi-plus" className="p-button-sm" severity="success" onClick={create}/>
          <Button label="Xoá tất cả" icon="pi pi-trash" className="p-button-sm" severity="danger" onClick={deleteMany}/>
        </div>
        <div className="flex gap-2">
          <Button label="Import Excel" icon="pi pi-file-import" className="p-button-sm" onClick={() => inputExcel.current.click()}/>
          <input ref={inputExcel} type="file" accept=".xlsx, .xls" onChange={onUploadExcel} style={{display: 'none'}}/>
          <Button label="Export Excel" icon="pi pi-file-export" className="p-button-sm" onClick={downloadExcel}/>
        </div>
      </div>
      <div className="col-12 p-datatable-paginator-sticky">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </Fragment>
  );
}