import {Fragment, useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {FilterMatchMode} from 'primereact/api';

import {InvoicesService} from './InvoicesService';
import {ToastService, useDialogCrup} from '@iamsoftware/react-hooks';
import {TagsService as Service} from './nhan-gan/TagsService';

export const Tags = ({display, setDisplay, tags, loadTags, reloadLazyData, actionType}) => {

  const header = 'Nhãn hoá đơn';
  const dataKey = '_id';

  const [selectedTags, setSelectedTags] = useState(null);

  useEffect(() => {
    if (display) {
      if (!Array.isArray(display) && display.tags && display.tags.length) {
        const _selectedTags = [];
        tags.forEach(tag => {
          display.tags.forEach(t => {
            if (t === tag._id) {
              _selectedTags.push(tag);
            }
          });
        });
        setSelectedTags(_selectedTags);
      } else {
        setSelectedTags(null);
      }
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const save = () => {
    if (actionType === 'PullTags') {
      if (!selectedTags?.length) {
        return ToastService.error('Chưa chọn nhãn hoá đơn');
      }
    }
    if (Array.isArray(display)) {
      const complete = [];
      display.forEach(invoice => {
        const _tags = [];
        if (actionType === 'PullTags') {
          const _selectedTags = [];
          selectedTags.forEach(tag => {
            _selectedTags.push(tag[dataKey]);
          });

          if (invoice.tags?.length) {
            invoice.tags.forEach(invT => {
              if (!_selectedTags.includes(invT)) {
                _tags.push(invT);
              }
            });
          }
        } else {
          if (selectedTags && selectedTags.length) {
            selectedTags.forEach(tag => {
              _tags.push(tag[dataKey]);
            });
          }
          if (invoice.tags?.length) {
            _tags.push(...invoice.tags);
          }
        }
        complete.push(InvoicesService.patchTags(invoice[dataKey], [...new Set(_tags) as any]));
      });
      Promise.all(complete).then(() => {
        setDisplay(false);
        reloadLazyData();
        ToastService.success();
      });
    } else {
      const _tags = [];
      if (selectedTags && selectedTags.length) {
        selectedTags.forEach(tag => {
          _tags.push(tag[dataKey]);
        });
      }
      InvoicesService.patchTags(display[dataKey], [...new Set(_tags) as any]).then(() => {
        setDisplay(false);
        reloadLazyData();
        ToastService.success();
      });
    }
  }

  const {render: renderDialogCrup, create} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'name', header: 'Tên nhãn dán', required: true, InputTextProps: {maxLength: 100}, className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData: item => {
      loadTags();
      setSelectedTags(prevTags => {
        if (!prevTags) {
          prevTags = [];
        }
        prevTags.push(item);
        return prevTags;
      });
    }
  });

  const createElement = (
    <Button icon="pi pi-plus" onClick={() => create()} className="p-button-rounded p-button-outlined"/>
  )

  const footer = (
    <div>
      <Button label="Lưu" icon="pi pi-check" className="p-button-sm mr-2" onClick={save}/>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Fragment>
      <Dialog header={header} footer={footer} visible={!!display} style={{width: '40rem'}} position="top" onHide={() => setDisplay(false)}>

        <DataTable value={tags} selection={selectedTags} onSelectionChange={e => setSelectedTags(e.value)} dataKey={dataKey} filterDisplay="row">
          <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
          <Column field="name" header="Tên nhãn dán" filter filterPlaceholder="Tìm theo tên nhãn dán" showFilterMenu={false} filterMatchMode={FilterMatchMode.CONTAINS}></Column>
          <Column headerStyle={{width: '3em'}} filter showFilterMenu={false} showClearButton={false} filterElement={createElement}></Column>
        </DataTable>

      </Dialog>

      {renderDialogCrup()}
    </Fragment>
  );
}