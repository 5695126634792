import {Fragment, useEffect, useState} from 'react';

import moment from 'moment';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {LicensesService} from '../LicensesService';
import {AgentsService} from '../AgentsService';

export const License = ({display, setDisplay, user, reloadLazyData}) => {

  const [currentUser, setCurrentUser] = useState(null);

  const [requiredParams, setRequiredParams] = useState(null);

  useEffect(() => {
    if (user) {
      setRequiredParams({user: {value: user._id, matchMode: 'ObjectId'}});
      setCurrentUser(user);
    }
  }, [user]);

  const {render: renderDataTable, reloadLazyData: reloadLicense} = useDataTable({
    dataKey: '_id',
    columns: [
      {field: 'created_date', header: 'Ngày cấp', width: 130, dataType: 'date'},
      {field: 'month', header: 'Số tháng sử dụng', width: 170, dataType: 'number'},
      {field: 'from_date', header: 'Ngày bắt đầu', width: 130, dataType: 'date'},
      {field: 'thru_date', header: 'Ngày hết hạn', width: 130, dataType: 'date'},
      {field: 'invoice_quantity', header: 'Số lượng hóa đơn', width: 180, dataType: 'number'},
      {field: 'grand_total', header: 'Thành tiền', width: 150, dataType: 'number'},
      {field: 'description', header: 'Ghi chú', minWidth: 150}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return LicensesService.getList(params);
    },
    requiredParams,
    initActions: items => {
      if (items) {
        items.forEach(item => {
          if (currentUser?.license && currentUser.license._id === item._id) {
            item.actions = [
              {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => doUpdate(item)}
            ];
          }
        });
      }
    }
  });

  const doUpdate = (item) => {
    item.from_date = new Date(item.from_date);
    item.thru_date = new Date(item.thru_date);
    update(item);
  }

  const reload = () => {
    const _event = {
      filters: {
        _id: {value: user._id, matchMode: 'ObjectId'}
      }
    };
    AgentsService.getList(JSON.stringify(_event)).then(({items}) => {
      setCurrentUser(items[0]);
      reloadLazyData();
      reloadLicense();
    });
  }

  const {render: renderDialogCrup, create, update, form} = useDialogCrup({
    header: 'Gói dịch vụ',
    dataKey: '_id',
    width: '40rem',
    fields: [
      {field: 'month', header: 'Số tháng', required: true, type: 'InputNumber', className: 'md:col-12'},
      {field: 'from_date', header: 'Ngày bắt đầu', required: true, type: 'Calendar', className: 'md:col-12'},
      {field: 'thru_date', header: 'Ngày hết hạn', required: true, type: 'Calendar', className: 'md:col-12'},
      {field: 'invoice_quantity', header: 'Số lượng hóa đơn', required: true, type: 'InputNumber', className: 'md:col-12'},
      {field: 'grand_total', header: 'Tổng tiền thanh toán', type: 'InputNumber', className: 'md:col-12'},
      {field: 'description', header: 'Ghi chú', InputTextProps: {maxLength: 500}, className: 'md:col-12'}
    ],
    createItem: item => {
      item.user = user._id;
      return LicensesService.create(item);
    },
    updateItem: (id, item) => {
      return LicensesService.update(id, item);
    },
    reloadLazyData: reload
  });

  const {month, from_date} = form.getRawValue();

  useEffect(() => {
    if (month && from_date) {
      const _value = form.getRawValue();
      if (!_value.isUpdateValue) {
        _value.thru_date = moment(from_date).add(month, 'months').toDate();
        form.setValue(_value);
      }
    }
  }, [month, from_date]); // eslint-disable-line react-hooks/exhaustive-deps

  const renewal = () => {
    create({
      month: currentUser.license.month,
      from_date: new Date(),
      invoice_quantity: currentUser.license.invoice_quantity,
      grand_total: currentUser.license.grand_total
    });
  }

  return (
    <Fragment>
      <Dialog header="Gói dịch vụ" visible={display} style={{width: '90vw'}} position="top" onHide={() => setDisplay(false)}>

        {renderDataTable()}

        <div className="flex justify-content-end">
          <Button label="Áp dụng gói dịch vụ mới" icon="pi pi-plus" onClick={() => create({from_date: new Date()})} className="p-button-sm p-button-success mr-2"/>
          {currentUser?.license &&
						<Button label="Gia hạn gói dịch vụ" icon="pi pi-refresh" onClick={renewal} className="p-button-sm mr-2"/>
          }
          <Button label="Đóng" icon="pi pi-times" onClick={() => setDisplay(false)} className="p-button-outlined p-button-secondary p-button-sm"/>
        </div>
      </Dialog>

      {renderDialogCrup()}
    </Fragment>
  );
}