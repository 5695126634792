import {Route, Routes} from 'react-router-dom';

import {BangKeBanRa} from './bang-ke-ban-ra';
import {BangKeMuaVao} from './bang-ke-mua-vao';
import {HoaDonMuaDcTt} from './hoa-don-mua-dc-tt';
import {BangKePmkt} from './bang-ke-pmkt';
import {ExportExcelConfig} from './bang-ke-pmkt/config/ExportExcelConfig';
import {ToKhaiThue} from './to-khai-thue';
import {BieuDoHoaDon} from './bieu-do-hoa-don';
import {TaxCodeInfo} from './tinh-trang-doanh-nghiep';
import {TinhHinhSuDung} from './tinh-hinh-su-dung';

export const BaoCao = ({me}) => {
  return (
    <Routes>
      <Route path="bang-ke-ban-ra" element={<BangKeBanRa/>}/>
      <Route path="bang-ke-mua-vao" element={<BangKeMuaVao/>}/>
      <Route path="hoa-don-mua-dc-tt" element={<HoaDonMuaDcTt/>}/>
      <Route path="bang-ke-pmkt" element={<BangKePmkt/>}/>
      <Route path="bang-ke-pmkt/config" element={<ExportExcelConfig me={me}/>}/>
      <Route path="to-khai-thue" element={<ToKhaiThue/>}/>
      <Route path="bieu-do-hoa-don" element={<BieuDoHoaDon/>}/>
      <Route path="tinh-trang-doanh-nghiep" element={<TaxCodeInfo/>}/>
      <Route path="tinh-hinh-su-dung" element={<TinhHinhSuDung/>}/>
    </Routes>
  );
}