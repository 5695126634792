import {Service} from '../../service/Service';

export class InvoicesService extends Service {

  static entity = 'invoices';

  static getList(account: string, type: string, lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/account/${account}/type/${type}`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static incPriority(_id: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/priority`).then(res => res.data);
  }

  static downloadXml(_id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${_id}/download/invoice.xml`, {responseType: 'blob'}).then(res => res.data);
  }

  static getAttachments(_id: string, lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/${_id}/attachments`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static createAttachment(_id: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${_id}/attachments`, data).then(res => res.data);
  }

  static updateAttachment(_id: string, attachment: string, description: string): Promise<any> {
    return this.axios.put(`${this.entity}/${_id}/attachments/${attachment}`, {description}).then(res => res.data);
  }

  static deleteAttachment(_id: string, attachment: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${_id}/attachments/${attachment}`).then(res => res.data);
  }

  static getAttachmentUrl(_id: string, attachment: string): string {
    return `${this.baseURL}/${this.entity}/${_id}/attachments/${attachment}/download`;
  }

  static getRelative(_id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${_id}/relative`).then(res => res.data);
  }

  static getRelated(_id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${_id}/related`).then(res => res.data);
  }

  static patchTags(_id: string, tags: any): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/tags`, {tags}).then(res => res.data);
  }

  static patchComment(_id: string, comment: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/comment`, {comment}).then(res => res.data);
  }

  static patchNkekhai(_id: string, data: any): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/nkekhai`, data).then(res => res.data);
  }

  static patchHideStatus(_id: string, hide_status: boolean): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/hide_status`, {hide_status}).then(res => res.data);
  }

  static getTaxCodes(account: string, type: string, filters: string): Promise<any> {
    return this.axios.get(`${this.entity}/tax-codes`, {params: {account, type, filters}}).then(res => res.data);
  }

  static getSearchHint(_id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${_id}/search-hint`).then(res => res.data);
  }

  static patchSearchKey(_id: string, search_key: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/search_key`, {search_key}).then(res => res.data);
  }

  static patchTthai(_id: string, tthai: number): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/tthai`, {tthai}).then(res => res.data);
  }

  static tthais = [
    {value: '', label: 'Tất cả'},
    {value: 1, label: 'Hóa đơn mới'},
    {value: 2, label: 'Hóa đơn thay thế'},
    {value: 3, label: 'Hóa đơn điều chỉnh'},
    {value: 4, label: 'Hóa đơn đã bị thay thế'},
    {value: 5, label: 'Hóa đơn đã bị điều chỉnh'},
    {value: 6, label: 'Hóa đơn đã bị hủy'}
  ];

  static m_tthais = [
    {value: 1, label: 'Hóa đơn mới'},
    {value: 2, label: 'Hóa đơn thay thế'},
    {value: 3, label: 'Hóa đơn điều chỉnh'},
    {value: 4, label: 'Hóa đơn đã bị thay thế'},
    {value: 5, label: 'Hóa đơn đã bị điều chỉnh'},
    {value: 6, label: 'Hóa đơn đã bị hủy'}
  ];

  static ttxlys = [
    {value: '', label: 'Tất cả'},
    {value: 0, label: 'Tổng cục Thuế đã nhận'},
    {value: 1, label: 'Đang tiến hành kiểm tra điều kiện cấp mã'},
    {value: 2, label: 'CQT từ chối hóa đơn theo từng lần phát sinh'},
    {value: 3, label: 'Hóa đơn đủ điều kiện cấp mã'},
    {value: 4, label: 'Hóa đơn không đủ điều kiện cấp mã'},
    {value: 5, label: 'Đã cấp mã hóa đơn'},
    {value: 6, label: 'Tổng cục thuế đã nhận không mã'},
    {value: 7, label: 'Đã kiểm tra định kỳ HĐĐT không có mã'},
    {value: 8, label: 'Tổng cục thuế đã nhận hóa đơn có mã khởi tạo từ máy tính tiền'}
  ];

  static khmshdons = [
    {value: '', label: 'Tất cả'},
    {value: 1, label: "1 - Hóa đơn điện tử giá trị gia tăng"},
    {value: 2, label: "2 - Hóa đơn bán hàng"},
    {value: 3, label: "3 - Hóa đơn bán tài sản công"},
    {value: 4, label: "4 - Hóa đơn bán hàng dự trữ quốc gia"},
    {value: 5, label: "5 - Hóa đơn khác"},
    {value: '6-N', label: "6 - Phiếu xuất kho kiêm vận chuyển nội bộ"},
    {value: '6-B', label: "6 - Phiếu xuất kho gửi bán hàng đại lý"}
  ];

  static m_khmshdons = [
    {value: 1, label: "1 - Hóa đơn điện tử giá trị gia tăng"},
    {value: 2, label: "2 - Hóa đơn bán hàng"},
    {value: 3, label: "3 - Hóa đơn bán tài sản công"},
    {value: 4, label: "4 - Hóa đơn bán hàng dự trữ quốc gia"},
    {value: 5, label: "5 - Hóa đơn khác"},
    {value: '6-N', label: "6 - Phiếu xuất kho kiêm vận chuyển nội bộ"},
    {value: '6-B', label: "6 - Phiếu xuất kho gửi bán hàng đại lý"}
  ];

  static kqktras = [
    {value: '', label: 'Tất cả'},
    {value: 'Cảnh báo', label: "Cảnh báo"},
    {value: 'Hợp lệ', label: "Hợp lệ"}
  ];

}
