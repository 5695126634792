import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import {FormatDisplay, ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';
import {XlsxService} from '../../service/XlsxService';

export const DownloadXmlFail = ({display, setDisplay, mstColumn, items}) => {

  const header = 'Hóa đơn không tải được XML';

  const columns: Array<any> = [
    {
      field: 'keys.khmshdon', header: 'Mẫu số', width: 100, matchMode: 'equals', dataType: 'custom', customCell(rowData): any {
        return rowData.keys?.khmshdon;
      }
    },
    {
      field: 'keys.khhdon', header: 'Ký hiệu', width: 100, matchMode: 'equals', dataType: 'custom', customCell(rowData): any {
        return rowData.keys?.khhdon;
      }
    },
    {
      field: 'keys.shdon', header: 'Số hóa đơn', width: 120, matchMode: 'equals', dataType: 'custom', customCell(rowData): any {
        return rowData.keys?.shdon;
      }
    },
    {
      field: 'data.tdlap', header: 'Ngày lập', width: 110, dataType: 'custom', customCell(rowData): any {
        return rowData.data ? FormatDisplay.date(rowData.data.tdlap) : '';
      }
    },
    mstColumn
  ];

  const downloadExcel = () => {
    if (!items?.length) {
      return ToastService.error('Không tìm thấy hoá đơn');
    }

    const aoa = [[]];
    columns.forEach(column => {
      aoa[0].push(column.header);
      items.forEach((item, index) => {
        if (!aoa[index + 1]) {
          aoa[index + 1] = [];
        }
        aoa[index + 1].push(XlsxService.getCellValue(column, item));
      });
    });

    XlsxService.generateFile(aoa, 'hoa-don-khong-tai-duoc-xml');
  }

  const tableHeader = <div className="flex flex-wrap align-items-center justify-content-between gap-2">
    <span className="font-bold">Hóa đơn không mã gửi theo Bảng Tổng Hợp</span>
    <span className="text-primary underline pointer" onClick={downloadExcel}><i className="pi pi-file-excel"></i> Kết xuất Excel</span>
  </div>

  const {render: renderDataTable} = useDataTableBasic({
    tableHeader,
    dataKey: '_id',
    indexColumnWidth: 45,
    columns,
    items
  });

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>

      {renderDataTable()}

    </Dialog>
  );
}