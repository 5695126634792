import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {FormatDisplay, useDataTable, useForm} from '@iamsoftware/react-hooks';

import {ExternalAccountsService} from '../../tai-khoan-thue/ExternalAccountsService';
import {HdtbssService as Service} from './HdtbssService';

import {ThongBaoSS} from './ThongBaoSS';

export const Hdtbss = () => {

  const [accounts, setAccounts] = useState([]);

  const [requiredParams, setRequiredParams] = useState(null);

  useEffect(() => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        _accounts.push({...item, label: `${item.username} - ${item.profile?.name || ''}`});
      });
      setAccounts(_accounts);
    });
  }, []);

  useEffect(() => {
    if (accounts?.length) {
      setValue({
        account: accounts[0],
        type: types[0].value
      });
    }
  }, [accounts]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSearchParams = () => {
    const {account, type, ngay} = getValue();
    const searchParams: any = {
      account: {value: account?._id, matchMode: 'ObjectId'},
      type: {value: type, matchMode: 'equals'}
    };

    if (ngay) {
      searchParams['data.ngay'] = {value: `${ngay[0]}@${ngay[1]}`, matchMode: 'between'};
    } else {
      searchParams['data.ngay'] = {value: '', matchMode: 'between'};
    }

    return searchParams;
  }

  const search = () => {
    if (formValid()) {
      setRequiredParams(getSearchParams());
    }
  }

  const {render: renderForm, setValue, valid: formValid, getValue} = useForm({
    fields: [
      {field: 'account', header: 'Tài khoản thuế', required: true, type: 'Dropdown', DropdownProps: {options: accounts, filter: true, resetFilterOnHide: true}, className: 'md:col-6'},
      {field: 'type', header: 'Loại hoá đơn', required: true, type: 'Dropdown', DropdownProps: {options: types}, className: 'md:col-2'},
      {field: 'ngay', header: 'Ngày thông báo', type: 'Period', className: 'md:col-2'},
      {type: 'custom', className: 'md:col-2 un-fluid', body: <Button label="Đồng bộ thủ công" className="p-button-sm" style={{marginTop: '24px'}}/>}
    ]
  });

  const {account, type, ngay} = getValue();
  useEffect(() => {
    search();
  }, [account, type, ngay]); // eslint-disable-line react-hooks/exhaustive-deps

  if (accounts?.length) {
    return (
      <div className="p-datatable-paginator-sticky" style={{minHeight: '50vh'}}>
        {renderForm()}
        {type && <Screen type={type} requiredParams={requiredParams}/>}
      </div>
    );
  } else {
    return (
      <div className="p-datatable-paginator-sticky" style={{height: '20rem'}}>
        <b>Bạn chưa liên kết tài khoản đơn.</b>
      </div>
    );
  }

}

const Screen = ({type, requiredParams}) => {

  const dataKey = '_id';

  const [displayThongBaoSS, setDisplayThongBaoSS] = useState(null);

  const _columns = [];

  if (type === 'sold') {
    _columns.push({
      field: 'data.so', header: 'Số thông báo', width: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        return rowData.data?.so;
      }
    });
  }

  _columns.push(...[
    {
      field: 'data.ngay', header: 'Ngày thông báo', width: 160, dataType: 'custom', customCell(rowData: any): any {
        return FormatDisplay.date(rowData.data?.ngay);
      }
    },
    {
      field: 'data.kqtnhan', header: 'Kết quả', width: 150, filterType: 'dropdown', filterOptions: Service.kqtnhanList, matchMode: 'equals', dataType: 'custom', customCell(rowData: any): any {
        return <span className={rowData.data?.kqtnhan === 1 ? 'alert-success' : 'alert-danger'}>{Service.kqtnhanMap[rowData.data?.kqtnhan || 0]}</span>
      }
    },
    {
      field: 'data.khhdon', header: 'Ký hiệu', width: 95, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        return `${rowData.data?.khmshdon}${rowData.data?.khhdon}`;
      }
    },
    {
      field: 'data.shdon', header: 'Số hóa đơn', width: 120, matchMode: 'equals', dataType: 'custom', customCell(rowData: any): any {
        return <span className={rowData.is_domestic ? 'alert-warning' : ''}>{rowData.data?.shdon}</span>;
      }
    },
    {
      field: 'dlhdon.tdlap', header: 'Ngày hóa đơn', width: 130, dataType: 'custom', customCell(rowData: any): any {
        return FormatDisplay.date(rowData.dlhdon?.tdlap);
      }
    },
    {
      field: 'data.tctbao', header: 'Tính chất', width: 150, filterType: 'dropdown', filterOptions: Service.tctbaoList, matchMode: 'equals', dataType: 'custom', customCell(rowData: any): any {
        return Service.tctbaoMap[rowData.data?.tctbao];
      }
    },
    {
      field: type === 'sold' ? 'assets.hdtbssrses.ldo' : 'data.ldo', header: 'Lý do', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        if (type === 'sold') {
          return rowData.assets?.hdtbssrses?.ldo || '';
        }
        return rowData.data?.ldo || '';
      }
    },
    ...(type === 'sold' ? sold_columns : purchase_columns)
  ]);

  const {render: renderDataTable} = useDataTable({
    stateKey: `table-${type}`,
    tableHeader: 'Thông báo 04SS',
    dataKey,
    columns: _columns,
    indexColumnWidth: 50,
    actionColumnWidth: 80,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams,
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', className: 'p-button-info', title: 'Xem in', command: () => setDisplayThongBaoSS(item)}
          ];
        });
      }
    }
  });

  return <Fragment>
    {renderDataTable()}

    <ThongBaoSS display={displayThongBaoSS} setDisplay={setDisplayThongBaoSS}/>
  </Fragment>;
}

const types = [
  {value: 'sold', label: 'Bán ra'},
  {value: 'purchase', label: 'Mua vào'}
];

const sold_columns = [
  {
    field: 'dlhdon.nmmst', header: 'Người mua', minWidth: 160, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
      const nm = [];
      if (rowData.dlhdon?.nmmst) {
       nm.push(rowData.dlhdon?.nmmst);
      }
      if (rowData.dlhdon?.nmten) {
        nm.push(rowData.dlhdon?.nmten);
      } else {
        nm.push(rowData.dlhdon?.nmtnmua || '');
      }
      return nm.join(' - ');
    }
  }
];
const purchase_columns = [
  {
    field: 'dlhdon.nbmst', header: 'MST người bán', width: 160, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
      return rowData.dlhdon?.nbmst;
    }
  },
  {
    field: 'dlhdon.nbten', header: 'Tên người bán', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
      return rowData.dlhdon?.nbten;
    }
  }
];