import {Route, Routes} from 'react-router-dom';

import {TaiKhoan} from './tai-khoan';
import {HopThuDen} from './hop-thu-den';
import {DongBo} from './dong-bo';

export const HopThu = ({me}) => {
  return (
    <Routes>
      <Route path="tai-khoan" element={<TaiKhoan/>}/>
      <Route path="hop-thu-den" element={<HopThuDen me={me}/>}/>
      <Route path="dong-bo" element={<DongBo/>}/>
    </Routes>
  );
}