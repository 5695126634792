import {Fragment, useState} from 'react';

import moment from 'moment';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {RadioButton} from 'primereact/radiobutton';

import {filterDateOptions} from '../filter';

export const PreCreate = ({display, setDisplay, doCreate}) => {

  const [kieuKy, setKieuKy] = useState('M');
  const [month, setMonth] = useState(null);
  const [quarter, setQuarter] = useState(null);
  const [year, setYear] = useState(null);
  const [filterDateBy, setFilterDateBy] = useState(null);

  const [PL_NQ43_GTGT, setPL_NQ43_GTGT] = useState(false);

  const onShow = () => {
    setMonth(moment().get('month'));
    setQuarter(moment().get('quarter') - 1 || 1);
    setYear(moment().get('year'));
    setPL_NQ43_GTGT(false);
    setFilterDateBy('tdlap');
  }

  const onSubmit = event => {
    doCreate({kieuKy, month, quarter, year, PL_NQ43_GTGT, filterDateBy});
    event.preventDefault();
  }

  return (
    <Dialog header="Chọn kỳ tính thuế" visible={display} style={{width: '40rem'}} position="top" closeOnEscape={false} onHide={() => setDisplay(false)} onShow={onShow}>
      <form onSubmit={onSubmit}>
        <div className="p-fluid">
          <div className="field grid">
            <div className="col-4">
              <div className="field-radiobutton mb-0">
                <RadioButton inputId="kieuKy1" name="kieuKy" value="M" onChange={e => setKieuKy(e.value)} checked={kieuKy === 'M'}/>
                <label htmlFor="kieuKy1">Tờ khai tháng</label>
              </div>
            </div>
            <div className="col-6">
              <div className="field-radiobutton mb-0">
                <RadioButton inputId="kieuKy2" name="kieuKy" value="Q" onChange={e => setKieuKy(e.value)} checked={kieuKy === 'Q'}/>
                <label htmlFor="kieuKy2">Tờ khai quý</label>
              </div>
            </div>
          </div>
          <div className="field grid">
            {kieuKy === 'M' &&
							<Fragment>
								<label htmlFor="month" className="col-2 mb-2 md:mb-0">Tháng</label>
								<div className="col-2">
									<InputNumber locale="vi-VN" inputId="month" value={month} onValueChange={e => setMonth(e.value)} min={1} max={12} format={false}/>
								</div>
							</Fragment>
            }
            {kieuKy === 'Q' &&
							<Fragment>
								<label htmlFor="quarter" className="col-2 mb-2 md:mb-0">Quý</label>
								<div className="col-2">
									<InputNumber locale="vi-VN" inputId="quarter" value={quarter} onValueChange={e => setQuarter(e.value)} min={1} max={4} format={false}/>
								</div>
							</Fragment>
            }
            <label htmlFor="year" className="col-2 mb-2 md:mb-0 justify-content-center">Năm</label>
            <div className="col-2">
              <InputNumber locale="vi-VN" inputId="year" value={year} onValueChange={e => setYear(e.value)} min={2000} max={3000} format={false}/>
            </div>
          </div>
          <div className="field-checkbox mt-5">
            <Checkbox inputId="binary" checked={PL_NQ43_GTGT} onChange={e => setPL_NQ43_GTGT(e.checked)}/>
            <label htmlFor="binary">Kèm PL 101/2023/QH15</label>
          </div>
          <div className="field grid">
            <label htmlFor="filterDateBy" className="col-4 mb-2 md:mb-0">Báo cáo theo ngày</label>
            <div className="col-8">
              <Dropdown value={filterDateBy} options={filterDateOptions} onChange={e => setFilterDateBy(e.value)}/>
            </div>
          </div>
        </div>

        <div className="flex justify-content-end mt-2">
          <Button label="Đồng ý" icon="pi pi-check" className="p-button-sm"/>
        </div>
      </form>
    </Dialog>
  );
}