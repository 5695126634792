import {Service} from '../../service/Service';

export class HDDTService extends Service {

  static entity = '';

  static getCaptcha(): Promise<any> {
    return this.axios.get(`${this.entity}/external_accounts/captcha`).then(res => res.data);
  }

  static queryGuestInvoices(params: any): Promise<any> {
    return this.axios.get(`${this.entity}/query/guest-invoices`, {params}).then(res => res.data);
  }

}