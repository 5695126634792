import {useEffect, useState} from 'react';

import moment from 'moment';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useForm} from '@iamsoftware/react-hooks';

import {InvoicesService as Service} from '../hoa-don/InvoicesService';
import {TagsService} from '../hoa-don/nhan-gan/TagsService';

const periods = [
  {value: 'TODAY', label: 'Hôm nay'},
  {value: 'THISWEEK', label: 'Tuần này'},
  {value: 'THISMONTH', label: 'Tháng này'},
  {value: 'THISFISCALQUARTER', label: 'Quý này'},
  {value: 'THISFISCALYEAR', label: 'Năm nay'},
  {value: 'FIRSTSIXMONTH', label: '6 tháng đầu năm'},
  {value: 'LASTSIXMONTH', label: '6 tháng cuối năm'},
  {value: 'JAN', label: 'Tháng 1'},
  {value: 'FEB', label: 'Tháng 2'},
  {value: 'MAR', label: 'Tháng 3'},
  {value: 'APR', label: 'Tháng 4'},
  {value: 'MAY', label: 'Tháng 5'},
  {value: 'JUN', label: 'Tháng 6'},
  {value: 'JUL', label: 'Tháng 7'},
  {value: 'AUG', label: 'Tháng 8'},
  {value: 'SEP', label: 'Tháng 9'},
  {value: 'OCT', label: 'Tháng 10'},
  {value: 'NOV', label: 'Tháng 11'},
  {value: 'DEC', label: 'Tháng 12'},
  {value: 'FISCALQUARTER1', label: 'Quý I'},
  {value: 'FISCALQUARTER2', label: 'Quý II'},
  {value: 'FISCALQUARTER3', label: 'Quý III'},
  {value: 'FISCALQUARTER4', label: 'Quý IV'},
  {value: 'LASTWEEK', label: 'Tuần trước'},
  {value: 'LASTMONTH', label: 'Tháng trước'},
  {value: 'LASTFISCALQUARTER', label: 'Quý trước'},
  {value: 'LASTFISCALYEAR', label: 'Năm trước'}
];

export const Filter = ({header, display, setDisplay, fieldFilter, defaultFilter, search}) => {

  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    const _value = getValue();

    if (!_value.period) {
      _value.filterDateBy = 'tdlap';
      _value.period = 'THISMONTH';
    }
    setValue({..._value, ...(defaultFilter || {})});

    TagsService.getList('{}').then(({items}) => {
      const _allTags = [{value: 'isNull', label: 'Chưa gán nhãn'}];
      items.forEach(item => {
        _allTags.push({value: item._id, label: item.name});
      });
      setAllTags(_allTags);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tagsTemplate = option => {
    if (option.value === 'isNull') {
      return <div className="alert-warning">{option.label}</div>;
    }
    return <div>{option.label}</div>;
  }

  const {render: renderForm, getValue, getRawValue, setValue} = useForm({
    fields: [
      {field: 'filterDateBy', header: 'Báo cáo theo ngày', type: 'Dropdown', DropdownProps: {options: filterDateOptions}, className: 'md:col-12 mt-1', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'period', header: 'Chọn kỳ', type: 'Dropdown', DropdownProps: {options: periods}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'fromDate', header: 'Từ ngày', type: 'Calendar', className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'toDate', header: 'Đến ngày', type: 'Calendar', className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'khmshdon', header: 'Ký hiệu mẫu số hóa đơn', type: 'MultiSelect', MultiSelectProps: {options: Service.m_khmshdons}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'khhdon', header: 'Ký hiệu', className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'tthai', header: 'Trạng thái hóa đơn', type: 'MultiSelect', MultiSelectProps: {options: Service.m_tthais}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'status', header: 'Kết quả kiểm tra', type: 'Dropdown', DropdownProps: {options: Service.kqktras}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'tags', header: 'Nhãn hóa đơn', type: 'MultiSelect', MultiSelectProps: {options: allTags, itemTemplate: tagsTemplate}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      ...(fieldFilter || [])
    ],
    layout: 'Horizontal'
  });

  const {period, fromDate, toDate, tags} = getValue();
  useEffect(() => {
    if (fromDate && toDate && new Date(fromDate).getTime() > new Date(toDate).getTime()) {
      setTimeout(() => {
        const _value = getRawValue();
        _value.toDate = null;

        setValue(_value);
      }, 500);
      ToastService.error('Giá trị Đến ngày không hợp lệ');
    }
  }, [fromDate, toDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (period) {
      let fromDate = new Date(), toDate = new Date();

      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      const quarters = ['FISCALQUARTER1', 'FISCALQUARTER2', 'FISCALQUARTER3', 'FISCALQUARTER4'];
      if (period && months.indexOf(period) >= 0) {
        fromDate = moment().month(months.indexOf(period)).startOf('month').toDate();
        toDate = moment().month(months.indexOf(period)).endOf('month').toDate();
      } else if (period && quarters.indexOf(period) >= 0) {
        fromDate = moment().quarter(quarters.indexOf(period) + 1).startOf('quarter').toDate();
        toDate = moment().quarter(quarters.indexOf(period) + 1).endOf('quarter').toDate();
      } else {
        switch (period) {
          case 'TODAY':
            fromDate = moment().startOf('day').toDate();
            toDate = moment().endOf('day').toDate();
            break;
          case 'THISWEEK':
            fromDate = moment().startOf('isoWeek').toDate();
            toDate = moment().endOf('isoWeek').toDate();
            break;
          case 'THISWEEKTODATE':
            fromDate = moment().startOf('isoWeek').toDate();
            break;
          case 'THISMONTH':
            fromDate = moment().startOf('month').toDate();
            toDate = moment().endOf('month').toDate();
            break;
          case 'THISMONTHTODATE':
            fromDate = moment().startOf('month').toDate();
            break;
          case 'THISFISCALQUARTER':
            fromDate = moment().startOf('quarter').toDate();
            toDate = moment().endOf('quarter').toDate();
            break;
          case 'THISFISCALQUARTERTODATE':
            fromDate = moment().startOf('quarter').toDate();
            break;
          case 'THISFISCALYEAR':
            fromDate = moment().startOf('year').toDate();
            toDate = moment().endOf('year').toDate();
            break;
          case 'THISFISCALYEARTODATE':
            fromDate = moment().startOf('year').toDate();
            break;
          case 'FIRSTSIXMONTH':
            fromDate = moment().startOf('year').toDate();
            toDate = moment().month(5).endOf('month').toDate();
            break;
          case 'LASTSIXMONTH':
            fromDate = moment().month(6).startOf('month').toDate();
            toDate = moment().endOf('year').toDate();
            break;
          case 'YESTERDAY':
            fromDate = moment().subtract(1, 'day').startOf('day').toDate();
            toDate = moment().subtract(1, 'day').endOf('day').toDate();
            break;
          case 'LASTWEEK':
            fromDate = moment().subtract(1, 'week').startOf('isoWeek').toDate();
            toDate = moment().subtract(1, 'week').endOf('isoWeek').toDate();
            break;
          case 'LASTMONTH':
            fromDate = moment().subtract(1, 'month').startOf('month').toDate();
            toDate = moment().subtract(1, 'month').endOf('month').toDate();
            break;
          case 'LASTFISCALQUARTER':
            fromDate = moment().subtract(1, 'quarter').startOf('quarter').toDate();
            toDate = moment().subtract(1, 'quarter').endOf('quarter').toDate();
            break;
          case 'LASTFISCALYEAR':
            fromDate = moment().subtract(1, 'year').startOf('year').toDate();
            toDate = moment().subtract(1, 'year').endOf('year').toDate();
            break;
          case 'NEXTWEEK':
            fromDate = moment().add(1, 'week').startOf('isoWeek').toDate();
            toDate = moment().add(1, 'week').endOf('isoWeek').toDate();
            break;
          case 'NEXTMONTH':
            fromDate = moment().add(1, 'month').startOf('month').toDate();
            toDate = moment().add(1, 'month').endOf('month').toDate();
            break;
          case 'NEXTFISCALQUARTER':
            fromDate = moment().add(1, 'quarter').startOf('quarter').toDate();
            toDate = moment().add(1, 'quarter').endOf('quarter').toDate();
            break;
          case 'NEXTFISCALYEAR':
            fromDate = moment().add(1, 'year').startOf('year').toDate();
            toDate = moment().add(1, 'year').endOf('year').toDate();
            break;
        }
      }
      const _value = getValue();
      _value.fromDate = fromDate;
      _value.toDate = toDate;
      setValue(_value);
    }
  }, [period]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (tags?.length) {
      if (tags.find(it => it === 'isNull')) {
        setAllTags(prevState => {
          prevState.forEach(it => {
            it.disabled = it.value !== 'isNull';
          });
          return [...prevState];
        });
      } else {
        setAllTags(prevState => {
          prevState.forEach(it => {
            it.disabled = it.value === 'isNull';
          });
          return [...prevState];
        });
      }
    } else if (allTags) {
      setAllTags(prevState => {
        prevState.forEach(it => {
          it.disabled = false;
        });
        return [...prevState];
      });
    }
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tags?.length && tags?.length === allTags?.length) {
      const _value = getValue();
      _value.tags = tags.filter(it => it !== 'isNull');
      setValue(_value);
    }
  }, [tags, allTags]); // eslint-disable-line react-hooks/exhaustive-deps

  const apply = () => {
    search(getValue());
  }

  return (
    <Dialog header={header} visible={display} style={{width: '40rem'}} position="top" onHide={() => setDisplay(false)}>

      {renderForm()}

      <div className="flex justify-content-end">
        <Button label="Hủy bỏ" icon="pi pi-times" onClick={() => setDisplay(false)} className="p-button-text p-button-sm"/>
        <Button label="Nhận" icon="pi pi-check" onClick={apply} className="p-button-sm"/>
      </div>
    </Dialog>
  );
}

export const filterDateOptions = [
  {value: 'tdlap', label: 'Ngày lập hoá đơn'},
  {value: 'nkekhai', label: 'Ngày kê khai hoá đơn'}
]