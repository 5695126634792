import {ReportService} from '../ReportService';

import {BangKe} from '../BangKe';

export const BangKeBanRa = () => {

  const header = 'Bảng kê hoá đơn bán ra';

  const partyColumns = [
    {
      field: 'data.nmten', header: 'Tên khách hàng', width: 200, dataType: 'custom', customCell(rowData: any): any {
        return rowData.header?.nmten || rowData.header?.nmtnmua;
      }
    },
    {
      field: 'header.nmdchi', header: 'Địa chỉ', width: 200, sortable: false, dataType: 'custom', customCell(rowData: any): any {
        return rowData.header ? rowData.header.nmdchi : '';
      }
    },
    {
      field: 'data.nmmst', header: 'Mã số thuế', width: 120, dataType: 'custom', customCell(rowData: any): any {
        return rowData.data ? rowData.data.nmmst : '';
      }
    }
  ];

  const endColumns = [];

  const getData = (account: string, lazyLoadEvent: string) => {
    return ReportService.getBangKe('sold', account, lazyLoadEvent);
  }

  const fieldFilter = [
    {field: 'nmmst', header: 'MST bên mua', className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'}
  ];

  return <BangKe header={header} partyColumns={partyColumns} endColumns={endColumns} fieldFilter={fieldFilter} defaultFilter={0} getData={getData}/>;
}