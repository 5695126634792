import {EntityService} from '../../../service/EntityService';

export class AgentsService extends EntityService {

  static entity = 'agents/user_accounts';

  static changePassword(_id: string, data: any): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/change_password`, data).then(res => res.data);
  }

  static changeParent(_id: string, data: any): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/change_parent`, data).then(res => res.data);
  }

  static types = [
    {value: 'agent', label: 'Đại lý'},
    {value: 'customer', label: 'Khách hàng'}
  ];

}
