import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {Link, NavLink} from 'react-router-dom';

import {Menu} from 'primereact/menu';
import {classNames} from 'primereact/utils';
import {ToastService, useDialogCrup} from '@iamsoftware/react-hooks';

import {AppTopbarRef} from 'src/types/types';
import {LayoutContext} from 'src/layout/context/layoutcontext';

import {UsersService} from 'src/service/UsersService';

const AppTopbar = forwardRef<AppTopbarRef>((props, ref) => {

  const {me, layoutState, onMenuToggle, showProfileSidebar} = useContext(LayoutContext);

  const menu = useRef(null);

  const menubuttonRef = useRef(null);
  const topbarmenuRef = useRef(null);
  const topbarmenubuttonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
    topbarmenu: topbarmenuRef.current,
    topbarmenubutton: topbarmenubuttonRef.current
  }));

  const logout = () => {
    UsersService.logout().then(() => {
      window.location.hash = '/login';
    });
  }

  const {render: renderDialogChangePassword, create: changePassword} = useDialogCrup({
    header: '!Đổi mật khẩu',
    width: '40rem',
    fields: [
      {field: 'oldPassword', header: 'Mật khẩu cũ', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPassword', header: 'Mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPasswordVerify', header: 'Nhập lại mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'}
    ],
    createItem: item => {
      if (item.newPassword !== item.newPasswordVerify) {
        ToastService.error('Mật khẩu nhập lại không chính xác');
        return Promise.reject();
      }
      return UsersService.setPassword(item.newPassword);
    },
    useSaveSplitButton: false
  });

  const template = (item: any, options: any) => {
    const onClick = () => {
      options.onClick({
        preventDefault: () => {
        }
      });
    }
    return (
      <NavLink to={item.target} className={options.className} onClick={onClick}>
        <span className={options.iconClassName}/>
        <span className={options.labelClassName}>{item.label}</span>
      </NavLink>
    );
  };

  const accountMenus = [
    {label: me.username, icon: 'pi pi-fw pi-user', target: '/tai-khoan', template},
    {label: 'Đổi mật khẩu', icon: 'pi pi-fw pi-key', command: changePassword},
    {separator: true},
    {label: 'Quản lý Token', icon: 'pi pi-fw pi-key', url: '/#/tai-khoan/quan-ly-token'},
    {label: 'Quản lý Nhân viên', icon: 'pi pi-fw pi-users', url: '/#/tai-khoan/quan-ly-nhan-vien', visible: !['employee'].includes(me.type)},
    {label: 'Khai báo ngày kê khai', icon: 'pi pi-fw pi-sliders-h', url: '/#/tai-khoan/khai-bao-ngay-ke-khai', visible: !['employee'].includes(me.type)},
    {separator: true},
    {label: 'Đăng xuất', icon: 'pi pi-fw pi-sign-out', command: logout}
  ];

  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo" style={{width: '200px'}}>
        <img src="/homecasta/logo.png" alt="IAM-TASK" style={{height: '3.2rem'}}/>
      </Link>

      <button ref={menubuttonRef} type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onMenuToggle}>
        <i className="pi pi-bars"/>
      </button>

      <button ref={topbarmenubuttonRef} type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={showProfileSidebar}>
        <i className="pi pi-ellipsis-v"/>
      </button>

      <div ref={topbarmenuRef} className={classNames('layout-topbar-menu', {'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible})}>
        <div className="house-holds">
          <p className="mb-0"><b>{me.description}</b></p>
          <p className="text-right">{me.username}</p>
        </div>

        <button className="p-link layout-topbar-button" onClick={event => menu.current.toggle(event)}>
          <i className="pi pi-user"/>
          <span>Profile</span>
        </button>
        <Menu model={accountMenus} popup ref={menu} className="app-menu-14"/>
      </div>

      {renderDialogChangePassword()}
    </div>
  );
});

AppTopbar.displayName = 'AppTopbar';

export default AppTopbar;