import {EntityService} from '../../../../service/EntityService';

export class ClassificationService extends EntityService {

  static entity = 'system/tax-code-classifications';

  static deleteAppl(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${id}/appl`).then(res => res.data);
  }

}
