import {Service} from '../../service/Service';

export class ReportService extends Service {

  static entity = 'report';

  static getBangKe(type: string, account: string, lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/bang-ke/${type}`, {params: {account, lazyLoadEvent}}).then(res => res.data);
  }

  static getHoaDonDcTt(type: string, account: string, lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/hoa-don-dc-tt/${type}`, {params: {account, lazyLoadEvent}}).then(res => res.data);
  }

  static getTaxAuthorities(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/tax-authorities`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static getToKhaiThue01PhuLuc43(params: any): Promise<any> {
    return this.axios.get(`${this.entity}/to-khai-thue-01/phu-luc-43`, {params}).then(res => res.data);
  }

  static getToKhaiThue01Data(type: string, params: any): Promise<any> {
    return this.axios.get(`${this.entity}/to-khai-thue-01/data/${type}`, {params}).then(res => res.data);
  }

  static getToKhaiThue01(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/to-khai-thue-01`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static createToKhaiThue01(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/to-khai-thue-01`, data).then(res => res.data);
  }

  static updateToKhaiThue01(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/to-khai-thue-01/${id}`, data).then(res => res.data);
  }

  static deleteToKhaiThue01(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/to-khai-thue-01/${id}`).then(res => res.data);
  }

  static downloadToKhaiThue01Xml(_id: string): Promise<any> {
    return this.axios.get(`${this.entity}/to-khai-thue-01/${_id}/download-xml`, {responseType: 'blob'}).then(res => res.data);
  }

}
