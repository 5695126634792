import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {ToastService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {ExternalAccountsService} from '../../tai-khoan-thue/ExternalAccountsService';
import {ProductService as Service} from './ProductService';
import {XlsxService} from '../../../service/XlsxService';

import {CreateProduct} from './CreateProduct';

export const Products = () => {

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);

  const [displayCreateProduct, setDisplayCreateProduct] = useState(false);

  useEffect(() => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      if (items.length) {
        items.forEach(item => {
          item.label = `${item.username} - ${item.profile?.name || ''}`;
        });
        setAccount(items[0]);
      }
      setAccounts(items);
    });
  }, []);

  useEffect(() => {
    if (account?._id) {
      reloadLazyData();
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: Array<any> = [
    {
      field: 'items.product.code', header: 'Mã hàng', width: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        let code = '';
        if (rowData.items?.length) {
          for (const item of rowData.items) {
            if (item['product']?.code) {
              code = item['product'].code;
              break;
            }
          }
        }
        return code;
      }
    },
    {
      field: 'items.sold_name', header: 'Tên hàng bán ra', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        if (rowData.items?.length) {
          return rowData.items.filter(item => item.sold_name).map(item => item.sold_name?.trim()).join(', ');
        } else {
          return '';
        }
      }
    },
    {
      field: 'items.purchase_name', header: 'Tên hàng mua vào', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        if (rowData.items?.length) {
          return rowData.items.filter(item => item.purchase_name).map(item => item.purchase_name?.trim()).join(', ');
        } else {
          return '';
        }
      }
    },
    {
      field: 'items.dvtinh', header: 'Đơn vị tính', width: 110, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        let dvtinh = '';
        if (rowData.items?.length) {
          for (const item of rowData.items) {
            if (item['product']?.dvtinh) {
              dvtinh = item['product'].dvtinh;
              break;
            } else {
              dvtinh = item.dvtinh;
            }
          }
        }
        return dvtinh;
      }
    },
    {
      field: 'items.product.tkhoanhanghoa', header: 'Tài khoản\nhàng hoá', width: 130, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        let tkhoanhanghoa = '';
        if (rowData.items?.length) {
          for (const item of rowData.items) {
            if (item['product']?.tkhoanhanghoa) {
              tkhoanhanghoa = item['product'].tkhoanhanghoa;
              break;
            }
          }
        }
        return tkhoanhanghoa;
      }
    },
    {
      field: 'items.product.tkhoangiavon', header: 'Tài khoản\ngiá vốn', width: 130, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        let tkhoangiavon = '';
        if (rowData.items?.length) {
          for (const item of rowData.items) {
            if (item['product']?.tkhoangiavon) {
              tkhoangiavon = item['product'].tkhoangiavon;
              break;
            }
          }
        }
        return tkhoangiavon;
      }
    },
    {
      field: 'items.product.tkhoandoanhthu', header: 'Tài khoản\ndoanh thu', width: 130, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        let tkhoandoanhthu = '';
        if (rowData.items?.length) {
          for (const item of rowData.items) {
            if (item['product']?.tkhoandoanhthu) {
              tkhoandoanhthu = item['product'].tkhoandoanhthu;
              break;
            }
          }
        }
        return tkhoandoanhthu;
      }
    }
  ];

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey: '_id',
    columns,
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      if (account?._id) {
        return Service.getProducts(account._id, params);
      } else {
        return Promise.resolve({});
      }
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          const actions = [];
          if (item.items?.filter(item => item.product)?.length) {
            actions.push({icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa mã hàng', command: () => doUpdateCode(item)});
          } else {
            actions.push({icon: 'pi pi-file-import', className: 'p-button-info', title: 'Gán mã hàng', command: () => doSetCode(item)});
            if (item?.items?.length && item.items[0].hidden) {
              actions.push({icon: 'pi pi-plus-circle', className: 'p-button-success', title: 'Sử dụng', command: () => doUpdateName(item, true)});
            } else {
              actions.push({icon: 'pi pi-minus-circle', className: 'p-button-danger', title: 'Không sử dụng', command: () => doUpdateName(item, true)});
            }
          }
          item.actions = actions;
        });
      }
    },
    selectionMode: 'checkbox'
  });

  const doSetCode = item => {
    let sold_name;
    if (item.items[0].sold_name) {
      sold_name = [item.items[0].sold_name];
    }
    let purchase_name;
    if (item.items[0].purchase_name) {
      purchase_name = [item.items[0].purchase_name];
    }
    if (item?.items?.length) {
      setCode({
        _id: item._id,
        sold_name,
        purchase_name
      });
    }
  }

  const doUpdateName = (item, hidden) => {
    Service.updateName(item._id, {hidden}).then(() => {
      reloadLazyData();
    });
  }

  const getProd = () => {
    const _value = form.getRawValue();
    if (_value.code && _value.code.trim()) {
      const _event = {
        filters: {
          code: {value: _value.code, matchMode: 'equals'}
        }
      };
      Service.findProducts(account?._id, JSON.stringify(_event)).then(docs => {
        if (docs?.length) {
          const doc = docs[0];
          doc.tkhoanhanghoa && (_value.tkhoanhanghoa = doc.tkhoanhanghoa);
          doc.tkhoangiavon && (_value.tkhoangiavon = doc.tkhoangiavon);
          doc.tkhoandoanhthu && (_value.tkhoandoanhthu = doc.tkhoandoanhthu);
        } else {
          _value.tkhoanhanghoa = '';
          _value.tkhoangiavon = '';
          _value.tkhoandoanhthu = '';
        }
        form.setValue(_value);
      });
    }
  }

  const {render: renderDialogSetCode, update: setCode, form} = useDialogCrup({
    header: '!Gán mã hàng',
    dataKey: '_id',
    width: '40rem',
    fields: [
      {field: 'sold_name', header: 'Tên hàng bán ra', disabled: true, type: 'Chips', className: 'md:col-12 View'},
      {field: 'purchase_name', header: 'Tên hàng mua vào', disabled: true, type: 'Chips', className: 'md:col-12 View'},
      {field: 'code', header: 'Mã hàng', required: true, className: 'md:col-10'},
      {
        type: 'custom', className: 'md:col-1', body: <Button icon="pi pi-search" className="p-button-rounded p-button-sm" style={{marginTop: '24px'}} onClick={getProd}/>
      },
      {field: 'tkhoanhanghoa', header: 'Tài khoản hàng hoá (15__)', InputTextProps: {maxLength: 20}, className: 'md:col-12'},
      {field: 'tkhoangiavon', header: 'Tài khoản giá vốn (63__)', InputTextProps: {maxLength: 20}, className: 'md:col-12'},
      {field: 'tkhoandoanhthu', header: 'Tài khoản doanh thu (51__)', InputTextProps: {maxLength: 20}, className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return validateTkhoan(item).then(async () => {
        let ids;
        if (Array.isArray(id)) {
          ids = id;
        } else {
          ids = [id];
        }
        for (const _id of ids) {
          await Service.setCode(_id, item);
        }
        return Promise.resolve();
      });
    },
    reloadLazyData: () => {
      setTimeout(() => {
        reloadLazyData();
      }, 500);
    }
  });

  const doUpdateCode = item => {
    if (item?.items?.length) {
      const product = item.items.filter(it => it.product)[0]?.product;
      updateCode({
        _id: item._id,
        code: product?.code || '',
        dvtinh: product?.dvtinh || '',
        tkhoanhanghoa: product?.tkhoanhanghoa || '',
        tkhoangiavon: product?.tkhoangiavon || '',
        tkhoandoanhthu: product?.tkhoandoanhthu || '',
        sold_name: item.items.filter(it => it.sold_name)?.map(it => it.sold_name) || [],
        purchase_name: item.items.filter(it => it.purchase_name)?.map(it => it.purchase_name) || []
      });
    }
  }

  const {render: renderDialogUpdateCode, update: updateCode} = useDialogCrup({
    header: 'Mã hàng',
    dataKey: '_id',
    width: '50rem',
    fields: [
      {field: 'code', header: 'Mã hàng', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-12'},
      {field: 'dvtinh', header: 'Đơn vị tính', InputTextProps: {maxLength: 50}, className: 'md:col-12'},
      {field: 'tkhoanhanghoa', header: 'Tài khoản hàng hoá (15__)', InputTextProps: {maxLength: 20}, className: 'md:col-12'},
      {field: 'tkhoangiavon', header: 'Tài khoản giá vốn (63__)', InputTextProps: {maxLength: 20}, className: 'md:col-12'},
      {field: 'tkhoandoanhthu', header: 'Tài khoản doanh thu (51__)', InputTextProps: {maxLength: 20}, className: 'md:col-12'},
      {field: 'sold_name', header: 'Tên hàng bán ra', type: 'Chips', className: 'md:col-12'},
      {field: 'purchase_name', header: 'Tên hàng mua vào', type: 'Chips', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return validateTkhoan(item).then(validatedItem => Service.updateCode(id, validatedItem));
    },
    reloadLazyData
  });

  const {render: renderDialogUpdateProduct, update: updateProduct} = useDialogCrup({
    header: 'Mã hàng',
    dataKey: '_id',
    width: '50rem',
    fields: [
      {field: 'code', header: 'Mã hàng', disabled: true, className: 'md:col-12'},
      {field: 'tkhoanhanghoa', header: 'Tài khoản hàng hoá (15__)', InputTextProps: {maxLength: 20}, className: 'md:col-12'},
      {field: 'tkhoangiavon', header: 'Tài khoản giá vốn (63__)', InputTextProps: {maxLength: 20}, className: 'md:col-12'},
      {field: 'tkhoandoanhthu', header: 'Tài khoản doanh thu (51__)', InputTextProps: {maxLength: 20}, className: 'md:col-12'}
    ],
    updateItem: (id: any, item) => {
      delete item.code;
      return validateTkhoan(item).then(async validatedItem => {
        for (const _id of id) {
          await Service.updateProduct(_id, validatedItem);
        }
        return Promise.resolve();
      });
    },
    reloadLazyData
  });

  const validateTkhoan = item => {
    return new Promise((resolve, reject) => {
      if (item.tkhoanhanghoa && !/^15/.test(item.tkhoanhanghoa)) {
        ToastService.error('Tài khoản hàng hoá không hợp lệ.');
        reject();
      }
      if (item.tkhoangiavon && !/^63/.test(item.tkhoangiavon)) {
        ToastService.error('Tài khoản giá vốn không hợp lệ.');
        reject();
      }
      if (item.tkhoandoanhthu && !/^51/.test(item.tkhoandoanhthu)) {
        ToastService.error('Tài khoản doanh thu không hợp lệ.');
        reject();
      }

      resolve(item);
    });
  }

  const sync = () => {
    if (account?._id) {
      Service.sync(account?._id).then(() => {
        reloadLazyData();
      });
    }
  }

  const batchSetCode = () => {
    const setCodeItems = [];
    if (selectedItems?.length) {
      selectedItems.forEach(selectedItem => {
        selectedItem.items?.forEach(item => {
          setCodeItems.push({
            _id: item.name_id,
            sold_name: item.sold_name || '',
            purchase_name: item.purchase_name || ''
          });
        });
      });
    }
    if (setCodeItems.length) {
      setCode({
        _id: setCodeItems.filter(it => it._id).map(it => it._id),
        sold_name: setCodeItems.filter(it => it.sold_name).map(it => it.sold_name),
        purchase_name: setCodeItems.filter(it => it.purchase_name).map(it => it.purchase_name)
      });
    } else {
      ToastService.error('Chưa chọn hàng hoá');
    }
  }

  const batchSetProduct = () => {
    const ids = [];
    const code = [];
    let tkhoanhanghoa, tkhoangiavon, tkhoandoanhthu;
    if (selectedItems?.length) {
      selectedItems.forEach(selectedItem => {
        if (selectedItem.items?.filter(item => item.product?._id)?.length) {
          ids.push(selectedItem._id);
          code.push(selectedItem.items[0].product.code);
          if (selectedItem.items[0].product.tkhoanhanghoa) {
            tkhoanhanghoa = selectedItem.items[0].product.tkhoanhanghoa;
          }
          if (selectedItem.items[0].product.tkhoangiavon) {
            tkhoangiavon = selectedItem.items[0].product.tkhoangiavon;
          }
          if (selectedItem.items[0].product.tkhoandoanhthu) {
            tkhoandoanhthu = selectedItem.items[0].product.tkhoandoanhthu;
          }
        }
      });
    }
    if (ids.length) {
      updateProduct({
        _id: ids, code: code.join(', '),
        tkhoanhanghoa: tkhoanhanghoa || '', tkhoangiavon: tkhoangiavon || '', tkhoandoanhthu: tkhoandoanhthu || ''
      });
    } else {
      ToastService.error('Chưa chọn hàng hoá');
    }
  }

  const downloadExcel = () => {
    Service.getProducts(account._id, '{}', 'N').then(({items}) => {
      const aoa = [[]];
      columns.forEach(column => {
        aoa[0].push(column.header);
        items.forEach((item, index) => {
          if (!aoa[index + 1]) {
            aoa[index + 1] = [];
          }
          aoa[index + 1].push(XlsxService.getCellValue(column, item));
        });
      });

      XlsxService.generateFile(aoa, 'hang-hoa-dich-vu');
    });
  }

  return (
    <div className="grid">
      <div className="field col-9 p-fluid mb-0">
        <label htmlFor="account">Tài khoản thuế ({accounts?.length} tài khoản)</label>
        <Dropdown value={account} options={accounts} onChange={e => setAccount(e.value)} filter={true}/>
      </div>
      <div className="col-12">
        <div className="flex justify-content-between flex-wrap">
          <div className="flex gap-2">
            <Button icon="pi pi-plus" label="Thêm mã hàng" size="small" severity="success" onClick={() => setDisplayCreateProduct(true)}/>
            <Button icon="pi pi-file-import" label="Gán mã hàng" size="small" severity="info" onClick={batchSetCode}/>
            <Button icon="pi pi-file-import" label="Gán tài khoản" size="small" severity="info" onClick={batchSetProduct}/>
          </div>
          <div className="flex gap-2">
            <Button label="Đồng bộ" outlined size="small" onClick={sync}/>
            <Button icon="pi pi-download" label="Export Excel" outlined size="small" severity="help" onClick={downloadExcel}/>
          </div>
        </div>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogSetCode()}
      {renderDialogUpdateCode()}
      {renderDialogUpdateProduct()}

      <CreateProduct account={account?._id}
                     display={displayCreateProduct} setDisplay={setDisplayCreateProduct} reloadLazyData={reloadLazyData}/>
    </div>
  );
}