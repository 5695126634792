import {Service} from '../../../../service/Service';

export class ExcelConfigService extends Service {

  static entity = 'config/export_excel_config';

  static getBase(): Promise<any> {
    return this.axios.get(`${this.entity}/base`).then(res => res.data);
  }

  static setBase(data: any): Promise<any> {
    return this.axios.put(`${this.entity}/base`, data).then(res => res.data);
  }

  static getConfigs(): Promise<any> {
    return this.axios.get(`${this.entity}`).then(res => res.data);
  }

  static createConfig(data: any): Promise<any> {
    return this.axios.post(`${this.entity}`, data).then(res => res.data);
  }

  static updateConfig(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}`, data).then(res => res.data);
  }

  static deleteConfig(id: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${id}`).then(res => res.data);
  }

  static sort(ids: Array<string>): Promise<any> {
    return this.axios.post(`${this.entity}/sort`, {ids}).then(res => res.data);
  }

}
