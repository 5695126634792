import {useEffect, useState} from 'react';

import {Dropdown} from 'primereact/dropdown';
import {SelectButton} from 'primereact/selectbutton';
import {useDataTable} from '@iamsoftware/react-hooks';

import {ExternalAccountsService as Service} from './ExternalAccountsService';

export const Sync = () => {

  const dataKey = '_id';

  const [requiredParams, setRequiredParams] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);
  const [types] = useState([
    {value: 'sync_invoices_sold', label: 'Hóa đơn bán ra'},
    {value: 'sync_invoices_sold_sco', label: 'Hóa đơn bán ra máy tính tiền'},
    {value: 'sync_invoices_purchase', label: 'Hóa đơn mua vào'},
    {value: 'sync_invoices_purchase_sco', label: 'Hóa đơn mua vào máy tính tiền'}
  ]);
  const [type, setType] = useState('sync_invoices_sold');

  useEffect(() => {
    Service.getList('{}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        _accounts.push({value: item._id, label: `${item.username} - ${item.profile?.name || ''}`});
      });
      if (_accounts.length) {
        setAccount(_accounts[0].value);
      }
      setAccounts(_accounts);
    });
  }, []);

  useEffect(() => {
    if (account && type) {
      const _requiredParams = {
        account: {value: account, matchMode: 'ObjectId'},
        type: {value: type, matchMode: 'equals'}
      }
      setRequiredParams(_requiredParams);
    }
  }, [account, type]);

  const {render: renderDataTable} = useDataTable({
    dataKey,
    columns: [
      {field: 'created_date', header: 'Thời gian', width: 160, dataType: 'date-time'},
      {field: 'ge', header: 'Đồng bộ từ ngày', width: 160, dataType: 'date-time'},
      {field: 'le', header: 'Đến ngày', width: 160, dataType: 'date-time'},
      {field: 'status', header: 'Trạng thái', width: 140, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', minWidth: 140, matchMode: 'contains'},
      {field: 'total', header: 'Tổng số hóa đơn', width: 160, dataType: 'number'},
      {field: 'synced', header: 'Tải thành công', width: 160, dataType: 'number'},
      {field: 'created', header: 'Hóa đơn mới', width: 160, dataType: 'number'}
    ],
    indexColumnWidth: 45,
    getList: params => {
      return Service.getSync(params);
    },
    requiredParams
  });

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-12 p-fluid">
          <Dropdown value={account} options={accounts} onChange={e => setAccount(e.value)} filter={true}/>
        </div>
        <div className="col-12">
          <SelectButton value={type} options={types} onChange={e => setType(e.value)}/>
        </div>
      </div>
      {renderDataTable()}
    </div>
  );
}