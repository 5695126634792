import {Fragment, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';
import {FormatDisplay, ToastService, useDataTable} from '@iamsoftware/react-hooks';

import {ExternalAccountsService} from '../../tai-khoan-thue/ExternalAccountsService';

import {TaxCodeInfoService as Service} from './TaxCodeInfoService';
import {TaxCodeService} from '../../../service/TaxCodeService';
import {ClassificationService} from '../../administrator/tax-code-classification/classification/ClassificationService';

import {TaxCodeStatus} from './TaxCodeStatus';
import {Invoices} from './Invoices';
import {XlsxService} from '../../../service/XlsxService';

export const TaxCodeInfo = () => {

  const [params] = useSearchParams();

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      if (items.length) {
        items.forEach(item => {
          item.label = `${item.username} - ${item.profile?.name || ''}`;
        });
      }
      setAccounts(items);
    });
  }, []);

  return (
    <div className="card pb-0">
      <Screen key={params.get('tthai')} accountParam={params.get('account')} tthai={params.get('tthai')} accounts={accounts}/>
    </div>
  );
}

const Screen = ({accountParam, tthai, accounts}) => {

  const [currentParams, setCurrentParams] = useState(null);

  const [defaultFilters, setDefaultFilters] = useState(null);

  const [account, setAccount] = useState(null);

  const [displayInvoices, setDisplayInvoices] = useState(null);

  const [msttthaiList, setMsttthaiList] = useState(null);

  const [classes, setClasses] = useState(null);
  const [tthaiStyle, setTthaiStyle] = useState({});
  const [styleMap, setStyleMap] = useState({});

  useEffect(() => {
    const _event = {
      filters: {
        active: {value: true, matchMode: 'equals'}
      }
    };
    ClassificationService.getList(JSON.stringify(_event)).then(({items}) => {
      const _msttthaiList = [...TaxCodeService.msttthaiList];
      if (items?.length) {
        setClasses(items);
        const _styleMap = {};
        items.forEach(item => {
          if (item.ref === 'SMI') {
            _msttthaiList.push({value: item._id, label: item.name});
          } else {
            if (!_styleMap[item.ref]) {
              _styleMap[item.ref] = {
                color: item.color || 'black',
                backgroundColor: item.background || 'white'
              };
            }
          }
        });
        setStyleMap(_styleMap);
      }
      setMsttthaiList(_msttthaiList);
    });
  }, []);

  useEffect(() => {
    if (tthai) {
      setDefaultFilters({'tax_code.tthai': tthai});
    } else {
      setDefaultFilters(null);
    }
  }, [tthai]);

  useEffect(() => {
    if (tthai && classes) {
      classes.forEach(cl => {
        if (cl._id === tthai) {
          setTthaiStyle({
            color: cl.color || 'black',
            backgroundColor: cl.background || 'white'
          });
        }
      });
    }
  }, [classes, tthai]);

  useEffect(() => {
    if (!account?._id && accounts?.length) {
      if (accountParam) {
        setAccount(accounts.filter(ac => ac._id === accountParam)[0]);
      } else {
        setAccount(accounts[0]);
      }
    }
  }, [accountParam, accounts]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (account?._id) {
      reloadLazyData();
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: Array<any> = [
    {
      field: 'tax_code.tthai', header: 'Tình trạng', minWidth: 150, matchMode: 'equals', filterType: 'dropdown', filterOptions: msttthaiList,
      dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        if (skipFormat === true) {
          return TaxCodeService.msttthaiMap[rowData.tax_code.tthai];
        }
        return <span className={TaxCodeService.getClassName(rowData.tax_code.tthai)}
                     title={`Dữ liệu cập nhật lúc ${FormatDisplay.dateTime(rowData.tax_code.sync.ran)}`}
                     onClick={() => setDisplayInvoices(rowData)} style={Object.assign(styleMap[rowData.tax_code.tthai] || {}, tthaiStyle)}>
            {TaxCodeService.msttthaiMap[rowData.tax_code.tthai]}
          </span>
      }, className: `${JSON.stringify(tthaiStyle)}_${JSON.stringify(styleMap)}`
    },
    {
      field: 'tax_code.mst', header: 'Mã số thuế', width: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        return rowData.tax_code.mst;
      }
    },
    {
      field: 'tax_code.tennnt', header: 'Tên tổ chức, cá nhân', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
        return rowData.tax_code.tennnt;
      }
    },
    {
      field: 'tax_code.dctstinh', header: 'Địa chỉ', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
        const dcctiet = [];
        if (rowData.tax_code?.dctsdchi) {
          dcctiet.push(rowData.tax_code?.dctsdchi);
        }
        if (rowData.tax_code?.dctstxa) {
          dcctiet.push(rowData.tax_code?.dctstxa);
        }
        if (rowData.tax_code?.dctsthuyen) {
          dcctiet.push(rowData.tax_code?.dctsthuyen);
        }
        if (rowData.tax_code?.dctstinh) {
          dcctiet.push(rowData.tax_code?.dctstinh);
        }
        return dcctiet.join(', ');
      }
    },
    {
      field: 'tax_code.tencqt', header: 'CQT quản lý', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
        return rowData.tax_code.tencqt;
      }
    },
    {
      field: 'purchase.count', header: 'SL HĐ\nmua vào', width: 100, matchMode: 'numeric', dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        if (rowData.tax_code.tthai === '00') {
          return '';
        }
        if (skipFormat === true) {
          return rowData.purchase?.count;
        }
        return FormatDisplay.number(rowData.purchase?.count);
      }, className: 'text-right'
    },
    {
      field: 'purchase.totalAmount', header: 'Tổng tiền mua vào', width: 160, matchMode: 'numeric', dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        if (rowData.tax_code.tthai === '00') {
          return '';
        }
        if (skipFormat === true) {
          return rowData.purchase?.totalAmount;
        }
        return FormatDisplay.number(rowData.purchase?.totalAmount);
      }, className: 'text-right'
    },
    {
      field: 'sold.count', header: 'SL HĐ\nbán ra', width: 100, matchMode: 'numeric', dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        if (rowData.tax_code.tthai === '00') {
          return '';
        }
        if (skipFormat === true) {
          return rowData.sold?.count;
        }
        return FormatDisplay.number(rowData.sold?.count);
      }, className: 'text-right'
    },
    {
      field: 'sold.totalAmount', header: 'Tổng tiền bán ra', width: 160, matchMode: 'numeric', dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        if (rowData.tax_code.tthai === '00') {
          return '';
        }
        if (skipFormat === true) {
          return rowData.sold?.totalAmount;
        }
        return FormatDisplay.number(rowData.sold?.totalAmount);
      }, className: 'text-right'
    },
    {field: 'aggregated_date', header: 'Cập nhật', width: 110, dataType: 'date-time'}
  ];

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: 'Danh sách chi tiết',
    dataKey: '_id',
    defaultFilters,
    columns,
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      if (account?._id) {
        const _params = JSON.parse(params);
        if (defaultFilters && !_params.sortField) {
          _params.sortField = 'purchase.totalAmount';
          _params.sortOrder = -1;
        }
        setCurrentParams(_params);
        return Service.getTaxCodes(account._id, JSON.stringify(_params));
      } else {
        return Promise.resolve({});
      }
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-list', className: 'p-button-info', title: 'Danh sách hoá đơn', command: () => setDisplayInvoices(item)}
          ]
        });
      }
    }
  });

  const syncTaxCodes = () => {
    Service.syncTaxCodes(account._id).then(() => {
      reloadLazyData();
    });
  }

  const downloadExcel = () => {
    delete currentParams.first;
    delete currentParams.rows;
    delete currentParams.page;
    Service.getTaxCodes(account._id, JSON.stringify(currentParams)).then(({items}) => {
      if (!items?.length) {
        return ToastService.error('Không tìm thấy dữ liệu.');
      }
      const aoa = [[]];
      columns.forEach(column => {
        aoa[0].push(column.header);
        items.forEach((item, index) => {
          if (!aoa[index + 1]) {
            aoa[index + 1] = [];
          }

          aoa[index + 1].push(XlsxService.getCellValue(column, item));
        });
      });

      XlsxService.generateFile(aoa, 'tinh-trang-doanh-nghiep');
    });
  }

  return <Fragment>
    <div className="grid">
      <div className="field col-9 p-fluid mb-0">
        <label htmlFor="account">Tài khoản thuế ({accounts?.length} tài khoản)</label>
        <Dropdown value={account} options={accounts} onChange={e => setAccount(e.value)} filter={true}/>
      </div>
      <div className="field col-3">
        <label htmlFor="account">&nbsp;</label>
        <div className="flex gap-2">
          <Button label="Kiểm tra lại" outlined size="small" onClick={syncTaxCodes}/>
          <Button icon="pi pi-download" label="Export Excel" outlined size="small" severity="help" onClick={downloadExcel}/>
        </div>
      </div>
      <div className="col-6">
        <Fieldset legend="Trạng thái doanh nghiệp">
          <TaxCodeStatus account={account?._id}/>
        </Fieldset>
      </div>
      <div className="col-12 mt-3">
        {renderDataTable()}
      </div>

      <Invoices display={displayInvoices} setDisplay={setDisplayInvoices} account={account}/>
    </div>
  </Fragment>;
}