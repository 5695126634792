import {Fragment, useEffect, useRef, useState} from 'react';

import {ToastService} from '@iamsoftware/react-hooks';

import {CheckXml} from '../../shared/components/check-xml';

export const UploadXml = ({me}) => {

  const inputXml = useRef(null);

  const [displayXml, setDisplayXml] = useState(false);
  const [xmlContent, setXmlContent] = useState(null);

  useEffect(() => {
    if (!displayXml) {
      setXmlContent(null);
    }
  }, [displayXml]);

  const upload = (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = evt => {
      const readerData = evt.target.result;
      setXmlContent(readerData);
      setDisplayXml(true);
      inputXml.current.value = '';
    }
  }

  const onUploadXml = e => {
    if (e.target.files && e.target.files.length === 1) {
      const file = e.target.files[0];
      upload(file);
    } else {
      ToastService.error('Chỉ được phép chọn 1 file XML');
    }
  }

  const preventDefault = e => {
    e.preventDefault();
  }

  const onDrop = e => {
    if (e.dataTransfer.files && e.dataTransfer.files.length === 1) {
      const file = e.dataTransfer.files[0];
      upload(file);
    } else {
      ToastService.error('Chỉ được phép chọn 1 file XML');
    }
    e.preventDefault();
  }

  return (
    <Fragment>
      <div className="my-6" style={{width: '31rem', border: '1px dashed #0069b4', borderRadius: '5px', height: '15rem', margin: '0 auto'}}
           onClick={() => inputXml.current.click()} onDragOver={preventDefault} onDragEnter={preventDefault} onDrop={onDrop}>
        <input ref={inputXml} type="file" accept=".xml" hidden onChange={onUploadXml}/>
        <p className="text-center mt-3"><i className="pi pi-cloud-upload" style={{color: '#0069b4', fontSize: '5rem'}}></i></p>
        <p className="text-center"><b>Nhấp chuột hoặc kéo thả tập tin hóa đơn vào đây để tải lên</b></p>
        <p className="text-center">Lưu ý: Chỉ chọn một tập tin có phần mở rộng xml</p>
      </div>
      <CheckXml me={me} xmlContent={xmlContent} display={displayXml} setDisplay={setDisplayXml}/>
    </Fragment>
  );
}