import {Button} from 'primereact/button';
import {useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {ClassificationService as Service} from './ClassificationService';

export const Classification = () => {

  const dataKey = '_id';

  const {render: renderDataTable, refreshLazyData} = useDataTable({
    dataKey,
    columns: [

      {field: 'sequence', header: 'Thứ tự', width: 120, matchMode: 'numeric', dataType: 'number'},
      {
        field: 'name', header: 'Tên', minWidth: 250, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          const color = rowData.color || 'black';
          const backgroundColor = rowData.background || 'white';
          return <span style={{color, backgroundColor}}>{rowData.name}</span>
        }
      },
      {field: 'ref', header: 'Trạng thái gốc', width: 150, matchMode: 'contains'},
      {field: 'active', header: 'Kích hoạt', width: 110, matchMode: 'equals', filterType: 'boolean', dataType: 'checkbox'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => update(item)}
          ];
        });
      }
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header: 'Tình trạng MST',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'sequence', header: `Thứ tự`, type: 'InputNumber', className: 'md:col-12'},
      {field: 'name', header: 'Tên', required: true, className: 'md:col-12'},
      {field: 'color', header: 'Màu chữ', className: 'md:col-6'},
      {field: 'background', header: 'Màu nền', className: 'md:col-6'},
      {field: 'ref', header: 'Trạng thái gốc', className: 'md:col-12'},
      {field: 'active', header: 'Kích hoạt', type: 'Checkbox', className: 'md:col-12'}
    ],
    createItem: (item) => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData: refreshLazyData
  });

  const doCreate = () => {
    create({active: true});
  }

  return (
    <div className="grid">
      <div className="col-12">
        <Button label="Thêm" icon="pi pi-plus" className="p-button-sm" onClick={doCreate}/>
      </div>
      <div className="col-12 p-datatable-paginator-sticky">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>

  );
}