import {Button} from 'primereact/button';
import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {TagsService as Service} from './TagsService';

export const NhanGan = () => {

  const header = 'Nhãn hoá đơn';
  const dataKey = '_id';

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'name', header: 'Tên nhãn dán', minWidth: 250, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => update(item)},
            {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => doDelete(item)}
          ]
        });
      }
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'name', header: 'Tên nhãn dán', required: true, InputTextProps: {maxLength: 100}, className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const doDelete = item => {
    ConfirmDialogService.confirm('Xoá Nhãn hoá đơn', `Xác nhận xoá Nhãn hoá đơn ${item.name}?`, () => {
      Service.delete(item._id).then(() => {
        ToastService.success();
        reloadLazyData();
      }).catch(() => {
      });
    });
  }

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-12">
          <Button label="Thêm" icon="pi pi-plus" className="p-button-sm" onClick={create}/>
        </div>
      </div>
      {renderDataTable()}
      {renderDialogCrup()}
    </div>
  );
}