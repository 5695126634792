import {Service} from '../../service/Service';

export class DashboardService extends Service {

  static entity = 'report';

  static getStats(account: string): Promise<any> {
    return this.axios.get(`${this.entity}/stats`, {params: {account}}).then(res => res.data);
  }

  static getRecentAdjust(account: string): Promise<any> {
    return this.axios.get(`${this.entity}/recent-adjust`, {params: {account}}).then(res => res.data);
  }

  static getLineData(account: string): Promise<any> {
    return this.axios.get(`${this.entity}/line-data`, {params: {account}}).then(res => res.data);
  }

}
