import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {ExternalAccountsService} from '../../tai-khoan-thue/ExternalAccountsService';

import {Config} from './Config';

export const DateOfInvoiceDeclaration = () => {

  const dataKey = '_id';

  const [accounts, setAccounts] = useState([]);

  const [displayConfig, setDisplayConfig] = useState(null);

  useEffect(() => {
    loadAccounts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadAccounts = () => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        item.actions = [
          {icon: 'pi pi-eye', title: 'Xem', command: () => doConfig(item, true)},
          {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => doConfig(item)}
        ]
        _accounts.push({...item, label: `${item.username} - ${item.profile?.name || ''}`});
      });
      setAccounts(_accounts);
    });
  }

  const onNameClick = rowData => {
    setSelectedItems(accounts.filter(account => account.date_of_invoice_declaration_name === rowData.date_of_invoice_declaration_name));
  }

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: `Tài khoản thuế`,
    dataKey,
    indexColumnWidth: 45,
    actionColumnWidth: 100,
    columns: [
      {field: 'username', header: 'Mã số thuế', width: 170, matchMode: 'contains'},
      {
        field: 'profile.name', header: 'Tên công ty', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.profile ? rowData.profile.name : '';
        }
      },
      {
        field: 'date_of_invoice_declaration_name', header: 'Tên cấu hình', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <span className="text-primary pointer" onClick={() => onNameClick(rowData)}>{rowData.date_of_invoice_declaration_name}</span>
        }
      },
      {field: 'auto_date_of_invoice_declaration', header: 'Tự động update', width: 150, matchMode: 'equals', filterType: 'boolean', dataType: 'checkbox'}
    ],
    items: accounts,
    selectionMode: 'checkbox'
  });

  const doConfig = (items: any, isView?: boolean) => {
    const _items = [];
    if (Array.isArray(items)) {
      _items.push(...items);
    } else {
      _items.push(items);
    }
    if (_items.length) {
      setDisplayConfig({
        items: _items,
        isView
      });
    } else {
      ToastService.error('Chưa chọn tài khoản');
    }
  }

  return (
    <div className="grid">
      <div className="col-12 pb-0 text-right">
        <Button label="Khai báo ngày kê khai" icon="pi pi-list" onClick={() => doConfig(selectedItems)} className="p-button-sm"/>
      </div>
      <div className="col-12 pt-0">
        {render()}
      </div>

      <Config display={displayConfig} setDisplay={setDisplayConfig} loadAccounts={loadAccounts}/>
    </div>
  );
}