import {Route, Routes} from 'react-router-dom';

import {Products} from './hang-hoa';

export const Category = () => {

  return (
    <Routes>
      <Route path="hang-hoa" element={<Products/>}/>
    </Routes>
  );
}