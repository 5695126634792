import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {ConfirmDialogService, ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {XlsxService} from '../../../service/XlsxService';
import {ExternalAccountsService} from '../../tai-khoan-thue/ExternalAccountsService';

export const Config = ({display, setDisplay, loadAccounts}) => {

  const dataKey = 'dataKey';

  const [selectedAccounts, setSelectedAccounts] = useState(null);

  const [autoDateOfInvoiceDeclaration, setAutoDateOfInvoiceDeclaration] = useState(false);
  const [name, setName] = useState('');

  const [originalItems, setOriginalItems] = useState([]);
  const [items, setItems] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getOriginalItems().then(rows => {
      setOriginalItems(rows);
    });
  }, []);

  useEffect(() => {
    if (display?.items?.length) {
      setSelectedAccounts(display.items);
    }
  }, [display]);

  useEffect(() => {
    if (originalItems?.length && selectedAccounts?.length) {
      const account = selectedAccounts[0];
      if (account.date_of_invoice_declaration_config?.length) {
        setItems(account.date_of_invoice_declaration_config);
      } else {
        setItems(originalItems);
      }
      if (account.date_of_invoice_declaration_name) {
        setName(account.date_of_invoice_declaration_name);
      } else {
        setName('');
      }
      setAutoDateOfInvoiceDeclaration(account.auto_date_of_invoice_declaration);
    }
  }, [originalItems, selectedAccounts]);

  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem[dataKey] === rowData[dataKey]) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }

  const {render} = useDataTableBasic({
    tableHeader: `Cấu hình`,
    dataKey,
    indexColumnWidth: 40,
    columns: [
      {field: 'khmshdon', header: 'Loại hóa đơn', width: 120, sortable: false},
      {field: 'tlhdon', header: 'Tên loại hóa đơn', minWidth: 150, sortable: false},
      {field: 'khhdon', header: 'Có mã hay không', width: 150, sortable: false},
      {
        field: '_typeLabel', header: 'Hóa đơn mua vào/ Hóa đơn bán ra', width: 150, sortable: false, dataType: 'custom', customCell(rowData: any): any {
          return <span className={rowData._typeValue === 'sold' ? '' : 'alert-info'}>{rowData._typeLabel}</span>
        }
      },
      {
        field: '_fromField', header: 'Ngày kê khai theo', width: 200, sortable: false,
        dataType: 'editor', getEditorConfig(rowData: any): any {
          let options = [];
          if (rowData.khhdon) {
            switch (rowData.khhdon) {
              case 'C':
                options = fromFieldOptions.filter(option => ['tdlap', 'ncma'].includes(option.value));
                break;
              case 'K':
                options = fromFieldOptions.filter(option => ['tdlap', 'nky'].includes(option.value));
                break;
            }
          }
          return {onEditorChange, field: {type: 'Dropdown', DropdownProps: {options}, disabled: display?.isView === true}};
        }
      }
    ],
    items
  });

  const onRestore = () => {
    ConfirmDialogService.confirm('Khôi phục mặc định', `Xác nhận Khôi phục mặc định cấu hình Ngày kê khai của tài khoản`, () => {
      setItems(originalItems);
    });
  }

  const onSubmit = () => {
    if (!submitting && selectedAccounts?.length && items?.length) {
      setSubmitting(true);

      const complete = [];
      selectedAccounts.forEach(account => {
        complete.push(ExternalAccountsService.updateDateOfInvoiceDeclaration(account?._id, {
          auto_date_of_invoice_declaration: autoDateOfInvoiceDeclaration,
          date_of_invoice_declaration_config: items.map(item => {
            for (const fromField of fromFieldOptions) {
              if (fromField.value === item._fromField) {
                item._fromFieldLabel = fromField.label;
                break;
              }
            }
            return item;
          }),
          date_of_invoice_declaration_name: name
        }));
      });

      Promise.all(complete).then(() => {
        ToastService.success();
        loadAccounts();
        setDisplay(false);
      }).finally(() => {
        setSubmitting(false);
      });
    }
  }

  const footer = (
    <div className="text-center">
      {display?.isView !== true && <Fragment>
				<Button label="Lưu" icon="pi pi-check" onClick={onSubmit} className="p-button-sm mr-2" loading={submitting}/>
				<Button label="Khôi phục mặc định" icon="pi pi-undo" severity="secondary" onClick={onRestore} className="p-button-sm"/>
			</Fragment>}
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header="Khai báo ngày kê khai" footer={footer} visible={!!display} maximized onHide={() => setDisplay(false)}>
      <div className="grid">
        <div className="col-12">
          Tài khoản thuế:
          {selectedAccounts?.map((selectedAccount, index) => <span key={index} className="alert-success ml-2">{selectedAccount.profile?.name}</span>)}
        </div>
        <div className="col-4 pt-3">
          <div className="flex align-items-center">
            <Checkbox inputId="autoDateOfInvoiceDeclaration" onChange={e => setAutoDateOfInvoiceDeclaration(e.checked)} checked={autoDateOfInvoiceDeclaration}
                      disabled={display?.isView === true}/>
            <label htmlFor="autoDateOfInvoiceDeclaration" className="ml-2">Tự động thêm ngày kê khai</label>
          </div>
        </div>
        <div className="col-fixed pt-3" style={{width: '8rem'}}>
          <label htmlFor="autoDateOfInvoiceDeclaration">Tên cấu hình:</label>
        </div>
        <div className="col">
          <InputText value={name} onChange={e => setName(e.target.value)}/>
        </div>
        <div className="col-12">
          <i className={autoDateOfInvoiceDeclaration ? 'alert-info' : 'alert-warning'}>({autoDateOfInvoiceDeclaration ? 'Khi tích chọn, từ giờ trở đi hóa đơn đồng bộ về sẽ tự động được gán ngày kê khai' : 'Khi bỏ tích chọn, từ giờ trở đi hóa đơn sẽ không được tự động gán ngày kê khai'})</i>
        </div>
        <div className="col-12">
          {render()}
        </div>
      </div>
    </Dialog>
  );
}

export const getOriginalItems = () => {
  return XlsxService.readExcelUrl('/data/gia-tri-mac-dinh-ngay-ke-khai.xlsx', {startRow: 1}).then(rows => {
    if (Array.isArray(rows)) {
      return rows.map((row, index) => {
        const _typeValue = row[3] === 'Hóa đơn mua vào' ? 'purchase' : 'sold';
        let _fromField;
        for (const fromField of fromFieldOptions) {
          if (fromField.label === row[4]) {
            _fromField = fromField.value;
            break;
          }
        }
        return {
          dataKey: index,
          khmshdon: row[0], tlhdon: row[1], khhdon: row[2], _typeValue, _typeLabel: row[3], _fromField
        };
      });
    }
  });
}

export const fromFieldOptions = [
  {value: 'tdlap', label: 'Ngày lập'},
  {value: 'nky', label: 'Ngày ký'},
  {value: 'ncma', label: 'Ngày cấp mã'}
];