import {Service} from '../../../service/Service';

export class CheckXmlService extends Service {

  static entity: string = 'upload_xml_data';

  static uploadXmlData(data): Promise<any> {
    return this.axios.post(`${this.entity}`, data).then(res => res.data);
  }

}