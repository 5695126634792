import {Fragment, useContext, useEffect, useRef} from 'react';
import {Route, Routes} from 'react-router-dom';

import {classNames} from 'primereact/utils';
import {useEventListener, useMountEffect, useUnmountEffect,} from 'primereact/hooks';
import {PrimeReactContext} from 'primereact/api';

import {AppTopbarRef, LayoutState} from 'src/types/types';
import {LayoutContext} from 'src/layout/context/layoutcontext';

import {UsersService} from '../service/UsersService';

import AppTopbar from './AppTopbar';
import AppSidebar from './AppSidebar';

import Dashboard from './dashboard';
import HeThong from './he-thong';
import {TaiKhoanThue} from './tai-khoan-thue';
import HoaDon from './hoa-don';
import {HopThu} from './hop-thu-email';
import {Category} from './danh-muc';
import {BaoCao} from './bao-cao';
import TaiKhoan from './tai-khoan';
import Administrator from './administrator';

function App() {

  const {setRipple} = useContext(PrimeReactContext);

  const {me, setMe, layoutConfig, layoutState, setLayoutState} = useContext(LayoutContext);
  const topbarRef = useRef<AppTopbarRef>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    UsersService.getMe().then(data => {
      if (data && data._id) {
        setMe(data);
      } else {
        window.location.hash = '/login';
      }
    });
  }, []);

  const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] =
    useEventListener({
      type: 'click',
      listener: (event) => {
        const isOutsideClicked = !(
          sidebarRef.current?.isSameNode(event.target as Node) ||
          sidebarRef.current?.contains(event.target as Node) ||
          topbarRef.current?.menubutton?.isSameNode(event.target as Node) ||
          topbarRef.current?.menubutton?.contains(event.target as Node)
        );

        if (isOutsideClicked) {
          hideMenu();
        }
      },
    });

  const [
    bindProfileMenuOutsideClickListener,
    unbindProfileMenuOutsideClickListener,
  ] = useEventListener({
    type: "click",
    listener: (event) => {
      const isOutsideClicked = !(
        topbarRef.current?.topbarmenu?.isSameNode(event.target as Node) ||
        topbarRef.current?.topbarmenu?.contains(event.target as Node) ||
        topbarRef.current?.topbarmenubutton?.isSameNode(event.target as Node) ||
        topbarRef.current?.topbarmenubutton?.contains(event.target as Node)
      );

      if (isOutsideClicked) {
        hideProfileMenu();
      }
    },
  });

  const hideMenu = () => {
    setLayoutState((prevLayoutState: LayoutState) => ({
      ...prevLayoutState,
      overlayMenuActive: false,
      staticMenuMobileActive: false,
      menuHoverActive: false,
    }));
    unbindMenuOutsideClickListener();
    unblockBodyScroll();
  };

  const hideProfileMenu = () => {
    setLayoutState((prevLayoutState: LayoutState) => ({
      ...prevLayoutState,
      profileSidebarVisible: false,
    }));
    unbindProfileMenuOutsideClickListener();
  };

  const blockBodyScroll = (): void => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = (): void => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  useMountEffect(() => {
    setRipple(layoutConfig.ripple);
  });

  useEffect(() => {
    if (layoutState.overlayMenuActive || layoutState.staticMenuMobileActive) {
      bindMenuOutsideClickListener();
    }

    layoutState.staticMenuMobileActive && blockBodyScroll();
  }, [layoutState.overlayMenuActive, layoutState.staticMenuMobileActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (layoutState.profileSidebarVisible) {
      bindProfileMenuOutsideClickListener();
    }
  }, [layoutState.profileSidebarVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useUnmountEffect(() => {
    unbindMenuOutsideClickListener();
    unbindProfileMenuOutsideClickListener();
  });

  const containerClass = classNames("layout-wrapper", {
    "layout-overlay": layoutConfig.menuMode === "overlay",
    "layout-static": layoutConfig.menuMode === "static",
    "layout-static-inactive":
      layoutState.staticMenuDesktopInactive &&
      layoutConfig.menuMode === "static",
    "layout-overlay-active": layoutState.overlayMenuActive,
    "layout-mobile-active": layoutState.staticMenuMobileActive,
    "p-input-filled": layoutConfig.inputStyle === "filled",
    "p-ripple-disabled": !layoutConfig.ripple,
  });

  return (
    <div className={containerClass}>
      {me &&
				<Fragment>
					<AppTopbar ref={topbarRef}/>

					<div ref={sidebarRef} className="layout-sidebar">
						<AppSidebar/>
					</div>

					<div className="layout-main-container">
						<div className="layout-main">
							<div className="card" style={{padding: '0.7rem', height: 'calc(100vh - 5.2rem)'}}>
								<Routes>
									<Route path="hoa-don/*" element={<HoaDon/>}/>
									<Route path="hop-thu/*" element={<HopThu me={me}/>}/>
									<Route path="danh-muc/*" element={<Category/>}/>
									<Route path="bao-cao/*" element={<BaoCao me={me}/>}/>
									<Route path="tai-khoan-thue/*" element={<TaiKhoanThue me={me}/>}/>
									<Route path="he-thong/*" element={<HeThong me={me}/>}/>
									<Route path="tai-khoan/*" element={<TaiKhoan me={me}/>}/>
									<Route path="administrator/*" element={<Administrator/>}/>
									<Route path="*" element={<Dashboard/>}/>
								</Routes>
							</div>
						</div>
					</div>

					<div className="layout-mask"></div>
				</Fragment>
      }
    </div>
  );
}

export default App;
