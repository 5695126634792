import {Fragment, useEffect, useState} from 'react';
import {Buffer} from 'buffer';

import {Dialog} from 'primereact/dialog';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {EmailService as Service} from '../EmailService';

import {CheckXml} from '../../../shared/components/check-xml';

export const Content = ({me, message}) => {

  const [messageDetail, setMessageDetail] = useState(null);

  const [displayPdf, setDisplayPdf] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  const [displayXml, setDisplayXml] = useState(false);
  const [xmlContent, setXmlContent] = useState(null);

  useEffect(() => {
    setMessageDetail(null);
    if (message?._id) {
      Service.getMessage(message._id).then(data => {
        setMessageDetail(data);
      });
    }
  }, [message?._id]);

  const viewPart = part => {
    switch (part.contentType) {
      case 'application/pdf':
        const blob = new Blob([Buffer.from(part.content, 'base64')], {type: 'application/pdf'});
        setPdfData(window.URL.createObjectURL(blob));
        setDisplayPdf(true);
        break;
      case 'text/xml':
        setXmlContent(Buffer.from(part.content, 'base64').toString());
        setDisplayXml(true);
        break;
    }
  }

  return (
    <Fragment>
      <div className="grid px-3 h-full pt-3 pb-0" style={{position: 'relative'}}>
        <div className="col-12">
          <div className="grid">
            <div className="col-12">
              <h4 className="mb-0">{message.detail.subject}</h4>
            </div>
            <div className="col-8">
              {message.detail.from}
            </div>
            <div className="col-4 text-right">
              {FormatDisplay.date(message.detail.date)}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="grid">
            {
              messageDetail?.detail?.attachments.map((attachment, index) => {
                if (['application/pdf', 'text/xml'].includes(attachment.contentType)) {
                  return (
                    <div key={index} className="col-3 pb-0 pt-4" onClick={() => viewPart(attachment)}>
                      <div className="px-2 pt-2" style={{border: '1px solid green'}}>
                        <div className="grid pointer">
                          <div className="col-1">
                            <i className={`pi ${attachment.contentType === 'application/pdf' ? 'pi-file-pdf' : 'pi-code'}`} style={{fontSize: '20px', color: 'green'}}></i>
                          </div>
                          <div className="col-9" style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                            <b>{attachment.filename}</b>
                          </div>
                          <div className="col-2">
                            <i className="pi pi-download" style={{fontSize: '20px'}}></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return null;
                }
              })
            }
          </div>
        </div>
        <div className="col-12" dangerouslySetInnerHTML={{__html: message.detail.html}}></div>
      </div>
      <Dialog visible={displayPdf} style={{width: '890px', height: '95vh'}} resizable={true} position="top" onHide={() => setDisplayPdf(false)}>
        {pdfData &&
					<iframe className="iframe-full" src={pdfData} title="template-preview"/>
        }
      </Dialog>
      <CheckXml me={me} xmlContent={xmlContent} display={displayXml} setDisplay={setDisplayXml}/>
    </Fragment>
  );
}