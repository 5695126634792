import {EntityService} from '../../service/EntityService';

export class GmailService extends EntityService {

  static entity = 'gmail';

  static getNewToken(state: string): Promise<any> {
    return this.axios.get(`${this.entity}/getNewToken`, {params: {state}}).then(res => res.data);
  }

  static makeToken(id: string, code: string): Promise<any> {
    return this.axios.post(`${this.entity}/makeToken`, {id, code}).then(res => res.data);
  }

  static getMessages(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/gmail_messages`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static getSync(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/sync`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

}
