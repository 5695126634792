import {useEffect, useState} from 'react';

import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';

import {ForeverLogsService as Service} from './ForeverLogsService';

export const ForeverLogs = () => {

  const [type, setType] = useState('wwwuid');

  const [log, setLog] = useState(null);

  const types = [
    {value: 'wwwuid', label: 'wwwuid'},
    {value: 'service1detailpurchaseuid', label: 'service1 detail purchaseu uid'},
    {value: 'service1detailpurchasescouid', label: 'service1 detail purchaseu sco uid'},
    {value: 'service1detailsolduid', label: 'service1 detail sold uid'},
    {value: 'service1detailsoldscouid', label: 'service1 detail sold sco uid'},
    {value: 'service1purchaseuid', label: 'service1 purchase uid'},
    {value: 'service1purchasescouid', label: 'service1 purchase sco uid'},
    {value: 'service1solduid', label: 'service1 sold uid'},
    {value: 'service1soldscouid', label: 'service1 sold sco uid'},
    {value: 'service2uid', label: 'service 2 uid'},
    {value: 'service3uid', label: 'service 3 uid'}
  ];

  useEffect(() => {
    if (type) {
      load();
    }
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  const load = () => {
    Service.load(type).then(data => {
      setLog(data);
    });
  }

  const clear = () => {
    Service.clear(type).then(() => {
      load();
    });
  }

  return (
    <div className="card">
      <div className="grid p-fluid">
        <div className="col-3">
          <Dropdown value={type} options={types} onChange={e => setType(e.value)}/>
        </div>
        <div className="col-1">
          <Button label="Reload" icon="pi pi-refresh" onClick={load} disabled={!type}/>
        </div>
        <div className="col-1">
          <Button label="Clear" icon="pi pi-trash" className="p-button-warning" onClick={clear} disabled={!type}/>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div style={{whiteSpace: 'pre-wrap'}}>{log}</div>
        </div>
      </div>
    </div>
  );
}