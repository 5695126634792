import {useEffect, useRef, useState} from 'react';

import {Subject} from 'rxjs';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ConfirmDialogService, ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {ProductService} from './ProductService';
import {XlsxService} from '../../../service/XlsxService';

const submitSubject = new Subject<any>();

export const CreateProduct = ({account, display, setDisplay, reloadLazyData}) => {

  const [submitting, setSubmitting] = useState(false);

  const save = () => {
    submitSubject.next(Date.now());
  }

  const footer = (
    <div className="text-center">
      <Button label="Lưu" icon="pi pi-check" className="p-button-sm" type="button" onClick={save} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header="Thêm mã hàng" footer={footer} visible={display} maximized={true} onHide={() => setDisplay(false)}>
      <Screen account={account} setDisplay={setDisplay} submitting={submitting} setSubmitting={setSubmitting} reloadLazyData={reloadLazyData}/>
    </Dialog>
  );
}

const Screen = ({account, setDisplay, submitting, setSubmitting, reloadLazyData}) => {

  const dataKey = 'dataKey';

  const [items, setItems] = useState([]);

  const [displayImport, setDisplayImport] = useState(false);

  useEffect(() => {
    addBlankItem();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const subscription = submitSubject.subscribe(() => {
      doSubmit();
    });
    return () => {
      subscription.unsubscribe();
    }
  }, [JSON.stringify(items)]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem[dataKey] === rowData[dataKey]) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }

  const {render: renderItems} = useDataTableBasic({
    dataKey,
    columns: [
      {field: 'code', header: 'Mã hàng', width: 150, dataType: 'editor', editorConfig: {onEditorChange, field: {InputTextProps: {maxLength: 50}}}},
      {field: 'dvtinh', header: 'Đơn vị tính', width: 150, dataType: 'editor', editorConfig: {onEditorChange, field: {InputTextProps: {maxLength: 50}}}},
      {field: 'tkhoanhanghoa', header: 'Tài khoản\nhàng hoá', width: 150, dataType: 'editor', editorConfig: {onEditorChange, field: {InputTextProps: {maxLength: 20}}}},
      {field: 'tkhoangiavon', header: 'Tài khoản\ngiá vốn', width: 150, dataType: 'editor', editorConfig: {onEditorChange, field: {InputTextProps: {maxLength: 20}}}},
      {field: 'tkhoandoanhthu', header: 'Tài khoản\ndoanh thu', width: 150, dataType: 'editor', editorConfig: {onEditorChange, field: {InputTextProps: {maxLength: 20}}}},
      {field: 'sold_name', header: 'Tên hàng bán ra', minWidth: 200, dataType: 'editor', editorConfig: {onEditorChange, field: {type: 'Chips'}}},
      {field: 'purchase_name', header: 'Tên hàng mua vào', minWidth: 200, dataType: 'editor', editorConfig: {onEditorChange, field: {type: 'Chips'}}}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    items
  });

  const addBlankItem = () => {
    setItems(prevItems => {
      const _dataKey = `${prevItems.length}_${Date.now()}`;
      prevItems.push({
        dataKey: _dataKey,
        actions: [
          {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá mã hàng', command: () => doDeleteItem(_dataKey)}
        ]
      });
      return [...prevItems];
    });
  }

  const doDeleteItem = key => {
    ConfirmDialogService.confirm('Xoá mã hàng', `Xác nhận Xoá mã hàng?`, () => {
      setItems(prevItems => {
        return prevItems.filter(item => item[dataKey] !== key);
      });
    });
  }

  const importExcel = () => {
    setDisplayImport(true);
  }

  const doSetFromExcel = rows => {
    const _items = [];
    rows.forEach((row, index) => {
      const _dataKey = `${index}_${Date.now()}`;
      let sold_name = [];
      if (row[5] && row[5].length) {
        sold_name = row[5].split('\n');
      }
      let purchase_name = [];
      if (row[6] && row[6].length) {
        purchase_name = row[6].split('\n');
      }
      const _item = {
        dataKey: _dataKey,
        actions: [
          {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá mã hàng', command: () => doDeleteItem(_dataKey)}
        ],
        code: row[0],
        dvtinh: row[1],
        tkhoanhanghoa: row[2],
        tkhoangiavon: row[3],
        tkhoandoanhthu: row[4],
        sold_name,
        purchase_name
      };
      _items.push(_item);
    });
    setItems(_items);
  }

  const doSubmit = () => {
    const validItems = items?.filter(it => it.code);
    if (validItems?.length) {

      for (const item of validItems) {
        if (item.tkhoanhanghoa && !/^15/.test(item.tkhoanhanghoa)) {
          return ToastService.error(`Mã hàng ${item.code}: Tài khoản hàng hoá không hợp lệ.`);
        }
        if (item.tkhoangiavon && !/^63/.test(item.tkhoangiavon)) {
          return ToastService.error(`Mã hàng ${item.code}: Tài khoản giá vốn không hợp lệ.`);
        }
        if (item.tkhoandoanhthu && !/^51/.test(item.tkhoandoanhthu)) {
          return ToastService.error(`Mã hàng ${item.code}: Tài khoản doanh thu không hợp lệ.`);
        }
      }

      if (!submitting) {
        setSubmitting(true);

        ProductService.batchStoreCode(account, validItems).then(() => {
          setDisplay(false);
          reloadLazyData();
        }).finally(() => setSubmitting(false));
      }
    } else {
      ToastService.error('Không có dữ liệu');
    }
  }

  return (
    <div className="grid">
      <div className="col-12">
        <div className="flex justify-content-between flex-wrap">
          <div className="flex gap-2">
            <Button icon="pi pi-plus" label="Thêm dòng" size="small" severity="success" onClick={addBlankItem}/>
          </div>
          <div className="flex gap-2">
            <Button icon="pi pi-file-import" label="Nhận Excel" outlined size="small" severity="help" onClick={importExcel}/>
          </div>
        </div>
      </div>
      <div className="col-12 pb-0 p-fluid" style={{maxHeight: 'calc(100vh - 14rem)'}}>
        {renderItems()}
      </div>

      <ImportExcel display={displayImport} setDisplay={setDisplayImport} doSetFromExcel={doSetFromExcel}/>
    </div>
  );
}

const ImportExcel = ({display, setDisplay, doSetFromExcel}) => {

  const inputExcel = useRef(null);

  const [submitting, setSubmitting] = useState(false);
  const [contentFile, setContentFile] = useState(null);

  const downloadTemplate = () => {
    window.location.href = '/import-template/import-hang-hoa-dich-vu.xlsx';
  }

  const onUploadExcel = e => {
    if (e.target.files && e.target.files.length === 1) {
      setContentFile(e.target.files[0]);
    }
  }

  const doImport = () => {
    if (!submitting && contentFile) {
      setSubmitting(true);

      XlsxService.readExcelFile(contentFile, {startRow: 1}).then(async rows => {
        try {
          if (rows?.length) {
            doSetFromExcel(rows);
            setDisplay(false);
          } else {
            ToastService.error('Không có dữ liệu');
          }
          setSubmitting(false);
          inputExcel.current.value = '';
        } catch (e) {
        }
      });
    }
  }

  const footer = (
    <div>
      <Button label="Tải File mẫu" icon="pi pi-download" severity="success" size="small" onClick={downloadTemplate}/>
      <Button label="Import" icon="pi pi-file-import" size="small" onClick={doImport} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header="Import Excel" footer={footer} visible={display} style={{width: '40rem'}} position="top" onHide={() => setDisplay(false)}>
      <div className="p-fluid formgrid grid">
        <div className="field col-12 md:col-12">
          <input ref={inputExcel} type="file" accept=".xlsx, .xls" onChange={onUploadExcel}/>
        </div>
        <div className="field col-12 md:col-12">
          <i>Thêm nhiều Tên hàng bán ra / Mua vào bằng ký tự Xuống dòng trong file Excel.</i>
        </div>
      </div>
    </Dialog>
  );
}