import axios from 'axios';
import {read, utils, write} from 'xlsx';
import {saveAs} from 'file-saver';

import {FormatDisplay} from '@iamsoftware/react-hooks';

interface ReadOpts {
  startRow?: number
}

export class XlsxService {

  static readExcelFile(file: any, opts?: ReadOpts): Promise<any> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = event => {
        if (event && event.target && event.target.result) {
          const wb = read(event.target.result);

          const sheet2JSONOpts: any = {
            header: 1
          };
          if (opts?.startRow) {
            const range = utils.decode_range(wb.Sheets[wb.SheetNames[0]]['!ref']);
            range.s.r = opts.startRow;
            sheet2JSONOpts.range = utils.encode_range(range);
          }

          const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], sheet2JSONOpts);
          resolve(data);
        } else {
          resolve([]);
        }
      }
      reader.onerror = () => {
        resolve([]);
      }
      reader.readAsArrayBuffer(file);
    });
  }

  static readExcelUrl(url: string, opts?: ReadOpts): Promise<any> {
    return axios.get(url, {responseType: 'arraybuffer'})
      .then(response => {
        const wb = read(new Uint8Array(response.data), {type: 'array'});

        const sheet2JSONOpts: any = {
          header: 1
        };
        if (opts?.startRow) {
          const range = utils.decode_range(wb.Sheets[wb.SheetNames[0]]['!ref']);
          range.s.r = opts.startRow;
          sheet2JSONOpts.range = utils.encode_range(range);
        }

        return utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], sheet2JSONOpts);
      })
      .catch(error => {
        console.error('Error reading file:', error);
      });
  }

  static generateFile = (aoa, fileName) => {
    const worksheet = utils.aoa_to_sheet(aoa);
    const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer = write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  static saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE
    });

    saveAs(data, fileName + '_export_' + FormatDisplay.dateTime(new Date()) + EXCEL_EXTENSION);
  }

  static getCellValue = (column, item) => {
    let value = '';
    if (column.customCell) {
      value = column.customCell(item, true);
    } else {
      switch (column.dataType) {
        case 'date':
          if (item[column.field]) {
            value = FormatDisplay.date(item[column.field]);
          }
          break;
        case 'date-time':
          if (item[column.field]) {
            value = FormatDisplay.dateTime(item[column.field]);
          }
          break;
        default:
          value = item[column.field] || '';
      }
    }
    return value;
  }

}