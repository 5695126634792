import {useState} from 'react';

import moment from 'moment';

import {Button} from 'primereact/button';
import {FormatDisplay, ToastService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {AgentsService as Service} from '../AgentsService';
import {TaxCodeService} from '../../../../service/TaxCodeService';

import {License} from './License';
import {XlsxService} from '../../../../service/XlsxService';

export const List = ({me}) => {

  const header = 'Đại lý';
  const dataKey = '_id';

  const [parents, setParents] = useState([]);
  const [defaultParent] = useState({value: me._id, label: `${me.username} - ${me.description}`});

  const [displayLicense, setDisplayLicense] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);

  const downloadExcel = () => {
    Service.getList('{}').then(({items}) => {
      const aoa = [[]];
      columns.forEach(column => {
        aoa[0].push(column.header);
        items.forEach((item, index) => {
          if (!aoa[index + 1]) {
            aoa[index + 1] = [];
          }
          let colData;
          if (column.customCell) {
            colData = column.customCell(item, true);
          } else {
            switch (column.field) {
              case 'username':
              case 'description':
                colData = item[column.field];
                break;
              case 'type':
                for (const type of Service.types) {
                  if (type.value === item[column.field]) {
                    colData = type.label;
                    break;
                  }
                }
                break;
            }
          }
          aoa[index + 1].push(colData);
        });
      });

      XlsxService.generateFile(aoa, 'danh-sach-dai-ly');
    });
  }

  const columns: any = [
    {field: 'username', header: 'Tài khoản', width: 250, matchMode: 'contains'},
    {field: 'type', header: 'Loại', width: 150, matchMode: 'equals', filterType: 'dropdown', filterOptions: Service.types, dataType: 'fromFilterOptions'},
    {field: 'description', header: 'Tên đơn vị', minWidth: 250, matchMode: 'contains'},
    {
      field: 'license', header: 'Tổng số hoá đơn', width: 160, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): string {
        if (rowData.license) {
          return skipFormat === true ? rowData.license.invoice_quantity : FormatDisplay.number(rowData.license.invoice_quantity);
        }
        return '';
      }, style: {textAlign: 'right'}
    },
    {
      field: 'in_use', header: 'Đã sử dụng', width: 140, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): string {
        if (rowData.in_use) {
          return skipFormat === true ? rowData.in_use.invoice : FormatDisplay.number(rowData.in_use.invoice);
        }
        return '';
      }, style: {textAlign: 'right'}
    },
    {
      field: 'thru_date', header: 'Ngày hết hạn', width: 170, dataType: 'custom', customCell(rowData: any): string {
        if (rowData.license) {
          return FormatDisplay.dateTime(rowData.license.thru_date);
        }
        return '';
      }
    },
    {
      field: 'remaining_days', header: 'Số ngày còn lại', width: 130, sortable: false, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
        if (rowData.license && rowData.license.thru_date) {
          const remaining_days = moment(rowData.license.thru_date).diff(moment(), 'days');
          if (remaining_days > 0) {
            return skipFormat === true ? remaining_days : <span className="alert-info">{FormatDisplay.number(remaining_days)}</span>
          } else {
            return skipFormat === true ? 'Hết hạn' : <span className="alert-danger">Hết hạn</span>
          }
        }
        return '';
      }, style: {textAlign: 'right'}
    },
    {
      field: 'parent', header: 'Thuộc đại lý', minWidth: 200, dataType: 'custom', customCell(rowData: any): string {
        return rowData.parent ? `${rowData.parent.username} - ${rowData.parent.description}` : ''
      }
    }
  ];

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns,
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', disabled: item._id === me._id, command: () => update(item)}
          ];
          item.actionMenus = [];
          switch (me.type) {
            case 'admin':
              item.actionMenus.push(...[
                {label: 'Gói dịch vụ', icon: 'pi pi-credit-card', command: () => showLicenseDialog(item)},
                {label: 'Đặt lại mật khẩu', icon: 'pi pi-replay', command: () => changePassword(item)},
                {
                  label: 'Đổi đại lý', icon: 'pi pi-id-card', command: () => {
                    const _item = Object.assign({}, item);
                    if (_item.parent) {
                      _item.parent = {value: item.parent._id, label: `${item.parent.username} - ${item.parent.description}`};
                    }
                    changeParent(_item);
                  }
                }
              ]);
              break;
            case 'agent':
              if (item._id !== me._id) {
                item.actionMenus.push(...[
                  {label: 'Gói dịch vụ', icon: 'pi pi-credit-card', command: () => showLicenseDialog(item)}
                ]);
              }
              break;
          }
          if (item.actionMenus.length === 0) {
            delete item.actionMenus;
          }
        });
      }
    }
  });

  const showLicenseDialog = (item) => {
    setSelectedItem(item);
    setDisplayLicense(true);
  }

  const searchParents = (event) => {
    const _event = {
      filters: {
        username: {value: event.query, matchMode: 'contains'}
      }
    };
    Service.getList(JSON.stringify(_event)).then(({items}) => {
      const _parents = [];
      items.forEach(item => {
        _parents.push({value: item._id, label: `${item.username} - ${item.description}`});
      });
      setParents(_parents);
    });
  }

  const getOrgByTaxCode = () => {
    const _value = form.getValue();
    if (_value.username) {
      TaxCodeService.get(_value.username).then(org => {
        if (org?.tennnt) {
          _value.password = _value.username;
          _value.description = org.tennnt;
          form.setValue(_value);
        } else {
          ToastService.error('Không tìm thấy thông tin Mã số thuế');
        }
      });
    } else {
      ToastService.error('Tài khoản (Mã số thuế) không được để trống');
    }
  }

  const {render: renderDialogCreate, form, create} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'parent', header: 'Thuộc đại lý', required: true, type: 'AutoComplete', AutoCompleteProps: {suggestions: parents, completeMethod: searchParents, field: 'label', dropdown: true}, className: 'md:col-6'},
      {field: 'type', header: 'Loại', required: true, type: 'Dropdown', DropdownProps: {options: Service.types}, className: 'md:col-6'},
      {field: 'username', header: 'Tài khoản', required: true, InputTextProps: {maxLength: 50, keyfilter: 'alphanum'}, className: 'md:col-5'},
      {
        type: 'custom', className: 'md:col-1', body: <Button icon="pi pi-search" className="p-button-rounded p-button-sm" style={{marginTop: '24px'}} onClick={getOrgByTaxCode}/>
      },
      {field: 'password', header: 'Mật khẩu', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-6'},
      {field: 'description', header: 'Tên đơn vị', required: true, InputTextProps: {maxLength: 500}, className: 'md:col-12'},
      {field: 'email', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-12'},
      {field: 'comment', header: 'Ghi chú', InputTextProps: {maxLength: 500}, className: 'md:col-12'}
    ],
    createItem: item => {
      item.parent = item.parent.value;
      return Service.create(item);
    },
    reloadLazyData
  });

  const {render: renderDialogUpdate, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'type', header: 'Loại', required: true, type: 'Dropdown', DropdownProps: {options: Service.types}, className: 'md:col-12'},
      {field: 'username', header: 'Tài khoản', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-12'},
      {field: 'email', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-12'},
      {field: 'description', header: 'Tên đơn vị', InputTextProps: {maxLength: 50}, className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const {render: renderDialogChangePassword, update: changePassword} = useDialogCrup({
    header: '!Đặt lại mật khẩu',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'password', header: 'Mật khẩu', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.changePassword(id, item);
    },
    reloadLazyData
  });

  const {render: renderDialogChangeParent, update: changeParent} = useDialogCrup({
    header: '!Đổi đại lý',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'parent', header: 'Thuộc đại lý', required: true, type: 'AutoComplete', AutoCompleteProps: {suggestions: parents, completeMethod: searchParents, field: 'label', dropdown: true}, className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      item.parent = item.parent.value;
      return Service.changeParent(id, item);
    },
    reloadLazyData
  });

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
				<div className="col-4">
					<Button label="Thêm" icon="pi pi-plus" className="p-button-sm mr-2" onClick={() => create({parent: defaultParent, type: 'customer'})}/>
          {me.type === 'admin' &&
            <Button label="Kết xuất Excel" icon="pi pi-file-excel" className="p-button-sm" severity="help" onClick={downloadExcel}/>
          }
				</div>
			</div>
      {renderDataTable()}
      {renderDialogCreate()}
      {renderDialogUpdate()}
      {renderDialogChangePassword()}
      {renderDialogChangeParent()}

      <License display={displayLicense} setDisplay={setDisplayLicense} user={selectedItem} reloadLazyData={reloadLazyData}/>
    </div>
  );
}