import {useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import {FormatDisplay, useDataTableBasic} from '@iamsoftware/react-hooks';

import {InvoicesService as Service} from './InvoicesService';

export const Relative = ({account, type, mstColumn, allTags, display, setDisplay, showInvoice}) => {

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(null);

  useEffect(() => {
    if (display && display._id) {

      const rootInvoice = {
        rel: display,
        header: display.header,
        actions: [
          {icon: 'pi pi-eye', title: 'Xem', command: () => showInvoice(display)}
        ]
      };

      setLoading(true);
      setItems([]);
      if ([2, 3].includes(display.header?.tthai) && [1, '1', null].includes(display.header?.lhdgoc)) {
        let _event: any = {
          filters: {
            'keys.khmshdon': {value: display.header.khmshdgoc, matchMode: 'equals'},
            'keys.khhdon': {value: display.header.khhdgoc, matchMode: 'equals'},
            'keys.shdon': {value: display.header.shdgoc, matchMode: 'equals'},
            'keys.nbmst': {value: display.header.nbmst, matchMode: 'equals'}
          }
        };
        Service.getList(account, type, JSON.stringify(_event)).then(async hdgocData => {
          if (hdgocData.items?.length) {
            const _items = [];
            for (const hdgoc of hdgocData.items) {
              _items.push({
                rel: hdgoc,
                header: hdgoc.header,
                actions: [
                  {icon: 'pi pi-eye', title: 'Xem', command: () => showInvoice(hdgoc)}
                ]
              });

              _event = {
                filters: {
                  'header.khmshdgoc': {value: '' + hdgoc.header.khmshdon, matchMode: 'equals'},
                  'header.khhdgoc': {value: '' + hdgoc.header.khhdon, matchMode: 'equals'},
                  'header.shdgoc': {value: hdgoc.header.shdon, matchMode: 'equals'},
                  'keys.nbmst': {value: '' + hdgoc.header.nbmst, matchMode: 'equals'}
                }
              };
              const invoiceData = await Service.getList(account, type, JSON.stringify(_event));
              if (invoiceData?.items?.length) {
                for (const item of invoiceData.items) {
                  const invoiceItem = {
                    rel: item,
                    header: item.header,
                    actions: [
                      {icon: 'pi pi-eye', title: 'Xem', command: () => showInvoice(item)}
                    ]
                  };
                  _items.push(invoiceItem);
                  if (item._id === display._id) {
                    setSelectedItemsNd123(invoiceItem as any);
                  }
                }
              }
            }
            setItems(_items);
          }
        }).finally(() => {
          setLoading(false);
        });
      } else if ([4, 5].includes(display.header?.tthai)) {
        const _event = {
          filters: {
            'header.khmshdgoc': {value: '' + display.header.khmshdon, matchMode: 'equals'},
            'header.khhdgoc': {value: '' + display.header.khhdon, matchMode: 'equals'},
            'header.shdgoc': {value: display.header.shdon, matchMode: 'equals'},
            'keys.nbmst': {value: '' + display.header.nbmst, matchMode: 'equals'}
          }
        };
        Service.getList(account, type, JSON.stringify(_event)).then(data => {
          if (data.items?.length) {
            const _items = [rootInvoice];
            for (const item of data.items) {
              _items.push({
                rel: item,
                header: item.header,
                actions: [
                  {icon: 'pi pi-eye', title: 'Xem', command: () => showInvoice(item)}
                ]
              });
            }
            setItems(_items);
            setSelectedItemsNd123(rootInvoice as any);
          }
        }).finally(() => {
          setLoading(false);
        });
      } else if ([3, '3'].includes(display.header?.lhdgoc)) {
        const _items = [
          rootInvoice,
          {
            khmshdon: display.header?.khmshdgoc,
            khhdon: display.header?.khhdgoc,
            shdon: display.header?.shdgoc,
            tdlap: display.header?.tdlhdgoc,
            lhdgoc: 'Hoá đơn NĐ51'
          }
        ];
        setItems(_items);
        setSelectedItemsNd51(rootInvoice as any);
        setLoading(false);
      } else if ([6].includes(display.header?.tthai)) {
        setLoading(false);
      }
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTableNd123, setSelectedItems: setSelectedItemsNd123} = useDataTableBasic({
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    columns: [
      {
        field: 'nkekhai', header: 'Ngày kê khai', width: 125, dataType: 'custom', customCell(rowData): any {
          return <span title={`${rowData.rel?.data?.nkekhai_label} | ${rowData.rel?.data?.nkekhai_config_name}`}>{FormatDisplay.date(rowData.rel?.data?.nkekhai || '')}</span>;
        }
      },
      {
        field: 'header.khhdon', header: 'Ký hiệu', width: 100, dataType: 'custom', customCell(rowData): any {
          return `${rowData.header?.khmshdon}${rowData.header?.khhdon}`;
        }
      },
      {
        field: 'header.shdon', header: 'Số hóa đơn', width: 120, dataType: 'custom', customCell(rowData): any {
          let tthai = rowData.header.tthai;
          Service.tthais.forEach(t => {
            if (t.value === tthai) {
              tthai = t.label;
            }
          });
          return <div>
            <p className="mb-0">{rowData.header?.shdon}</p>
            <p className={`mb-0 tthai-${rowData.header.tthai}`}>{tthai}</p>
          </div>
        }
      },
      {
        field: 'header.tdlap', header: 'Ngày lập', width: 100, dataType: 'custom', customCell(rowData): any {
          return FormatDisplay.date(rowData.header?.tdlap);
        }
      },
      {
        field: 'header.nky', header: 'Ngày ký', width: 100, dataType: 'custom', customCell(rowData): any {
          return FormatDisplay.date(rowData.header?.nky);
        }
      },
      mstColumn,
      {
        field: 'header.tgtcthue', header: 'Tổng tiền chưa thuế', width: 160, sortable: false, dataType: 'custom', customCell(rowData): any {
          return FormatDisplay.number(rowData.header?.tgtcthue);
        }, style: {textAlign: 'right'}
      },
      {
        field: 'header.tgtthue', header: 'Tổng tiền thuế', width: 120, sortable: false, dataType: 'custom', customCell(rowData): any {
          return FormatDisplay.number(rowData.header?.tgtthue);
        }, style: {textAlign: 'right'}
      },
      {
        field: 'header.tgtttbso', header: 'Tổng tiền thanh toán', width: 160, sortable: false, dataType: 'custom', customCell(rowData): any {
          return FormatDisplay.number(rowData.header?.tgtttbso);
        }, style: {textAlign: 'right'}
      },
      {
        field: 'warning.status', header: 'KQ kiểm tra', width: 130, dataType: 'custom', customCell(rowData): any {
          let title = '';
          if (rowData.rel?.warning?.warning_array) {
            title = rowData.rel?.warning?.warning_array.join(' - ');
          }
          return <div className={rowData.rel?.warning?.status === 'Cảnh báo' ? 'alert alert-danger' : 'alert alert-info'} title={title}>{rowData.rel?.warning ? rowData.rel?.warning.status : ''}</div>
        }
      },
      {
        field: 'rel.comment', header: 'Ghi chú', minWidth: 100, dataType: 'custom', customCell(rowData): any {
          return rowData.rel?.comment;
        }
      },
      {
        field: 'tags', header: 'Nhãn', minWidth: 160, sortable: false, dataType: 'custom', customCell(rowData): any {
          if (rowData.rel?.tags && rowData.rel?.tags.length) {
            let _tags = [];
            allTags?.forEach(tag => {
              rowData.rel?.tags.forEach(t => {
                if (t === tag._id) {
                  _tags.push(tag.name);
                }
              });
            });
            return _tags.join(', ');
          } else {
            return '';
          }
        }, className: `${allTags?.length}`
      },
      {
        field: 'tags', header: 'Thông tin liên quan', minWidth: 160, sortable: false, dataType: 'custom', customCell(rowData): any {
          return <Related _id={rowData?.rel?._id}/>
        }
      }
    ],
    items,
    originalProps: {
      loading
    }
  });

  const {render: renderDataTableNd51, setSelectedItems: setSelectedItemsNd51} = useDataTableBasic({
    indexColumnWidth: 45,
    columns: [
      {field: 'khmshdon', header: 'Mẫu số', width: 150},
      {field: 'khhdon', header: 'Ký hiệu', width: 150},
      {field: 'shdon', header: 'Số hóa đơn', width: 150},
      {field: 'tdlap', header: 'Ngày lập', width: 150, dataType: 'date'},
      {field: 'lhdgoc', header: 'Loại hoá đơn liên quan', minWidth: 150}
    ],
    items
  });

  const footer = (
    <div className="text-center">
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  if (display.header?.tthai === 6) {
    return (
      <Dialog header={`Thông tin liên quan`} footer={footer} visible={!!display} style={{width: '50rem'}} position="top" onHide={() => setDisplay(false)}>
        <div className="grid">
          <div className="col-12">
            <Related _id={display?._id}/>
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog header={`Danh sách hóa đơn liên quan`} footer={footer} visible={!!display} style={{width: '100vw'}} position="top" onHide={() => setDisplay(false)}>
      <div className="grid">
        <div className="col-12">
          {[1, '1', null].includes(display.header?.lhdgoc) && renderDataTableNd123()}
          {[3, '3'].includes(display.header?.lhdgoc) && renderDataTableNd51()}
        </div>
      </div>
    </Dialog>
  );
}

const Related = ({_id}) => {

  const [hdtbssrses, setHdtbssrses] = useState(null);

  useEffect(() => {
    if (_id) {
      Service.getRelated(_id).then(result => {
        let _hdtbssrses = '';
        if (result?.hdtbssrses?.length) {
          _hdtbssrses = result.hdtbssrses.map((tbss, index) => {
            let _content = '';
            switch (tbss.loai) {
              case 0:
                _content = `Hóa đơn có thông báo rà soát số ${tbss.so}, ngày thông báo ${FormatDisplay.date(tbss.ngay)}, lý do ${tbss.ldo}.`;
                break;
              case 1:
              case 2:
                let ttxly = '';
                if ([8, 9].includes(tbss.ttxly)) {
                  if (tbss.kqtnhan === 0) {
                    ttxly = `Cơ quan thuế không tiếp nhận, lý do ${tbss.gchu}.`;
                  } else if (tbss.kqtnhan === 1) {
                    ttxly = 'Cơ quan thuế tiếp nhận.';
                  }
                } else {
                  ttxly = 'Cán bộ thuế đang xử lý.';
                }
                _content = `Hóa đơn có ${tbss.ten} ngày ${FormatDisplay.date(tbss.ngay)}. Tính chất ${tctbaoMap[tbss.tctbao]}, lý do ${tbss.ldo}.<br/>${ttxly}`;
                break;
            }
            return <p key={index} dangerouslySetInnerHTML={{__html: _content}}></p>
          });
        }
        setHdtbssrses(_hdtbssrses);
      });
    }
  }, [_id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>{hdtbssrses}</div>
  );
}

const tctbaoMap = {
  1: 'Huỷ',
  2: 'Điều chỉnh',
  3: 'Thay thế',
  4: 'Giải trình'
};