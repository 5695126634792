import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {EmployeesService as Service} from './EmployeesService';
import {ExternalAccountsService} from '../../tai-khoan-thue/ExternalAccountsService';

export const Employees = ({me}) => {

  const header = 'Nhân viên';
  const dataKey = '_id';

  const [accounts, setAccounts] = useState(null);

  const [refLinkTooltip, setRefLinkTooltip] = useState('');

  useEffect(() => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        _accounts.push({value: item._id, label: `${item.username} - ${item.profile?.name || ''}`});
      });
      setAccounts(_accounts);
    });
  }, []);

  const copyToken = username => async event => {
    try {
      await navigator.clipboard.writeText(username);
      setRefLinkTooltip('Đã sao chép');
    } catch (e) {
      setRefLinkTooltip('Sao chép không thành công, Có lỗi xảy ra.');
    }
    event.preventDefault();
  }

  const setDisabled = (id, disabled) => {
    Service.update(id, {disabled}).then(() => {
      reloadLazyData();
    });
  }

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {
        field: 'username', header: 'Tài khoản', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <div className="grid">
            <div className="col py-3">{rowData.username}</div>
            <div className="col-fixed" style={{width: '50px'}} onMouseEnter={() => setRefLinkTooltip('Click để sao chép')}>
              <Button icon="pi pi-copy" onClick={copyToken(rowData.username)} tooltip={refLinkTooltip}
                      className="p-button-sm p-button-rounded p-button-outlined p-button-secondary"/>
            </div>
          </div>
        }
      },
      {field: 'description', header: 'Tên nhân viên', minWidth: 200, matchMode: 'contains'},
      {field: 'email', header: 'Email', minWidth: 200, matchMode: 'contains'},
      {
        field: 'relatives', header: 'Tài khoản CQT', minWidth: 200, sortable: false, dataType: 'custom', customCell(rowData): any {
          if (rowData.relatives && rowData.relatives.length) {
            let _accounts = [];
            accounts?.forEach(account => {
              rowData.relatives.forEach(t => {
                if (t === account.value) {
                  _accounts.push(account.label);
                }
              });
            });
            return _accounts.join(', ');
          } else {
            return '';
          }
        }, className: `${accounts?.length}`
      },
      {
        field: 'disabled', header: 'Sử dụng', width: 90, sortable: false, dataType: 'custom', customCell(rowData): any {
          return <Checkbox inputId="sync_invoices_sold" checked={!rowData.disabled} onChange={e => setDisabled(rowData[dataKey], !e.checked)}/>
        }, style: {justifyContent: 'center'}
      }
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => doUpdate(item)}
          ];
          item.actionMenus = [
            {label: 'Đặt lại mật khẩu', icon: 'pi pi-replay', command: () => changePassword(item)}
          ];
        });
      }
    }
  });

  const doCreate = () => {
    Service.getList('{}').then(data => {
      create({
        username: me.username + '_smi' + `${(data.totalRecords + 1)}`.padStart(3, '0')
      });
    });
  }

  const doUpdate = item => {
    if (item.expired_at) {
      item.expired_at = new Date(item.expired_at);
    }
    update(item);
  }

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: '_id', type: 'hidden'},
      {field: 'username', header: 'Tài khoản', disabled: true, className: 'md:col-12'},
      {
        field: 'password', header: 'Mật khẩu', required: true, type: 'Password', displayDependency(item: any): boolean {
          return !item._id;
        }, className: 'md:col-12'
      },
      {field: 'description', header: 'Tên nhân viên', required: true, className: 'md:col-12'},
      {field: 'email', header: 'Email', required: true, disabled: 'Update', type: 'email', className: 'md:col-12'},
      {field: 'relatives', header: 'Tài khoản CQT', required: true, type: 'MultiSelect', MultiSelectProps: {options: accounts, display: 'chip'}, className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const {render: renderDialogChangePassword, update: changePassword} = useDialogCrup({
    header: '!Đặt lại mật khẩu',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'password', header: 'Mật khẩu', required: true, type: 'Password', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.changePassword(id, item);
    },
    reloadLazyData
  });

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-12">
          <Button label="Thêm" icon="pi pi-plus" className="p-button-sm" onClick={doCreate}/>
        </div>
      </div>
      {renderDataTable()}
      {renderDialogCrup()}
      {renderDialogChangePassword()}
    </div>
  );
}