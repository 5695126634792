import {useState} from 'react';

import {Checkbox} from 'primereact/checkbox';
import {Button} from 'primereact/button';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable} from '@iamsoftware/react-hooks';

import {ExternalAccountsService as Service} from './ExternalAccountsService';

import {Crup} from './Crup';
import {Login} from './Login';
import {Config} from './Config';

export const List = ({me}) => {

  const dataKey = '_id';

  const [requiredParams] = useState({
    type: {value: 'hoadondientu-gov', matchMode: 'equals'},
    visible: {value: true, matchMode: 'equals'}
  });

  const [displayCrup, setDisplayCrup] = useState(false);
  const [crupItem, setCrupItem] = useState(null);

  const [displayLogin, setDisplayLogin] = useState(false);
  const [loginItem, setLoginItem] = useState(null);

  const [displayConfig, setDisplayConfig] = useState(false);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    // headerColumnGroups: [
    //   {header: 'Tài khoản', colSpan: 4},
    //   {header: 'Hóa đơn điện tử bán ra', colSpan: 3},
    //   {header: 'Hóa đơn điện tử mua vào', colSpan: 3},
    //   {header: '', colSpan: 2}
    // ],
    columns: [
      {field: 'username', header: 'Tài khoản', width: 140, matchMode: 'contains'},
      {
        field: 'profile.name', header: 'Tên', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return rowData.profile ? rowData.profile.name : '';
        }
      },
      {
        field: 'auto_login.auto', header: 'Tự động đồng bộ', width: 130, dataType: 'custom', style: {justifyContent: 'center'}, customCell(rowData: any): any {
          if (rowData.sync_invoices_sold) {
            return <Checkbox inputId="binary" checked={rowData.auto_login.auto}/>;
          }
          return '';
        }, className: 'text-center'
      },
      {
        field: 'token', header: 'Trạng thái', width: 120, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.token) {
            return <b style={{color: 'green'}}>Đã kết nối</b>
          } else {
            return <b style={{color: 'red'}}>Chưa kết nối</b>
          }
        }
      },
      {
        field: 'sync_invoices_sold.sync', header: 'Đồng bộ - BR', width: 130, dataType: 'custom', style: {justifyContent: 'center'}, customCell(rowData: any): any {
          if (rowData.sync_invoices_sold) {
            return <Checkbox inputId="binary" checked={rowData.sync_invoices_sold.sync}/>;
          }
          return '';
        }, className: 'text-center'
      },
      {
        field: 'sync_invoices_sold.ran', header: 'Gần đây - BR', width: 160, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.sync_invoices_sold) {
            return FormatDisplay.dateTime(rowData.sync_invoices_sold.ran);
          }
          return '';
        }, hidden: true
      },
      {
        field: 'sync_invoices_sold.run', header: 'Tiếp theo - BR', width: 160, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.sync_invoices_sold) {
            return FormatDisplay.dateTime(rowData.sync_invoices_sold.run);
          }
          return '';
        }, hidden: true
      },
      {
        field: 'sync_invoices_purchase.sync', header: 'Đồng bộ - MV', width: 135, dataType: 'custom', style: {justifyContent: 'center'}, customCell(rowData: any): any {
          if (rowData.sync_invoices_purchase) {
            return <Checkbox inputId="binary" checked={rowData.sync_invoices_purchase.sync}/>;
          }
          return '';
        }, className: 'text-center'
      },
      {
        field: 'sync_invoices_purchase.ran', header: 'Gần đây - MV', width: 160, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.sync_invoices_purchase) {
            return FormatDisplay.dateTime(rowData.sync_invoices_purchase.ran);
          }
          return '';
        }, hidden: true
      },
      {
        field: 'sync_invoices_purchase.run', header: 'Tiếp theo - MV', width: 160, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.sync_invoices_purchase) {
            return FormatDisplay.dateTime(rowData.sync_invoices_purchase.run);
          }
          return '';
        }, hidden: true
      },
      {
        field: 'login', header: '', width: 130, sortable: false, dataType: 'custom', customCell(rowData): any {
          return <Button label="Đăng nhập lại" className="p-button-outlined p-button-sm" onClick={() => login(rowData)}/>
        }
      }
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => update(item)}
          ];
          item.actionMenus = [
            {label: 'Đồng bộ hóa đơn bán ra ngay', icon: 'pi pi-replay', command: () => syncInvoicesSold(item)},
            {label: 'Đồng bộ hóa đơn mua vào ngay', icon: 'pi pi-replay', command: () => syncInvoicesPurchase(item)},
            {separator: true},
            {label: 'Đăng xuất', icon: 'pi pi-sign-out', command: () => signOutAccount(item)}
          ]
        });
      }
    },
    requiredParams
  });

  const create = () => {
    setCrupItem(null);
    setDisplayCrup(true);
  }

  const update = (item) => {
    setCrupItem(item);
    setDisplayCrup(true);
  }

  const login = (item) => {
    setLoginItem(item);
    setDisplayLogin(true);
  }

  const syncInvoicesSold = (item) => {
    Service.syncInvoicesSold(item._id).then(() => {
      ToastService.success();
      reloadLazyData();
    });
  }

  const syncInvoicesPurchase = (item) => {
    Service.syncInvoicesPurchase(item._id).then(() => {
      ToastService.success();
      reloadLazyData();
    });
  }

  const signOutAccount = (item) => {
    ConfirmDialogService.confirm('Đăng xuất', 'Đăng xuất tài khoản sẽ dừng tác vụ đồng bộ hoá đơn của tài khoản này.', () => {
      Service.signOut(item._id).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    });
  }

  return (
    <div className="p-datatable-paginator-sticky">
      {!['employee'].includes(me.type) && <div className="grid">
				<div className="col-12 flex gap-2">
					<Button label="Thêm" icon="pi pi-plus" className="p-button-sm" severity="success" onClick={create}/>
					<Button label="Cấu hình hiển thị" icon="pi pi-cog" className="p-button-sm" onClick={() => setDisplayConfig(true)}/>
				</div>
			</div>}
      {renderDataTable()}

      <Crup display={displayCrup} setDisplay={setDisplayCrup} account={crupItem} reloadLazyData={reloadLazyData}/>
      <Login display={displayLogin} setDisplay={setDisplayLogin} account={loginItem} reloadLazyData={reloadLazyData}/>
      <Config display={displayConfig} setDisplay={setDisplayConfig} reloadLazyData={reloadLazyData}/>
    </div>
  );
}