import {Service} from '../../../service/Service';

export class ProductService extends Service {

  static entity = 'category';

  static sync(account: string): Promise<any> {
    return this.axios.post(`${this.entity}/${account}/products/sync`).then(res => res.data);
  }

  static getProducts(account: string, lazyLoadEvent: string, hidden?: string): Promise<any> {
    return this.axios.get(`${this.entity}/${account}/products`, {params: {lazyLoadEvent, hidden}}).then(res => res.data);
  }

  static updateName(_id: string, data): Promise<any> {
    return this.axios.post(`${this.entity}/${_id}/update`, data).then(res => res.data);
  }

  static setCode(_id: string, data): Promise<any> {
    return this.axios.post(`${this.entity}/${_id}/set-code`, data).then(res => res.data);
  }

  static updateCode(_id: string, data): Promise<any> {
    return this.axios.post(`${this.entity}/${_id}/update-code`, data).then(res => res.data);
  }

  static updateProduct(_id: string, data): Promise<any> {
    return this.axios.post(`${this.entity}/${_id}/update-product`, data).then(res => res.data);
  }

  static findOne(account: string, params: any): Promise<any> {
    return this.axios.get(`${this.entity}/${account}/find-one-product`, {params}).then(res => res.data);
  }

  static batchStoreCode(account: string, items: Array<any>): Promise<any> {
    return this.axios.post(`${this.entity}/${account}/batch-store-code`, {items}).then(res => res.data);
  }

  static findProducts(account: string, lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/${account}/find-products`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

}
