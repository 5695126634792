import {Service} from '../../../service/Service';

export class ForeverLogsService extends Service {

  static entity = 'system/log-file';

  static load(type: string): Promise<any> {
    return this.axios.get(`${this.entity}/${type}`).then(res => res.data);
  }

  static clear(type: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${type}`).then(res => res.data);
  }

}
