import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {InvoicesService} from './InvoicesService';
import {ExternalAccountsService} from '../tai-khoan-thue/ExternalAccountsService';

import {fromFieldOptions, getOriginalItems} from '../tai-khoan/khai-bao-ngay-ke-khai/Config';

export const SetDateOfInvoiceDeclaration = ({display, setDisplay, account, reloadLazyData}) => {

  const dataKey = '_id';

  const [originalItems, setOriginalItems] = useState([]);
  const [date_of_invoice_declaration_config, setDate_of_invoice_declaration_config] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const [items, setItems] = useState([]);

  useEffect(() => {
    getOriginalItems().then(rows => {
      setOriginalItems(rows);
    });
  }, []);

  useEffect(() => {
    if (account && originalItems?.length) {
      const _event = {
        filters: {
          _id: {value: account, matchMode: 'ObjectId'}
        }
      };
      ExternalAccountsService.getList(JSON.stringify(_event)).then(data => {
        if (data?.items?.length && data.items[0].date_of_invoice_declaration_config?.length) {
          setDate_of_invoice_declaration_config(data.items[0].date_of_invoice_declaration_config);
        } else {
          setDate_of_invoice_declaration_config(originalItems);
        }
      });
    }
  }, [account, originalItems]);

  useEffect(() => {
    if (display && date_of_invoice_declaration_config?.length) {
      const invoices = [];
      if (Array.isArray(display)) {
        display.forEach(d => {
          const nkekhai_field = get_nkekhai_field(d);
          let nkekhai_manual = '';
          if (nkekhai_field === 'manual') {
            nkekhai_manual = d.data?.nkekhai;
          }
          if (nkekhai_manual) {
            nkekhai_manual = FormatDisplay.date(nkekhai_manual, 'YYYY-MM-DD');
          }
          invoices.push({...d.header, nkekhai_field, nkekhai_manual, _id: d._id});
        });
      } else {
        const nkekhai_field = get_nkekhai_field(display);
        let nkekhai_manual = '';
        if (nkekhai_field === 'manual') {
          nkekhai_manual = display.data?.nkekhai;
        }
        if (nkekhai_manual) {
          nkekhai_manual = FormatDisplay.date(nkekhai_manual, 'YYYY-MM-DD');
        }
        invoices.push({...display.header, nkekhai_field, nkekhai_manual, _id: display._id});
      }
      setItems(invoices);
    }
  }, [display, date_of_invoice_declaration_config]); // eslint-disable-line react-hooks/exhaustive-deps

  const get_nkekhai_field = invoice => {
    if (invoice.data?.nkekhai_field) {
      return invoice.data?.nkekhai_field;
    } else if (invoice.header) {
      for (const config of date_of_invoice_declaration_config) {
        if (invoice.type === config._typeValue && invoice.header.khmshdon?.toString() === config.khmshdon?.toString() && invoice.header.khhdon[0] === config.khhdon) {
          return config._fromField;
        }
      }
    }
  }

  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem[dataKey] === rowData[dataKey]) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }

  const {render} = useDataTableBasic({
    tableHeader: `Hoá đơn`,
    dataKey,
    indexColumnWidth: 40,
    columns: [
      {field: 'khmshdon', header: 'Mẫu số', width: 80, sortable: false},
      {field: 'khhdon', header: 'Ký hiệu', width: 100, sortable: false},
      {field: 'shdon', header: 'Số hoá đơn', width: 120, sortable: false},
      {
        field: 'tgtttbso', header: 'Số tiền', minWidth: 150, sortable: false, dataType: 'custom', customCell(rowData: any): any {
          return <span className={rowData.tgtttbso > 0 ? 'alert-info' : 'alert-danger'}>{FormatDisplay.number(rowData.tgtttbso)}</span>
        }, className: 'text-right'
      },
      {field: 'tdlap', header: 'Ngày lập', width: 110, sortable: false, dataType: 'date'},
      {field: 'nky', header: 'Ngày ký', width: 110, sortable: false, dataType: 'date'},
      {field: 'ncma', header: 'Ngày cấp mã', width: 130, sortable: false, dataType: 'date'},
      {
        field: 'nkekhai_field', header: 'Ngày kê khai', width: 170, sortable: false,
        dataType: 'editor', getEditorConfig(rowData: any): any {
          let options = [];
          if (rowData.khhdon) {
            switch (rowData.khhdon[0]) {
              case 'C':
                options = fromFieldOptions.filter(option => ['tdlap', 'ncma'].includes(option.value));
                break;
              case 'K':
                options = fromFieldOptions.filter(option => ['tdlap', 'nky'].includes(option.value));
                break;
            }
          }
          options.push({value: 'manual', label: 'Nhập thủ công'});
          return {onEditorChange, field: {type: 'Dropdown', DropdownProps: {options}}};
        }
      },
      {
        field: 'nkekhai_manual', header: '', width: 150, sortable: false, dataType: 'editor', getEditorConfig(rowData: any): any {
          if (rowData?.nkekhai_field === 'manual') {
            return {onEditorChange, field: {InputTextProps: {type: 'date'}}};
          }
          return {field: {InputTextProps: {className: 'hidden'}}};
        }
      }
    ],
    items
  });

  const onSubmit = () => {
    if (!submitting) {
      setSubmitting(true);

      const complete = [];
      items.forEach(item => {

        let nkekhai, nkekhai_field, nkekhai_label;
        if (item.nkekhai_field) {
          nkekhai_field = item.nkekhai_field;

          if (nkekhai_field === 'manual') {
            nkekhai_label = 'Nhập thủ công';
            nkekhai = item.nkekhai_manual;
          } else {
            nkekhai = item[nkekhai_field];

            for (const fromField of fromFieldOptions) {
              if (fromField.value === nkekhai_field) {
                nkekhai_label = fromField.label;
                break;
              }
            }
          }
        }

        complete.push(InvoicesService.patchNkekhai(item._id, {
          nkekhai,
          nkekhai_field,
          nkekhai_label
        }));
      });

      Promise.all(complete).then(() => {
        ToastService.success();
        reloadLazyData();
        setDisplay(false);
      }).finally(() => {
        setSubmitting(false);
      });
    }
  }

  const footer = (
    <div className="text-center">
      <Button label="Lưu" icon="pi pi-check" className="p-button-sm" type="button" onClick={onSubmit} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header="Cấu hình Ngày kê khai" footer={footer} visible={!!display} style={{width: '90rem', maxWidth: '100vw'}} position="top" onHide={() => setDisplay(false)}>
      {render()}
    </Dialog>
  );
}