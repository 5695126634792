import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {Dialog} from 'primereact/dialog';
import {TabView, TabPanel} from 'primereact/tabview';
import {FormatDisplay, ToastService, useDataTable} from '@iamsoftware/react-hooks';

import {InvoicesService as Service} from '../../hoa-don/InvoicesService';
import {XlsxService} from '../../../service/XlsxService';

import {View} from '../../hoa-don/View';

export const Invoices = ({display, setDisplay, account}) => {

  const footer = (
    <div className="text-center">
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog showHeader={false} footer={footer} visible={!!display} style={{width: '80vw'}} position="top" onHide={() => setDisplay(false)}>
      {account?._id &&
				<TabView>
					<TabPanel header="Hoá đơn mua vào">
						<Screen account={account._id} type="purchase" mst={display?.tax_code?.mst}/>
					</TabPanel>
					<TabPanel header="Hoá đơn bán ra">
						<Screen account={account._id} type="sold" mst={display?.tax_code?.mst}/>
					</TabPanel>
				</TabView>
      }
    </Dialog>
  );
}

const Screen = ({account, type, mst}) => {

  const dataKey = '_id';

  const [requiredParams, setRequiredParams] = useState(null);

  const [total, setTotal] = useState({} as any);

  const [invoice, setInvoice] = useState(null);
  const [displayView, setDisplayView] = useState(false);

  useEffect(() => {
    reloadLazyData();
  }, [account, type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mst) {
      switch (type) {
        case 'sold':
          setRequiredParams({'data.nmmst': {value: mst, matchMode: 'equals'}});
          break;
        case 'purchase':
          setRequiredParams({'keys.nbmst': {value: mst, matchMode: 'equals'}});
          break;
      }
    }
  }, [type, mst]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: Array<any> = [
    {
      field: 'keys.khmshdon', header: 'Mẫu số', width: 100, matchMode: 'equals', dataType: 'custom', customCell(rowData): any {
        return rowData.keys?.khmshdon;
      }
    },
    {
      field: 'keys.khhdon', header: 'Ký hiệu', width: 100, matchMode: 'equals', dataType: 'custom', customCell(rowData): any {
        return rowData.keys?.khhdon;
      }
    },
    {
      field: 'keys.shdon', header: 'Số hóa đơn', width: 120, matchMode: 'equals', dataType: 'custom', customCell(rowData): any {
        return rowData.keys?.shdon;
      }
    },
    {
      field: 'data.tdlap', header: 'Ngày lập', width: 110, dataType: 'custom', customCell(rowData): any {
        return rowData.data ? FormatDisplay.date(rowData.data.tdlap) : '';
      }
    },
    {
      field: 'header.tthai', header: 'Trạng thái', minWidth: 100, matchMode: 'equals', filterType: 'dropdown', filterOptions: Service.tthais, dataType: 'custom', customCell(rowData): any {
        let tthai = rowData.header.tthai;
        Service.tthais.forEach(t => {
          if (t.value === tthai) {
            tthai = t.label;
          }
        });
        return tthai;
      }
    },
    {
      field: 'header.tgtcthue', header: 'Tổng tiền chưa thuế', width: 160, sortable: false, dataType: 'custom', customCell(rowData, skipFormat: boolean): any {
        if (skipFormat === true) {
          return rowData.header?.tgtcthue;
        }
        return FormatDisplay.number(rowData.header?.tgtcthue);
      }, style: {textAlign: 'right'}
    },
    {
      field: 'header.tgtthue', header: 'Tổng tiền thuế', width: 120, sortable: false, dataType: 'custom', customCell(rowData, skipFormat: boolean): any {
        if (skipFormat === true) {
          return rowData.header?.tgtthue;
        }
        return FormatDisplay.number(rowData.header?.tgtthue);
      }, style: {textAlign: 'right'}
    },
    {
      field: 'header.tgtttbso', header: 'Tổng tiền thanh toán', width: 160, sortable: false, dataType: 'custom', customCell(rowData, skipFormat: boolean): any {
        if (skipFormat === true) {
          return rowData.header?.tgtttbso;
        }
        return FormatDisplay.number(rowData.header?.tgtttbso);
      }, style: {textAlign: 'right'}
    }
  ];

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns,
    indexColumnWidth: 50,
    actionColumnWidth: 90,
    getList: params => {
      if (account) {
        return Service.getList(account, type, params);
      } else {
        return new Promise(resolve => {
          resolve({items: [], totalRecords: 0});
        });
      }
    },
    initActions: items => {
      if (items) {
        const _total = {
          tgtcthue: 0,
          tgtthue: 0,
          tgtttbso: 0
        };
        items.forEach(item => {
          if (item.header?.tgtcthue > 0) {
            _total.tgtcthue += item.header.tgtcthue;
          }
          if (item.header?.tgtthue > 0) {
            _total.tgtthue += item.header.tgtthue;
          }
          if (item.header?.tgtttbso > 0) {
            _total.tgtttbso += item.header.tgtttbso;
          }
          item.actions = [
            {icon: 'pi pi-eye', className: 'p-button-info', title: 'Xen hoá đơn', command: () => viewInvoice(item)}
          ];
        });
        setTotal(_total);
      }
    },
    requiredParams,
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={6}/>
          <Column footer={FormatDisplay.number(total.tgtcthue)} footerStyle={{textAlign: 'right'}}/>
          <Column footer={FormatDisplay.number(total.tgtthue)} footerStyle={{textAlign: 'right'}}/>
          <Column footer={FormatDisplay.number(total.tgtttbso)} footerStyle={{textAlign: 'right'}}/>
          <Column/>
        </Row>
      </ColumnGroup>
    }
  });

  const viewInvoice = item => {
    setInvoice(item);
    setDisplayView(true);
  }

  const exportExcel = () => {
    const event = {
      filters: requiredParams
    }
    Service.getList(account, type, JSON.stringify(event)).then(({items}) => {
      if (!items?.length) {
        return ToastService.error('Không tìm thấy hoá đơn');
      }
      const aoa = [[]];
      columns.forEach(column => {
        aoa[0].push(column.header);
        items.forEach((item, index) => {
          if (!aoa[index + 1]) {
            aoa[index + 1] = [];
          }
          aoa[index + 1].push(XlsxService.getCellValue(column, item));
        });
      });

      XlsxService.generateFile(aoa, 'hoa-don-MST-' + mst);
    });
  }

  return (
    <div className="grid">
      <div className="col-12 text-right">
        <Button icon="pi pi-download" label="Kết xuất Excel" onClick={exportExcel} className="p-button-outlined"/>
      </div>
      <div className="col-12">
        {renderDataTable()}
      </div>

      <View display={displayView} setDisplay={setDisplayView} reloadLazyData={0} invoice={invoice}
            downloadXml={0} comment={0} assignTags={0} setDisplayAttachment={0}/>
    </div>
  );
}