import {Button} from 'primereact/button';
import {useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {SyncInvoicesService as Service} from './SyncInvoicesService';

export const SyncInvoices = () => {

  const dataKey = '_id';

  const {render: renderDataTable, refreshLazyData, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {
        field: 'account_username', header: 'Tài khoản', minWidth: 180, matchMode: 'contains', sortable: false, dataType: 'custom', customCell(rowData): any {
          if (!rowData.account) {
            return null;
          }
          const updateData = {
            _id: rowData.account._id,
            duration: rowData.account[rowData.type].duration,
            type: rowData.type
          }
          return (
            <div>
              <p className="mb-0">{rowData.account.username} <b style={{color: 'blue'}} onClick={() => update(updateData)}>[{rowData.account[rowData.type].duration}]</b> {rowData.type}</p>
              <p className="mb-0">{rowData.account.profile?.name}</p>
            </div>
          );
        }
      },
      {field: 'created_date', header: 'Thời gian', width: 160, dataType: 'date-time'},
      {field: 'ge', header: 'Đồng bộ từ ngày', width: 160, dataType: 'date-time'},
      {field: 'le', header: 'Đến ngày', width: 160, dataType: 'date-time'},
      {field: 'status', header: 'Trạng thái', width: 140, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', minWidth: 140, matchMode: 'contains'},
      {field: 'total', header: 'Tổng số hóa đơn', width: 160, dataType: 'number'},
      {field: 'synced', header: 'Tải thành công', width: 160, dataType: 'number'},
      {field: 'created', header: 'Hóa đơn mới', width: 160, dataType: 'number'}
    ],
    indexColumnWidth: 45,
    getList: params => {
      return Service.getList(params);
    }
  });

  const {render: renderDialogUpdate, update} = useDialogCrup({
    header: 'Số ngày đồng bộ',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'type', type: 'hidden'},
      {field: 'duration', header: 'Số ngày đồng bộ', type: 'InputNumber', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.setDuration(id, item.type, item.duration);
    },
    reloadLazyData
  });

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-4">
          <Button label="Refresh" icon="pi pi-refresh" className="p-button-sm" onClick={refreshLazyData}/>
        </div>
      </div>
      {renderDataTable()}
      {renderDialogUpdate()}
    </div>
  );
}