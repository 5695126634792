import {EntityService} from '../../../../service/EntityService';

export class ApplService extends EntityService {

  static entity = 'system/tax-code-class-appl';

  static batchStore(items: Array<any>): Promise<any> {
    return this.axios.post(`${this.entity}/batch-store`, {items}).then(res => res.data);
  }

}
