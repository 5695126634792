import {useState} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {TokensService as Service} from './TokensService';

export const QuanLyToken = () => {

  const header = 'Api Token';
  const dataKey = '_id';

  const [refLinkTooltip, setRefLinkTooltip] = useState('');

  const copyToken = token => async event => {
    try {
      await navigator.clipboard.writeText(token);
      setRefLinkTooltip('Đã sao chép');
    } catch (e) {
      setRefLinkTooltip('Sao chép không thành công, Có lỗi xảy ra.');
    }
    event.preventDefault();
  }

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'description', header: 'Mô tả', minWidth: 200, matchMode: 'contains'},
      {
        field: 'token', header, minWidth: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <div className="grid">
            <div className="col">{rowData.token}</div>
            <div className="col-fixed" style={{width: '50px'}} onMouseEnter={() => setRefLinkTooltip('Click để sao chép')}>
              <Button icon="pi pi-copy" onClick={copyToken(rowData.token)} tooltip={refLinkTooltip}
                      className="p-button-sm p-button-rounded p-button-outlined p-button-secondary"/>
            </div>
          </div>
        }
      },
      {field: 'expired_at', header: 'Ngày hết hạn', width: 160, dataType: 'date-time'},
      {field: 'created_date', header: 'Ngày tạo', width: 160, dataType: 'date-time'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => doUpdate(item)},
            {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => doDelete(item)}
          ]
        });
      }
    }
  });

  const doUpdate = item => {
    if (item.expired_at) {
      item.expired_at = new Date(item.expired_at);
    }
    update(item);
  }

  const doDelete = item => {
    ConfirmDialogService.confirm(`Xóa ${header}`, `Xác nhận xoá Token '${item.token}'`, () => {
      Service.delete(item._id).then(() => {
        reloadLazyData();
        ToastService.success();
      });
    });
  }

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'description', header: 'Mô tả', className: 'md:col-12'},
      {field: 'expired_at', header: 'Ngày hết hạn', type: 'Calendar', CalendarProps: {showTime: true}, className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-12">
          <Button label="Thêm" icon="pi pi-plus" className="p-button-sm" onClick={create}/>
        </div>
      </div>
      {renderDataTable()}
      {renderDialogCrup()}
    </div>
  );
}