import {TabPanel, TabView} from 'primereact/tabview';

import {List} from './list';
import {Tree} from './tree';

export default function DaiLy({me}) {

  return (
    <TabView>
      <TabPanel header="Danh sách đại lý">
        <List me={me}/>
      </TabPanel>
      <TabPanel header="Danh sách hình cây">
        <Tree me={me}/>
      </TabPanel>
    </TabView>
  );
}
