import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Fieldset} from 'primereact/fieldset';
import {useDialogCrup} from '@iamsoftware/react-hooks';

import {InvoicesService as Service} from './InvoicesService';

export const SearchHint = ({display, setDisplay}) => {

  const [searching, setSearching] = useState(true);

  const [invoice, setInvoice] = useState(null);
  const [hint, setHint] = useState(null);

  useEffect(() => {
    getSearchHint();
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSearchHint = () => {
    setSearching(true);
    setInvoice(null);
    setHint(null);
    if (display?._id) {
      Service.getSearchHint(display._id).then(data => {
        setInvoice(data?.invoice);
        setHint(data?.hint);
      }).finally(() => {
        setSearching(false);
      });
    }
  }

  const {render: renderPatchKey, update: patchKey} = useDialogCrup({
    header: '!Gán mã tra cứu',
    dataKey: '_id',
    width: '40rem',
    fields: [
      {field: 'search_key', header: 'Mã tra cứu', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.patchSearchKey(id, item.search_key || '');
    },
    reloadLazyData: getSearchHint
  });

  const doPatchKey = () => {
    patchKey({_id: display._id, search_key: hint?.search_key || ''});
  }

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Fragment>
      <Dialog header="Tra cứu hóa đơn" footer={footer} visible={!!display} style={{width: '60rem'}} onHide={() => setDisplay(false)}>
        {!searching && <div className="grid">
					<div className="col-12">
						<Fieldset legend="Tra cứu">
              {!hint && <div className="p-message p-component p-message-warn p-message-enter-done">
								<div className="p-message-wrapper">
									<span className="p-message-icon pi pi-exclamation-triangle"></span>
									<span className="p-message-detail">Không tìm thấy mã tra cứu.</span>
								</div>
							</div>}
              {hint && <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-sm iam-cell-editing p-fluid">
								<div className="p-datatable-wrapper">
									<table className="p-datatable-table">
										<tbody className="p-datatable-tbody">
                    {hint?.search_link_1 && <tr>
											<td style={{width: '8rem'}}>Link tra cứu 1</td>
											<td><a href={hint?.search_link_1} rel="noreferrer" target="_blank">{hint?.search_link_1}</a></td>
										</tr>}

                    {hint?.search_link_2 && <tr>
											<td>Link tra cứu 2</td>
											<td><a href={hint?.search_link_2} rel="noreferrer" target="_blank">{hint?.search_link_2}</a></td>
										</tr>}

                    {(hint?.search_link_1 || hint?.search_link_2) && <tr>
											<td>Mã tra cứu</td>
											<td>
                        {hint?.is_patch !== true && <span>{hint?.search_key}</span>}
                        {hint?.is_patch === true && <div className="grid">
													<div className="col flex align-items-center">{hint?.search_key}</div>
													<div className="col-fixed" style={{width: '11rem'}}>
														<Button label="Gán mã tra cứu" outlined onClick={doPatchKey}/>
													</div>
												</div>}
											</td>
										</tr>}

                    {hint?.comments && <tr>
											<td>Ghi chú</td>
											<td>{hint?.comments}</td>
										</tr>}
										</tbody>
									</table>
								</div>
							</div>}
						</Fieldset>
					</div>
					<div className="col-12">
						<Fieldset legend="Thông tin người bán">
							<div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-sm iam-cell-editing p-fluid">
								<div className="p-datatable-wrapper">
									<table className="p-datatable-table">
										<tbody className="p-datatable-tbody">
                    {!!invoice?.nbten && <tr>
											<td style={{width: '10rem'}}>Tên người bán</td>
											<td>{invoice?.nbten}</td>
										</tr>}
                    {!!invoice?.nbmst && <tr>
											<td>Mã số thuế</td>
											<td>{invoice?.nbmst}</td>
										</tr>}
                    {!!invoice?.nbsdthoai && <tr>
											<td>Số điện thoại</td>
											<td>{invoice?.nbsdthoai}</td>
										</tr>}
                    {!!invoice?.nbdctdtu && <tr>
											<td>Email</td>
											<td>{invoice?.nbdctdtu}</td>
										</tr>}
                    {!!invoice?.mhdon && <tr>
											<td>Mã cơ quan thuế</td>
											<td>{invoice?.mhdon}</td>
										</tr>}
										</tbody>
									</table>
								</div>
							</div>
						</Fieldset>
					</div>
				</div>}
        {searching && <div></div>}
      </Dialog>
      {renderPatchKey()}
    </Fragment>
  );

}