import {useEffect} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {InvoicesService as Service} from './InvoicesService';

export const Attachment = ({display, setDisplay}) => {

  const header = 'File đính kèm';
  const dataKey = '_id';

  useEffect(() => {
    if (display) {
      reloadLazyData();
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    stateKey: 'attachments',
    dataKey,
    columns: [
      {
        field: 'properties.name', header: 'Tên file', minWidth: 200, dataType: 'custom', customCell(rowData): any {
          return rowData.properties ? rowData.properties.name : '';
        }
      },
      {field: 'description', header: 'Ghi chú', minWidth: 170}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 110,
    getList: params => {
      if (display) {
        return Service.getAttachments(display._id, params);
      } else {
        return new Promise(resolve => {
          resolve({items: [], totalRecords: 0});
        });
      }
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          if (item.type !== 'admin') {
            item.actions = [
              {icon: 'pi pi-eye', title: 'Xem', command: () => downloadAttachment(item)},
              {icon: 'pi pi-pencil', title: 'Sửa', command: () => update(item)},
              {icon: 'pi pi-trash', title: 'In', className: 'p-button-danger', command: () => deleteAttachment(item)}
            ];
          }
        });
      }
    }
  });

  const {render: renderCreate, create} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'attachment', header: 'Tải lên tệp tin', required: true, type: 'file', className: 'md:col-12 mb-1'},
      {
        type: 'custom', className: 'md:col-12 mb-4', body: <i>Tập tin tối đa 5 MB</i>
      },
      {field: 'description', header: 'Ghi chú', InputTextProps: {maxLength: 50}, className: 'md:col-12'}
    ],
    createItem: item => {
      const {description, attachment} = item;

      const formData = new FormData();
      formData.append('attachment', attachment[0]);
      formData.append('account', display.account);
      formData.append('description', description || '');

      return Service.createAttachment(display._id, formData);
    },
    reloadLazyData
  });

  const {render: renderUpdate, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'description', header: 'Ghi chú', InputTextProps: {maxLength: 50}, className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.updateAttachment(display._id, id, item.description || '');
    },
    reloadLazyData
  });

  const deleteAttachment = (item) => {
    Service.deleteAttachment(display._id, item._id).then(() => {
      reloadLazyData();
    });
  }

  const downloadAttachment = (item) => {
    window.open(Service.getAttachmentUrl(display._id, item._id), '_blank');
  }

  return (
    <Dialog header={header} visible={!!display} style={{width: '890px'}} onHide={() => setDisplay(false)}>
      <div className="flex justify-content-end">
        <Button label="Thêm file" icon="pi pi-upload" onClick={create} className="p-button-outlined p-button-sm"/>
      </div>

      {renderDataTable()}
      {renderCreate()}
      {renderUpdate()}

    </Dialog>
  );
}