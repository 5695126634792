import {useEffect, useState} from 'react';

import moment from 'moment';

import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Checkbox} from 'primereact/checkbox';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';

import {ToastService} from '@iamsoftware/react-hooks';

import {ExternalAccountsService as Service} from './ExternalAccountsService';

export const Crup = ({display, setDisplay, account, reloadLazyData}) => {

  const [submitting, setSubmitting] = useState(false);

  const [id, setId] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [sync_invoices_sold, setSync_invoices_sold] = useState(true);
  const [init_sync_sold, setInit_sync_sold] = useState(null);
  const [max_sync_sold, setMax_sync_sold] = useState(null);
  const [sync_invoices_purchase, setSync_invoices_purchase] = useState(true);
  const [init_sync_purchase, setInit_sync_purchase] = useState(null);
  const [max_sync_purchase, setMax_sync_purchase] = useState(null);
  const [captchaContent, setCaptchaContent] = useState(null);
  const [ckey, setCkey] = useState(null);
  const [cvalue, setCvalue] = useState('');

  useEffect(() => {
    if (display) {
      loadCaptcha();
      if (account) {
        setId(account._id);
        setUsername(account.username);
        setPassword(account.password);
        setSync_invoices_sold(account.sync_invoices_sold.sync);
        if (account.sync_invoices_sold.init_sync) {
          setInit_sync_sold(new Date(account.sync_invoices_sold.init_sync));
          setMax_sync_sold(new Date(account.sync_invoices_sold.init_sync));
        } else {
          setInit_sync_sold(null);
          setMax_sync_sold(new Date());
        }
        setSync_invoices_purchase(account.sync_invoices_purchase.sync);
        if (account.sync_invoices_purchase.init_sync) {
          setInit_sync_purchase(new Date(account.sync_invoices_purchase.init_sync));
          setMax_sync_purchase(new Date(account.sync_invoices_purchase.init_sync));
        } else {
          setInit_sync_purchase(null);
          setMax_sync_purchase(new Date());
        }
      } else {
        setId(null);
        setUsername('');
        setPassword('');
        setSync_invoices_sold(false);
        setInit_sync_sold(null);
        setMax_sync_sold(new Date());
        setSync_invoices_purchase(true);
        setInit_sync_purchase(moment().startOf('month').toDate());
        setMax_sync_purchase(new Date());
      }
    }
  }, [display, account]);

  useEffect(() => {
    if (sync_invoices_sold) {
      if (!init_sync_sold) {
        setInit_sync_sold(moment().startOf('month').toDate());
      }
    }
  }, [sync_invoices_sold]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCaptcha = () => {
    Service.getCaptcha().then(({key, content}) => {
      setCkey(key);
      setCaptchaContent(content);
      setCvalue('');
    });
  }

  const submit = (event) => {
    if (!submitting) {
      setSubmitting(true);

      let promise;
      if (id) {
        promise = Service.update(id, {
          username, password, sync_invoices_sold, init_sync_sold, sync_invoices_purchase, init_sync_purchase, ckey, cvalue
        });
      } else {
        promise = Service.create({
          username, password, sync_invoices_sold, init_sync_sold, sync_invoices_purchase, init_sync_purchase, ckey, cvalue
        });
      }

      promise.then(result => {
        if (result && result.username) {
          ToastService.success();
          setDisplay(false);
          reloadLazyData();
        } else {
          ToastService.error('Có lỗi xảy ra');
        }
      }).finally(() => {
        setSubmitting(false);
        loadCaptcha();
      });
    }
    event.preventDefault();
  }

  return (
    <Dialog header="Tài khoản trang hoadondientu.gdt.gov.vn" visible={display} style={{width: '500px'}} position="top" closeOnEscape={false} onHide={() => setDisplay(false)}>
      <form onSubmit={submit}>
        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="username">Tài khoản<span className="required">*</span></label>
            <InputText id="username" value={username} onChange={e => setUsername(e.target.value)} required disabled={id}/>
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="password">Mật khẩu<span className="required">*</span></label>
            <Password id="password" value={password} onChange={e => setPassword(e.target.value)} toggleMask={true} feedback={false} required/>
          </div>
          <div className="field col-fixed" style={{width: '200px'}}>
            <label>&nbsp;</label>
            <div className="captcha" dangerouslySetInnerHTML={{__html: captchaContent}}></div>
          </div>
          <div className="field col-fixed pl-0" style={{width: '30px'}}>
            <label>&nbsp;</label>
            <i className="pi pi-refresh pointer mt-2" style={{fontSize: 20}} onClick={loadCaptcha}></i>
          </div>
          <div className="field col">
            <label htmlFor="cvalue">Nhập mã captcha<span className="required">*</span></label>
            <InputText id="cvalue" value={cvalue} onChange={e => setCvalue(e.target.value)} required/>
          </div>
          <div className="field-checkbox col-12 md:col-6 mt-2">
            <Checkbox inputId="sync_invoices_sold" checked={sync_invoices_sold} onChange={e => setSync_invoices_sold(e.checked)}/>
            <label htmlFor="sync_invoices_sold">Đồng bộ hóa đơn bán ra</label>
          </div>
          <div className="field col">
            <label htmlFor="cvalue">Đồng bộ từ ngày</label>
            <Calendar id="init_sync_sold" maxDate={max_sync_sold} value={init_sync_sold} onChange={e => setInit_sync_sold(e.value)} dateFormat="dd/mm/yy"
                      disabled={!sync_invoices_sold} tooltip={id ? 'Chỉ được phép chỉnh ngày bắt đầu đồng bộ về ngày trước đó' : ''}/>
          </div>
          <div className="field-checkbox col-12 md:col-6 mt-2">
            <Checkbox inputId="sync_invoices_purchase" checked={sync_invoices_purchase} onChange={e => setSync_invoices_purchase(e.checked)}/>
            <label htmlFor="sync_invoices_purchase">Đồng bộ hóa đơn mua vào</label>
          </div>
          <div className="field col">
            <label htmlFor="cvalue">Đồng bộ từ ngày</label>
            <Calendar id="init_sync_purchase" maxDate={max_sync_purchase} value={init_sync_purchase} onChange={e => setInit_sync_purchase(e.value)} dateFormat="dd/mm/yy"
                      disabled={!sync_invoices_purchase} tooltip={id ? 'Chỉ được phép chỉnh ngày bắt đầu đồng bộ về ngày trước đó' : ''}/>
          </div>
        </div>
        <div className="flex justify-content-end mt-2">
          <Button label="Lưu" icon="pi pi-check" className="p-button-sm mr-2" loading={submitting}/>
          <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
        </div>
      </form>
    </Dialog>
  );

}