import {EntityService} from '../../../service/EntityService';

export class HdtbssService extends EntityService {

  static entity = 'hdtbssrses';

  static kqtnhanList = [
    {value: 0, label: 'Không tiếp nhận'},
    {value: 1, label: 'Tiếp nhận'},
    {value: 2, label: 'Thông tin chung lỗi'}
  ];

  static kqtnhanMap = {
    0: 'Không tiếp nhận',
    1: 'Tiếp nhận',
    2: 'Thông tin chung lỗi'
  };

  static tctbaoList = [
    {value: 1, label: 'Huỷ'},
    {value: 2, label: 'Điều chỉnh'},
    {value: 3, label: 'Thay thế'},
    {value: 4, label: 'Giải trình'}
  ];

  static tctbaoMap = {
    1: 'Huỷ',
    2: 'Điều chỉnh',
    3: 'Thay thế',
    4: 'Giải trình'
  };

}