import {Fragment, useEffect, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ConfirmDialogService} from '@iamsoftware/react-hooks';

import {CheckXmlService as Service} from './CheckXmlService';

export const CheckXml = ({me, xmlContent, display, setDisplay}) => {

  const xmldsig = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [xml, setXml] = useState(null);
  const [infoHeight, setInfoHeight] = useState(0);

  useEffect(() => {
    if (xmlContent) {
      if (!submitting) {
        setSubmitting(true);

        Service.uploadXmlData({xmlContent}).then(result => {
          if (result && result._id) {
            setXml(result._id);
            setDisplay(true);
          } else {
            setXml(null);
            setDisplay(false);
            ConfirmDialogService.showInvalid('Hóa đơn có định dạng và dữ liệu không hợp lệ theo Quyết định 1450/QD-TCT');
          }
        }).finally(() => {
          setSubmitting(false);
        });
      }
    }
  }, [xmlContent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    if (display) {
      body.classList.add('has-dialog-maximized');
    } else {
      body.classList.remove('has-dialog-maximized');
    }
  }, [display]);

  const onIframeInfoLoad = e => {
    let retry = 100;
    const interval = setInterval(() => {
      retry--;
      if (retry <= 0) {
        clearInterval(interval);
      }
      const mainTag = e.target.contentWindow.document.getElementsByClassName('invoice-info')[0];
      if (mainTag) {
        clearInterval(interval);
        setInfoHeight(mainTag.offsetHeight + 800);
      }
    }, 100);
  }

  const printInvoice = () => {
    const myWindow = window.open(`${Service.baseURL}/${Service.entity}/${xml}/html`);
    myWindow.onafterprint = myWindow.close;
    myWindow.print();
  }

  const printInfo = () => {
    window.open(`#/xml-info?id=${xml}`);
  }

  return (
    <Fragment>
      <Dialog visible={display} maximized={true} onHide={() => setDisplay(false)} className="dialog-full">
        <div className="grid h-full">
          <div className="col h-full iam-scroll" style={{overflowX: 'auto'}}>
            {xml &&
							<iframe className="iframe-full" src={`${Service.baseURL}/${Service.entity}/${xml}/html`} title="template-preview"/>
            }
          </div>
          <div className="col-fixed h-full" style={{width: '790px'}}>
            <div className="grid">
              <div className="col-12 text-right">
                <Button icon="pi pi-times" className="p-button-rounded p-button-secondary" onClick={() => setDisplay(false)}/>
              </div>
            </div>
            <div className="iam-scroll" style={{height: 'calc(100% - 58px)', overflowX: 'auto'}}>
              <div style={{height: infoHeight, minHeight: '100%'}}>
                {xml &&
									<iframe className="iframe-full" src={`#/xml-info?id=${xml}&excludeTawk=Y`}
													title="template-preview" onLoad={onIframeInfoLoad}/>
                }
              </div>
            </div>
            <div className="grid">
              <div className="col-12 text-center">
                {me &&
									<Fragment>
										<Button icon="pi pi-download" label="Tải xuống" className="p-button-sm mr-2"/>
										<Button icon="pi pi-comment" label="Ghi chú" className="p-button-success p-button-sm mr-2"/>
										<Button icon="pi pi-tag" label="Gắn nhãn" className="p-button-success p-button-sm mr-2"/>
									</Fragment>
                }
                <Button icon="pi pi-print" label="In hóa đơn" className="p-button-help p-button-sm mr-2" onClick={printInvoice}/>
                <Button icon="pi pi-print" label="In kết quả kiểm tra" className="p-button-help p-button-sm" onClick={printInfo}/>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <iframe src={`${Service.baseURL}/xmldsig`} ref={xmldsig} title="xmldsig" style={{display: 'none'}}/>
    </Fragment>
  );
}