import {useEffect} from 'react';

import {TabView, TabPanel} from 'primereact/tabview';

import {MST} from './MST';
import {CCCD} from './CCCD';

export const KiemTraMst = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-CX7ZP446Z8';
    script.async = true;
    document.head.appendChild(script);

    const scriptRun = document.createElement('script');
    scriptRun.type = 'text/javascript';
    scriptRun.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-CX7ZP446Z8');
    `;
    document.head.appendChild(scriptRun);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(scriptRun);
    }
  }, []);

  return (
    <div className="card p-0">
      <TabView>
        <TabPanel header="Tra cứu theo Mã số thuế">
          <MST/>
        </TabPanel>
        <TabPanel header="Tra cứu theo CMND/CCCD">
          <CCCD/>
        </TabPanel>
      </TabView>
    </div>
  );
}