import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {ToastService} from '@iamsoftware/react-hooks';

import {ExcelConfigService as Service} from './ExcelConfigService';

export const Base = () => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    Service.getBase().then(data => {
      setItems(data);
    });
  }

  const onRowReorder = e => {
    setItems(e.value);
  }

  const create = () => {
    setItems(prevItems => [...prevItems, {value: '', label: ''}]);
  }

  const deleteItem = item => {
    setItems(prevItems => {
      prevItems.splice(items.indexOf(item), 1);
      return [...prevItems];
    });
  }

  const save = () => {
    Service.setBase(items).then(() => {
      ToastService.success();
      load();
    });
  }

  const textEditor = options => <InputText value={options.value} onChange={e => options.editorCallback(e.target.value)}/>

  const onCellEditComplete = e => {
    let {rowData, newValue, field} = e;
    rowData[field] = newValue;
  }

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-12">
          <Button label="Lưu" icon="pi pi-check" className="p-button-sm mr-2" onClick={save}/>
          <Button label="Thêm dòng" icon="pi pi-plus" className="p-button-success p-button-sm" onClick={create}/>
        </div>
      </div>

      <div style={{height: 'calc(100vh - 16.5rem)'}}>
        <DataTable value={items}
                   editMode="cell" className="editable-cells-table p-fluid"
                   reorderableRows onRowReorder={onRowReorder} stripedRows scrollable scrollHeight="flex" responsiveLayout="scroll">
          <Column rowReorder style={{width: '3em'}}/>
          <Column field="value" header="Dữ liệu" editor={textEditor} onCellEditComplete={onCellEditComplete} style={{maxWidth: '30em'}}/>
          <Column field="label" header="Tiêu đề" editor={textEditor} onCellEditComplete={onCellEditComplete}/>
          <Column style={{maxWidth: '15em'}} className="p-0"
                  body={rowData => <Button icon="pi pi-trash" onClick={() => deleteItem(rowData)} className="p-button-rounded p-button-outlined p-button-danger"/>}/>
        </DataTable>
      </div>
    </div>
  );
}