import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useDialogCrup} from '@iamsoftware/react-hooks';

import {InvoicesService as Service} from './InvoicesService';

export const View = ({display, setDisplay, reloadLazyData, invoice, downloadXml, comment, assignTags, setDisplayAttachment}) => {

  const [htmlVer, setHtmlVer] = useState(0);

  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    if (display) {
      body.classList.add('has-dialog-maximized');
    } else {
      body.classList.remove('has-dialog-maximized');
    }
  }, [display]);

  const {render: renderCreatePdf, create: createPdf} = useDialogCrup({
    header: '!Cập nhật hoá đơn PDF của Nhà cung cấp',
    width: '40rem',
    fields: [
      {field: '_id', type: 'hidden'},
      {field: 'account', type: 'hidden'},
      {field: 'attachment', header: 'Tải lên tệp tin', required: true, type: 'file', InputFileProps: {accept: '.pdf'}, className: 'md:col-12 mb-1'},
      {
        type: 'custom', className: 'md:col-12 mb-4', body: <i>Tập tin tối đa 5 MB</i>
      }
    ],
    createItem: item => {
      const {attachment, account} = item;

      if (attachment[0].type === 'application/pdf') {
        const formData = new FormData();
        formData.append('attachment', attachment[0]);
        formData.append('account', account);
        formData.append('purpose', 'pdf');

        return Service.createAttachment(item._id, formData);
      } else {
        ToastService.error('Chỉ được chọn file có định dạng PDF.');
        return Promise.reject();
      }
    },
    reloadLazyData: () => {
      setHtmlVer(Date.now());
    }
  });

  const printInvoice = () => {
    const myWindow = window.open(`${Service.baseURL}/${Service.entity}/${invoice._id}/download/invoice.html`);
    myWindow.onafterprint = myWindow.close;
    myWindow.print();
  }

  const printInfo = () => {
    window.open(`#/invoice-info?id=${invoice._id}`);
  }

  const patchHideStatus = hide => {
    Service.patchHideStatus(invoice._id, hide).then(() => {
      reloadLazyData();
      setDisplay(false);
    });
  }

  return (
    <Fragment>
      <Dialog visible={display} maximized={true} onHide={() => setDisplay(false)} className="dialog-full">
        <div className="grid h-full">
          <div className="col iam-scroll">
            {invoice &&
							<iframe className="iframe-full" src={`${Service.baseURL}/${Service.entity}/${invoice._id}/download/invoice.html?htmlVer=${htmlVer}`} title="template-preview"/>
            }
          </div>
          <div className="col-fixed" style={{width: '790px', height: 'calc(100% - 30px)'}}>
            <div className="grid">
              <div className="col-12 text-right">
                <Button icon="pi pi-times" className="p-button-rounded p-button-secondary" onClick={() => setDisplay(false)}/>
              </div>
            </div>
            <div className="iam-scroll" style={{height: 'calc(100% - 58px)', overflowX: 'auto'}}>
              {invoice &&
								<iframe className="iframe-full" src={`#/invoice-info?id=${invoice._id}&excludeTawk=Y`} title="template-preview"/>
              }
            </div>
          </div>
          <div className="col-12 flex justify-content-between">
            <div className="text-right" style={{width: '50rem'}}>
              <Button label="Cập nhật hoá đơn PDF của Nhà cung cấp" className="p-button-outlined p-button-sm p-button-success" onClick={() => createPdf(invoice)}/>
            </div>
            <div className="flex gap-2">
              {!!setDisplayAttachment &&
								<Button icon="pi pi-paperclip" label="File đính kèm" className="p-button-sm" onClick={() => setDisplayAttachment(invoice)}/>
              }

              {invoice && !!reloadLazyData &&
								<Fragment>
                  {invoice.warning.hide_status &&
										<Button icon="pi pi-check-circle" label="Hiện cảnh báo" className="p-button-sm p-button-warning" onClick={() => patchHideStatus(false)}/>
                  }
                  {!invoice.warning.hide_status &&
										<Button icon="pi pi-check-circle" label="Ẩn cảnh báo" className="p-button-sm p-button-warning" onClick={() => patchHideStatus(true)}/>
                  }
								</Fragment>
              }
              {!!downloadXml &&
								<Button icon="pi pi-download" label="Tải xuống" className="p-button-sm" onClick={() => downloadXml(invoice)}/>
              }
              {!!comment &&
								<Button icon="pi pi-comment" label="Ghi chú" className="p-button-success p-button-sm" onClick={() => comment(invoice)}/>
              }
              {!!assignTags &&
								<Button icon="pi pi-tag" label="Gắn nhãn" className="p-button-success p-button-sm" onClick={() => assignTags(invoice)}/>
              }
              <Button icon="pi pi-print" label="In hóa đơn" className="p-button-help p-button-sm" onClick={printInvoice}/>
              <Button icon="pi pi-print" label="In kết quả kiểm tra" className="p-button-help p-button-sm" onClick={printInfo}/>
            </div>
          </div>
        </div>
      </Dialog>
      {renderCreatePdf()}
    </Fragment>
  );
}