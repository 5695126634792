import {Route, Routes} from 'react-router-dom';

import {QuanLyToken} from './quan-ly-token';
import {Employees} from './quan-ly-nhan-vien';
import {DateOfInvoiceDeclaration} from './khai-bao-ngay-ke-khai';

export default function TaiKhoan({me}) {

  return (
    <Routes>
      <Route path="quan-ly-token" element={<QuanLyToken/>}/>
      {!['employee'].includes(me.type) && <Route path="quan-ly-nhan-vien" element={<Employees me={me}/>}/>}
      {!['employee'].includes(me.type) && <Route path="khai-bao-ngay-ke-khai" element={<DateOfInvoiceDeclaration/>}/>}
    </Routes>
  );
}
