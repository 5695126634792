import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {InputNumber} from 'primereact/inputnumber';

import {ConfirmDialogService, ToastService} from '@iamsoftware/react-hooks';

import {ExternalAccountsService as Service} from './ExternalAccountsService';

export const Config = ({display, setDisplay, reloadLazyData}) => {

  const [submitting, setSubmitting] = useState(false);
  const [items, setItems] = useState([]);

  const sort = () => {
    setItems(prevItems => {
      prevItems.sort((a, b) => a.sequence - b.sequence);
      return [...prevItems];
    });
  }

  const save = () => {
    if (!submitting) {

      if (items.find(it => !it.sequence)) {
        return ToastService.error('Số thứ tự không được để trống');
      }
      if (!items.find(it => it.visible)) {
        return ToastService.error('Phải có ít nhất một mã số thuế được hiển thị');
      }

      const run = async () => {
        setSubmitting(true);

        for (const item of items) {
          await Service.updateDisplayConfig(item._id, {sequence: item.sequence, visible: item.visible});
        }

        ToastService.success();
        reloadLazyData();

        setSubmitting(false);
        setDisplay(false);
      }

      const hasToken = items.filter(it => !it.visible && it.token).map(it => it.username);

      if (hasToken.length) {
        ConfirmDialogService.confirm('Xác nhận muốn ẩn mã số thuế', `Bạn có muốn ẩn mã số thuế ${hasToken.join(', ')} và thoát đồng bộ?`, run);
      } else {
        run();
      }
    }
  }

  const footer = (
    <div className="text-center">
      <Button label="Sắp xếp" icon="pi pi-sort-numeric-down" className="p-button-sm" type="button" severity="help" onClick={sort}/>
      <Button label="Lưu" icon="pi pi-check" className="p-button-sm" type="button" onClick={save} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header="Cấu hình hiển thị tài khoản thuế" footer={footer} visible={display} style={{width: '80rem', maxWidth: '90vw'}} position="top" onHide={() => setDisplay(false)}>
      <Screen items={items} setItems={setItems}/>
    </Dialog>
  );
}

const Screen = ({items, setItems}) => {

  const dataKey = '_id';

  useEffect(() => {
    Service.getList('{}').then(({items}) => {
      items?.forEach((item, index) => {
        if (!item.sequence) {
          item.sequence = index + 1;
        }
      });
      setItems(items);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRowReorder = e => {
    if (e.value) {
      setItems(e.value.map((rowData, index) => {
        rowData.sequence = index + 1;
        return rowData;
      }));
    }
  }

  const onCellChange = (rowData, newValue, field) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem[dataKey] === rowData[dataKey]) {
        if (field === 'sequence' && typeof newValue === 'number' && newValue < 1) {
          newValue = 1;
        }
        return {
          ...prevItem,
          [field]: newValue
        }
      }
      return prevItem;
    }));
  }

  return (<div className="grid">
    <div className="col-12">
      <DataTable value={items} className="editable-cells-table p-fluid datatable-border-top"
                 reorderableRows onRowReorder={onRowReorder} stripedRows showGridlines scrollable scrollHeight="flex">
        <Column rowReorder style={{width: '3em'}}/>
        <Column field="username" header="Tài khoản" style={{minWidth: '12rem'}}/>
        <Column field="profile.name" header="Tên" style={{minWidth: '30rem'}} body={rowData => rowData.profile?.name || ''}/>
        <Column field="sequence" header="Thứ tự"
                body={rowData => <InputNumber value={rowData.sequence} onChange={e => onCellChange(rowData, e.value, 'sequence')} min={1}/>}
                style={{minWidth: '10rem', maxWidth: '10rem'}}/>
        <Column field="visible" header="Hiển thị"
                body={rowData => <Checkbox checked={rowData.visible} onChange={e => onCellChange(rowData, e.checked, 'visible')}/>}
                style={{minWidth: '6rem', textAlign: 'center'}}/>
      </DataTable>
    </div>
  </div>);
}