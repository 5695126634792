import {useEffect, useState} from 'react';

import {TreeTable} from 'primereact/treetable';
import {Column} from 'primereact/column';
import {AgentsService as Service} from '../AgentsService';

export const Tree = ({me}) => {

  const [nodes, setNodes] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    loadNodes(first, rows)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadNodes = (first, rows) => {
    const _event = {
      first,
      rows,
      filters: {
        parent: {value: me._id, matchMode: 'equals'}
      }
    };
    Service.getList(JSON.stringify(_event)).then(response => {
      const _nodes = [];
      response.items.forEach(item => {
        _nodes.push({
          key: item._id,
          data: item,
          leaf: false
        });
      });
      setNodes(_nodes);
      setTotalRecords(response.totalRecords);
    });
  }

  const onExpand = (event) => {
    const _event = {
      filters: {
        parent: {value: event.node.key, matchMode: 'equals'}
      }
    };
    Service.getList(JSON.stringify(_event)).then(({items}) => {
      const children = [];
      items.forEach(item => {
        children.push({
          key: item._id,
          data: item,
          leaf: false
        });
      });

      const lazyNode = {...event.node, children};
      lazyNode.leaf = children.length === 0;

      patchNodes(lazyNode);
    });
  }

  const patchNodes = (lazyNode) => {
    const _nodes = nodes.map(node => {
      return patchNode(node, lazyNode);
    });
    setNodes(_nodes);
  }

  const patchNode = (node, lazyNode) => {
    if (node.key === lazyNode.key) {
      node = lazyNode;
    } else if (node.children) {
      node.children = node.children.map(child => {
        return patchNode(child, lazyNode);
      });
    }
    return node;
  }

  const onPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    loadNodes(event.first, event.rows);
  }

  return (
    <TreeTable value={nodes} lazy paginator totalRecords={totalRecords}
               first={first} rows={rows} onPage={onPage} onExpand={onExpand}>
      <Column field="username" header="Tài khoản" expander></Column>
      <Column field="type" header="Loại"></Column>
      <Column field="description" header="Ghi chú"></Column>
    </TreeTable>
  );
}