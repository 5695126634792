import {Service} from '../../../service/Service';

export class SyncInvoiceAssetsService extends Service {

  static entity = 'system';

  static getList(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/invoices`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static getPending(): Promise<any> {
    return this.axios.get(`${this.entity}/invoices/pending`).then(res => res.data);
  }

}
