import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {useDataTableBasic} from '@iamsoftware/react-hooks';

import {TaxCodeInfoService as Service} from './TaxCodeInfoService';
import {TaxCodeService} from '../../../service/TaxCodeService';
import {ClassificationService} from '../../administrator/tax-code-classification/classification/ClassificationService';

export const TaxCodeStatus = ({account}) => {

  const [overviewItems, setOverviewItems] = useState(null);

  useEffect(() => {
    if (account) {
      const _event = {
        filters: {
          active: {value: true, matchMode: 'equals'}
        }
      };
      ClassificationService.getList(JSON.stringify(_event)).then(({items}) => {
        Service.getOverview(account).then(data => {
          const _overviewItems = [];
          for (const _overviewItem of data) {
            let setting;
            for (const item of items) {
              if (item.ref === _overviewItem._id) {
                setting = item;
                break;
              }
            }
            if (setting) {
              _overviewItem.sequence = setting.sequence;
              _overviewItem.style = {
                color: setting.color || 'black',
                backgroundColor: setting.background || 'white'
              };
            } else {
              _overviewItem.sequence = 999;
            }
            _overviewItems.push(_overviewItem);
          }

          Service.getSmiOverview(account).then(smiOverview => {
            if (smiOverview?.length) {
              for (const smiItem of smiOverview) {
                smiItem.sequence = smiItem.classification.sequence || 999;
                smiItem.style = {
                  color: smiItem.classification.color || 'black',
                  backgroundColor: smiItem.classification.background || 'white'
                };
                _overviewItems.push(smiItem);
              }
            }
            _overviewItems.sort((a, b) => a.sequence - b.sequence);
            setOverviewItems(_overviewItems);
          });
        });
      });
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderOverview} = useDataTableBasic({
    columns: [
      {
        field: '_id', header: 'Tình trạng', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.classification) {
            return <Link to={`/bao-cao/tinh-trang-doanh-nghiep?account=${account}&tthai=${rowData._id}`} className={TaxCodeService.getClassName(rowData._id)} style={rowData.style}>
              {rowData.classification.name}
            </Link>
          }
          return <Link to={`/bao-cao/tinh-trang-doanh-nghiep?account=${account}&tthai=${rowData._id}`} className={TaxCodeService.getClassName(rowData._id)} style={rowData.style}>
            {rowData._id ? TaxCodeService.msttthaiMap[rowData._id] : 'Chưa có thông tin'}
          </Link>
        }
      },
      {field: 'count', header: 'Tổng số mã số thuế', width: 170, dataType: 'number'},
      {field: 'totalPurchase', header: 'Tổng tiền mua vào', width: 170, dataType: 'number'},
      {field: 'lastSync', header: 'Ngày cập nhật', width: 170, dataType: 'date-time'}
    ],
    items: overviewItems
  });

  return renderOverview()
}