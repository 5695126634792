import {useEffect, useState} from 'react';

import {TaxCodeService} from '../../service/TaxCodeService';

const getStatus = (tthai) => {
  let className;
  if (['01', '03', '06'].includes(tthai)) {
    className = 'alert-danger';
  } else if (['02', '05'].includes(tthai)) {
    className = 'alert-warning';
  }
  return <span className={className}>{TaxCodeService.msttthaiMap[tthai]}</span>
}

export const TaxCodeInfo = ({taxCode}) => {

  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (taxCode) {
      TaxCodeService.get(taxCode).then(data => {
        setInfo(data);
      });
    }
  }, [taxCode]);

  if (taxCode) {
    if (info) {
      return (
        <div className="col-12">
          <p>- Mã số thuế: {info.mst}
            {info.tthai &&
							<span className="ml-8">- Trạng thái: {getStatus(info.tthai)}</span>
            }
          </p>
          <p>- Tên đơn vị: {info.tennnt}</p>
          <p>- Địa chỉ: {info.dctsdchi}, {info.dctsxaten}, {info.dctshuyenten}, {info.dctstinhten}.</p>
          <p>- Cơ quan thuế: {info.tencqt}</p>
        </div>
      );
    } else {
      return <p>- Không lấy được thông tin mã số thuế.</p>;
    }
  } else {
    return <p>- Không có mã số thuế.</p>;
  }
}