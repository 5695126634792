import {TabView, TabPanel} from 'primereact/tabview';

import {Pack} from './Pack';

export const GoiDichVu = () => {

  return (
    <TabView>
      <TabPanel header="Gói dịch vụ theo số lượng hóa đơn">
        <Pack header="Gói dịch vụ theo số lượng hóa đơn" type="invoice" unit="hóa đơn"/>
      </TabPanel>
      <TabPanel header="Gói dịch vụ theo dung lượng">
        <Pack header="Gói dịch vụ theo dung lượng" type="capacity" unit="MB"/>
      </TabPanel>
    </TabView>
  );
}