import {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Checkbox} from 'primereact/checkbox';
import {ConfirmDialogService, ToastService} from '@iamsoftware/react-hooks';

import {ExcelConfigService as Service} from './ExcelConfigService';

import {Crup} from './Crup';

export const Config = ({me, type}) => {

  const [displayCrup, setDisplayCrup] = useState(null);

  const [configs, setConfigs] = useState([]);

  useEffect(() => {
    loadConfigs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadConfigs = () => {
    Service.getConfigs().then(data => {
      if (type === 'Seed') {
        setConfigs(data.filter(item => item.type === type));
      } else {
        setConfigs(data);
      }
    });
  }

  const doDelete = rowData => {
    ConfirmDialogService.confirm('Xóa cấu hình', `Xác nhận xoá '${rowData.description}'`, () => {
      Service.deleteConfig(rowData._id).then(() => {
        loadConfigs();
        ToastService.success();
      });
    });
  }

  // const {render: renderConfigTable} = useDataTableBasic({
  //   header: 'Cấu hình',
  //   columns: [
  //     {field: 'sequence', header: 'Thứ tự', width: 200},
  //     {field: '', header: '', minWidth: 250},
  //     {field: '', header: 'Kích hoạt', dataType: 'checkbox', width: 200},
  //     {
  //       field: 'action', dataType: 'custom', sortable: false, customCell(rowData: any): any {
  //         return <Fragment>
  //
  //         </Fragment>
  //       }, width: 140
  //     }
  //   ],
  //   items: configs
  // });

  const onRowReorder = e => {
    Service.sort(e.value.filter(rowData => (rowData.type === type && rowData.user === me._id)).map(rowData => rowData._id)).then(() => {
      loadConfigs();
    });
  }

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-6">
          <Button label="Thêm cấu hình" icon="pi pi-plus" className="p-button-success p-button-sm" onClick={() => setDisplayCrup(true)}/>
        </div>
        <div className="col-6 text-right">
          <Link to="/bao-cao/bang-ke-pmkt">
            <Button label="Quay lại" icon="pi pi-arrow-left" className="p-button-outlined p-button-secondary p-button-sm"/>
          </Link>
        </div>
      </div>

      <div style={{height: 'calc(100vh - 16.5rem)'}}>
        <DataTable value={configs}
                   reorderableRows onRowReorder={onRowReorder} stripedRows scrollable scrollHeight="flex" responsiveLayout="scroll">
          <Column rowReorder style={{width: '3em'}}
                  bodyClassName={rowData => (rowData.type === type && rowData.user === me._id) ? '' : 'invisible'}/>
          <Column field="description" header="Tên cấu hình" style={{minWidth: '30em'}}/>
          <Column field="active" header="Kích hoạt" body={rowData => <Fragment>
            <Checkbox checked={rowData.active} readOnly={true}></Checkbox>
          </Fragment>} style={{maxWidth: '15em'}}/>
          <Column className="p-0" body={rowData => <Fragment>
            {(rowData.type === type && rowData.user === me._id) &&
							<Button icon="pi pi-pencil" onClick={() => setDisplayCrup(rowData)} className="p-button-rounded p-button-outlined p-button-warning mr-2"/>
            }
            <Button icon="pi pi-copy" onClick={() => setDisplayCrup({...rowData, _id: 1})} className="p-button-rounded p-button-outlined mr-2"/>
            {(rowData.type === type && rowData.user === me._id) &&
							<Button icon="pi pi-trash" onClick={() => doDelete(rowData)} className="p-button-rounded p-button-outlined p-button-danger"/>
            }
          </Fragment>
          } style={{maxWidth: '15em'}}/>
        </DataTable>
      </div>

      <Crup display={displayCrup} setDisplay={setDisplayCrup} type={type} loadConfigs={loadConfigs}/>
    </div>
  );
}