import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {JobMomentService as Service} from './JobMomentService';
import {Button} from 'primereact/button';
import {useEffect} from 'react';

export const JobMoment = () => {

  const dataKey = '_id';

  const {render: renderDataTable, refreshLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'description', header: 'Mô tả', minWidth: 250, matchMode: 'contains'},
      {field: 'case', header: 'Trường hợp', minWidth: 150, matchMode: 'contains'},
      {field: 'amount', header: 'Thời gian', width: 170, matchMode: 'numeric', dataType: 'number'},
      {field: 'unit', header: 'Đơn vị', width: 170, matchMode: 'equals', filterType: 'dropdown', filterOptions: units, dataType: 'fromFilterOptions'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 90,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.forCase = item.case;
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => update(item)}
          ];
          if (item.manualSetting) {
            item.actions.push({icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => doDelete(item)});
          }
        });
      }
    }
  });

  const {render: renderDialogCrup, create, update, form} = useDialogCrup({
    header: 'Cấu hình thời gian job',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'manualSetting', type: 'hidden'},
      {
        field: 'service', header: 'Loại Job', type: 'Dropdown', required: true, DropdownProps: {options: services}, displayDependency(item: any): boolean {
          return item.manualSetting;
        }, className: 'md:col-12'
      },
      {
        field: 'forCase', header: 'Trường hợp', type: 'InputTextarea', required: true, displayDependency(item: any): boolean {
          return item.manualSetting;
        }, className: 'md:col-12'
      },
      {
        field: 'description', header: 'Mô tả', required: true, disabledDependency(item: any): boolean {
          return !item.manualSetting;
        }, className: 'md:col-12'
      },
      {field: 'amount', header: `Thời gian`, required: true, type: 'InputNumber', InputNumberProps: {min: 1}, className: 'md:col-12'},
      {field: 'unit', header: 'Đơn vị', required: true, type: 'Dropdown', DropdownProps: {options: units}, className: 'md:col-12'}
    ],
    createItem(item: any): Promise<any> {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData: refreshLazyData
  });

  const {service} = form.getValue();
  useEffect(() => {
    if (service && form.dirty['service']) {
      const sv = services.find(s => s.value === service);
      if (sv?.label) {
        const _value = form.getRawValue();
        _value.description = sv.label;
        form.setValue(_value);
      }
    }
  }, [service]); // eslint-disable-line react-hooks/exhaustive-deps

  const doCreate = () => {
    create({
      manualSetting: true,
      service: services[0].value,
      description: services[0].label
    });
  }

  const doDelete = item => {
    ConfirmDialogService.confirm('Xoá cấu hình', `Xoá cấu hình ${item.description}?`, () => {
      Service.delete(item._id).then(() => {
        ToastService.success();
        refreshLazyData();
      });
    });
  }

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="col-12">
        <Button label="Thêm" icon="pi pi-plus" className="p-button-sm" severity="success" onClick={doCreate}/>
      </div>
      {renderDataTable()}
      {renderDialogCrup()}
    </div>
  );
}

const services = [
  {value: 'auto_login-error', label: 'Tự động đăng nhập - Lỗi'}
]
const units = [
  {value: 'days', label: 'Ngày'},
  {value: 'hours', label: 'Giờ'},
  {value: 'minutes', label: 'Phút'}
]