import {useState, createContext} from 'react';
import {LayoutState, ChildContainerProps, LayoutConfig, LayoutContextProps} from 'src/types/types';

export const LayoutContext = createContext({} as LayoutContextProps);

export const LayoutProvider = ({children}: ChildContainerProps) => {

  const [me, setMe] = useState(null);

  const [layoutConfig, setLayoutConfig] = useState<LayoutConfig>({
    ripple: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    colorScheme: 'light',
    theme: 'lara-light-indigo',
    scale: 14
  });

  const [layoutState, setLayoutState] = useState<LayoutState>({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false
  });

  const onMenuToggle = () => {
    if (isOverlay()) {
      setLayoutState((prevLayoutState) => ({...prevLayoutState, overlayMenuActive: !prevLayoutState.overlayMenuActive}));
    }

    if (isDesktop()) {
      setLayoutState((prevLayoutState) => ({...prevLayoutState, staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive}));
    } else {
      setLayoutState((prevLayoutState) => ({...prevLayoutState, staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive}));
    }
  };

  const showProfileSidebar = () => {
    setLayoutState((prevLayoutState) => ({...prevLayoutState, profileSidebarVisible: !prevLayoutState.profileSidebarVisible}));
  };

  const isOverlay = () => {
    return layoutConfig.menuMode === 'overlay';
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const value: LayoutContextProps = {
    me, setMe,
    layoutConfig,
    setLayoutConfig,
    layoutState,
    setLayoutState,
    onMenuToggle,
    showProfileSidebar
  };

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};
