import {useEffect, useState} from 'react';

import {Dropdown} from 'primereact/dropdown';
import {Chart} from 'primereact/chart';

import {DashboardService} from '../../dashboard/DashboardService';
import {ExternalAccountsService} from '../../tai-khoan-thue/ExternalAccountsService';

const lineOptions = {
  maintainAspectRatio: false,
  aspectRatio: .6,
  plugins: {
    legend: {
      labels: {
        color: '#495057'
      }
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#495057'
      },
      grid: {
        color: '#ebedef'
      }
    },
    y: {
      ticks: {
        color: '#495057'
      },
      grid: {
        color: '#ebedef'
      }
    }
  }
};

export const BieuDoHoaDon = () => {

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);

  const [lineData, setLineData] = useState({});

  useEffect(() => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        _accounts.push({value: item._id, label: `${item.username} - ${item.profile?.name || ''}`});
      });
      if (_accounts.length) {
        setAccount(_accounts[0].value);
      }
      setAccounts(_accounts);
    });
  }, []);

  useEffect(() => {
    if (account) {
      DashboardService.getLineData(account).then(data => {
        const _lineData = {
          labels: [],
          datasets: [
            {
              label: 'Hóa đơn bán ra',
              data: [],
              fill: false,
              backgroundColor: '#2f4860',
              borderColor: '#2f4860',
              tension: .4
            },
            {
              label: 'Hóa đơn mua vào',
              data: [],
              fill: false,
              backgroundColor: '#00bb7e',
              borderColor: '#00bb7e',
              tension: .4
            }
          ]
        };

        _lineData.labels = [...data.labels];
        _lineData.datasets[0].data = [...data.sold];
        _lineData.datasets[1].data = [...data.purchase];
        setLineData(_lineData);
      });
    }
  }, [account]);

  return (
    <div className="card pb-0">
      <div className="grid">
        <div className="field col-6 p-fluid mb-0">
          <label htmlFor="account">Tài khoản thuế ({accounts?.length} tài khoản)</label>
          <Dropdown value={account} options={accounts} onChange={e => setAccount(e.value)} filter={true}/>
        </div>
        <div className="col-6"></div>
        <div className="col-12">
          <div className="card">
            <h5>Biểu đồ Hóa đơn mua - bán</h5>
            <Chart type="line" data={lineData} options={lineOptions} height="60vh"/>
          </div>
        </div>
      </div>
    </div>
  );
}