import {Service} from './Service';

export class TaxCodeService extends Service {

  static entity = 'org-by-tax-code';

  static get(taxCode: string): Promise<any> {
    return this.axios.get(`${this.entity}/${taxCode}`).then(res => res.data);
  }

  static getTaxCodeV2(taxCode) {
    return this.axios.get(`https://mst.minvoice.com.vn/api/System/SearchTaxCodeV2?tax=${taxCode}`).then(res => res.data);
  }

  static getClassName(tthai: string): string {
    let className = 'alert-warning';
    switch (tthai) {
      case '00':
        className = 'alert-success';
        break;
      case '01':
      case '03':
        className = 'alert-error';
        break;
      case '05':
      case '06':
        className = 'alert-danger';
        break;
    }
    return className;
  }

  static msttthaiMap = {
    '00': 'NNT đang hoạt động (đã được cấp GCN ĐKT)',
    '01': 'NNT ngừng hoạt động và đã đóng MST',
    '02': 'NNT đã chuyển cơ quan thuế quản lý',
    '03': 'NNT ngừng hoạt động nhưng chưa hoàn thành thủ tục đóng MST',
    '04': 'NNT đang hoạt động (được cấp thông báo MST)',
    '05': 'NNT tạm nghỉ kinh doanh có thời hạn',
    '06': 'NNT không hoạt động tại địa chỉ đã đăng ký',
    '07': 'NNT chờ làm thủ tục phá sản'
  };

  static msttthaiList = [
    {value: '00', label: 'NNT đang hoạt động (đã được cấp GCN ĐKT)'},
    {value: '01', label: 'NNT ngừng hoạt động và đã đóng MST'},
    {value: '02', label: 'NNT đã chuyển cơ quan thuế quản lý'},
    {value: '03', label: 'NNT ngừng hoạt động nhưng chưa hoàn thành thủ tục đóng MST'},
    {value: '04', label: 'NNT đang hoạt động (được cấp thông báo MST)'},
    {value: '05', label: 'NNT tạm nghỉ kinh doanh có thời hạn'},
    {value: '06', label: 'NNT không hoạt động tại địa chỉ đã đăng ký'},
    {value: '07', label: 'NNT chờ làm thủ tục phá sản'}
  ]

}
