import {ProgressBar} from 'primereact/progressbar';

export const LoadingService = {

  start: () => {
    const body = document.getElementsByTagName('BODY')[0];
    body.classList.add('loading');
  },

  stop: () => {
    const body = document.getElementsByTagName('BODY')[0];
    body.classList.remove('loading');
  }

}

export const AppLoading = () => {
  return (
    <div className="app-loading">
      <div className="app-loading-box">
        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
      </div>
    </div>
  );
}
