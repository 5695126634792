import {useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';

import {ExcelConfigService as Service} from './ExcelConfigService';

export const Crup = ({display, setDisplay, type, loadConfigs}) => {

  const [submitting, setSubmitting] = useState(false);

  const [baseFields, setBaseFields] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    Service.getBase().then(data => {
      setBaseFields(data);
    });
  }, []);

  useEffect(() => {
    if (display) {
      reset();
      if (display._id) {
        setValue(display);
        if (display.config && display.config.fields) {
          setFields(display.config.fields);
        } else {
          setFields([]);
        }
      } else {
        setValue({active: true});
        setFields([]);
      }
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderForm, reset, setValue, getValue, valid} = useForm({
    fields: [
      {field: 'description', header: 'Tên cấu hình', required: true, className: 'md:col-12'},
      // {field: 'sequence', header: 'Thứ tự', type: 'InputNumber', InputNumberProps: {min: 1}, className: 'md:col-12'},
      {field: 'active', header: 'Kích hoạt', type: 'Checkbox', className: 'md:col-12'}
    ]
  });

  const addField = base => {
    setFields(prevFields => [...prevFields, Object.assign({}, base)]);
  }

  const {render: renderBaseFieldTable} = useDataTableBasic({
    tableHeader: 'Trường dữ liệu',
    columns: [
      {
        field: 'action', dataType: 'custom', sortable: false, customCell(rowData: any): any {
          return <Button icon="pi pi-plus" onClick={() => addField(rowData)} className="p-button-rounded p-button-outlined"/>
        }, width: 50, className: 'text-center'
      },
      {field: 'value', header: 'Dữ liệu', matchMode: 'contains', width: 200},
      {field: 'label', header: 'Tiêu đề', matchMode: 'contains', minWidth: 250}
    ],
    items: baseFields
  });

  const onRowReorder = e => {
    setFields(e.value);
  }

  const textEditor = options => <InputText value={options.value} onChange={e => options.editorCallback(e.target.value)}/>

  const onCellEditComplete = e => {
    let {rowData, newValue, field} = e;
    rowData[field] = newValue;
  }

  const deleteField = rowData => {
    setFields(prevFields => {
      prevFields.splice(prevFields.indexOf(rowData), 1);
      return [...prevFields];
    });
  }

  const save = () => {
    if (valid() && !submitting) {
      if (fields?.length) {
        setSubmitting(true);

        const data = getValue();
        data.type = type;
        data.config = {
          fields
        };

        let promise;
        if (typeof display._id === 'string') {
          promise = Service.updateConfig(display._id, data);
        } else {
          promise = Service.createConfig(data);
        }
        promise.then(() => {
          ToastService.success();
          loadConfigs();
          setDisplay(false)
        }).finally(() => {
          setSubmitting(false);
        });
      } else {
        ToastService.error('Cấu hình trường dữ liệu trống');
      }
    }
  }

  const footer = (
    <div className="text-center">
      <Button label="Lưu" icon="pi pi-check" className="p-button-sm" type="button" onClick={save} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header="Cấu hình" footer={footer} visible={!!display} maximized={true} onHide={() => setDisplay(false)}>
      <div className="grid">
        <div className="col-6" style={{height: 'calc(100vh - 11rem)'}}>

          {renderForm()}

          <div style={{height: 'calc(100vh - 20rem)'}}>
            <DataTable header="Cấu hình trường dữ liệu" value={fields}
                       editMode="cell" className="editable-cells-table p-fluid"
                       reorderableRows onRowReorder={onRowReorder} showGridlines stripedRows scrollable scrollHeight="flex">
              <Column rowReorder style={{width: '3em'}} className="text-center"/>
              <Column header="#" body={(data, bodyOptions) => excelHeaders[bodyOptions.rowIndex]} style={{width: '5em'}} className="text-center"/>
              <Column field="value" header="Dữ liệu" editor={textEditor} onCellEditComplete={onCellEditComplete} style={{width: '40%'}}/>
              <Column field="label" header="Tiêu đề" editor={textEditor} onCellEditComplete={onCellEditComplete}/>
              <Column field="action" style={{width: '50px', padding: '0.5rem'}}
                      body={rowData => <Button icon="pi pi-trash" onClick={() => deleteField(rowData)} className="p-button-rounded p-button-outlined p-button-danger"/>}/>
            </DataTable>
          </div>
        </div>
        <div className="col-6" style={{height: 'calc(100vh - 11rem)'}}>
          {renderBaseFieldTable()}
        </div>
      </div>
    </Dialog>
  );
}

function generateExcelColumnHeaders() {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const headers = [];

  for (let i = 0; i < alphabet.length; i++) {
    headers.push(alphabet[i]);
  }

  for (let i = 0; i < alphabet.length; i++) {
    for (let j = 0; j < alphabet.length; j++) {
      headers.push(alphabet[i] + alphabet[j]);
    }
  }

  return headers;
}

const excelHeaders = generateExcelColumnHeaders();