import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import moment from 'moment/moment';

import {Button} from 'primereact/button';
import {FormatDisplay, ToastService, useForm} from '@iamsoftware/react-hooks';

import {ReportService} from '../ReportService';
import {InvoicesService as Service} from '../../hoa-don/InvoicesService';
import {ExternalAccountsService} from '../../tai-khoan-thue/ExternalAccountsService';
import {TagsService} from '../../hoa-don/nhan-gan/TagsService';
import {ExcelConfigService} from './config/ExcelConfigService';
import {ProductService} from '../../danh-muc/hang-hoa/ProductService';

import {filterDateOptions} from '../filter';
import {XlsxService} from '../../../service/XlsxService';

const periods = [
  {value: 'TODAY', label: 'Hôm nay'},
  {value: 'THISWEEK', label: 'Tuần này'},
  {value: 'THISMONTH', label: 'Tháng này'},
  {value: 'THISFISCALQUARTER', label: 'Quý này'},
  {value: 'THISFISCALYEAR', label: 'Năm nay'},
  {value: 'FIRSTSIXMONTH', label: '6 tháng đầu năm'},
  {value: 'LASTSIXMONTH', label: '6 tháng cuối năm'},
  {value: 'JAN', label: 'Tháng 1'},
  {value: 'FEB', label: 'Tháng 2'},
  {value: 'MAR', label: 'Tháng 3'},
  {value: 'APR', label: 'Tháng 4'},
  {value: 'MAY', label: 'Tháng 5'},
  {value: 'JUN', label: 'Tháng 6'},
  {value: 'JUL', label: 'Tháng 7'},
  {value: 'AUG', label: 'Tháng 8'},
  {value: 'SEP', label: 'Tháng 9'},
  {value: 'OCT', label: 'Tháng 10'},
  {value: 'NOV', label: 'Tháng 11'},
  {value: 'DEC', label: 'Tháng 12'},
  {value: 'FISCALQUARTER1', label: 'Quý I'},
  {value: 'FISCALQUARTER2', label: 'Quý II'},
  {value: 'FISCALQUARTER3', label: 'Quý III'},
  {value: 'FISCALQUARTER4', label: 'Quý IV'},
  {value: 'LASTWEEK', label: 'Tuần trước'},
  {value: 'LASTMONTH', label: 'Tháng trước'},
  {value: 'LASTFISCALQUARTER', label: 'Quý trước'},
  {value: 'LASTFISCALYEAR', label: 'Năm trước'}
];

const types = [
  {value: 'sold', label: 'Hoá đơn bán ra'},
  {value: 'purchase', label: 'Hoá đơn mua vào'}
];

const toPartiesFixed = [
  // {value: 'DTECH', label: 'Phần mềm kế toán DTECH'},
  // {value: 'MISA', label: 'Phần mềm kế toán MISA'},
  // {value: 'VACOM', label: 'Phần mềm kế toán VACOM'},
  // {value: '3TSOFT', label: 'Phần mềm kế toán 3T SOFT'},
  // {value: 'SIVIP1', label: 'Phần mềm kế toán SIVIP (Mẫu 1)'},
  // {value: 'SIVIP2', label: 'Phần mềm kế toán SIVIP (Mẫu 2)'},
  // {value: 'IPOS', label: 'Phần mềm kế toán IPOS'}
];

export const BangKePmkt = () => {

  const [accounts, setAccounts] = useState([]);
  const [allTags, setAllTags] = useState(null);

  const [toParties, setToParties] = useState(toPartiesFixed);
  const [configs, setConfigs] = useState(null);

  useEffect(() => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        _accounts.push({value: item._id, label: `${item.username} - ${item.profile?.name || ''}`});
      });
      setAccounts(_accounts);
    });
    TagsService.getList('{}').then(({items}) => {
      const _allTags = [{value: 'isNull', label: 'Chưa gán nhãn'}];
      items.forEach(item => {
        _allTags.push({value: item._id, label: item.name});
      });
      setAllTags(_allTags);
    });
    ExcelConfigService.getConfigs().then(data => {
      if (data?.length) {
        const _toParties = [...toPartiesFixed];
        _toParties.push(...data.filter(item => item.active).map(item => {
          return {value: item._id, label: item.description}
        }));
        setToParties(_toParties);
        setConfigs(data);
      }
    });
  }, []);

  useEffect(() => {
    if (accounts.length) {
      const _value = getValue();

      _value.filterDateBy = 'tdlap';
      _value.period = 'THISMONTH';
      _value.account = accounts[0].value;
      _value.type = types[0].value;
      try {
        _value.to = localStorage.getItem('smi-ept-to') || toParties[0].value;
      } catch (e) {
      }
      setValue(_value);
    }
  }, [accounts]); // eslint-disable-line react-hooks/exhaustive-deps

  const tagsTemplate = option => {
    if (option.value === 'isNull') {
      return <div className="alert-warning">{option.label}</div>;
    }
    return <div>{option.label}</div>;
  }

  const {render: renderForm, getValue, setValue} = useForm({
    fields: [
      {field: 'account', header: 'Đơn vị', type: 'Dropdown', DropdownProps: {options: accounts, filter: true}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'type', header: 'Loại hóa đơn', type: 'Dropdown', DropdownProps: {options: types}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'filterDateBy', header: 'Báo cáo theo ngày', type: 'Dropdown', DropdownProps: {options: filterDateOptions}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'period', header: 'Chọn kỳ', type: 'Dropdown', DropdownProps: {options: periods}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'fromDate', header: 'Từ ngày', type: 'Calendar', className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'toDate', header: 'Đến ngày', type: 'Calendar', className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'khmshdon', header: 'Ký hiệu mẫu số hóa đơn', type: 'MultiSelect', MultiSelectProps: {options: Service.m_khmshdons}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'khhdon', header: 'Ký hiệu', className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'tthai', header: 'Trạng thái hóa đơn', type: 'MultiSelect', MultiSelectProps: {options: Service.m_tthais}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'status', header: 'Kết quả kiểm tra', type: 'Dropdown', DropdownProps: {options: Service.kqktras}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'tags', header: 'Nhãn hóa đơn', type: 'MultiSelect', MultiSelectProps: {options: allTags, itemTemplate: tagsTemplate}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {field: 'to', header: 'Phần mềm kế toán', type: 'Dropdown', DropdownProps: {options: toParties, filter: true}, className: 'md:col-12', labelClassName: 'md:col-4', inputClassName: 'md:col-8'}
    ],
    layout: 'Horizontal'
  });

  const {period, to, tags} = getValue();
  useEffect(() => {
    if (period) {
      let fromDate = new Date(), toDate = new Date();

      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      const quarters = ['FISCALQUARTER1', 'FISCALQUARTER2', 'FISCALQUARTER3', 'FISCALQUARTER4'];
      if (period && months.indexOf(period) >= 0) {
        fromDate = moment().month(months.indexOf(period)).startOf('month').toDate();
        toDate = moment().month(months.indexOf(period)).endOf('month').toDate();
      } else if (period && quarters.indexOf(period) >= 0) {
        fromDate = moment().quarter(quarters.indexOf(period) + 1).startOf('quarter').toDate();
        toDate = moment().quarter(quarters.indexOf(period) + 1).endOf('quarter').toDate();
      } else {
        switch (period) {
          case 'TODAY':
            fromDate = moment().startOf('day').toDate();
            toDate = moment().endOf('day').toDate();
            break;
          case 'THISWEEK':
            fromDate = moment().startOf('isoWeek').toDate();
            toDate = moment().endOf('isoWeek').toDate();
            break;
          case 'THISWEEKTODATE':
            fromDate = moment().startOf('isoWeek').toDate();
            break;
          case 'THISMONTH':
            fromDate = moment().startOf('month').toDate();
            toDate = moment().endOf('month').toDate();
            break;
          case 'THISMONTHTODATE':
            fromDate = moment().startOf('month').toDate();
            break;
          case 'THISFISCALQUARTER':
            fromDate = moment().startOf('quarter').toDate();
            toDate = moment().endOf('quarter').toDate();
            break;
          case 'THISFISCALQUARTERTODATE':
            fromDate = moment().startOf('quarter').toDate();
            break;
          case 'THISFISCALYEAR':
            fromDate = moment().startOf('year').toDate();
            toDate = moment().endOf('year').toDate();
            break;
          case 'THISFISCALYEARTODATE':
            fromDate = moment().startOf('year').toDate();
            break;
          case 'FIRSTSIXMONTH':
            fromDate = moment().startOf('year').toDate();
            toDate = moment().month(5).endOf('month').toDate();
            break;
          case 'LASTSIXMONTH':
            fromDate = moment().month(6).startOf('month').toDate();
            toDate = moment().endOf('year').toDate();
            break;
          case 'YESTERDAY':
            fromDate = moment().subtract(1, 'day').startOf('day').toDate();
            toDate = moment().subtract(1, 'day').endOf('day').toDate();
            break;
          case 'LASTWEEK':
            fromDate = moment().subtract(1, 'week').startOf('isoWeek').toDate();
            toDate = moment().subtract(1, 'week').endOf('isoWeek').toDate();
            break;
          case 'LASTMONTH':
            fromDate = moment().subtract(1, 'month').startOf('month').toDate();
            toDate = moment().subtract(1, 'month').endOf('month').toDate();
            break;
          case 'LASTFISCALQUARTER':
            fromDate = moment().subtract(1, 'quarter').startOf('quarter').toDate();
            toDate = moment().subtract(1, 'quarter').endOf('quarter').toDate();
            break;
          case 'LASTFISCALYEAR':
            fromDate = moment().subtract(1, 'year').startOf('year').toDate();
            toDate = moment().subtract(1, 'year').endOf('year').toDate();
            break;
          case 'NEXTWEEK':
            fromDate = moment().add(1, 'week').startOf('isoWeek').toDate();
            toDate = moment().add(1, 'week').endOf('isoWeek').toDate();
            break;
          case 'NEXTMONTH':
            fromDate = moment().add(1, 'month').startOf('month').toDate();
            toDate = moment().add(1, 'month').endOf('month').toDate();
            break;
          case 'NEXTFISCALQUARTER':
            fromDate = moment().add(1, 'quarter').startOf('quarter').toDate();
            toDate = moment().add(1, 'quarter').endOf('quarter').toDate();
            break;
          case 'NEXTFISCALYEAR':
            fromDate = moment().add(1, 'year').startOf('year').toDate();
            toDate = moment().add(1, 'year').endOf('year').toDate();
            break;
        }
      }
      const _value = getValue();
      _value.fromDate = fromDate;
      _value.toDate = toDate;
      setValue(_value);
    }
  }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (to) {
      localStorage.setItem('smi-ept-to', to);
    }
  }, [to]);

  useEffect(() => {
    if (tags?.length) {
      if (tags.find(it => it === 'isNull')) {
        setAllTags(prevState => {
          prevState.forEach(it => {
            it.disabled = it.value !== 'isNull';
          });
          return [...prevState];
        });
      } else {
        setAllTags(prevState => {
          prevState.forEach(it => {
            it.disabled = it.value === 'isNull';
          });
          return [...prevState];
        });
      }
    } else if (allTags) {
      setAllTags(prevState => {
        prevState.forEach(it => {
          it.disabled = false;
        });
        return [...prevState];
      });
    }
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tags?.length && tags?.length === allTags?.length) {
      const _value = getValue();
      _value.tags = tags.filter(it => it !== 'isNull');
      setValue(_value);
    }
  }, [tags, allTags]); // eslint-disable-line react-hooks/exhaustive-deps

  const apply = () => {
    const filter = getValue();
    const event = {
      filters: filter
    }
    ReportService.getBangKe(filter.type, filter.account, JSON.stringify(event)).then(async ({items}) => {
      if (!items?.length) {
        return ToastService.error('Không tìm thấy hoá đơn');
      }
      let sync = true;
      items.forEach(item => {
        if (!item.detail?.hdhhdvu && !item.detail_id) {
          sync = false;
        }
        if (!item.detail_id) {
          Service.incPriority(item._id).then();
        }
      });
      if (sync) {
        const aoa = [];
        switch (filter.to) {
          case 'MISA':
            aoa.push([
              'Ngày hạch toán (*)', 'Ngày chứng từ (*)', 'Số chứng từ (*)', 'Mẫu số HĐ', 'Ký hiệu HĐ', 'Số hóa đơn', 'Ngày hóa đơn',
              'Mã khách hàng', 'Tên khách hàng', 'Địa chỉ', 'Mã số thuế', 'Diễn giải',
              'Mã hàng (*)', 'Tên hàng', 'TK Tiền/Chi phí/Nợ (*)', 'Hình thức thanh toán', 'ĐVT', 'Số lượng', 'Đơn giá', 'Thành tiền',
              '% thuế GTGT', 'Tiền thuế GTGT', 'TK thuế GTGT', 'ty gia'
            ]);
            items.forEach(rowData => {

              let tkhang = rowData.data.nmten;
              let dchi = rowData.header.nmdchi;
              let mst = rowData.data.nmmst;
              if (filter.type === 'purchase') {
                tkhang = rowData.data.nbten || rowData.header.nbtnban;
                dchi = rowData.header.nbdchi;
                mst = rowData.keys.nbmst;
              }

              if (rowData.detail && rowData.detail.hdhhdvu) {
                rowData.detail.hdhhdvu.forEach(hh => {
                  aoa.push([
                    FormatDisplay.date(rowData.data.tdlap), FormatDisplay.date(rowData.data.tdlap), rowData.keys.shdon,
                    rowData.keys.khmshdon, rowData.keys.khhdon, rowData.keys.shdon, FormatDisplay.date(rowData.data.tdlap),
                    '', tkhang, dchi, mst, '', '', hh.ten, '', rowData.header.thtttoan, hh.dvtinh, hh.sluong, hh.dgia, hh.thtien,
                    hh.ltsuat, hh.tthue, '', rowData.header.tgia
                  ]);
                });
              }
            });
            break;
          case 'VACOM':
          case 'DTECH':
            aoa.push([
              'Mẫu số HĐ', 'Ký hiệu HĐ', 'Số hóa đơn', 'Ngày hóa đơn', 'Mã khách hàng', 'Tên khách hàng', 'Địa chỉ', 'Mã số thuế',
              'Diễn giải', 'Mã hàng (*)', 'Tên hàng', 'TK Tiền/Chi phí/Nợ (*)', 'Hình thức thanh toán', 'ĐVT', 'Số lượng',
              'Đơn giá', 'Thành tiền', '% thuế GTGT', 'Tiền thuế GTGT', 'TK thuế GTGT'
            ]);
            items.forEach(rowData => {

              let tkhang = rowData.data.nmten;
              let dchi = rowData.header.nmdchi;
              let mst = rowData.data.nmmst;
              if (filter.type === 'purchase') {
                tkhang = rowData.data.nbten || rowData.header.nbtnban;
                dchi = rowData.header.nbdchi;
                mst = rowData.keys.nbmst;
              }

              if (rowData.detail && rowData.detail.hdhhdvu) {
                rowData.detail.hdhhdvu.forEach(hh => {
                  aoa.push([
                    rowData.keys.khmshdon, rowData.keys.khhdon, rowData.keys.shdon, FormatDisplay.date(rowData.data.tdlap),
                    '', tkhang, dchi, mst, '', '', hh.ten, '', rowData.header.thtttoan, hh.dvtinh, hh.sluong, hh.dgia, hh.thtien,
                    hh.ltsuat, hh.tthue, ''
                  ]);
                });
              }
            });
            break;
          case '3TSOFT':
            aoa.push([
              'Ngày hạch toán (*)', 'Ngày chứng từ (*)', 'Số chứng từ (*)', 'Mẫu số HĐ', 'Ký hiệu HĐ', 'Số hóa đơn', 'Ngày hóa đơn',
              'Mã khách hàng', 'Tên khách hàng', 'Địa chỉ', 'Mã số thuế', 'Diễn giải', 'Mã hàng (*)', 'Tên hàng', 'TK Tiền/Chi phí/Nợ (*)',
              'Hình thức thanh toán', 'ĐVT', 'Số lượng', 'Đơn giá', 'Thành tiền', '% thuế GTGT', 'Tiền thuế GTGT', 'TK thuế GTGT'
            ]);
            items.forEach(rowData => {

              let tkhang = rowData.data.nmten;
              let dchi = rowData.header.nmdchi;
              let mst = rowData.data.nmmst;
              if (filter.type === 'purchase') {
                tkhang = rowData.data.nbten || rowData.header.nbtnban;
                dchi = rowData.header.nbdchi;
                mst = rowData.keys.nbmst;
              }

              if (rowData.detail && rowData.detail.hdhhdvu) {
                rowData.detail.hdhhdvu.forEach(hh => {
                  aoa.push([
                    FormatDisplay.date(rowData.data.tdlap), FormatDisplay.date(rowData.data.tdlap), rowData.keys.shdon,
                    rowData.keys.khmshdon, rowData.keys.khhdon, rowData.keys.shdon, FormatDisplay.date(rowData.data.tdlap),
                    '', tkhang, dchi, mst, '', '', hh.ten, '', rowData.header.thtttoan, hh.dvtinh, hh.sluong, hh.dgia, hh.thtien,
                    hh.ltsuat, hh.tthue, ''
                  ]);
                });
              }
            });
            break;
          case 'SIVIP1':
            aoa.push([
              '* Mã ncc', 'Tên nhà cung cấp', '* Số chứng từ', '* Ngày chứng từ', 'Ngày hóa đơn', 'Số hóa đơn',
              'Ký hiệu', 'Diễn giải', '* Tài khoản có', '* Tài khoản nợ', 'Tiền', 'Mã nt', 'Tỷ giá',
              'Diễn giải (Trong phần chi tiết)', 'Mã thanh toán', '* Mẫu báo cáo',
              '* Mã tính chất', 'Mẫu hóa đơn', '* Số hóa đơn', 'Ký hiệu', '* Ngày hóa đơn',
              'Mã nhà cung cấp (Trong phần thuế)', '* Tên nhà cung cấp (Trong phần thuế)', 'Địa chỉ', 'Mã số thuế',
              'Tên hàng hóa - dịch vụ', 'Tiền hàng', '* Mã thuế', '* Tài khoản thuế', 'Thuế',
              '* Cục thuế', 'Ghi chú', 'Vụ việc', 'Bộ phận', 'Lsx', 'Sản phẩm', 'Hợp đồng', 'Phí', 'Khế ước', 'Ghi chú'
            ]);
            items.forEach(rowData => {
              if (rowData.detail && rowData.detail.hdhhdvu) {
                rowData.detail.hdhhdvu.forEach(hh => {
                  aoa.push([
                    '', '', rowData.keys.shdon, FormatDisplay.date(rowData.data.tdlap), FormatDisplay.date(rowData.data.tdlap), rowData.keys.shdon,
                    rowData.keys.khmshdon + rowData.keys.khhdon, '', '', '', rowData.header.tgtcthue, 'VND', 1,
                    '', '', '',
                    rowData.header.tchat, '', rowData.keys.shdon, rowData.keys.khmshdon + rowData.keys.khhdon, FormatDisplay.date(rowData.data.tdlap),
                    '', rowData.header.nbten || rowData.header.nbtnban, rowData.header.nbdchi, rowData.header.nbmst,
                    hh.ten, hh.thtien, hh.ltsuat, '', hh.tthue,
                    '', '', '', '', ''
                  ]);
                });
              }
            });
            break;
          case 'SIVIP2':
            aoa.push([
              '* Mã ncc', 'Tên nhà cung cấp', 'Người giao hàng', '* Ngày chứng từ', '* Số chứng từ', 'Ngày hóa đơn', 'Số hóa đơn',
              'Ký hiệu', 'Diễn giải', '* Mã hàng', 'Tên mặt hàng', 'Đvt',
              '* Mã kho', '* Mã vị trí', '* Mã lô', 'Số lượng', 'Giá', 'Tiền hàng', 'Mã nt', 'Tỷ giá',
              '* Tài khoản có', 'Tài khoản nợ', 'Mã thanh toán', '* Mẫu báo cáo', '* Mã tính chất', 'Mẫu hóa đơn',
              '* Số hóa đơn', 'Ký hiệu', '* Ngày hóa đơn',
              'Mã nhà cung cấp (Trong phần thuế)', 'Tên nhà cung cấp (Trong phần thuế)', 'Địa chỉ', 'Mã số thuế',
              'Tên hàng hóa - dịch vụ', 'Tiền hàng', '* Mã thuế', '* Tk thuế', 'Thuế', '* Cục thuế', 'Ghi chú',
              'Vụ việc', 'Bộ phận', 'Lsx', 'Sản phẩm', 'Hợp đồng', 'Phí', 'Khế ước', 'Ghi chú'
            ]);
            items.forEach(rowData => {
              if (rowData.detail && rowData.detail.hdhhdvu) {
                rowData.detail.hdhhdvu.forEach(hh => {
                  aoa.push([
                    '', rowData.header.nbten || rowData.header.nbtnban, rowData.header.nbtnvchuyen, FormatDisplay.date(rowData.data.tdlap), rowData.keys.shdon, FormatDisplay.date(rowData.data.tdlap), rowData.keys.shdon,
                    rowData.keys.khmshdon + rowData.keys.khhdon, '', '', hh.ten, hh.dvtinh,
                    '', '', '', hh.sluong, hh.dgia, hh.thtien, '', '',
                    '', '', '', '', rowData.header.tchat, '',
                    rowData.keys.shdon, rowData.keys.khmshdon + rowData.keys.khhdon, FormatDisplay.date(rowData.data.tdlap),
                    '', rowData.header.nbten || rowData.header.nbtnban, rowData.header.nbdchi, rowData.header.nbmst,
                    hh.ten, hh.thtien, hh.ltsuat
                  ]);
                });
              }
            });
            break;
          case 'IPOS':
            aoa.push([
              'SoChungTu', 'NgayChungtu', 'GhiChu', 'SoHdon', 'NgayHdon',
              'KyHieuHdon', 'KyhieuMauHdon', 'MaThueDv', 'KhoNhap',
              'MaNcc', 'TenNcc', 'MaNccLq', 'TkCno', 'TkThue', 'TkThueNkhau',
              'TkHbtl', 'TkKho', 'TkDoiUng', 'NguoiGiaoDich', 'DiaChi', 'MaSoThue',
              'TienTe', 'TyGia',
              'MaHH', 'TenHH', 'Dvt', 'SoLuong', 'DonGiaNte', 'DonGia', 'SoTien', 'SoTienNte',
              'SoLuongMua', 'DonGiaMua', 'TyLeGG', 'TienGG', 'TienGGNte',
              'LoaiThue', 'TienThue', 'TienThueNte', 'TyLeNK', 'TienThueNK', 'TienThueNKNte', 'TyLeThueTTDB',
              'TienThueTTDBNte', 'TienThueTTDB', 'PhiVanChuyenNte', 'PhiVanChuyen', 'MaExtra1', 'MaExtra2',
              'MaNvien', 'MucGia', 'CongViec', 'MucCp', 'DoiTuongDoiUng', 'MaSp'
            ]);
            items.forEach(rowData => {
              if (rowData.detail && rowData.detail.hdhhdvu) {
                rowData.detail.hdhhdvu.forEach(hh => {
                  aoa.push([
                    rowData.keys.shdon, FormatDisplay.date(rowData.data.tdlap), '', rowData.keys.shdon, FormatDisplay.date(rowData.data.tdlap),
                    rowData.keys.khmshdon + rowData.keys.khhdon, '', '', '',
                    rowData.header.nbmst, rowData.header.nbten || rowData.header.nbtnban, '', '', '', '',
                    '', '', '', rowData.header.nbtnvchuyen, rowData.header.nbdchi, rowData.header.nbmst,
                    rowData.header.dvtte, rowData.header.tgia,
                    '', hh.ten, hh.dvtinh, hh.sluong, '', hh.dgia, hh.thtien, '',
                    '', '', '', '', '',
                    hh.ltsuat, hh.tthue
                  ]);
                });
              }
            });
            break;
          default:
            let fields;
            configs.forEach(config => {
              if (filter.to === config._id && config.config && config.config.fields?.length) {
                fields = config.config.fields;
              }
            });
            if (fields) {
              const getCat = fields.filter(f => f?.value?.includes('dmuc_')).length;

              const cat: any = {};
              if (getCat) {
                const nameSet = new Set();
                for (const rowData of items) {
                  if (rowData.detail?.hdhhdvu?.length) {
                    for (const hdhhdvu of rowData.detail.hdhhdvu) {
                      if (hdhhdvu?.ten?.trim()) {
                        nameSet.add(hdhhdvu.ten.trim());
                      }
                    }
                  }
                }
                for (const name of Array.from(nameSet.values())) {
                  const _cat = await ProductService.findOne(filter.account, {
                    type: filter.type,
                    name
                  });
                  if (_cat?._id) {
                    cat[`${name}`] = _cat;
                  }
                }
              }

              aoa.push(fields.map(field => field.label));

              items.forEach(rowData => {
                if (rowData.detail && rowData.detail.hdhhdvu) {
                  const {detail} = rowData;

                  detail.warning_status = rowData.warning?.status;
                  detail.warning_status_text = '';
                  if (rowData?.warning?.warning_array?.length) {
                    detail.warning_status_text = rowData.warning.warning_array.join(' - ');
                  }
                  for (const t of Service.tthais) {
                    if (t.value === rowData.header.tthai) {
                      detail.tthai_text = t.label;
                      break;
                    }
                  }

                  detail.hdhhdvu.forEach(hdhhdvu => {
                    const getFieldValue = fieldTarget => {
                      try {
                        switch (fieldTarget.split('.').length) {
                          case 1:
                            return detail[fieldTarget];
                          case 2:
                            switch (fieldTarget.split('.')[0]) {
                              case 'hdhhdvu':
                                const hdhhdvu_field = fieldTarget.split('.')[1];
                                if (hdhhdvu_field) {
                                  if (hdhhdvu.ten?.trim() && hdhhdvu_field.includes('dmuc_')) {
                                    const hdhhdvu_cat = cat[hdhhdvu.ten] || cat[hdhhdvu.ten.trim()];
                                    if (hdhhdvu_cat) {
                                      return hdhhdvu_cat[hdhhdvu_field.replace('dmuc_', '')] || '';
                                    }
                                    return '';
                                  } else {
                                    return hdhhdvu[hdhhdvu_field];
                                  }
                                } else {
                                  return '';
                                }
                              default:
                                if (fieldTarget.split('.')[0] && fieldTarget.split('.')[1]) {
                                  return detail[fieldTarget.split('.')[0]][fieldTarget.split('.')[1]];
                                } else {
                                  return '';
                                }
                            }
                          default:
                            return '';
                        }
                      } catch (e) {
                        return '';
                      }
                    }

                    const _row = [];
                    fields.forEach(field => {
                      if (field.value) {
                        if (field.value.substring(0, 5) === 'calc(') {
                          try {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const calc = arg => {
                              _row.push(arg);
                            }
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const formatDate = (value, format) => {
                              return FormatDisplay.date(value, format);
                            }

                            // eslint-disable-next-line no-eval
                            eval(field.value);
                          } catch (e) {
                            console.log(e);
                            _row.push('');
                          }
                        } else {
                          _row.push(getFieldValue(field.value));
                        }
                      } else {
                        _row.push('');
                      }
                    });
                    aoa.push(_row);
                  });
                }
              });
            }
        }
        XlsxService.generateFile(aoa, 'bang-ke-hoa-don');
      } else {
        ToastService.error('Hóa đơn chưa đồng bộ xong. Quý khách vui lòng chờ trong ít phút.');
      }
    });
  }

  return (
    <div className="card pb-0">
      <div className="grid">
        <div className="col-6">
          {renderForm()}
        </div>
        <div className="col-6"></div>
        <div className="col-6">
          <div className="flex justify-content-end mt-2">
            <Link to="/bao-cao/bang-ke-pmkt/config">
              <Button label="Cấu hình" icon="pi pi-cog" className="p-button-help p-button-sm mr-2"/>
            </Link>
            <Button label="Kết xuất" icon="pi pi-file-excel" className="p-button-sm" onClick={apply}/>
          </div>
        </div>
      </div>
    </div>
  );
}