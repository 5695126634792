import {EntityService} from '../../service/EntityService';

export class ExternalAccountsService extends EntityService {

  static entity = 'external_accounts';

  static checkAlive(_id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${_id}/check-alive`).then(res => res.data);
  }

  static getCaptcha(): Promise<any> {
    return this.axios.get(`${this.entity}/captcha`).then(res => res.data);
  }

  static login(_id: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${_id}/login`, data).then(res => res.data);
  }

  static signOut(_id: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/sign-out`).then(res => res.data);
  }

  static syncInvoicesSold(_id: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/sync_invoices_sold`).then(res => res.data);
  }

  static syncInvoicesPurchase(_id: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/sync_invoices_purchase`).then(res => res.data);
  }

  static getSync(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/sync`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static updateTo_khai_thue_01(_id: string, config: any): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/config/to_khai_thue_01`, config).then(res => res.data);
  }

  static updateDateOfInvoiceDeclaration(_id: string, config: any): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/date_of_invoice_declaration`, config).then(res => res.data);
  }

  static updateDisplayConfig(_id: string, config: any): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/display-config`, config).then(res => res.data);
  }

}
