import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {ReportService} from '../ReportService';
import {ExternalAccountsService} from '../../tai-khoan-thue/ExternalAccountsService';

import {PreCreate} from './PreCreate';
import {Crup} from './Crup';

export const ToKhaiThue = () => {

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);

  const [requiredParams, setRequiredParams] = useState(null);

  const [cqtCCucs, setCqtCCucs] = useState([]);
  const [cqtNNops, setCqtNNops] = useState([]);

  const [displayPreCreate, setDisplayPreCreate] = useState(false);
  const [crupData, setCrupData] = useState(null);

  useEffect(() => {
    loadAccounts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (account) {
      setRequiredParams({account: {value: account._id, matchMode: 'ObjectId'}});
    }
  }, [account]);

  const loadAccounts = () => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      if (items.length) {

        items.forEach(item => {
          item.label = `${item.username} - ${item.profile?.name || ''}`;
        });

        if (account) {
          items.forEach(item => {
            if (item._id === account._id) {
              setAccount(item);
            }
          });
        } else {
          setAccount(items[0]);
        }
      }
      setAccounts(items);
    });
  }

  const searchCqtCCucs = event => {
    const _event = {
      filters: {
        isParent: {value: true, matchMode: 'equals'},
        name: {value: event.query, matchMode: 'contains'}
      }
    };
    ReportService.getTaxAuthorities(JSON.stringify(_event)).then(({items}) => {
      setCqtCCucs(items);
    });
  }

  const searchCqtNNops = event => {
    const {maCQTCapCuc} = form.getValue();
    if (maCQTCapCuc && maCQTCapCuc.code) {
      const _event = {
        filters: {
          isParent: {value: false, matchMode: 'equals'},
          parentCode: {value: maCQTCapCuc.parentCode, matchMode: 'equals'},
          name: {value: event.query, matchMode: 'contains'}
        }
      };
      ReportService.getTaxAuthorities(JSON.stringify(_event)).then(({items}) => {
        setCqtNNops(items);
      });
    } else {
      setCqtNNops([]);
    }
  }

  const {render: renderDialogConfig, update: updateConfig, form} = useDialogCrup({
    header: 'Hồ sơ kê khai thuế',
    dataKey: '_id',
    width: '80rem',
    fields: [
      {field: 'mst', header: 'Mã số thuế', type: 'tax-code', className: 'md:col-5'},
      {field: 'tenNNT', header: 'Người nộp thuế', required: true, className: 'md:col-7'},
      {field: 'dchiNNT', header: 'Địa chỉ trụ sở', required: true, className: 'md:col-12'},
      {field: 'tenHuyenNNT', header: 'Quận/ huyện', required: true, className: 'md:col-6'},
      {field: 'tenTinhNNT', header: 'Tỉnh/ Thành phố', required: true, className: 'md:col-6'},
      {field: 'dthoaiNNT', header: 'Điện thoại', className: 'md:col-4'},
      {field: 'faxNNT', header: 'Fax', className: 'md:col-4'},
      {field: 'emailNNT', header: 'Email', className: 'md:col-4'},
      {field: 'nganhNghe', header: 'Ngành nghề kinh doanh chính', required: true, type: 'Dropdown', DropdownProps: {options: nganhNgheOptions, optionLabel: 'ten_NganhNghe'}, className: 'md:col-6'},
      {field: 'nguoiKy', header: 'Người ký tờ khai', className: 'md:col-6'},
      {field: 'maCQTCapCuc', header: 'Cơ quan thuế cấp cục', required: true, type: 'AutoComplete', AutoCompleteProps: {suggestions: cqtCCucs, completeMethod: searchCqtCCucs, field: 'name', dropdown: true}, className: 'md:col-6'},
      {field: 'maCQTNoiNop', header: 'Cơ quan thuế quản lý', required: true, type: 'AutoComplete', AutoCompleteProps: {suggestions: cqtNNops, completeMethod: searchCqtNNops, field: 'name', dropdown: true}, className: 'md:col-6'},
      {field: 'soHDongDLyThue', header: 'Số hợp đồng đại lý thuế', className: 'md:col-6'},
      {field: 'ngayKyHDDLyThue', header: 'Ngày hợp đồng đại lý thuế', type: 'Calendar', className: 'md:col-6'},
      {field: 'tenDLyThue', header: 'Tên đại lý thuế', className: 'md:col-6'},
      {field: 'mstDLyThue', header: 'MST đại lý thuế', InputTextProps: {validator: 'taxCode'}, className: 'md:col-6'},
      {field: 'tenNVienDLyThue', header: 'Nhân viên đại lý thuế', className: 'md:col-6'},
      {field: 'cchiHNghe', header: 'Chứng chỉ hành nghề số', className: 'md:col-6'}
    ],
    updateItem: (id, item) => {
      return ExternalAccountsService.updateTo_khai_thue_01(id, item);
    },
    reloadLazyData: loadAccounts
  });

  const showConfig = () => {
    const config = {_id: account._id, ...(account.config?.to_khai_thue_01 || {})};
    if (!config.mst) {
      config.mst = account.username;
    }
    updateConfig(config);
  }

  const showCreate = () => {
    const config = {_id: account._id, ...(account.config?.to_khai_thue_01 || {})};
    if (!config.mst) {
      showConfig();
    } else {
      setDisplayPreCreate(true);
    }
  }

  const doCreate = period => {
    setCrupData(period);
    setDisplayPreCreate(false);
  }

  const doUpdate = item => {
    setCrupData(item);
    setDisplayPreCreate(false);
  }

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey: '_id',
    columns: [
      {field: 'description', header: 'Tờ khai thuế', minWidth: 250, matchMode: 'contains'},
      {field: 'created_date', header: 'Ngày tạo', width: 200, dataType: 'date-time'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 115,
    getList: params => {
      return ReportService.getToKhaiThue01(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => doUpdate(item)},
            {icon: 'pi pi-download', title: 'Tải Xml', command: () => doDownloadXml(item)},
            {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xóa', command: () => doDelete(item)}
          ]
        });
      }
    },
    requiredParams
  });

  const doDownloadXml = item => {
    ReportService.downloadToKhaiThue01Xml(item._id).then(data => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]));
      let time;
      switch (item.period.kieuKy) {
        case 'M':
          time = `M${item.period.month}`;
          break;
        case 'Q':
          time = `Q${item.period.quarter}`;
          break;
      }
      link.download = `HAN-${item.config.mst}-01_GTGT_TT80-${time}${item.period.year}-L00.xml`;
      link.click();
    });
  }

  const doDelete = item => {
    ConfirmDialogService.confirm('Xóa tờ khai', 'Xác nhận xóa tờ khai thuế này?', () => {
      ReportService.deleteToKhaiThue01(item._id).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    });
  }

  return (
    <div className="card pb-0">
      <div className="grid">
        <div className="field col-6 p-fluid mb-0">
          <label htmlFor="account">Tài khoản thuế ({accounts?.length} tài khoản)</label>
          <Dropdown value={account} options={accounts} onChange={e => setAccount(e.value)} filter={true}/>
        </div>
        <div className="col-6"></div>
        {account &&
					<Fragment>
						<div className="col-12">
							<Button label="Hồ sơ kê khai thuế" icon="pi pi-credit-card" className="p-button-warning p-button-sm mr-2" onClick={showConfig}/>
							<Button label="Thêm tờ khai" icon="pi pi-plus" className="p-button-sm" onClick={showCreate}/>
						</div>
						<div className="col-12">
              {renderDataTable()}
						</div>
					</Fragment>
        }
      </div>
      {account &&
				<Fragment>
          {renderDialogConfig()}

					<PreCreate display={displayPreCreate} setDisplay={setDisplayPreCreate} doCreate={doCreate}/>
					<Crup display={crupData} setDisplay={setCrupData} account={account} reloadLazyData={reloadLazyData}/>
				</Fragment>
      }
    </div>
  );
}

const nganhNgheOptions = [
  {ma_NganhNghe: '00', ten_NganhNghe: 'Hoạt động sản xuất kinh doanh thông thường', tieuMucHachToan: '1701'},
  {ma_NganhNghe: '05', ten_NganhNghe: 'Hoạt động thăm dò, khai thác dầu khí ', tieuMucHachToan: '1704'}
];