import {EntityService} from '../../../service/EntityService';

export class LoginServices extends EntityService {

  static entity = 'system/external_accounts';

  static setAuto(_id: string, data: any): Promise<any> {
    return this.axios.patch(`${this.entity}/${_id}/auto_login`, data).then(res => res.data);
  }

}
