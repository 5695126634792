import {Fragment, useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {ToastService} from '@iamsoftware/react-hooks';

import {ExternalAccountsService as Service} from './ExternalAccountsService';
import {Checkbox} from 'primereact/checkbox';

export const Login = ({display, setDisplay, account, reloadLazyData}) => {

  const [submitting, setSubmitting] = useState(false);

  const [captchaContent, setCaptchaContent] = useState(null);
  const [ckey, setCkey] = useState(null);
  const [cvalue, setCvalue] = useState('');

  const [auto, setAuto] = useState(true);

  useEffect(() => {
    if (display) {
      loadCaptcha();
    }
  }, [display]);

  useEffect(() => {
    if (account) {
      setAuto(account.auto_login?.auto);
    }
  }, [account]);

  const loadCaptcha = () => {
    Service.getCaptcha().then(({key, content}) => {
      setCkey(key);
      setCaptchaContent(content);
      setCvalue('');
    });
  }

  const login = (event) => {
    if (!submitting) {
      setSubmitting(true);

      Service.login(account._id, {
        ckey,
        cvalue,
        auto
      }).then(result => {
        if (result && result.username) {
          ToastService.success();
          setDisplay(false);
          reloadLazyData();
        } else {
          ToastService.error('Có lỗi xảy ra');
        }
      }).finally(() => {
        setSubmitting(false);
        loadCaptcha();
      });
    }
    event.preventDefault();
  }

  return (
    <Dialog header="Đăng nhập" visible={display} style={{width: '500px'}} position="top" closeOnEscape={false} onHide={() => setDisplay(false)}>
      <form onSubmit={login}>
        <div className="p-fluid formgrid grid">
          <div className="field col-fixed" style={{width: '200px'}}>
            <label>&nbsp;</label>
            <div className="captcha" dangerouslySetInnerHTML={{__html: captchaContent}}></div>
          </div>
          <div className="field col-fixed pl-0" style={{width: '30px'}}>
            <label>&nbsp;</label>
            <i className="pi pi-refresh pointer mt-2" style={{fontSize: 20}} onClick={loadCaptcha}></i>
          </div>
          <div className="field col">
            <label htmlFor="cvalue">Nhập mã captcha<span className="required">*</span></label>
            <InputText id="cvalue" value={cvalue} onChange={e => setCvalue(e.target.value)} required/>
          </div>

          <AutoLogin account={account} auto={auto} setAuto={setAuto}/>
        </div>
        <div className="flex justify-content-end mt-2">
          <Button label="Đăng nhập" icon="pi pi-check" className="p-button-sm" loading={submitting}/>
        </div>
      </form>
    </Dialog>
  );
}

export const AutoLogin = ({account, auto, setAuto}) => {

  return (
    <Fragment>
      <div className="field-checkbox col-12 md:col-6 mt-2">
        <Checkbox inputId="auto_login" checked={auto} onChange={e => setAuto(e.checked)}/>
        <label htmlFor="auto_login">Nhớ tài khoản</label>
      </div>
      {account?.auto_login?.last_message && <div className="field col-12">
				<i>Lỗi đăng nhập gần đây: <b className="alert-danger">{account?.auto_login?.last_message}</b></i>
			</div>}
    </Fragment>
  );
}