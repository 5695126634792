import {useRef} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {InvoiceSearchLinkService as Service} from './InvoiceSearchLinkService';
import {XlsxService} from '../../../service/XlsxService';

export const InvoiceSearchLinks = () => {

  const dataKey = '_id';

  const inputExcel = useRef(null);

  const columns: Array<any> = [
    {field: 'MSTTCGP', header: 'MST TCGP', width: 150, matchMode: 'contains'},
    {field: 'TENTCGP', header: 'Tổ chức giải pháp', minWidth: 150, matchMode: 'contains'},
    {field: 'mstNBan', header: 'MST Người bán', minWidth: 150, matchMode: 'contains'},
    {field: 'search_link_1', header: 'Link tra cứu 1', minWidth: 170, matchMode: 'contains'},
    {field: 'search_link_2', header: 'Link tra cứu 2', minWidth: 170, matchMode: 'contains'},
    {field: 'search_key', header: 'Mã tra cứu', minWidth: 170, matchMode: 'contains'},
    {field: 'comments', header: 'Ghi chú', minWidth: 150, matchMode: 'contains'}
  ];

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns,
    indexColumnWidth: 45,
    actionColumnWidth: 115,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.forCase = item.case;
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => update(item)},
            {icon: 'pi pi-copy', className: 'p-button-info', title: 'Sao chép', command: () => create(item)},
            {icon: 'pi pi-trash', className: 'p-button-danger', title: 'Xoá', command: () => doDelete(item)}
          ];
        });
      }
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header: 'Link tra cứu',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'MSTTCGP', header: 'MST TCGP', required: true, className: 'md:col-12'},
      {field: 'TENTCGP', header: 'Tổ chức giải pháp', className: 'md:col-12'},
      {field: 'mstNBan', header: 'MST Người bán', className: 'md:col-12'},
      {field: 'search_link_1', header: 'Link tra cứu 1', required: true, className: 'md:col-12'},
      {field: 'search_link_2', header: 'Link tra cứu 2', className: 'md:col-12'},
      {field: 'search_key', header: 'Mã tra cứu', required: true, className: 'md:col-12'},
      {field: 'comments', header: 'Ghi chú', className: 'md:col-12'}
    ],
    createItem(item: any): Promise<any> {
      const _event = {
        filters: {
          MSTTCGP: {value: item.MSTTCGP, matchMode: 'equals'},
          mstNBan: {value: item.mstNBan || 'NULL', matchMode: 'equals'}
        }
      };
      return new Promise<void>((resolve, reject) => {
        Service.getList(JSON.stringify(_event)).then(data => {
          if (data.totalRecords) {
            reject('xxx');
            ToastService.error('Thông tin tra cứu của đơn vị đã tồn tại trên phần mềm, vui lòng kiểm tra lại');
          } else {
            resolve();
          }
        })
      }).then(() => Service.create(item));
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const doCreate = () => {
    create();
  }

  const doDelete = item => {
    ConfirmDialogService.confirm('Xoá cấu hình Link tra cứu', `Xoá cấu hình Link tra cứu ${item.search_key}?`, () => {
      Service.delete(item._id).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    });
  }

  const onUploadExcel = e => {
    if (e.target.files && e.target.files.length === 1) {
      XlsxService.readExcelFile(e.target.files[0], {startRow: 1}).then(async rows => {

        const importItems = [];
        rows?.forEach(row => {
          const importItem = {
            MSTTCGP: row[0] || '',
            TENTCGP: row[1] || '',
            mstNBan: row[2] || '',
            search_link_1: row[3] || '',
            search_link_2: row[4] || '',
            search_key: row[5] || '',
            comments: row[6] || ''
          };
          if (importItem.MSTTCGP && importItem.search_link_1 && importItem.search_key) {
            importItems.push(importItem);
          }
        });

        if (importItems.length) {
          for (const importItem of importItems) {
            await Service.create(importItem);
          }
          ToastService.success();
          reloadLazyData();
          inputExcel.current.value = '';
        } else {
          ToastService.error('Không có dữ liệu');
        }
      });
    }
  }

  const downloadExcel = () => {
    Service.getList('{}').then(({items}) => {
      const aoa = [[]];
      columns.forEach(column => {
        aoa[0].push(column.header);
        items.forEach((item, index) => {
          if (!aoa[index + 1]) {
            aoa[index + 1] = [];
          }
          aoa[index + 1].push(XlsxService.getCellValue(column, item));
        });
      });

      XlsxService.generateFile(aoa, 'link-tra-cuu');
    });
  }

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="col-12 flex gap-2">
        <Button label="Thêm" icon="pi pi-plus" className="p-button-sm" severity="success" onClick={doCreate}/>
        <Button label="Import Excel" icon="pi pi-file-import" className="p-button-sm" onClick={() => inputExcel.current.click()}/>
        <input ref={inputExcel} type="file" accept=".xlsx, .xls" onChange={onUploadExcel} style={{display: 'none'}}/>
        <Button label="Export Excel" icon="pi pi-file-export" className="p-button-sm" severity="help" onClick={downloadExcel}/>
      </div>
      {renderDataTable()}
      {renderDialogCrup()}
    </div>
  );
}
