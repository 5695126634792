import {Fragment} from 'react';

import {List} from '../List';

export const MuaVao = () => {

  const mstFields = [
    {field: 'nbmst', header: 'MST người bán', InputTextProps: {maxLength: 14, keyfilter: 'int'}, className: 'md:col-2'},
    {field: 'nbten', header: 'Tên người bán', InputTextProps: {maxLength: 400}, className: 'md:col-2'}
  ];
  const mstColumn = {
    field: 'keys.nbmst', header: 'Thông tin người bán', minWidth: 180, sortable: false, dataType: 'custom', customCell(rowData: any, skipFormat: boolean): any {
      if (skipFormat === true) {
        return `MST người bán: ${rowData.header?.nbmst}\nTên người bán: ${(rowData.header?.nbten || rowData.header?.nbtnban)}`
      }
      return (
        <div>
          <p className="mb-0">MST người bán: {rowData.header?.nbmst}</p>
          <p className="mb-0">Tên người bán: {(rowData.header?.nbten || rowData.header?.nbtnban)}</p>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <List type="purchase" mstFields={mstFields} mstColumn={mstColumn}/>
    </Fragment>
  );
}