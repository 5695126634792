import {Route, Routes} from 'react-router-dom';

import {GoiDichVu} from './goi-dich-vu';
import DaiLy from './dai-ly';

export default function HeThong({me}) {

  return (
    <Routes>
      <Route path="goi-dich-vu" element={<GoiDichVu/>}/>
      <Route path="dai-ly/*" element={<DaiLy me={me}/>}/>
    </Routes>
  );
}
