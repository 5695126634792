import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {HdtbssService} from './HdtbssService';

export const ThongBaoSS = ({display, setDisplay}) => {

  const doPrint = () => {
    const myWindow = window.open(`${HdtbssService.baseURL}/${HdtbssService.entity}/${display._id}/tbss-html`);
    myWindow.onafterprint = myWindow.close;
    myWindow.print();
  }

  const footer = (
    <div>
      <Button label="In thông báo" icon="pi pi-print" type="button" size="small" onClick={doPrint}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Dialog header="Thông báo hóa đơn điện tử có sai sót 04/SS-HĐĐT" footer={footer} visible={!!display} style={{width: '330mm', height: '95vh'}} position="top" onHide={() => setDisplay(false)}>
      {display?._id && <iframe className="iframe-full" src={`${HdtbssService.baseURL}/${HdtbssService.entity}/${display._id}/tbss-html`}
															 title="template-preview"/>}
    </Dialog>
  );
}
