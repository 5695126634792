import {useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {InputTextarea} from 'primereact/inputtextarea';

import {InvoicesService} from './InvoicesService';

export const Comment = ({display, setDisplay, reloadLazyData}) => {

  const header = 'Ghi chú hoá đơn';
  const dataKey = '_id';

  const [comment, setComment] = useState('');

  useEffect(() => {
    if (display && display.comment) {
      setComment(display.comment);
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps


  const save = () => {
    InvoicesService.patchComment(display[dataKey], comment).then(() => {
      setDisplay(false);
      reloadLazyData();
    });
  }

  return (
    <Dialog header={header} visible={!!display} style={{width: '40rem'}} position="top" onHide={() => setDisplay(false)}>

      <div className="p-fluid formgrid grid mt-2">
        <div className="field col-12">
          <InputTextarea value={comment} onChange={e => setComment(e.target.value)} autoResize/>
        </div>
      </div>

      <div className="flex justify-content-end mt-2">
        <Button label="Lưu" icon="pi pi-check" className="p-button-sm mr-2" onClick={save}/>
        <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={() => setDisplay(false)}/>
      </div>
    </Dialog>
  );
}