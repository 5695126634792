import {useEffect, useState} from 'react';

import moment from 'moment';

import {Chart} from 'primereact/chart';
import {FormatDisplay} from '@iamsoftware/react-hooks';

import {UsersService} from '../../../service/UsersService';

export const TinhHinhSuDung = () => {

  const [license, setLicense] = useState(null);
  const [inUse, setInUse] = useState(null);
  const [daysRemaining, setDaysRemaining] = useState(0);

  const [invoiceData, setInvoiceData] = useState({
    labels: ['Đã sử dụng', 'Còn lại'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: [
          '#FFA726',
          '#42A5F5'

        ],
        hoverBackgroundColor: [
          '#FFB74D',
          '#64B5F6'
        ]
      }
    ]
  });
  const [capacityData, setCapacityData] = useState({
    labels: ['Đã sử dụng', 'Còn lại'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: [
          '#FFA726',
          '#42A5F5'

        ],
        hoverBackgroundColor: [
          '#FFB74D',
          '#64B5F6'
        ]
      }
    ]
  });

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    }
  });

  useEffect(() => {
    UsersService.getLicense().then(data => {
      if (data?.thru_date) {
        setDaysRemaining(moment(data.thru_date).diff(moment(), 'days'));
      }
      setLicense(data);
    });
    UsersService.getInUse().then(data => {
      setInUse(data);
    });
  }, []);

  useEffect(() => {
    if (license && license._id && inUse) {
      const _invoiceData = {...invoiceData};
      let used = 0;
      let free = license.invoice_quantity;
      if (inUse.invoice) {
        used = inUse.invoice;
        free = free - used;
      }
      _invoiceData.datasets[0].data = [used, free];
      setInvoiceData(_invoiceData);

      const _capacityData = {...capacityData};
      used = 0;
      free = 100;
      if (inUse.capacity) {
        used = inUse.capacity / (1024 * 1024);
        free = free - used;
      }
      _capacityData.datasets[0].data = [used.toFixed(5), free.toFixed(5)];
      setCapacityData(_capacityData);
    }
  }, [license, inUse]); // eslint-disable-line react-hooks/exhaustive-deps

  if (license && license._id) {
    return (
      <div className="card pb-0">
        <div className="grid">
          <div className="col-12">
            <b>Ngày hết hạn: {FormatDisplay.dateTime(license?.thru_date)} (Còn lại {FormatDisplay.number(daysRemaining)} ngày).</b>
          </div>
          <div className="col-12 xl:col-6">
            <div className="card text-center">
              <h5>Số lượng hóa đơn</h5>
              <Chart type="pie" data={invoiceData} options={lightOptions} style={{position: 'relative', width: '40%', margin: '0 auto'}}/>
            </div>
          </div>
          <div className="col-12 xl:col-6">
            {/*<div className="card text-center">*/}
            {/*  <h5>Dung lượng</h5>*/}
            {/*  <Chart type="pie" data={capacityData} options={lightOptions} style={{position: 'relative', width: '40%', margin: '0 auto'}}/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <div className="p-message p-component p-message-error p-message-enter-done">
              <div className="p-message-wrapper">
                <span className="p-message-icon pi pi-times-circle"></span>
                <span className="p-message-detail">Bạn chưa được đăng ký sử dụng chương trình!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}