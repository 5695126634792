import {useState} from 'react';

import {Button} from 'primereact/button';
import {useDataTable, useDialogCrup} from '@iamsoftware/react-hooks';

import {PacksService as Service} from './PacksService';

export const Pack = ({header, type, unit}) => {

  const dataKey = '_id';

  const [requiredParams] = useState({type: {value: type, matchMode: 'equals'}});

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'description', header: 'Tên gói', minWidth: 250, matchMode: 'contains'},
      {field: 'amount', header: `Số lượng ${unit}`, width: 200, dataType: 'number'},
      {field: 'price', header: 'Giá', width: 200, dataType: 'number'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 50,
    getList: params => {
      return Service.getList(params);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', className: 'p-button-warning', title: 'Chỉnh sửa', command: () => update(item)}
          ]
        });
      }
    },
    requiredParams
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'description', header: 'Tên gói', required: true, className: 'md:col-12'},
      {field: 'amount', header: `Số lượng ${unit}`, required: true, type: 'InputNumber', InputNumberProps: {min: 1}, className: 'md:col-12'},
      {field: 'price', header: 'Giá', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-12'}
    ],
    createItem: item => {
      item.type = type;
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-12">
          <Button label="Thêm" icon="pi pi-plus" className="p-button-sm" onClick={create}/>
        </div>
      </div>
      {renderDataTable()}
      {renderDialogCrup()}
    </div>
  );
}