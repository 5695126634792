import {Service} from '../../../service/Service';

export class ThrottleService extends Service {

  static entity = 'system/throttle';

  static load(): Promise<any> {
    return this.axios.get(`${this.entity}`).then(res => res.data);
  }

}
