import {Service} from '../../service/Service';

export class InvoiceInfoService extends Service {

  static getInvoiceInfo(_id: string): Promise<any> {
    return this.axios.get(`invoices/${_id}/invoice-info`).then(res => res.data);
  }

  static getXmlInfo(_id: string): Promise<any> {
    return this.axios.get(`upload_xml_data/${_id}`).then(res => res.data);
  }

}
