import {Fragment, useEffect, useRef, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {TabView, TabPanel} from 'primereact/tabview';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {InputNumber} from 'primereact/inputnumber';
import {Checkbox} from 'primereact/checkbox';
import {OverlayPanel} from 'primereact/overlaypanel';
import {FormatDisplay, ToastService, validateTaxCode} from '@iamsoftware/react-hooks';

import {ReportService} from '../ReportService';
import {InvoicesService} from '../../hoa-don/InvoicesService';

import {View} from '../../hoa-don/View';
import {InvoiceListPurchase} from './InvoiceListPurchase';
import {InvoiceListSold} from './InvoiceListSold';

export const Crup = ({display, setDisplay, account, reloadLazyData}) => {

  const op23 = useRef(null);
  const op24 = useRef(null);
  const op27 = useRef(null);
  const op28 = useRef(null);

  const [period, setPeriod] = useState(null);
  const [config, setConfig] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const [purchaseInvoices, setPurchaseInvoices] = useState(null);
  const [purchaseFromDate, setPurchaseFromDate] = useState(null);
  const [purchaseToDate, setPurchaseToDate] = useState(null);
  const [soldInvoices, setSoldInvoices] = useState(null);
  const [soldFromDate, setSoldFromDate] = useState(null);
  const [soldToDate, setSoldToDate] = useState(null);

  const [ct09, setct09] = useState('');
  const [ct10, setct10] = useState('');
  const [ct11c_tinhTP_ten, setct11c_tinhTP_ten] = useState('');
  const [ct11b_quanHuyen_ten, setct11b_quanHuyen_ten] = useState('');
  const [ct11a_phuongXa_ten, setct11a_phuongXa_ten] = useState('');

  const [ct21, setct21] = useState(false);
  const [ct22, setct22] = useState(0);
  const [ct23, setct23] = useState(0);
  const [ct23a, setct23a] = useState(0);
  const [ct24, setct24] = useState(0);
  const [ct24a, setct24a] = useState(0);
  const [ct25, setct25] = useState(0);
  const [ct26, setct26] = useState(0);
  const [ct27, setct27] = useState(0);
  const [ct28, setct28] = useState(0);
  const [ct29, setct29] = useState(0);
  const [ct30, setct30] = useState(0);
  const [ct31, setct31] = useState(0);
  const [ct32, setct32] = useState(0);
  const [ct32a, setct32a] = useState(0);
  const [ct33, setct33] = useState(0);
  const [ct34, setct34] = useState(0);
  const [ct35, setct35] = useState(0);
  const [ct36, setct36] = useState(0);
  const [ct37, setct37] = useState(0);
  const [ct38, setct38] = useState(0);
  const [ct39a, setct39a] = useState(0);
  const [ct40, setct40] = useState(0);
  const [ct40a, setct40a] = useState(0);
  const [ct40b, setct40b] = useState(0);
  const [ct41, setct41] = useState(0);
  const [ct42, setct42] = useState(0);
  const [ct43, setct43] = useState(0);

  const [dirty, setDirty] = useState({});

  const [ct23Check, setct23Check] = useState(null);
  const [ct24Check, setct24Check] = useState(null);
  const [ct27Check, setct27Check] = useState(null);
  const [ct28Check, setct28Check] = useState(null);

  const [purchaseTgtcthueByVat, setPurchaseTgtcthueByVat] = useState(null);
  const [purchaseTgtcthue, setPurchaseTgtcthue] = useState(null);

  const [purchaseTgtthueByVat, setPurchaseTgtthueByVat] = useState(null);
  const [purchaseTgtthue, setPurchaseTgtthue] = useState(null);

  const [soldTgtcthueByVat, setSoldTgtcthueByVat] = useState(null);
  const [soldTgtcthue, setSoldTgtcthue] = useState(null);

  const [soldTgtthueByVat, setSoldTgtthueByVat] = useState(null);
  const [soldTgtthue, setSoldTgtthue] = useState(null);

  const [tenNVienDLyThue, settenNVienDLyThue] = useState('');
  const [cchiHNghe, setcchiHNghe] = useState('');
  const [nguoiKy, setnguoiKy] = useState('');
  const [ngayKy, setngayKy] = useState(null);

  const [BangKeTenHHDV, setBangKeTenHHDV] = useState([]);
  const [tongCongGiaTriHHDV, settongCongGiaTriHHDV] = useState(0);
  const [tongCongThueGTGTDuocGiam, settongCongThueGTGTDuocGiam] = useState(0);

  const [invoice, setInvoice] = useState(null);
  const [displayView, setDisplayView] = useState(false);

  useEffect(() => {
    if (display && account) {
      if (display._id) {
        beUpdate();
      } else {
        let _ky, _year = display.year;
        if (display.kieuKy === 'M') {
          if (display.month === 1) {
            _ky = `Tháng 12`;
            _year--;
          } else {
            _ky = `Tháng ${display.month - 1}`;
          }
        } else {
          if (display.quarter === 1) {
            _ky = `Quý 4`;
            _year--;
          } else {
            _ky = `Quý ${display.quarter - 1}`;
          }
        }
        const _event = {
          filters: {
            account: {value: account._id, matchMode: 'ObjectId'},
            description: {value: `${_ky} năm ${_year}`, matchMode: 'startsWith'}
          },
          rows: 1
        };
        ReportService.getToKhaiThue01(JSON.stringify(_event)).then(({items}) => {
          let last_ct43 = 0;
          if (items?.length && items[0].data?.ct43) {
            last_ct43 = items[0].data?.ct43;
          }
          if (display.PL_NQ43_GTGT) {
            ReportService.getToKhaiThue01PhuLuc43({
              account: account._id,
              ...display
            }).then(data => {
              setBangKeTenHHDV(data.BangKeTenHHDV);
              settongCongGiaTriHHDV(data.tongCongGiaTriHHDV);
              settongCongThueGTGTDuocGiam(data.tongCongThueGTGTDuocGiam);
              beCreate(last_ct43);
            }).catch(() => {
              setDisplay(false);
            });
          } else {
            beCreate(last_ct43);
          }
        });
      }
    }
  }, [display, account]); // eslint-disable-line react-hooks/exhaustive-deps

  const beUpdate = () => {
    setDirty({});
    setConfig(display.config);
    setPeriod(display.period);
    setct09(display.data.ct09);
    setct10(display.data.ct10);
    setct11c_tinhTP_ten(display.data.ct11c_tinhTP_ten);
    setct11b_quanHuyen_ten(display.data.ct11b_quanHuyen_ten);
    setct11a_phuongXa_ten(display.data.ct11a_phuongXa_ten);
    setct21(display.data.ct21);
    setct22(display.data.ct22);
    setct23(display.data.ct23);
    setct23a(display.data.ct23a);
    setct24(display.data.ct24);
    setct24a(display.data.ct24a);
    setct25(display.data.ct25);
    setct26(display.data.ct26);
    setct27(display.data.ct27);
    setct28(display.data.ct28);
    setct29(display.data.ct29);
    setct30(display.data.ct30);
    setct31(display.data.ct31);
    setct32(display.data.ct32);
    setct32a(display.data.ct32a);
    setct33(display.data.ct33);
    setct34(display.data.ct34);
    setct35(display.data.ct35);
    setct36(display.data.ct36);
    setct37(display.data.ct37);
    setct38(display.data.ct38);
    setct39a(display.data.ct39a);
    setct40(display.data.ct40);
    setct40a(display.data.ct40a);
    setct40b(display.data.ct40b);
    setct41(display.data.ct41);
    setct42(display.data.ct42);
    setct43(display.data.ct43);
    settenNVienDLyThue(display.data.tenNVienDLyThue);
    setcchiHNghe(display.data.cchiHNghe);
    setnguoiKy(display.data.nguoiKy);
    if (display.data.ngayKy) {
      setngayKy(new Date(display.data.ngayKy));
    }

    setBangKeTenHHDV(display.data.BangKeTenHHDV || []);
    settongCongGiaTriHHDV(display.data.tongCongGiaTriHHDV);
    settongCongThueGTGTDuocGiam(display.data.tongCongThueGTGTDuocGiam);
  }

  const beCreate = last_ct43 => {
    setDirty({});
    const _config = {_id: account._id, ...(account.config?.to_khai_thue_01 || {})};
    setConfig(_config);
    setPeriod(display);
    settenNVienDLyThue(_config?.tenNVienDLyThue || '');
    setcchiHNghe(_config?.cchiHNghe || '');
    setnguoiKy(_config?.nguoiKy || '');
    setngayKy(new Date());

    setct22(last_ct43);

    ReportService.getToKhaiThue01Data('purchase', {
      account: account._id,
      ...display
    }).then(data => {
      setPurchaseInvoices(data?.invoices);
      setPurchaseFromDate(data?.from);
      setPurchaseToDate(data?.to);

      setct23(data?.tgtcthue);
      setct24(data?.tgtthue);

      setPurchaseTgtcthue(data.tgtcthue);
      setPurchaseTgtthue(data.tgtthue);
      let _purchaseTgtcthueByVat = 0;
      let _purchaseTgtthueByVat = 0;
      if (Array.isArray(data?.thttltsuat)) {
        for (const item of data.thttltsuat) {
          if (item.thtien) {
            item.thtien = Number.parseFloat(item.thtien);
          }
          if (item.tthue) {
            item.tthue = Number.parseFloat(item.tthue);
          }
          if (typeof item.thtien === 'number' && !Number.isNaN(item.thtien)) {
            _purchaseTgtcthueByVat += item.thtien;
          }
          if (typeof item.tthue === 'number' && !Number.isNaN(item.tthue)) {
            _purchaseTgtthueByVat += item.tthue;
          }
        }
      }
      setPurchaseTgtcthueByVat(_purchaseTgtcthueByVat);
      setPurchaseTgtthueByVat(_purchaseTgtthueByVat);

      setct23Check(data?.tgtcthue_check);
      setct24Check(data?.tgtthue_check);
    });
    ReportService.getToKhaiThue01Data('sold', {
      account: account._id,
      ...display
    }).then(data => {
      setSoldInvoices(data?.invoices);
      setSoldFromDate(data?.from);
      setSoldToDate(data?.to);

      let _ct29 = 0;
      let _ct30 = 0, _ct31 = 0;
      let _ct32 = 0, _ct33 = 0;
      let _ct32a = 0;
      if (Array.isArray(data?.thttltsuat)) {
        for (const item of data.thttltsuat) {
          if (item.thtien) {
            item.thtien = Number.parseFloat(item.thtien);
          }
          if (item.tthue) {
            item.tthue = Number.parseFloat(item.tthue);
          }
          switch (item.tsuat) {
            case '0%':
              if (typeof item.thtien === 'number' && !Number.isNaN(item.thtien)) {
                _ct29 += item.thtien;
              }
              break;
            case '5%':
              if (typeof item.thtien === 'number' && !Number.isNaN(item.thtien)) {
                _ct30 += item.thtien;
              }
              if (typeof item.tthue === 'number' && !Number.isNaN(item.tthue)) {
                _ct31 += item.tthue;
              }
              break;
            case '8%':
            case '10%':
              if (typeof item.thtien === 'number' && !Number.isNaN(item.thtien)) {
                _ct32 += item.thtien;
              }
              if (typeof item.tthue === 'number' && !Number.isNaN(item.tthue)) {
                _ct33 += item.tthue;
              }
              break;
            default:
              if (typeof item.thtien === 'number' && !Number.isNaN(item.thtien)) {
                _ct32a += item.thtien;
              }
          }
        }
      }
      setct29(Math.round(_ct29));
      setct30(Math.round(_ct30));
      setct31(Math.round(_ct31));
      setct32(Math.round(_ct32));
      setct33(Math.round(_ct33));
      setct32a(Math.round(_ct32a));

      setct27Check(data.tgtcthue_check);
      setct28Check(data.tgtthue_check);

      setSoldTgtcthue(data.tgtcthue);
      setSoldTgtcthueByVat(Math.round(_ct29) + Math.round(_ct30) + Math.round(_ct32) + Math.round(_ct32a));
      setct27(data.tgtcthue);

      setSoldTgtthue(data.tgtthue)
      setSoldTgtthueByVat(Math.round(_ct31) + Math.round(_ct33));
      setct28(data.tgtthue);
    });
  }

  useEffect(() => {
    if (!display?._id || dirty['ct29'] || dirty['ct30'] || dirty['ct32'] || dirty['ct32a']) {
      setct27((ct29 || 0) + (ct30 || 0) + (ct32 || 0) + (ct32a || 0));
    }
  }, [ct29, ct30, ct32, ct32a]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display?._id || dirty['ct31'] || dirty['ct33']) {
      setct28((ct31 || 0) + (ct33 || 0));
    }
  }, [ct31, ct33]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display?._id || dirty['ct26'] || dirty['ct27']) {
      setct34((ct26 || 0) + (ct27 || 0));
    }
  }, [ct26, ct27]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display?._id || dirty['ct28']) {
      setct35(ct28);
    }
  }, [ct28]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display?._id || dirty['ct35'] || dirty['ct25']) {
      setct36((ct35 || 0) - (ct25 || 0));
    }
  }, [ct35, ct25]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display?._id || dirty['ct36'] || dirty['ct22'] || dirty['ct37'] || dirty['ct38'] || dirty['ct39a']) {
      const _ct40a = (ct36 || 0) - (ct22 || 0) + (ct37 || 0) - (ct38 || 0) - (ct39a || 0);
      if (_ct40a > 0) {
        setct40a(_ct40a);
      } else {
        setct40a(0);
      }
    }
  }, [ct36, ct22, ct37, ct38, ct39a]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display?._id || dirty['ct40a'] || dirty['ct40b']) {
      const _ct40 = (ct40a || 0) - (ct40b || 0);
      if (_ct40 > 0) {
        setct40(_ct40);
      } else {
        setct40(0);
      }
    }
  }, [ct40a, ct40b]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display?._id || dirty['ct36'] || dirty['ct22'] || dirty['ct37'] || dirty['ct38'] || dirty['ct39a']) {
      const _ct41 = (ct36 || 0) - (ct22 || 0) + (ct37 || 0) - (ct38 || 0) - (ct39a || 0);
      if (_ct41 < 0) {
        setct41(Math.abs(_ct41));
      } else {
        setct41(0);
      }
    }
  }, [ct36, ct22, ct37, ct38, ct39a]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display?._id || dirty['ct41'] || dirty['ct42']) {
      setct43((ct41 || 0) - (ct42 || 0));
    }
  }, [ct41, ct42]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display?._id || dirty['ct24']) {
      setct25(ct24);
    }
  }, [ct24]); // eslint-disable-line react-hooks/exhaustive-deps

  const setValue = (field, value, fn) => {
    fn(value);
    setDirty(prevState => {
      prevState[field] = true;
      return prevState;
    });
  }

  const onSave = (downloadXml?: boolean) => {
    let valid = true;

    if (ct10) {
      if (ct10.length === 5) {
        let patt = /^[0-9][0-9][0-9][0-9][0-9]$/;
        if (patt.test(ct10) === false) {
          valid = false;
          ToastService.error(`Mã số địa điểm kinh doanh ${ct10} không hợp lệ`);
        }
      } else {
        if (!validateTaxCode(ct10)) {
          valid = false;
          ToastService.error(`Mã số thuế ${ct10} không hợp lệ`);
        }
      }
    }

    if (!submitting && valid) {
      setSubmitting(true);

      const data: any = {
        account: account._id,
        description: `${getKy()} năm ${period?.year} (dữ liệu ${display.filterDateBy === 'tdlap' ? 'ngày lập' : 'ngày kê khai'})`,
        config: config,
        data: {
          ct09,
          ct10,
          ct11c_tinhTP_ten,
          ct11b_quanHuyen_ten,
          ct11a_phuongXa_ten,
          ct21,
          ct22,
          ct23,
          ct23a,
          ct24,
          ct24a,
          ct25,
          ct26,
          ct27,
          ct28,
          ct29,
          ct30,
          ct31,
          ct32,
          ct32a,
          ct33,
          ct34,
          ct35,
          ct36,
          ct37,
          ct38,
          ct39a,
          ct40,
          ct40a,
          ct40b,
          ct41,
          ct42,
          ct43,
          tenNVienDLyThue,
          cchiHNghe,
          nguoiKy,
          ngayKy,
          BangKeTenHHDV, tongCongGiaTriHHDV, tongCongThueGTGTDuocGiam
        }
      };

      let promise;
      if (display._id) {
        promise = ReportService.updateToKhaiThue01(display._id, data);
      } else {
        data.period = period;
        promise = ReportService.createToKhaiThue01(data);
      }

      promise.then(item => {
        if (downloadXml) {
          doDownloadXml(item);
        }
        ToastService.success();
        setDisplay(false);
        reloadLazyData();
      }).finally(() => {
        setSubmitting(false);
      });
    }
  }

  const doDownloadXml = item => {
    ReportService.downloadToKhaiThue01Xml(item._id).then(data => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]));
      let time;
      switch (item.period.kieuKy) {
        case 'M':
          time = `M${item.period.month}`;
          break;
        case 'Q':
          time = `Q${item.period.quarter}`;
          break;
      }
      link.download = `HAN-${item.config.mst}-01_GTGT_TT80-${time}${item.period.year}-L00.xml`;
      link.click();
    });
  }

  const getKy = () => {
    if (period?.kieuKy === 'M') {
      return `Tháng ${period?.month}`;
    } else {
      return `Quý ${period?.quarter}`;
    }
  }

  const onItemChange = (index, field, value) => {
    const _BangKeTenHHDV = [...BangKeTenHHDV];
    _BangKeTenHHDV[index][field] = value;
    setBangKeTenHHDV(_BangKeTenHHDV);

    let _tongCongGiaTriHHDV = 0, _tongCongThueGTGTDuocGiam = 0;
    for (const item of _BangKeTenHHDV) {
      if (typeof item.giaTriHHDV === 'number' && !Number.isNaN(item.giaTriHHDV)) {
        _tongCongGiaTriHHDV += item.giaTriHHDV;
      }
      if (typeof item.thueGTGTDuocGiam === 'number' && !Number.isNaN(item.thueGTGTDuocGiam)) {
        _tongCongThueGTGTDuocGiam += item.thueGTGTDuocGiam;
      }
    }
    settongCongGiaTriHHDV(_tongCongGiaTriHHDV);
    settongCongThueGTGTDuocGiam(_tongCongThueGTGTDuocGiam);
  }

  const view = (msg: string, type: string) => {
    const _event = {
      filters: {
        _id: {value: msg.split('@IAM@')[0], matchMode: 'ObjectId'}
      }
    };
    InvoicesService.getList(account._id, type, JSON.stringify(_event)).then(data => {
      setInvoice(data.items[0]);
      setDisplayView(true);
    });
  }

  const footer = (
    <div>
      <Button label="Lưu" icon="pi pi-check" className="p-button-sm" onClick={() => onSave()} loading={submitting}/>
      <Button label="Lưu và tải Xml" icon="pi pi-check" className="p-button-success p-button-sm" onClick={() => onSave(true)} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => setDisplay(false)}/>
    </div>
  );

  return (
    <Fragment>
      <Dialog footer={footer} visible={!!display} style={{width: '80vw', minWidth: '80rem'}} position="top" closable={false} closeOnEscape={false} onHide={() => setDisplay(false)}>
        <TabView>
          <TabPanel header="Tờ khai">
            <div className="grid p-fluid">
              <div className="col-12 text-center">
                <h5 className="mb-0">TỜ KHAI THUẾ GIÁ TRỊ GIA TĂNG (Mẫu số 01/GTGT)</h5>
              </div>
              <div className="col-12 text-center pt-0">
                <i>(Áp dụng đối với người nộp thuế tính thuế theo phương pháp khấu trừ có hoạt động sản xuất kinh doanh)</i>
              </div>
              <div className="col-12">
                <b>[01a] Tên hoạt động sản xuất kinh doanh: Hoạt động sản xuất kinh doanh thông thường</b>
              </div>
              <div className="col-12">
                <b>[01b] Kỳ tính thuế: {getKy()} năm {period?.year}</b>
              </div>
              <div className="col-4">
                <b>[02] Lần đầu: X</b>
              </div>
              <div className="col-8">
                <b>[03] Bổ sung lần thứ: </b>
              </div>
              <div className="col-3">
                <b>[04] Tên người nộp thuế:</b>
              </div>
              <div className="col-9">
                <b>{config?.tenNNT}</b>
              </div>
              <div className="col-3">
                <b>[05] Mã số thuế:</b>
              </div>
              <div className="col-9">
                <b>{config?.mst}</b>
              </div>
              <div className="col-6">
                <b>[08] Hợp đồng đại lý thuế: Số {config?.soHDongDLyThue}</b>
              </div>
              <div className="col-3">
                <b>ngày {FormatDisplay.date(config?.ngayKyHDDLyThue, '')}</b>
              </div>
              <div className="col-12">
                <b>[09] Tên đơn vị phụ thuộc/địa điểm kinh doanh của hoạt động sản xuất kinh doanh khác tỉnh nơi đóng trụ sở chính:</b>
              </div>
              <div className="col-12">
                <InputText value={ct09} onChange={e => setct09(e.target.value)}/>
              </div>
              <div className="col-5 pt-3">
                <b>[10] Mã số thuế đơn vị phụ thuộc/Mã số địa điểm kinh doanh:</b>
              </div>
              <div className="col-4">
                <InputText value={ct10} onChange={e => setct10(e.target.value)} keyfilter="int" maxLength={14}/>
              </div>
              <div className="col-12">
                <b>[11] Địa chỉ nơi có hoạt động sản xuất kinh doanh khác tỉnh nơi đóng trụ sở chính:</b>
              </div>
              <div className="col-3 pt-3">
                <b>[11c] Tỉnh/Thành phố</b>
              </div>
              <div className="col-5">
                <InputText value={ct11c_tinhTP_ten} onChange={e => setct11c_tinhTP_ten(e.target.value)}/>
              </div>
              <div className="col-4"></div>
              <div className="col-3 pt-3">
                <b>[11b] Quận/Huyện</b>
              </div>
              <div className="col-5">
                <InputText value={ct11b_quanHuyen_ten} onChange={e => setct11b_quanHuyen_ten(e.target.value)}/>
              </div>
              <div className="col-4"></div>
              <div className="col-3 pt-3">
                <b>[11a] Phường/xã</b>
              </div>
              <div className="col-5">
                <InputText value={ct11a_phuongXa_ten} onChange={e => setct11a_phuongXa_ten(e.target.value)}/>
              </div>
              <div className="col-4"></div>
              <div className="col-12 text-right pb-0">
                <i>Đơn vị tiền tệ: Đồng Việt Nam</i>
              </div>
              <div className="col-12">
                <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid">
                  <div className="p-datatable-wrapper">
                    <table className="p-datatable-table">
                      <colgroup>
                        <col style={{width: '4%'}}/>
                        <col/>
                        <col style={{width: '4rem'}}/>
                        <col style={{width: '5rem'}}/>
                        <col style={{width: '18%'}}/>
                        <col style={{width: '5rem'}}/>
                        <col style={{width: '18%'}}/>
                      </colgroup>
                      <thead className="p-datatable-thead">
                      <tr role="row">
                        <th className="text-center">STT</th>
                        <th colSpan={2} className="text-center">Chỉ tiêu</th>
                        <th colSpan={2} className="text-center">Giá trị hàng hóa, dịch vụ <br/> (chưa có thuế giá trị gia tăng)</th>
                        <th colSpan={2} className="text-center">Thuế giá trị gia tăng</th>
                      </tr>
                      </thead>
                      <tbody className="p-datatable-tbody">
                      <tr role="row">
                        <td className="text-center"><b>A</b></td>
                        <td><b>Không phát sinh hoạt động mua, bán trong kỳ (đánh dấu "X")</b></td>
                        <td className="text-center"><b>[21]</b></td>
                        <td colSpan={2} className="text-center"><Checkbox checked={ct21} onChange={e => setct21(e.checked)}/></td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center"><b>B</b></td>
                        <td colSpan={4}><b>Thuế giá trị gia tăng còn được khấu trừ kỳ trước chuyển sang</b></td>
                        <td className="text-center"><b>[22]</b></td>
                        <td><InputNumber locale="vi-VN" value={ct22} onChange={e => setValue('ct22', e.value, setct22)} onValueChange={e => setValue('ct22', e.value, setct22)}/></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center"><b>C</b></td>
                        <td colSpan={6}><b>Kê khai thuế giá trị gia tăng phải nộp ngân sách nhà nước</b></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center"><b>I</b></td>
                        <td colSpan={6}><b>Hàng hoá, dịch vụ mua vào trong kỳ</b></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">1</td>
                        <td colSpan={2}>Giá trị và thuế giá trị gia tăng của hàng hóa, dịch vụ mua vào</td>
                        <td className="text-center">
                          {!!ct23Check?.length && <b className="alert-danger" onClick={e => op23.current.toggle(e)}>
														[23]
														<i className="pi pi-question-circle ml-1 pointer"/>
													</b>}
                          {!ct23Check?.length && <b className="alert-success">[23]</b>}

                          <OverlayPanel ref={op23}>
                            <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid mb-2">
                              <div className="p-datatable-wrapper">
                                <table className="p-datatable-table">
                                  <tbody className="p-datatable-tbody">
                                  <tr role="row">
                                    <td style={{borderTop: '1px solid #e9ecef'}}>Tổng tiền trước thuế cả hóa đơn</td>
                                    <td style={{borderTop: '1px solid #e9ecef'}} className="text-right">{FormatDisplay.number(purchaseTgtcthue)}</td>
                                  </tr>
                                  <tr role="row">
                                    <td>Tổng tiền trước thuế theo loại thuế suất</td>
                                    <td className="text-right">{FormatDisplay.number(purchaseTgtcthueByVat)}</td>
                                  </tr>
                                  <tr role="row">
                                    <td>Chênh lệch</td>
                                    <td className="text-right">{FormatDisplay.number(purchaseTgtcthue - purchaseTgtcthueByVat)}</td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <b>Các hoá đơn sai lệch:</b>
                            <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid mt-1">
                              <div className="p-datatable-wrapper">
                                <table className="p-datatable-table">
                                  <tbody className="p-datatable-tbody">
                                  {ct23Check?.map((c, index) => <tr key={index} role="row">
                                    <td>{c.split('@IAM@')[1]}</td>
                                    <td><i className="pi pi-eye alert-info pointer" onClick={() => view(c, 'purchase')}></i></td>
                                  </tr>)}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </OverlayPanel>
                        </td>
                        <td><InputNumber locale="vi-VN" value={ct23} onChange={e => setValue('ct23', e.value, setct23)} onValueChange={e => setValue('ct23', e.value, setct23)}/></td>
                        <td className="text-center">
                          {!!ct24Check?.length && <b className="alert-danger" onClick={e => op24.current.toggle(e)}>
														[24]
														<i className="pi pi-question-circle ml-1 pointer"/>
													</b>}
                          {!ct24Check?.length && <b className="alert-success">[24]</b>}

                          <OverlayPanel ref={op24}>
                            <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid mb-2">
                              <div className="p-datatable-wrapper">
                                <table className="p-datatable-table">
                                  <tbody className="p-datatable-tbody">
                                  <tr role="row">
                                    <td style={{borderTop: '1px solid #e9ecef'}}>Tổng tiền thuế cả hóa đơn</td>
                                    <td style={{borderTop: '1px solid #e9ecef'}} className="text-right">{FormatDisplay.number(purchaseTgtthue)}</td>
                                  </tr>
                                  <tr role="row">
                                    <td>Tổng tiền thuế theo loại thuế suất</td>
                                    <td className="text-right">{FormatDisplay.number(purchaseTgtthueByVat)}</td>
                                  </tr>
                                  <tr role="row">
                                    <td>Chênh lệch</td>
                                    <td className="text-right">{FormatDisplay.number(purchaseTgtthue - purchaseTgtthueByVat)}</td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <b>Các hoá đơn sai lệch:</b>
                            <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid mt-1">
                              <div className="p-datatable-wrapper">
                                <table className="p-datatable-table">
                                  <tbody className="p-datatable-tbody">
                                  {ct24Check?.map((c, index) => <tr key={index} role="row">
                                    <td>{c.split('@IAM@')[1]}</td>
                                    <td><i className="pi pi-eye alert-info pointer" onClick={() => view(c, 'purchase')}></i></td>
                                  </tr>)}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </OverlayPanel>
                        </td>
                        <td><InputNumber locale="vi-VN" value={ct24} onChange={e => setValue('ct24', e.value, setct24)} onValueChange={e => setValue('ct24', e.value, setct24)}/></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center"></td>
                        <td colSpan={2}>Trong đó: hàng hóa, dịch vụ nhập khẩu</td>
                        <td className="text-center">[23a]</td>
                        <td><InputNumber locale="vi-VN" value={ct23a} onChange={e => setValue('ct23a', e.value, setct23a)} onValueChange={e => setValue('ct23a', e.value, setct23a)}/></td>
                        <td className="text-center">[24a]</td>
                        <td><InputNumber locale="vi-VN" value={ct24a} onChange={e => setValue('ct24a', e.value, setct24a)} onValueChange={e => setValue('ct24a', e.value, setct24a)}/></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">2</td>
                        <td colSpan={4}>Thuế giá trị gia tăng của hàng hóa, dịch vụ mua vào được khấu trừ kỳ này</td>
                        <td className="text-center">[25]</td>
                        <td><InputNumber locale="vi-VN" value={ct25} onChange={e => setValue('ct25', e.value, setct25)} onValueChange={e => setValue('ct25', e.value, setct25)}/></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center"><b>II</b></td>
                        <td colSpan={6}><b>Hàng hoá, dịch vụ bán ra trong kỳ</b></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center"><b>1</b></td>
                        <td colSpan={2}><b>Hàng hóa, dịch vụ bán ra không chịu thuế giá trị gia tăng</b></td>
                        <td className="text-center"><b>[26]</b></td>
                        <td><InputNumber locale="vi-VN" value={ct26} onChange={e => setValue('ct26', e.value, setct26)} onValueChange={e => setValue('ct26', e.value, setct26)}/></td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center">2</td>
                        <td colSpan={2}><b>Hàng hóa, dịch vụ bán ra chịu thuế giá trị gia tăng <br/> ([27]=[29]+[30]+[32]+[32a]; [28]=[31]+[33])</b></td>
                        <td className="text-center">
                          {!!ct27Check?.length && <b className="alert-danger" onClick={e => op27.current.toggle(e)}>
														[27]
														<i className="pi pi-question-circle ml-1 pointer"/>
													</b>}
                          {!ct27Check?.length && <b className="alert-success">[27]</b>}

                          <OverlayPanel ref={op27}>
                            <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid mb-2">
                              <div className="p-datatable-wrapper">
                                <table className="p-datatable-table">
                                  <tbody className="p-datatable-tbody">
                                  <tr role="row">
                                    <td style={{borderTop: '1px solid #e9ecef'}}>Tổng tiền trước thuế cả hóa đơn</td>
                                    <td style={{borderTop: '1px solid #e9ecef'}} className="text-right">{FormatDisplay.number(soldTgtcthue)}</td>
                                  </tr>
                                  <tr role="row">
                                    <td>Tổng tiền trước thuế theo loại thuế suất</td>
                                    <td className="text-right">{FormatDisplay.number(soldTgtcthueByVat)}</td>
                                  </tr>
                                  <tr role="row">
                                    <td>Chênh lệch</td>
                                    <td className="text-right">{FormatDisplay.number(soldTgtcthue - soldTgtcthueByVat)}</td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <b>Các hoá đơn sai lệch:</b>
                            <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid mt-1">
                              <div className="p-datatable-wrapper">
                                <table className="p-datatable-table">
                                  <tbody className="p-datatable-tbody">
                                  {ct27Check?.map((c, index) => <tr key={index} role="row">
                                    <td>{c.split('@IAM@')[1]}</td>
                                    <td><i className="pi pi-eye alert-info pointer" onClick={() => view(c, 'sold')}></i></td>
                                  </tr>)}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </OverlayPanel>
                        </td>
                        <td><InputNumber locale="vi-VN" value={ct27} onChange={e => setValue('ct27', e.value, setct27)} onValueChange={e => setValue('ct27', e.value, setct27)}/></td>
                        <td className="text-center">
                          {!!ct28Check?.length && <b className="alert-danger" onClick={e => op28.current.toggle(e)}>
														[28]
														<i className="pi pi-question-circle ml-1 pointer"/>
													</b>}
                          {!ct28Check?.length && <b className="alert-success">[28]</b>}

                          <OverlayPanel ref={op28}>
                            <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid mb-2">
                              <div className="p-datatable-wrapper">
                                <table className="p-datatable-table">
                                  <tbody className="p-datatable-tbody">
                                  <tr role="row">
                                    <td style={{borderTop: '1px solid #e9ecef'}}>Tổng tiền thuế cả hóa đơn</td>
                                    <td style={{borderTop: '1px solid #e9ecef'}} className="text-right">{FormatDisplay.number(soldTgtthue)}</td>
                                  </tr>
                                  <tr role="row">
                                    <td>Tổng tiền thuế theo loại thuế suất</td>
                                    <td className="text-right">{FormatDisplay.number(soldTgtthueByVat)}</td>
                                  </tr>
                                  <tr role="row">
                                    <td>Chênh lệch</td>
                                    <td className="text-right">{FormatDisplay.number(soldTgtthue - soldTgtthueByVat)}</td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <b>Các hoá đơn sai lệch:</b>
                            <div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid mt-1">
                              <div className="p-datatable-wrapper">
                                <table className="p-datatable-table">
                                  <tbody className="p-datatable-tbody">
                                  {ct28Check?.map((c, index) => <tr key={index} role="row">
                                    <td>{c.split('@IAM@')[1]}</td>
                                    <td><i className="pi pi-eye alert-info pointer" onClick={() => view(c, 'sold')}></i></td>
                                  </tr>)}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </OverlayPanel>
                        </td>
                        <td><InputNumber locale="vi-VN" value={ct28} onChange={e => setValue('ct28', e.value, setct28)} onValueChange={e => setValue('ct28', e.value, setct28)}/></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">a</td>
                        <td colSpan={2}>Hàng hoá, dịch vụ bán ra chịu thuế suất 0%</td>
                        <td className="text-center">[29]</td>
                        <td><InputNumber locale="vi-VN" value={ct29} onChange={e => setValue('ct29', e.value, setct29)} onValueChange={e => setValue('ct29', e.value, setct29)}/></td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">b</td>
                        <td colSpan={2}>Hàng hoá, dịch vụ bán ra chịu thuế suất 5%</td>
                        <td className="text-center">[30]</td>
                        <td><InputNumber locale="vi-VN" value={ct30} onChange={e => setValue('ct30', e.value, setct30)} onValueChange={e => setValue('ct30', e.value, setct30)}/></td>
                        <td className="text-center">[31]</td>
                        <td><InputNumber locale="vi-VN" value={ct31} onChange={e => setValue('ct31', e.value, setct31)} onValueChange={e => setValue('ct31', e.value, setct31)}/></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">c</td>
                        <td colSpan={2}>Hàng hoá, dịch vụ bán ra chịu thuế suất 10%</td>
                        <td className="text-center">[32]</td>
                        <td><InputNumber locale="vi-VN" value={ct32} onChange={e => setValue('ct32', e.value, setct32)} onValueChange={e => setValue('ct32', e.value, setct32)}/></td>
                        <td className="text-center">[33]</td>
                        <td><InputNumber locale="vi-VN" value={ct33} onChange={e => setValue('ct33', e.value, setct33)} onValueChange={e => setValue('ct33', e.value, setct33)}/></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">d</td>
                        <td colSpan={2}>Hàng hoá, dịch vụ bán ra không tính thuế</td>
                        <td className="text-center">[32a]</td>
                        <td><InputNumber locale="vi-VN" value={ct32a} onChange={e => setValue('ct32a', e.value, setct32a)} onValueChange={e => setValue('ct32a', e.value, setct32a)}/></td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center"><b>3</b></td>
                        <td colSpan={2}><b>Tổng doanh thu và thuế giá trị gia tăng của hàng hóa, dịch vụ bán ra <br/> ([34]=[26]+[27]; [35]=[28])</b></td>
                        <td className="text-center"><b>[34]</b></td>
                        <td><InputNumber locale="vi-VN" value={ct34} onChange={e => setValue('ct34', e.value, setct34)} onValueChange={e => setValue('ct34', e.value, setct34)}/></td>
                        <td className="text-center"><b>[35]</b></td>
                        <td><InputNumber locale="vi-VN" value={ct35} onChange={e => setValue('ct35', e.value, setct35)} onValueChange={e => setValue('ct35', e.value, setct35)}/></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center"><b>III</b></td>
                        <td colSpan={4}><b>Thuế giá trị gia tăng phát sinh trong kỳ ([36]=[35]-[25])</b></td>
                        <td className="text-center"><b>[36]</b></td>
                        <td>
                          {ct36 < 0 &&
														<InputNumber locale="vi-VN" value={(-1 * ct36)} onChange={e => setValue('ct36', -1 * e.value, setct36)} onValueChange={e => setValue('ct36', -1 * e.value, setct36)} prefix="(" suffix=")"/>
                          }
                          {ct36 >= 0 &&
														<InputNumber locale="vi-VN" value={ct36} onChange={e => setValue('ct36', e.value, setct36)} onValueChange={e => setValue('ct36', e.value, setct36)}/>
                          }
                        </td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center"><b>IV</b></td>
                        <td colSpan={6}><b>Điều chỉnh tăng, giảm thuế giá trị gia tăng còn được khấu trừ của các kỳ trước</b></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">1</td>
                        <td colSpan={4}>Điều chỉnh giảm</td>
                        <td className="text-center">[37]</td>
                        <td><InputNumber locale="vi-VN" value={ct37} onChange={e => setValue('ct37', e.value, setct37)} onValueChange={e => setValue('ct37', e.value, setct37)}/></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">2</td>
                        <td colSpan={4}>Điều chỉnh tăng</td>
                        <td className="text-center">[38]</td>
                        <td><InputNumber locale="vi-VN" value={ct38} onChange={e => setValue('ct38', e.value, setct38)} onValueChange={e => setValue('ct38', e.value, setct38)}/></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center"><b>V</b></td>
                        <td colSpan={4}><b>Thuế giá trị gia tăng nhận bàn giao được khấu trừ trong kỳ</b></td>
                        <td className="text-center"><b>[39a]</b></td>
                        <td><InputNumber locale="vi-VN" value={ct39a} onChange={e => setValue('ct39a', e.value, setct39a)} onValueChange={e => setValue('ct39a', e.value, setct39a)}/></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center">VI</td>
                        <td colSpan={6}>Xác định nghĩa vụ thuế giá trị gia tăng phải nộp trong kỳ:</td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center">1</td>
                        <td colSpan={4}>Thuế giá trị gia tăng phải nộp của hoạt động sản xuất kinh doanh trong kỳ <br/> &#123;[40a]=([36]-[22]+[37]-[38]-[39a]) ≥ 0&#125;</td>
                        <td className="text-center">[40a]</td>
                        <td><InputNumber locale="vi-VN" value={ct40a} onChange={e => setValue('ct40a', e.value, setct40a)} onValueChange={e => setValue('ct40a', e.value, setct40a)}/></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">2</td>
                        <td colSpan={4}>Thuế giá trị gia tăng mua vào của dự án đầu tư được bù trừ với thuế GTGT còn phải nộp của hoạt động sản xuất kinh doanh cùng kỳ tính thuế ([40b]≤[40a])</td>
                        <td className="text-center">[40b]</td>
                        <td><InputNumber locale="vi-VN" value={ct40b} onChange={e => setValue('ct40b', e.value, setct40b)} onValueChange={e => setValue('ct40b', e.value, setct40b)}/></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center">3</td>
                        <td colSpan={4}>Thuế giá trị gia tăng còn phải nộp trong kỳ ([40]=[40a]-[40b])</td>
                        <td className="text-center">[40]</td>
                        <td><InputNumber locale="vi-VN" value={ct40} onChange={e => setValue('ct40', e.value, setct40)} onValueChange={e => setValue('ct40', e.value, setct40)}/></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center"><b>4</b></td>
                        <td colSpan={4}><b>Thuế giá trị gia tăng chưa khấu trừ hết kỳ này &#123;[41]=([36]-[22]+[37]-[38]-[39a]) ≤ 0&#125;</b></td>
                        <td className="text-center"><b>[41]</b></td>
                        <td><InputNumber locale="vi-VN" value={ct41} onChange={e => setValue('ct41', e.value, setct41)} onValueChange={e => setValue('ct41', e.value, setct41)}/></td>
                      </tr>
                      <tr role="row">
                        <td className="text-center">4.1</td>
                        <td colSpan={4}>Thuế giá trị gia tăng đề nghị hoàn ([42] ≤ [41])</td>
                        <td className="text-center">[42]</td>
                        <td><InputNumber locale="vi-VN" value={ct42} onChange={e => setValue('ct42', e.value, setct42)} onValueChange={e => setValue('ct42', e.value, setct42)}/></td>
                      </tr>
                      <tr role="row" style={{background: '#d4edda'}}>
                        <td className="text-center"><b>4.2</b></td>
                        <td colSpan={4}><b>Thuế giá trị gia tăng còn được khấu trừ chuyển kỳ sau ([43]=[41]-[42])</b></td>
                        <td className="text-center"><b>[43]</b></td>
                        <td><InputNumber locale="vi-VN" value={ct43} onChange={e => setValue('ct43', e.value, setct43)} onValueChange={e => setValue('ct43', e.value, setct43)}/></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <b>NHÂN VIÊN ĐẠI LÝ THUẾ</b>
              </div>
              <div className="col-3 pt-3">
                <b>Họ và tên:</b>
              </div>
              <div className="col-3">
                <InputText value={tenNVienDLyThue} onChange={e => settenNVienDLyThue(e.target.value)}/>
              </div>
              <div className="col-3 pt-3 text-center">
                <b>Người ký:</b>
              </div>
              <div className="col-3">
                <InputText value={nguoiKy} onChange={e => setnguoiKy(e.target.value)}/>
              </div>
              <div className="col-3 pt-3">
                <b>Chứng chỉ hành nghề số:</b>
              </div>
              <div className="col-3">
                <InputText value={cchiHNghe} onChange={e => setcchiHNghe(e.target.value)}/>
              </div>
              <div className="col-3 pt-3 text-center">
                <b>Ngày ký:</b>
              </div>
              <div className="col-3">
                <Calendar value={ngayKy} onChange={e => setngayKy(e.value)} dateFormat="dd/mm/yy"/>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="HĐ mua vào">
            <InvoiceListPurchase config={config} invoices={purchaseInvoices} from={purchaseFromDate} to={purchaseToDate}/>
          </TabPanel>
          <TabPanel header="HĐ bán ra">
            <InvoiceListSold config={config} invoices={soldInvoices} from={soldFromDate} to={soldToDate}/>
          </TabPanel>
          {period?.PL_NQ43_GTGT &&
						<TabPanel header="PL 101/2023/QH15">
							<div className="grid p-fluid">
								<div className="col-12 text-center">
									<h5 className="mb-0">Phụ lục</h5>
								</div>
								<div className="col-12 text-center">
									<h5 className="mb-0">GIẢM THUẾ GIÁ TRỊ GIA TĂNG THEO NGHỊ QUYẾT SỐ 101/2023/QH15</h5>
								</div>
								<div className="col-12 text-center pt-0">
									<i>(Kèm theo Tờ khai thuế GTGT kỳ tính thuế)</i>
								</div>
								<div className="col-3">
									<b>[01] Tên người nộp thuế:</b>
								</div>
								<div className="col-9">
									<b>{config?.tenNNT || ''}</b>
								</div>
								<div className="col-3">
									<b>[02] Mã số thuế:</b>
								</div>
								<div className="col-9">
									<b>{config?.mst || ''}</b>
								</div>
								<div className="col-3">
									<b>[03] Tên đại lý thuế (nếu có):</b>
								</div>
								<div className="col-9">
									<b>{config?.tenDLyThue || ''}</b>
								</div>
								<div className="col-3">
									<b>[04] Mã số thuế:</b>
								</div>
								<div className="col-9">
									<b>{config?.mstDLyThue || ''}</b>
								</div>
								<div className="col-12 text-right pb-0">
									<i>Đơn vị tiền tệ: Đồng Việt Nam</i>
								</div>
								<div className="col-12">
									<div className="p-datatable p-component p-datatable-gridlines p-datatable-sm p-fluid">
										<div className="p-datatable-wrapper">
											<table className="p-datatable-table">
												<colgroup>
													<col style={{width: '4%'}}/>
													<col/>
													<col style={{width: '15%'}}/>
													<col style={{width: '15%'}}/>
													<col style={{width: '15%'}}/>
													<col style={{width: '15%'}}/>
												</colgroup>
												<thead className="p-datatable-thead">
												<tr role="row">
													<th className="text-center">STT</th>
													<th className="text-center">Tên hàng hóa, dịch vụ</th>
													<th className="text-center">Giá trị hàng hóa, dịch vụ <br/> chưa có thuế giá trị gia tăng/Doanh thu hàng hóa, dịch vụ chịu thuế</th>
													<th className="text-center">Thuế suất/Tỷ lệ tính thuế GTGT theo quy định</th>
													<th className="text-center">Thuế suất/Tỷ lệ tính thuế GTGT sau giảm %</th>
													<th className="text-center">Thuế GTGT được giảm</th>
												</tr>
												<tr role="row">
													<th className="text-center">(1)</th>
													<th className="text-center">(2)</th>
													<th className="text-center">(3)</th>
													<th className="text-center">(4)</th>
													<th className="text-center">(5)=(4)x80%</th>
													<th className="text-center">(6)=(3)x[(4)-(5)]</th>
												</tr>
												</thead>
												<tbody className="p-datatable-tbody">
                        {BangKeTenHHDV.map((item, index) => <tr key={index} role="row">
                          <td className="text-center">{index + 1}</td>
                          <td>
                            <InputText value={item.tenHHDV} onChange={e => onItemChange(index, 'tenHHDV', e.target.value)}/>
                          </td>
                          <td className="text-right">
                            <InputNumber locale="vi-VN" value={item.giaTriHHDV} onChange={e => onItemChange(index, 'giaTriHHDV', e.value)} onValueChange={e => onItemChange(index, 'giaTriHHDV', e.value)}/>
                          </td>
                          <td className="text-right">{item.thueSuatTheoQuyDinh}</td>
                          <td className="text-right">{item.thueSuatSauGiam}</td>
                          <td className="text-right">
                            <InputNumber locale="vi-VN" value={item.thueGTGTDuocGiam} onChange={e => onItemChange(index, 'thueGTGTDuocGiam', e.value)} onValueChange={e => onItemChange(index, 'thueGTGTDuocGiam', e.value)}/>
                          </td>
                        </tr>)}
												</tbody>
												<tfoot className="p-datatable-tfoot">
												<tr role="row">
													<td></td>
													<td className="text-center">Tổng cộng</td>
													<td className="text-right">{FormatDisplay.number(tongCongGiaTriHHDV)}</td>
													<td></td>
													<td></td>
													<td className="text-right">{FormatDisplay.number(tongCongThueGTGTDuocGiam)}</td>
												</tr>
												</tfoot>
											</table>
										</div>
									</div>
								</div>
							</div>
						</TabPanel>
          }
        </TabView>
      </Dialog>

      <View display={displayView} setDisplay={setDisplayView} reloadLazyData={0} invoice={invoice}
            downloadXml={0} comment={0} assignTags={0} setDisplayAttachment={0}/>
    </Fragment>
  );
}