import {TabPanel, TabView} from 'primereact/tabview';

import {Config} from './Config';
import {Base} from './Base';

export const ExportExcelConfig = ({me}) => {

  return (
    <TabView>
      <TabPanel header="Cấu hình bảng kê">
        <Config me={me} type="Custom"/>
      </TabPanel>
      {me?.type === 'admin' && <TabPanel header="Dữ liệu mẫu">
				<Config me={me} type="Seed"/>
			</TabPanel>}
      {me?.type === 'admin' && <TabPanel header="Trường dữ liệu">
				<Base/>
			</TabPanel>}
    </TabView>
  );
}