import {useState} from 'react';

import {Button} from 'primereact/button';
import {FormatDisplay, useDataTable} from '@iamsoftware/react-hooks';

import {SyncInvoiceAssetsService as Service} from './SyncInvoiceAssetsService';

export const SyncInvoiceAssets = () => {

  const dataKey = '_id';

  const [info, setInfo] = useState(null);

  const getPending = () => {
    Service.getPending().then(data => {
      setInfo(data);
    });
  }

  const {render: renderDataTable, refreshLazyData} = useDataTable({
    dataKey,
    columns: [
      {
        field: 'user', header: 'User', minWidth: 100, dataType: 'custom', customCell(rowData): any {
          return rowData.user ? rowData.user.username : '';
        }
      },
      {
        field: 'account', header: 'Account', minWidth: 100, dataType: 'custom', customCell(rowData): any {
          return rowData.account ? rowData.account.username : '';
        }
      },
      {
        field: 'keys.khhdon', header: 'Ký hiệu', width: 100, dataType: 'custom', customCell(rowData): any {
          return rowData.keys ? `${rowData.keys.khmshdon}${rowData.keys.khhdon}` : '';
        }
      },
      {
        field: 'keys.shdon', header: 'Số hóa đơn', width: 120, dataType: 'custom', customCell(rowData): any {
          return <div>
            <span>{rowData.keys ? rowData.keys.shdon : ''}</span>
            <span style={{display: 'block', fontSize: '10px', marginTop: '5px', color: '#22C55E'}}>
              {(rowData['cloud'] && rowData['cloud'].google_pdf) &&
								<span>PDF</span>
              }
              {(rowData['cloud'] && rowData['cloud'].google_xml) &&
								<span style={{marginLeft: '3px'}}>XML</span>
              }
            </span>
          </div>
        }
      },
      {
        field: 'assets.detail.ran', header: 'Ran', width: 120, dataType: 'custom', customCell(rowData): any {
          return rowData.assets ? FormatDisplay.dateTime(rowData.assets.detail.ran) : '';
        }
      },
      {
        field: 'assets.detail.run_count', header: 'Run Count', width: 120, sortable: false, dataType: 'custom', customCell(rowData): any {
          return rowData.assets ? FormatDisplay.number(rowData.assets.detail.run_count) : '';
        }
      },
      {
        field: 'assets.detail.error_count', header: 'Error Count', width: 120, dataType: 'custom', customCell(rowData): any {
          return rowData.assets ? FormatDisplay.number(rowData.assets.detail.error_count) : '';
        }
      },
      {
        field: 'assets.detail.done', header: 'Done', width: 100, dataType: 'custom', customCell(rowData): any {
          return rowData.assets ? (rowData.assets.detail.done ? 'Y' : 'N') : '';
        }
      },
      // {
      //   field: 'assets.pdf.ran', header: 'Ran', width: 120, dataType: 'custom', customCell(rowData): any {
      //     return rowData.assets ? FormatDisplay.dateTime(rowData.assets.pdf.ran) : '';
      //   }
      // },
      // {
      //   field: 'assets.pdf.run_count', header: 'Run Count', width: 120, sortable: false, dataType: 'custom', customCell(rowData): any {
      //     return rowData.assets ? FormatDisplay.number(rowData.assets.pdf.run_count) : '';
      //   }
      // },
      // {
      //   field: 'assets.pdf.error_count', header: 'Error Count', width: 120, dataType: 'custom', customCell(rowData): any {
      //     return rowData.assets ? FormatDisplay.number(rowData.assets.pdf.error_count) : '';
      //   }
      // },
      // {
      //   field: 'assets.pdf.done', header: 'Done', width: 100, dataType: 'custom', customCell(rowData): any {
      //     return rowData.assets ? (rowData.assets.pdf.done ? 'Y' : 'N') : '';
      //   }
      // },
      // {
      //   field: 'assets.xml.ran', header: 'Ran', width: 120, dataType: 'custom', customCell(rowData): any {
      //     return rowData.assets ? FormatDisplay.dateTime(rowData.assets.xml.ran) : '';
      //   }
      // },
      // {
      //   field: 'assets.xml.run_count', header: 'Run Count', width: 120, sortable: false, dataType: 'custom', customCell(rowData): any {
      //     return rowData.assets ? FormatDisplay.number(rowData.assets.xml.run_count) : '';
      //   }
      // },
      // {
      //   field: 'assets.xml.error_count', header: 'Error Count', width: 120, dataType: 'custom', customCell(rowData): any {
      //     return rowData.assets ? FormatDisplay.number(rowData.assets.xml.error_count) : '';
      //   }
      // },
      // {
      //   field: 'assets.xml.done', header: 'Done', width: 100, dataType: 'custom', customCell(rowData): any {
      //     return rowData.assets ? (rowData.assets.xml.done ? 'Y' : 'N') : '';
      //   }
      // }
    ],
    getList: params => {
      return Service.getList(params);
    }
  });

  return (
    <div className="p-datatable-paginator-sticky">
      <div className="grid">
        <div className="col-4 flex gap-2">
          <Button label="Refresh" icon="pi pi-refresh" className="p-button-sm" onClick={refreshLazyData}/>
          <Button label="Refresh Pending" icon="pi pi-refresh" className="p-button-sm" onClick={getPending}/>
        </div>
      </div>
      {info?.estimatedRecords > 0 && <div>Pending: {info.pendingDetailSync} / {info.estimatedRecords}</div>}
      {renderDataTable()}
    </div>
  );
}