import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useForm, validateTaxCode} from '@iamsoftware/react-hooks';

import {InvoicesService as Service} from './InvoicesService';
import {ExternalAccountsService} from '../tai-khoan-thue/ExternalAccountsService';
import {TagsService} from './nhan-gan/TagsService';

import {Login} from '../tai-khoan-thue/Login';

import {View} from './View';
import {Attachment} from './Attachment';
import {Tags} from './Tags';
import {Comment} from './Comment';
import {SetDateOfInvoiceDeclaration} from './SetDateOfInvoiceDeclaration';
import {Relative} from './Relative';
import {DownloadXmlFail} from './DownloadXmlFail';
import {SearchHint} from './SearchHint';

export const List = ({type, mstFields, mstColumn}) => {

  const dataKey = '_id';

  const [maximize, setMaximize] = useState(false);

  const [accounts, setAccounts] = useState(null);

  const [allTags, setAllTags] = useState(null);
  const [allFilterTags, setAllFilterTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);

  const [batchType, setBatchType] = useState('');
  const [actionType, setActionType] = useState('');

  const [selectionMode, setSelectionMode] = useState(null);
  const [requiredParams, setRequiredParams] = useState(null);

  const [invoice, setInvoice] = useState(null);
  const [displayView, setDisplayView] = useState(false);
  const [displayAttachment, setDisplayAttachment] = useState(false);
  const [displayTags, setDisplayTags] = useState(null);
  const [displayComment, setDisplayComment] = useState(false);
  const [displaySetDateOfInvoiceDeclaration, setDisplaySetDateOfInvoiceDeclaration] = useState(null);
  const [displayRelative, setDisplayRelative] = useState(false);
  const [displayDownloadXmlFail, setDisplayDownloadXmlFail] = useState(false);
  const [downloadXmlFailItems, setDownloadXmlFailItems] = useState([]);

  const [displayLogin, setDisplayLogin] = useState(false);
  const [loginItem, setLoginItem] = useState(null);

  const [footerValue, setFooterValue] = useState<any>({});

  const [displaySearchHint, setDisplaySearchHint] = useState(null);

  useEffect(() => {
    ExternalAccountsService.getList('{"filters":{"visible":{"value":true,"matchMode":"equals"}}}').then(({items}) => {
      const _accounts = [];
      items.forEach(item => {
        _accounts.push({value: item._id, label: `${item.username} - ${item.profile?.name || ''}`});
      });
      setAccounts(_accounts);
    });
    loadTags();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accounts) {
      clear();
    }
  }, [accounts]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadTags = () => {
    TagsService.getList('{}').then(({items}) => {
      const _allTags = [];
      const _allFilterTags = [{value: 'isNull', label: 'Chưa gán nhãn'}];
      items.forEach(item => {
        _allTags.push(Object.assign(item, {value: item._id, label: item.name}));
        _allFilterTags.push({value: item._id, label: item.name});
      });
      setAllTags(_allTags);
      setAllFilterTags(_allFilterTags);
    });
  }

  const clear = () => {
    setValue({
      account: accounts[0]?.value
    });
  }

  const getSearchParams = (defaultValue?: any) => {
    const {nmmst, nmten, nbmst, nbten, tthai, ttxly, khmshdon, shdon, khhdon, tdlap, status, tags, kekhai} = defaultValue ? defaultValue : getValue();
    const searchParams: any = {};

    nmmst && (searchParams['data.nmmst'] = {value: nmmst, matchMode: 'equals'});
    if (nmten) {
      searchParams['nmten'] = {
        value: {
          $or: [
            {'data.nmten': {$regex: nmten.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), $options: 'si'}},
            {'header.nmtnmua': {$regex: nmten.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), $options: 'si'}}
          ]
        }, matchMode: 'assign'
      };
    }
    nbmst && (searchParams['keys.nbmst'] = {value: nbmst, matchMode: 'equals'});
    nbten && (searchParams['data.nbten'] = {value: nbten, matchMode: 'contains'});
    tthai && (searchParams['data.tthai'] = {value: tthai, matchMode: 'equals'});
    ttxly && (searchParams['data.ttxly'] = {value: ttxly, matchMode: 'equals'});
    khmshdon && (searchParams['keys.khmshdon'] = {value: khmshdon, matchMode: 'equals'});
    shdon && (searchParams['keys.shdon'] = {value: shdon, matchMode: 'equals'});
    khhdon && (searchParams['keys.khhdon'] = {value: khhdon, matchMode: 'contains'});
    status && (searchParams['warning.status'] = {value: status, matchMode: 'equals'});

    if (tags?.length && tags.find(it => it === 'isNull')) {
      searchParams['tags'] = {value: {tags: {$size: 0}}, matchMode: 'assign'};
    } else {
      searchParams['tags'] = {value: tags?.length ? tags : null, matchMode: 'in'};
    }

    tdlap?.length && (searchParams['data.tdlap'] = {value: `${tdlap[0]}@${tdlap[1]}`, matchMode: 'between'});

    // nkekhai
    switch (kekhai) {
      case 1:
        searchParams['data.nkekhai'] = {value: 1, matchMode: 'null'};
        break;
      case 2:
        searchParams['data.nkekhai'] = {value: 0, matchMode: 'null'};
        break;
    }

    return searchParams;
  }

  const validateSearchForm = () => {
    if (formValid()) {
      const {tdlap, shdon, nbmst, nmmst} = getValue();
      let valid = true;
      if (tdlap && tdlap.length === 2) {
        if (tdlap[0] > tdlap[1]) {
          valid = false;
          ToastService.error('Ngày lập hóa đơn không hợp lệ');
        }
      }
      if (shdon && `${shdon}`.length > 8) {
        valid = false;
        ToastService.error('Số hóa đơn không hợp lệ');
      }
      if (nbmst && !validateTaxCode(nbmst)) {
        valid = false;
        ToastService.error('MST người bán không hợp lệ');
      }
      if (nmmst && !validateTaxCode(nmmst)) {
        valid = false;
        ToastService.error('MST người mua không hợp lệ');
      }
      if (valid) {
        return getSearchParams();
      }
    }
  }

  const search = () => {
    const searchFormValue = validateSearchForm();
    if (searchFormValue) {
      setRequiredParams(searchFormValue);
    }
  }

  const checkTaxCode = () => {
    const searchFormValue = validateSearchForm();
    if (searchFormValue) {
      const {account} = getValue();
      const params = {account, type, filters: JSON.stringify(searchFormValue)};
      window.location.hash = '/hoa-don/kiem-tra-mst?' + new URLSearchParams(params).toString();
    }
  }

  const tagsTemplate = option => {
    if (option.value === 'isNull') {
      return <div className="alert-warning">{option.label}</div>;
    }
    return <div>{option.label}</div>;
  }

  const {render: renderForm, setValue, valid: formValid, getValue} = useForm({
    fields: [
      {field: 'account', header: `Tài khoản thuế (${accounts?.length} tài khoản)`, required: true, type: 'Dropdown', DropdownProps: {options: accounts, filter: true, resetFilterOnHide: true}, className: 'md:col-4'},
      ...mstFields,
      {field: 'tthai', header: 'Trạng thái hóa đơn', type: 'Dropdown', DropdownProps: {options: Service.tthais}, className: 'md:col-4'},
      {field: 'tdlap', header: 'Ngày lập hóa đơn', type: 'Period', className: 'md:col-2'},
      {field: 'status', header: 'Kết quả kiểm tra', type: 'Dropdown', DropdownProps: {options: Service.kqktras}, className: 'md:col-2'},
      {field: 'ttxly', header: 'Kết quả Tổng cục thuế', type: 'Dropdown', DropdownProps: {options: Service.ttxlys}, className: 'md:col-4', display: maximize},
      {field: 'khmshdon', header: 'Ký hiệu mẫu số hóa đơn', type: 'Dropdown', DropdownProps: {options: Service.khmshdons}, className: 'md:col-4', display: maximize},
      {field: 'shdon', header: 'Số hóa đơn', type: 'InputNumber', InputNumberProps: {min: 1, format: false}, className: 'md:col-2', display: maximize},
      {field: 'khhdon', header: 'Ký hiệu hóa đơn', InputTextProps: {maxLength: 6}, className: 'md:col-2', display: maximize},
      {field: 'tags', header: 'Nhãn hóa đơn', type: 'MultiSelect', MultiSelectProps: {options: allFilterTags, itemTemplate: tagsTemplate}, className: 'md:col-2', display: maximize},
      {field: 'kekhai', header: 'Kê khai', type: 'Dropdown', DropdownProps: {options: kekhaiOptions}, className: 'md:col-2', display: maximize},
      {
        type: 'custom', className: 'md:col-4', body: (
          <Fragment>
            <label>&nbsp;</label>
            <div className="un-fluid flex gap-2">
              <Button icon="pi pi-search" label="Tìm kiếm" className="p-button-outlined p-button-sm" onClick={search}/>
              <Button icon="pi pi-times" label="Bỏ tìm kiếm" className="p-button-outlined p-button-secondary p-button-sm" onClick={clear}/>
              {!maximize && <Button icon="pi pi-window-maximize" label="Thêm điều kiện" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => setMaximize(true)}/>}
              {maximize && <Button icon="pi pi-window-minimize" label="Thu gọn" className="p-button-outlined p-button-secondary p-button-sm" onClick={() => setMaximize(false)}/>}
            </div>
          </Fragment>
        )
      }
    ]
  });

  const {account, tags} = getValue();

  useEffect(() => {
    if (account) {
      search();
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tags?.length) {
      if (tags.find(it => it === 'isNull')) {
        setAllFilterTags(prevState => {
          prevState.forEach(it => {
            it.disabled = it.value !== 'isNull';
          });
          return [...prevState];
        });
      } else {
        setAllFilterTags(prevState => {
          prevState.forEach(it => {
            it.disabled = it.value === 'isNull';
          });
          return [...prevState];
        });
      }
    } else if (allFilterTags) {
      setAllFilterTags(prevState => {
        prevState.forEach(it => {
          it.disabled = false;
        });
        return [...prevState];
      });
    }
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tags?.length && tags?.length === allFilterTags?.length) {
      const _value = getValue();
      _value.tags = tags.filter(it => it !== 'isNull');
      setValue(_value);
    }
  }, [tags, allFilterTags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedTag) {
      const _value = getValue();
      _value.tags = [selectedTag];
      setValue(_value);

      const searchFormValue = validateSearchForm();
      if (searchFormValue) {
        searchFormValue['tags'] = {value: [selectedTag], matchMode: 'in'};
        setRequiredParams(searchFormValue);
      }
    }
  }, [selectedTag]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, reloadLazyData, selectedItems, setSelectedItems} = useDataTable({
    stateKey: 'mainTable',
    tableHeader: () => {
      return (
        <div className="flex justify-content-between">
          <div></div>
          <div className="flex gap-2">
            {batchType === '' && <Button label="Kiểm tra tình trạng DN" className="p-button-outlined p-button-sm" onClick={checkTaxCode}/>}
            {['', 'xml'].includes(batchType) && <Button label="Tải nhiều Xml" className="p-button-outlined p-button-sm" onClick={() => batchDownload('xml')}/>}
            {['', 'pdf'].includes(batchType) && <Button label="Tải file xem in" className="p-button-outlined p-button-sm" onClick={() => batchDownload('pdf')}/>}
            {['', 'html'].includes(batchType) && <Button label="In nhiều" className="p-button-outlined p-button-sm" onClick={() => batchDownload('html')}/>}
            {['', 'InsertTags'].includes(batchType) && <Button label="Gán nhãn" className="p-button-outlined p-button-sm" onClick={() => batchDownload('InsertTags')}/>}
            {['InsertTags'].includes(batchType) && <Button label="Hủy gán nhãn" className="p-button-outlined p-button-danger p-button-sm" onClick={() => batchDownload('PullTags')}/>}
            {['', 'SetDateOfInvoiceDeclaration'].includes(batchType) && <Button label="Gán kê khai" className="p-button-outlined p-button-sm" onClick={() => batchDownload('SetDateOfInvoiceDeclaration')}/>}
            {['SetDateOfInvoiceDeclaration'].includes(batchType) && <Button label="Hủy kê khai" className="p-button-outlined p-button-danger p-button-sm" onClick={() => batchDownload('UnsetDateOfInvoiceDeclaration')}/>}
            {['', 'PatchHideStatus'].includes(batchType) && <Button label="Ẩn cảnh báo" className="p-button-outlined p-button-sm" onClick={() => batchDownload('PatchHideStatus')}/>}
            {batchType !== '' && <Button label="Huỷ bỏ" className="p-button-outlined p-button-secondary p-button-sm" onClick={cancelDownload}/>}
          </div>
        </div>
      );
    },
    dataKey,
    selectionMode,
    columns: [
      {
        field: 'data.nkekhai', header: 'Ngày kê khai', width: 125, dataType: 'custom', customCell(rowData): any {
          return <span title={`${rowData.data?.nkekhai_label} | ${rowData.data?.nkekhai_config_name}`}>{FormatDisplay.date(rowData.data?.nkekhai || '')}</span>;
        }
      },
      {
        field: 'keys.khhdon', header: 'Ký hiệu', width: 100, dataType: 'custom', customCell(rowData, skipFormat: boolean): any {
          if (skipFormat === true) {
            return `${rowData.keys.khmshdon}${rowData.keys.khhdon}`;
          }
          return <div>
            <span>{`${rowData.keys.khmshdon}${rowData.keys.khhdon}`}</span>
            <span style={{display: 'block', fontSize: '12px', marginTop: '5px', color: '#2196F3', textDecoration: 'underline'}} onClick={() => setDisplaySearchHint(rowData)}>
              <i className="pi pi-search" title="Gợi ý tra cứu"></i>Tra cứu HĐ
            </span>
          </div>;
        }
      },
      {
        field: 'keys.shdon', header: 'Số hóa đơn', width: 120, dataType: 'custom', customCell(rowData): any {
          let tthai = rowData.header.tthai;
          Service.tthais.forEach(t => {
            if (t.value === tthai) {
              tthai = t.label;
            }
          });
          return <div>
            <p className="mb-0">{rowData.keys ? rowData.keys.shdon : ''}</p>
            <p className={`mb-0 tthai-${rowData.header.tthai}`} onClick={() => showRelative(rowData)}>{tthai}</p>
          </div>
        }
      },
      {
        field: 'data.tdlap', header: 'Ngày lập', width: 100, dataType: 'custom', customCell(rowData): any {
          return rowData.data ? FormatDisplay.date(rowData.data.tdlap) : '';
        }
      },
      {
        field: 'header.nky', header: 'Ngày ký', width: 100, dataType: 'custom', customCell(rowData): any {
          return rowData.header ? FormatDisplay.date(rowData.header.nky) : '';
        }
      },
      mstColumn,
      {
        field: 'header.tgtcthue', header: 'Tổng tiền chưa thuế', width: 160, sortable: false, dataType: 'custom', customCell(rowData): any {
          return rowData.header ? FormatDisplay.number(rowData.header.tgtcthue) : '';
        }, style: {textAlign: 'right'}
      },
      {
        field: 'header.tgtthue', header: 'Tổng tiền thuế', width: 140, sortable: false, dataType: 'custom', customCell(rowData): any {
          return rowData.header ? FormatDisplay.number(rowData.header.tgtthue) : '';
        }, style: {textAlign: 'right'}
      },
      {
        field: 'header.tgtttbso', header: 'Tổng tiền thanh toán', width: 160, sortable: false, dataType: 'custom', customCell(rowData): any {
          return rowData.header ? FormatDisplay.number(rowData.header.tgtttbso) : '';
        }, style: {textAlign: 'right'}
      },
      {
        field: 'header.dvtte', header: 'Tiền tệ', width: 70, sortable: false, dataType: 'custom', customCell(rowData): any {
          let dvtte = rowData.header?.dvtte;
          if (!dvtte || ['vnd', 'vnđ', 'VNĐ',].includes(dvtte)) {
            dvtte = 'VND';
          }
          return dvtte;
        }
      },
      {
        field: 'warning.status', header: 'KQ kiểm tra', width: 130, dataType: 'custom', customCell(rowData): any {
          let title = '';
          if (rowData.warning?.warning_array) {
            title = rowData.warning?.warning_array.join(' - ');
          }
          return <div className={rowData.warning?.status === 'Cảnh báo' ? 'alert alert-danger' : 'alert alert-info'} title={title}>{rowData.warning ? rowData.warning.status : ''}</div>
        }
      },
      {
        field: 'warning.warning_array', header: 'Cảnh báo', width: 160, dataType: 'custom', customCell(rowData): any {
          return rowData.warning?.warning_array?.map((warning, index) => <p key={index} className="mb-0 text-orange-500">- {warning}</p>);
        }
      },
      {field: 'comment', header: 'Ghi chú', width: 130},
      {
        field: 'tags', header: 'Nhãn', width: 160, sortable: false, dataType: 'custom', customCell(rowData): any {
          if (rowData.tags && rowData.tags.length) {
            let _tags = [];
            allTags?.forEach(tag => {
              rowData.tags.forEach(t => {
                if (t === tag._id) {
                  _tags.push(tag);
                }
              });
            });
            return _tags.map(tag => <p key={tag._id} className="mb-0" onClick={() => setSelectedTag(tag._id)}>{tag.name}</p>);
          } else {
            return '';
          }
        }, className: `${allTags?.length}`
      }
    ],
    indexColumnWidth: 50,
    actionColumnWidth: 110,
    getList: params => {
      const {account} = getValue();
      if (account) {
        return Service.getList(account, type, params).then(result => {
          if (result?.aggs?.length) {
            setFooterValue(result.aggs[0]);
          } else {
            setFooterValue({});
          }
          return result;
        });
      } else {
        return new Promise(resolve => {
          resolve({items: [], totalRecords: 0});
        });
      }
    },
    requiredParams,
    initActions: items => {
      if (items) {
        items.forEach(item => {
          if (item.type !== 'admin') {
            item.actions = [
              {icon: 'pi pi-eye', title: 'Xem', command: () => view(item)},
              {icon: 'pi pi-print', title: 'In', className: 'p-button-success', command: () => print(item)}
            ];
            item.actionMenus = [
              {label: 'Tải Xml', icon: 'pi pi-download', command: () => downloadXml(item)},
              {label: 'Xem Xml', icon: 'pi pi-external-link', command: () => viewXml(item)},
              {separator: true},
              {label: 'Ghi chú', icon: 'pi pi-comment', command: () => comment(item)},
              {label: 'Gán nhãn', icon: 'pi pi-tags', command: () => assignTags(item)},
              {label: 'Gán kê khai', icon: 'pi pi-clock', command: () => setDisplaySetDateOfInvoiceDeclaration(item)},
              {label: 'Hủy kê khai', icon: 'pi pi-times', command: () => unsetDateOfInvoiceDeclaration([item])},
              {separator: true},
              {label: 'File đính kèm', icon: 'pi pi-paperclip', command: () => attachment(item)}
            ];
          }
        });
      }
    },
    originalProps: {
      footerColumnGroup: <ColumnGroup>
        <Row>
          <Column colSpan={7}/>
          <Column footer={FormatDisplay.number(footerValue.tgtcthue)} footerStyle={{textAlign: 'right', fontSize: '12px'}}/>
          <Column footer={FormatDisplay.number(footerValue.tgtthue)} footerStyle={{textAlign: 'right', fontSize: '12px'}}/>
          <Column footer={FormatDisplay.number(footerValue.tgtttbso)} footerStyle={{textAlign: 'right', fontSize: '12px'}}/>
          <Column colSpan={4}/>
        </Row>
      </ColumnGroup>
    }
  });

  const cancelDownload = () => {
    setSelectionMode(null);
    setSelectedItems(null);
    setBatchType('');
  }

  const batchDownload = _batchType => {
    setActionType(_batchType);

    if (selectionMode !== 'checkbox') {
      setSelectedItems(null);
      setSelectionMode('checkbox');
      setBatchType(_batchType);
    } else {
      if (selectedItems && selectedItems.length) {
        switch (_batchType) {
          case 'InsertTags':
            setDisplayTags(selectedItems);
            break;
          case 'PullTags':
            setBatchType(_batchType);
            setDisplayTags(selectedItems);
            break;
          case 'SetDateOfInvoiceDeclaration':
            setDisplaySetDateOfInvoiceDeclaration(selectedItems);
            break;
          case 'UnsetDateOfInvoiceDeclaration':
            unsetDateOfInvoiceDeclaration(selectedItems);
            break;
          case 'PatchHideStatus':
            patchHideStatus(selectedItems);
            break;
          default:
            ExternalAccountsService.checkAlive(account).then(() => {

              if (_batchType === 'xml' && selectedItems.filter(it => !it.header?.nky).length) {
                setDownloadXmlFailItems(selectedItems.filter(it => !it.header?.nky));
                setDisplayDownloadXmlFail(true);
              }

              window.open(`${Service.baseURL}/${Service.entity}/batch-download/${_batchType}?ids=${selectedItems.map(item => item._id).join(',')}&type=${type}`);

            }).catch(() => {
              setLoginItem({_id: account});
              setDisplayLogin(true);
            });
        }
        setSelectionMode(null);
        setBatchType('');
      } else {
        ToastService.error('Bạn chưa chọn hoá đơn');
      }
    }
  }

  const view = item => {
    ExternalAccountsService.checkAlive(item.account).then(() => {
      showInvoice(item);
    }).catch(() => {
      setLoginItem({_id: item.account});
      setDisplayLogin(true);
    });
  }

  const showInvoice = item => {
    setInvoice(item);
    setDisplayView(true);
  }

  const print = item => {
    ExternalAccountsService.checkAlive(item.account).then(() => {
      window.open(`${Service.baseURL}/${Service.entity}/${item._id}/download/invoice.html`);
    }).catch(() => {
      setLoginItem({_id: item.account});
      setDisplayLogin(true);
    });
  }

  const viewXml = item => {
    Service.downloadXml(item._id).then(data => {
      window.open(window.URL.createObjectURL(new Blob([data], {type: 'application/xml'})));
    });
  }

  const comment = item => {
    setDisplayComment(item);
  }

  const assignTags = item => {
    setDisplayTags(item);
  }

  const downloadXml = item => {
    Service.downloadXml(item._id).then(async data => {
      try {
        const text = await data.text();
        const result = JSON.parse(text);
        if (result.status === 401 && result.account) {
          setLoginItem({_id: result.account});
          setDisplayLogin(true);
          return;
        }
      } catch (e) {
      }
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]));
      link.download = `${FormatDisplay.date(item.data.tdlap, 'YYYY-MM-DD')}_${item.keys.nbmst}_${item.data.nmmst || '0'}_${item.keys.khmshdon}${item.keys.khhdon}_${item.keys.shdon}.xml`;
      link.click();
    }).catch(({response}) => {
      if (response && response.data) {
        try {
          response.data.text().then(text => {
            const result = JSON.parse(text);
            if (result.status === 401 && result.account) {
              setLoginItem({_id: result.account});
              setDisplayLogin(true);
            } else if (result.message) {
              ToastService.error(result.message);
            }
          });
        } catch (e) {
        }
      }
    });
  }

  const attachment = item => {
    setInvoice(item);
    setDisplayAttachment(item);
  }

  const unsetDateOfInvoiceDeclaration = items => {
    if (items?.length) {
      ConfirmDialogService.confirm('Huỷ kê khai', `Xác nhận huỷ kê khai của ${items?.length} hoá đơn đã chọn`, () => {
        const complete = [];
        items.forEach(item => {
          complete.push(Service.patchNkekhai(item._id, {
            nkekhai: '',
            nkekhai_field: '',
            nkekhai_label: ''
          }));
        });

        Promise.all(complete).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      });
    }
  }

  const patchHideStatus = items => {
    if (items?.length) {
      ConfirmDialogService.confirm('Ẩn cảnh báo', `Xác nhận ẩn cảnh báo của ${items?.length} hoá đơn đã chọn`, () => {
        const complete = [];
        items.forEach(item => {
          complete.push(Service.patchHideStatus(item._id, true));
        });

        Promise.all(complete).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      });
    }
  }

  const showRelative = rowData => {
    if ([2, 3, 4, 5, 6].includes(rowData.header.tthai)) {
      setDisplayRelative(rowData);
    }
  }

  return (
    <div className="p-datatable-paginator-sticky">
      {renderForm()}
      <div className="grid">
        <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 21rem)'}}>
          {renderDataTable()}
        </div>
      </div>
      <View display={displayView} setDisplay={setDisplayView} reloadLazyData={reloadLazyData} invoice={invoice}
            downloadXml={downloadXml} comment={comment} assignTags={assignTags} setDisplayAttachment={setDisplayAttachment}/>
      <Attachment display={displayAttachment} setDisplay={setDisplayAttachment}/>
      <Tags display={displayTags} setDisplay={setDisplayTags} tags={allTags} loadTags={loadTags} reloadLazyData={reloadLazyData} actionType={actionType}/>
      <Comment display={displayComment} setDisplay={setDisplayComment} reloadLazyData={reloadLazyData}/>
      <SetDateOfInvoiceDeclaration display={displaySetDateOfInvoiceDeclaration} setDisplay={setDisplaySetDateOfInvoiceDeclaration} account={account} reloadLazyData={reloadLazyData}/>

      <Login display={displayLogin} setDisplay={setDisplayLogin} account={loginItem} reloadLazyData={reloadLazyData}/>

      <Relative account={account} type={type} mstColumn={mstColumn} allTags={allTags}
                display={displayRelative} setDisplay={setDisplayRelative} showInvoice={showInvoice}/>

      <DownloadXmlFail display={displayDownloadXmlFail} setDisplay={setDisplayDownloadXmlFail} mstColumn={mstColumn} items={downloadXmlFailItems}/>

      <SearchHint display={displaySearchHint} setDisplay={setDisplaySearchHint}/>
    </div>
  );
}

const kekhaiOptions = [
  {value: '', label: 'Tất cả'},
  {value: 1, label: 'Chưa kê khai'},
  {value: 2, label: 'Đã kê khai'}
]