import {Service} from '../../../service/Service';

export class TaxCodeInfoService extends Service {

  static entity = 'report/account';

  static getTaxCodes(account: string, lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/${account}/tax-codes`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static getOverview(account: string): Promise<any> {
    return this.axios.get(`${this.entity}/${account}/tax-codes/overview`).then(res => res.data);
  }

  static getSmiOverview(account: string): Promise<any> {
    return this.axios.get(`${this.entity}/${account}/tax-codes/smi-overview`).then(res => res.data);
  }

  static syncTaxCodes(account: string): Promise<any> {
    return this.axios.post(`${this.entity}/${account}/tax-codes/sync`).then(res => res.data);
  }

}
