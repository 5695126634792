import {Route, Routes} from 'react-router-dom';

import {BanRa} from './ban-ra';
import {MuaVao} from './mua-vao';
import {KiemTraMst} from './kiem-tra-mst';
import {NhanGan} from './nhan-gan';
import {Hdtbss} from './tbss';

export default function HoaDon() {

  return (
    <Routes>
      <Route path="ban-ra" element={<BanRa/>}/>
      <Route path="mua-vao" element={<MuaVao/>}/>
      <Route path="kiem-tra-mst" element={<KiemTraMst/>}/>
      <Route path="nhan-gan" element={<NhanGan/>}/>
      <Route path="tbss" element={<Hdtbss/>}/>
    </Routes>
  );
}
